import { UUID } from "@cp/toolkit";
import { Slot } from "@radix-ui/react-slot";
import { isEqual } from "lodash";
import { useNavigate, useParams } from "react-router-dom";

import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Icon } from "@/components/ui/icon";
import { useInsured } from "@/hooks/use-insured";
import { useCreateOpportunityMutation } from "src/generated/graphql";

export const CreateMarketingPlan = ({ children }: React.PropsWithChildren) => {
  const { opportunities, verticalByCGL } = useInsured();

  const { marketingPlanTemplates } = verticalByCGL || {};

  const validMarketingPlanTemplates =
    marketingPlanTemplates?.filter(
      (template) => !opportunities.some((opportunity) => isEqual(opportunity.selectedLinesOfBusiness, template.lobs))
    ) || [];

  if (validMarketingPlanTemplates[0]) {
    return (
      <Dialog>
        <DialogTrigger asChild>{children}</DialogTrigger>
        <DialogContent className="divide-y gap-0 p-0">
          <DialogHeader className="bg-accent p-6 space-y-0">
            <DialogTitle>Create Marketing Plan</DialogTitle>
            <DialogDescription className="hidden">Create Marketing Plan</DialogDescription>
          </DialogHeader>
          <DialogButton>Custom Marketing Plan</DialogButton>
          {validMarketingPlanTemplates.map((template) => (
            <DialogButton key={template.id} selectedLinesOfBusiness={template.lobs}>
              {template.lobs.join(", ")}
            </DialogButton>
          ))}
        </DialogContent>
      </Dialog>
    );
  }

  return <CreateMarketingPlanButton asChild>{children}</CreateMarketingPlanButton>;
};

type CreateMarketingPlanButtonProps = React.HTMLAttributes<HTMLButtonElement> & {
  selectedLinesOfBusiness?: string[];
  asChild?: boolean;
};

const CreateMarketingPlanButton = ({
  className,
  selectedLinesOfBusiness,
  asChild = false,
  ...props
}: CreateMarketingPlanButtonProps) => {
  const navigate = useNavigate();
  const { insuredId } = useParams();

  const [createOpportunity] = useCreateOpportunityMutation({
    onCompleted: (data) => navigate(`/insured/${insuredId}/plans/${data.createOpportunity.id}`),
  });

  const Comp = asChild ? Slot : "button";

  return (
    <Comp
      className={className}
      onClick={() => {
        void createOpportunity({
          variables: {
            input: {
              insuredId: insuredId as UUID,
              desiredEffectiveDate: new Date(),
              selectedLinesOfBusiness,
            },
          },
          onCompleted: (data) => {
            navigate(`/insured/${insuredId}/plans/${data.createOpportunity.id}`);
          },
          refetchQueries: ["Opportunities"],
        });
      }}
      {...props}
    />
  );
};

const DialogButton = ({ children, ...props }: CreateMarketingPlanButtonProps) => (
  <CreateMarketingPlanButton {...props} asChild>
    <DialogClose className="cursor-pointer flex h-12 items-center justify-between px-6 text-sm">
      {children}
      <Icon icon="add_circle" className="filled" />
    </DialogClose>
  </CreateMarketingPlanButton>
);

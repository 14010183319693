import { Button } from "@/components/ui/button";
import { Icon } from "@/components/ui/icon";
import { FileUploadFragment } from "src/generated/graphql";

export const ViewPdfNav = ({
  files,
  selectedFile,
  setSelectedFile,
}: {
  files: FileUploadFragment[];
  selectedFile: FileUploadFragment;
  setSelectedFile: (file?: FileUploadFragment) => void;
}) => {
  if (!files) {
    return null;
  }

  const index = files.findIndex((file) => file.id === selectedFile?.id);

  const getNextFile = () => {
    if (!index && index !== 0) {
      return;
    }
    setSelectedFile(files[index + 1]);
  };

  const getPreviousFile = () => {
    if (!index) {
      return;
    }
    setSelectedFile(files[index - 1]);
  };

  return (
    <nav className="absolute bg-background bottom-2 flex gap-2 items-center left-1/2 p rounded-full shadow-xl -translate-x-1/2">
      <Button
        variant="outline"
        size="sm"
        display="icon"
        className="rounded-full"
        onClick={getPreviousFile}
        disabled={index === 0}
      >
        <Icon icon="chevron_left" />
      </Button>
      <Button
        variant="ghost"
        size="sm"
        display="icon"
        className="rounded-full"
        onClick={() => setSelectedFile(undefined)}
      >
        <Icon icon="close_fullscreen" />
      </Button>
      <Button
        variant="outline"
        size="sm"
        display="icon"
        className="rounded-full"
        onClick={getNextFile}
        disabled={index === files.length - 1}
      >
        <Icon icon="chevron_right" />
      </Button>
    </nav>
  );
};

import { createContext, useContext } from "react";
import { useParams } from "react-router";
import { AlbyToolCallFragment, useResumableGraphsByBrokerQuery } from "src/generated/graphql";
import { useAccount } from "./use-account";

interface AlbyContextValue {
  brokerId?: string;
  graphs: AlbyToolCallFragment[];
  loading: boolean;
}

const AlbyContext = createContext({} as AlbyContextValue);

export const AlbyProvider = ({ children }: { children: React.ReactNode }) => {
  const { brokerId } = useParams();
  const { user } = useAccount();

  const { data: { resumableGraphsByBroker: graphs } = { resumableGraphsByBroker: [] }, loading } =
    useResumableGraphsByBrokerQuery({
      variables: {
        input: {
          brokerId: brokerId ?? user.id,
        },
      },
      fetchPolicy: "cache-and-network",
    });

  const value = { brokerId, graphs: graphs, loading };

  return <AlbyContext.Provider value={value}>{children}</AlbyContext.Provider>;
};

export const useAlby = () => {
  const context = useContext(AlbyContext);

  if (!context) {
    throw new Error("useAlby must be used within an AlbyProvider");
  }

  return context;
};

import { Dispatch, useState } from "react";
import { z } from "zod";

import { BusinessClassFragment, useAiGuessBusinessClassesLazyQuery } from "src/generated/graphql";

import { Button } from "@/components/ui/button";
import { FormLabel } from "@/components/ui/form";
import { Icon } from "@/components/ui/icon";
import { Spinner } from "@/components/ui/loading";
import { FieldInput } from "@/forms/fields/field-input";
import { Form } from "@/forms/form";

const validationSchema = z.object({
  description: z.string().min(1, "Description cannot be empty"),
});

const defaultValues: z.infer<typeof validationSchema> = {
  description: "",
};

interface Props {
  setResults: Dispatch<React.SetStateAction<BusinessClassFragment[] | undefined>>;
}

export const AiClassCodeGuesser: React.FC<Props> = ({ setResults }) => {
  const [loading, setLoading] = useState(false);
  const [load] = useAiGuessBusinessClassesLazyQuery();

  const handleSubmit = async (values: z.infer<typeof validationSchema>) => {
    setLoading(true);
    await load({
      variables: { description: values.description },
      onCompleted: (data) => {
        setLoading(false);
        setResults(data?.aiGuessBusinessClasses ?? []);
      },
    });
  };

  return (
    <Form
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      defaultValues={defaultValues}
      className="px-6 pb-6"
    >
      <div className="flex flex-col gap-1.5">
        <FormLabel className="text-xs font-semibold text-muted-foreground">Business Description</FormLabel>
        <div className="relative">
          <FieldInput name="description" placeholder="Full service restaurant" inputProps={{ className: "pr-10" }} />
          <Button
            variant="secondary"
            size="sm"
            disabled={loading}
            className="absolute px-2 right-1 rounded-sm top-1 w-8"
          >
            {loading ? <Spinner /> : <Icon icon="search" />}
          </Button>
        </div>
      </div>
    </Form>
  );
};

import { Slot } from "@radix-ui/react-slot";
import { ComponentProps, FC, HTMLProps } from "react";

import { Icon } from "@/components/ui/icon";
import { cn } from "src/utils";

export interface FieldRowProps extends HTMLProps<HTMLLabelElement> {
  icon?: ComponentProps<typeof Icon>["icon"];
  asChild?: boolean;
}

// TODO: Modify `FieldRow` and borderless fields to better support errors:
// - If there is an error, icon color should be red
// - If there is an error, placeholder text should be destructive
export const FieldRow: FC<FieldRowProps> = ({ children, className, icon, asChild, ...props }) => {
  const Comp = asChild ? Slot : "label";

  return (
    <Comp {...props}>
      <div className={cn("bg-background flex min-h-12 items-start gap-6 py-1 px-6", className)}>
        {icon && <Icon icon={icon} className="text-muted-foreground flex-none mt-3" />} {children}
      </div>
    </Comp>
  );
};

import { useEffect, useState } from "react";

import { ColumnStateIndicator } from "@/components/state";
import { Badge } from "@/components/ui/badge";
import { Spinner } from "@/components/ui/loading";
import { ColumnStates } from "@/metadata/bankan-columns";
import { cn } from "src/utils";

export const BankanColumn = ({
  children,
  id,
  count,
  loading,
}: {
  children: React.ReactNode;
  id: ColumnStates;
  count: number;
  loading: boolean;
}) => {
  const [expanded, setExpanded] = useState(false);

  useEffect(() => setExpanded(!loading), [loading]);

  return (
    <div
      className={cn(
        "border-r flex flex-col flex-none overflow-auto z-0",
        expanded ? "w-88" : "w-12",
        !loading && count === 0 && "hidden"
      )}
    >
      <button
        className={cn(
          "flex flex-none gap-4 h-14 items-center justify-between px-6 text-xs",
          !expanded && "flex-col justify-start py-5 h-80 w-12"
        )}
        onClick={() => setExpanded((expanded) => !expanded)}
      >
        <ColumnStateIndicator state={id} displayLabel={expanded} />
        {loading ? (
          <Spinner />
        ) : (
          <Badge variant="secondary" size="3xs" type="square">
            {count}
          </Badge>
        )}
      </button>
      <div className={cn("flex-auto p-6 pt-0 space-y-6", !expanded && "hidden")}>{children}</div>
    </div>
  );
};

import { FC } from "react";

import { GridRowAction } from "@/components/grid";
import { useOpenForm } from "@/components/modal-provider";
import { ButtonProps } from "@/components/ui/button";
import { Icon } from "@/components/ui/icon";
import { RuleFragment } from "src/generated/graphql";

import { getRuleFormDefaultValues, RuleFormValues } from "../../components/rule-form/rule-form";
import { useSegment } from "../../hooks/use-segment";
import { useUpdateSegmentAppetiteProductRule } from "../../hooks/use-update-segment-appetite-product-rule";
import {
  SegmentAppetiteProductRuleForm,
  UpdateSegmentAppetiteProductRuleFormSchema,
} from "./segment-appetite-product-rule-form";

export interface UpdateRuleButtonProps extends ButtonProps {
  rule: RuleFragment;
  segmentAppetiteProductId: string;
}

export const UpdateRuleButton: FC<UpdateRuleButtonProps> = ({ children, rule, segmentAppetiteProductId, ...props }) => {
  const { openForm } = useOpenForm();
  const { segment } = useSegment();
  const { updateRule } = useUpdateSegmentAppetiteProductRule();

  const handleClick = async () =>
    await openForm(<SegmentAppetiteProductRuleForm mode="update" segment={segment} />, {
      title: "Update Rule",
      defaultValues: { ...getRuleFormDefaultValues(rule as RuleFormValues), id: rule.id, segmentAppetiteProductId },
      validationSchema: UpdateSegmentAppetiteProductRuleFormSchema,
      onSubmit: async (values) => await updateRule(values),
    });

  return (
    <GridRowAction label="Edit" {...props} onClick={handleClick}>
      {children || <Icon icon="edit" />}
    </GridRowAction>
  );
};

import { useViewport } from "@xyflow/react";
import { useMemo, useState } from "react";

import { Clippy } from "@/components/clippy";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover";
import { Textarea } from "@/components/ui/textarea";
import { ToolbarMenuItem } from "@/components/ui/toolbar";
import { useKeyboardShortcut } from "@/hooks/use-keyboard-shortcut";
import { useFileProcessingPipelineState } from "../../../file-processing-pipeline.provider";

export const Share = () => {
  const viewport = useViewport();
  const { expandedNodes } = useFileProcessingPipelineState();
  const [isOpen, setIsOpen] = useState(false);

  const handleShareClick = () => setIsOpen((open) => !open);

  useKeyboardShortcut("ctrl+s", handleShareClick, { disabled: isOpen });

  const shareUrl = useMemo(() => {
    if (typeof window === "undefined") {
      return "";
    }

    const url = new URL(window.location.href);
    const searchParams = new URLSearchParams();

    // Set viewport URL param
    searchParams.set(`viewport`, `${viewport.x},${viewport.y},${viewport.zoom}`);

    // Set expandedNodes URL param
    if (expandedNodes) {
      searchParams.set(`expandedNodes`, `${expandedNodes.join(",")}`);
    }

    url.search = searchParams.toString();

    return url.toString();
  }, [window.location.href, viewport, expandedNodes]);

  return (
    <Popover open={isOpen} onOpenChange={setIsOpen}>
      <PopoverTrigger asChild>
        <ToolbarMenuItem label="Share" icon="ios_share" shortcut="⌃S" />
      </PopoverTrigger>
      <PopoverContent align="end" side="bottom" className="flex flex-col gap-4 w-96">
        <header className="flex gap-4 -my-2 items-center justify-between">
          <h3 className="font-semibold text-sm">Share your view</h3>

          <Clippy
            variant="ghost"
            type="button"
            size="sm"
            label="Copy URL"
            content={shareUrl}
            title="Copied URL to clipboard"
            description={<div className="max-w-full break-words line-clamp-6">{shareUrl}</div>}
            onClick={() => setIsOpen(false)}
            tabIndex={0}
            className="-mr-2 px-2"
          />
        </header>

        <Textarea rows={6} defaultValue={shareUrl} readOnly />
      </PopoverContent>
    </Popover>
  );
};

import { FC, ReactNode } from "react";
import { cn } from "src/utils";

export interface LoadingProps {
  className?: string;
  label?: ReactNode;
}

export const Loading: FC<LoadingProps> = ({ className, label = <>Loading&hellip;</> }) => (
  <div className={cn("flex gap-2 items-center text-sm", className)}>
    <Spinner />
    <span className="text-xs">{label}</span>
  </div>
);

export interface SpinnerProps {
  className?: string;
}

export const Spinner: FC<SpinnerProps> = ({ className }) => (
  <svg
    className={cn("animate-spin flex-none size-[1em]", className)}
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
  >
    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
    <path
      fill="currentColor"
      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
    ></path>
  </svg>
);

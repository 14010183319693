import { PdfDate, PdfYesNo } from "@/forms/pdf-fields/pdf-fields";
import { Address } from "./fieldsets/address";
import { GeneralInformation } from "./fieldsets/general-information";
import { Paper } from "./fieldsets/paper";

export const ContractorsPollutionLiability = () => (
  <>
    <Paper>
      <section>
        <h1>Contractors Pollution Liability</h1>
        <GeneralInformation />
      </section>
      <section>
        <Address header="Mailing Address" rootPath="mailing" />
      </section>
      <section>
        <Address header="Physical Address" rootPath="physical" />
      </section>
    </Paper>
    <Paper>
      <section>
        <h1>Risk Details</h1>
        <table>
          <tbody>
            <tr>
              <PdfYesNo colSpan={4} label="Do operations include any offshore work?" name="offshoreOperations" />
            </tr>
            <tr>
              <PdfYesNo
                colSpan={1}
                label="Does the applicant currently have any claims-made coverage (including transportation pollution and non-owned disposal site coverage)?"
                name="claimsMadeCoverage"
              />
              <td colSpan={3}>
                <span>If yes, please provide retroactive dates:</span>
                <table className="mt-1">
                  <thead>
                    <tr>
                      <th style={{ width: "50%" }}></th>
                      <th>Retroactive Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Contractors Pollution Liability</td>
                      <PdfDate name="claimsMadeCoverage:contractorsPollutionLiabilityRetroactiveDate" />
                    </tr>
                    <tr>
                      <td>Transportation Pollution Liability</td>
                      <PdfDate name="claimsMadeCoverage:transportationPollutionLiabilityRetroactiveDate" />
                    </tr>
                    <tr>
                      <td>Non-Owned Disposal Site Liability</td>
                      <PdfDate name="claimsMadeCoverage:nonOwnedDisposalSiteLiabilityRetroactiveDate" />
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
            <tr>
              <PdfYesNo
                colSpan={4}
                label="Does the applicant have any open pollution claims?"
                name="hasOpenPollutionClaims"
              />
            </tr>
            <tr>
              <PdfYesNo
                colSpan={4}
                label="Does the applicant currently have professional coverage under a separate policy?"
                name="hasProfessionalCoverageUnderSeparatePolicy"
              />
            </tr>
            <tr>
              <PdfYesNo
                colSpan={4}
                label="Does the applicant perform any aerial spraying of pesticides/herbicides?"
                name="aerialSprayingOfPesticides"
              />
            </tr>
            <tr>
              <PdfYesNo
                colSpan={4}
                label="Does the applicant perform any crop spraying operations?"
                name="cropSprayingOperations"
              />
            </tr>
            <tr>
              <PdfYesNo
                colSpan={4}
                label="Does the applicant perform any transportation of any bulk petroleum based products for others (i.e. gas, fuel, oil, diesel, biodiesel, ethanol or jet fuel)?"
                name="bulkPetroleumProductTransportation"
              />
            </tr>
            <tr>
              <PdfYesNo
                colSpan={4}
                label="Does the applicant perform any oil or gas pipeline construction, or any over-the-hole or downhole operations for the oil and gas industry?"
                name="oilOrGasPipelineConstruction"
              />
            </tr>
            <tr>
              <PdfYesNo
                colSpan={4}
                label="Does the applicant own, operate or manage any disposal wells including but not limited to salt water, produced water and brine?"
                name="disposalWellOperations"
              />
            </tr>
          </tbody>
        </table>
      </section>
    </Paper>
    <Paper>
      <section>
        <h1>Mold Coverage</h1>
        <h4>Please select desired mold coverage options</h4>
        <table>
          <tbody>
            <tr>
              <PdfYesNo colSpan={4} label="Occurrence Coverage" name="moldCoverage:occurenceCoverage" />
            </tr>
            <tr>
              <PdfYesNo colSpan={4} label="Claims-made coverage" name="moldCoverage:claimsMadeCoverage" />
            </tr>
            <tr>
              <PdfYesNo colSpan={4} label="Exclude" name="moldCoverage:exclude" />
            </tr>
          </tbody>
        </table>
      </section>
    </Paper>
  </>
);

import { UUID } from "@cp/toolkit";
import { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { z } from "zod";

import { Group } from "@/components/group";
import { HasInternalRole } from "@/components/has-role";
import { MoreMenu } from "@/components/more-menu";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@/components/ui/alert-dialog";
import { DropdownMenuItem } from "@/components/ui/dropdown-menu";
import { Icon } from "@/components/ui/icon";
import { FieldCombobox } from "@/forms/fields/field-combobox";
import { Form } from "@/forms/form";
import { FormReset } from "@/forms/form-reset";
import { FormSubmit } from "@/forms/form-submit";
import { useInsured } from "@/hooks/use-insured";
import { toast } from "@/hooks/use-toast";
import { useDeleteInsuredMutation, useInsuredsQuery, useMergeInsuredMutation } from "src/generated/graphql";
import { parseError } from "src/utils";
import { formatDate } from "src/utils/date";

export const InsuredDetailsMenu = () => {
  const [dialogVariant, setDialogVariant] = useState<"delete" | "merge" | null>(null);
  return (
    <HasInternalRole>
      <AlertDialog>
        <MoreMenu>
          <AlertDialogTrigger>
            <DropdownMenuItem onClick={() => setDialogVariant("merge")}>
              <Icon icon="merge" /> Merge Insured
            </DropdownMenuItem>
            <DropdownMenuItem className="text-destructive" onClick={() => setDialogVariant("delete")}>
              <Icon icon="delete" /> Delete Insured
            </DropdownMenuItem>
          </AlertDialogTrigger>
        </MoreMenu>
        {dialogVariant === "merge" && <MergeDialog />}
        {dialogVariant === "delete" && <DeleteDialog />}
      </AlertDialog>
    </HasInternalRole>
  );
};

const MergeInsuredFormSchema = z.object({
  survivorId: z.string().min(1, "Please select an insured with which to merge."),
});

const MergeDialog = () => {
  const navigate = useNavigate();
  const { insuredId, insured } = useInsured();
  const [mergeInsureds] = useMergeInsuredMutation();

  const { data: { insureds = [] } = {} } = useInsuredsQuery({
    variables: { input: { agencyId: insured.agency?.id as UUID } },
  });

  const defaultValues = { survivorId: "" };

  const otherInsuredsOptions = useMemo(
    () =>
      insureds
        .filter((i) => i.id !== insuredId)
        .map((i) => ({
          label: `${i.name} (created ${formatDate(new Date(i.createdAt), "MM/dd/yy h:mm a")})`,
          value: i.id,
        })),
    [insureds, insuredId]
  );

  const handleMerge = async ({ survivorId }: z.infer<typeof MergeInsuredFormSchema>) => {
    await mergeInsureds({
      variables: {
        input: {
          deprecatedId: insuredId as UUID,
          survivorId: survivorId as UUID,
        },
      },
      onCompleted: (data) => {
        toast({ title: "Insured Merged" });
        navigate(`/insured/${data.mergeInsured.id}`);
      },
      onError: (error) => toast({ title: "Error", description: parseError(error), variant: "destructive" }),
    });
  };

  return (
    <AlertDialogContent>
      <Form validationSchema={MergeInsuredFormSchema} defaultValues={defaultValues} onSubmit={handleMerge}>
        <Group className="gap-6">
          <AlertDialogHeader>
            <AlertDialogTitle>Merge Insured</AlertDialogTitle>
            <AlertDialogDescription>
              Select an Insured to merge into. The current Insured will be deleted, and its resources will be
              transferred to the selected Insured.
            </AlertDialogDescription>
          </AlertDialogHeader>

          <FieldCombobox name="survivorId" placeholder="Search insureds" options={otherInsuredsOptions} />

          <AlertDialogFooter>
            <AlertDialogCancel asChild>
              <FormReset>Cancel</FormReset>
            </AlertDialogCancel>
            <AlertDialogAction theme="destructive" asChild>
              <FormSubmit>Continue</FormSubmit>
            </AlertDialogAction>
          </AlertDialogFooter>
        </Group>
      </Form>
    </AlertDialogContent>
  );
};

const DeleteDialog = () => {
  const { insured } = useInsured();
  const navigate = useNavigate();
  const [deleteInsured] = useDeleteInsuredMutation();

  const handleDelete = async () => {
    await deleteInsured({
      variables: { id: insured.id },
      onCompleted: () => {
        toast({ title: "Insured Deleted" });
        navigate("/");
      },
      onError: () => toast({ title: "Error", description: "An error occurred.", variant: "destructive" }),
      refetchQueries: ["Opportunities"],
    });
  };

  return (
    <AlertDialogContent>
      <AlertDialogHeader>
        <AlertDialogTitle>Delete {insured.name}</AlertDialogTitle>
        <AlertDialogDescription>Are you sure?</AlertDialogDescription>
      </AlertDialogHeader>
      <AlertDialogFooter>
        <AlertDialogCancel>Cancel</AlertDialogCancel>
        <AlertDialogAction onClick={handleDelete} theme="destructive">
          Continue
        </AlertDialogAction>
      </AlertDialogFooter>
    </AlertDialogContent>
  );
};

import { ComponentProps, FC, forwardRef } from "react";

import { Icon } from "@/components/ui/icon";
import { Label } from "@/components/ui/label";
import { Switch } from "@/components/ui/switch";
import { useSegment } from "@/segments/hooks/use-segment";
import { cn } from "src/utils";

export const SegmentMenuItemSplitToggle: FC = forwardRef<HTMLLabelElement, ComponentProps<typeof Label>>(
  (props, ref) => {
    const { segment, toggleSegmentSplit, isUpdatingSegment } = useSegment();

    return (
      <Label ref={ref} {...props} className={cn("font-normal! cursor-pointer pr-2!", props.className)}>
        <Icon icon="circle" className={cn("text-sm", segment!.split ? "filled text-success" : "opacity-25")} />
        <span>Split</span>
        <span className="flex-1" />
        <Switch checked={segment!.split} onCheckedChange={toggleSegmentSplit} disabled={isUpdatingSegment} />
      </Label>
    );
  }
);
SegmentMenuItemSplitToggle.displayName = "SegmentMenuItemSplitToggle";

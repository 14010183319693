import React from "react";
import { useParams } from "react-router";

import { AlbyApprovalProvider } from "@/hooks/use-alby-approval";
import { SetMetadata } from "@/hooks/use-metadata";
import { useUpsertSearchParams } from "@/hooks/use-upsert-search-params";
import { AlbyMenu } from "./alby";
import { DefaultApprovalForm } from "./approval-forms/default-approval-form";
import QuoteTransitionGraphGenerateCoverLetter from "./approval-forms/quote-transition-graph-generate-cover-letter";
import SubmissionTransitionGraphSubmitToCarrier from "./approval-forms/submission-transition-graph-submit-to-carrier";
import VaveQuoteRequestForm from "./approval-forms/vave-submit-to-api-form";

const approvalFormMapping = {
  SubmissionTransitionGraph: {
    "submit-to-carrier": SubmissionTransitionGraphSubmitToCarrier,
    "submit-to-vave-api": VaveQuoteRequestForm,
  },
  QuoteTransitionGraph: {
    "generate-cover-letter": QuoteTransitionGraphGenerateCoverLetter,
  },
} as Record<string, Record<string, () => React.JSX.Element | null>>;

export const AlbyApproval = () => {
  const { graphName, insuredId, selectedTool } = useParams();
  const [searchParams] = useUpsertSearchParams();

  if (!graphName || !insuredId || !selectedTool) {
    return null;
  }

  const ApprovalForm = approvalFormMapping[graphName]?.[selectedTool] ?? DefaultApprovalForm;

  return (
    <>
      <SetMetadata title={graphName} menu={<AlbyMenu />} />
      <AlbyApprovalProvider
        graphName={graphName}
        insuredId={insuredId}
        selectedTool={selectedTool}
        invokeParams={[...searchParams.entries()].reduce<Record<string, string>>((acc, [key, value]) => {
          try {
            acc[key] = JSON.parse(value);
          } catch {
            acc[key] = decodeURIComponent(value);
          }

          return acc;
        }, {} as Record<string, string>)}
      >
        <div className="p-6 space-y-6">
          <ApprovalForm />
        </div>
      </AlbyApprovalProvider>
    </>
  );
};

import { KnownClientData } from "@qw/qw-common";

import { PdfInput } from "@/forms/pdf-fields/pdf-fields";
import { Address } from "./fieldsets/address";
import { LossHistory } from "./fieldsets/loss-history";
import { Paper } from "./fieldsets/paper";

export const SubmissionTemplate = () => (
  <Paper>
    <section>
      <h1>Submission Supplemental</h1>
      <Address header="Mailing Address" rootPath="mailing" />
    </section>
    <section>
      <table>
        <tbody>
          <tr>
            <PdfInput colSpan={2} label="Description Of Operations" name={KnownClientData.DescriptionOfOperations} />
          </tr>
          <tr>
            <PdfInput colSpan={1} label="Exposures" name="exposures" />
            <PdfInput colSpan={1} label="Attached Information" name="attachedInformation" />
          </tr>
          <tr>
            <PdfInput colSpan={1} label="Limits Requested" name="limitsRequested" />
            <PdfInput colSpan={1} label="Pending Information" name="pendingInformation" />
          </tr>
        </tbody>
      </table>
    </section>
    <LossHistory rootPath="gl" />
  </Paper>
);

import { Button } from "@/components/ui/button";
import { DropdownMenuItem } from "@/components/ui/dropdown-menu";
import { Icon } from "@/components/ui/icon";
import { Tooltip, TooltipContent, TooltipTrigger } from "@/components/ui/tooltip";
import { SubmissionAction, SubmissionFragment } from "src/generated/graphql";
import { SubmissionMenu } from "./submission-menu";
import { useSubmissionActions } from "./use-submission-actions";

export const Pending = ({ submission }: { submission: SubmissionFragment }) => {
  const { handleTransition } = useSubmissionActions();
  return (
    <>
      <Tooltip>
        <TooltipTrigger asChild>
          <Button
            variant="ghost"
            size="xs"
            display="icon"
            onClick={() => handleTransition(submission, SubmissionAction.RuleOut)}
          >
            <Icon icon="block" />
          </Button>
        </TooltipTrigger>
        <TooltipContent>Rule Out</TooltipContent>
      </Tooltip>
      <SubmissionMenu submission={submission}>
        <DropdownMenuItem onClick={() => handleTransition(submission, SubmissionAction.RuleOut)}>
          <Icon icon="block" />
          Rule Out
        </DropdownMenuItem>
      </SubmissionMenu>
    </>
  );
};

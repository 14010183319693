import { useState } from "react";
import { Outlet } from "react-router";
import { useDebounceValue } from "usehooks-ts";

import { Grid, GridCell, GridRowHeader, GridRowLink } from "@/components/grid";
import { Pagination, PaginationProvider, usePagination } from "@/components/pagination";
import { ScrollPane } from "@/components/scroll-pane";
import { SearchInput } from "@/components/search-input";
import { SectionHeader, SectionTitle } from "@/components/section";
import { Badge } from "@/components/ui/badge";
import { Spinner } from "@/components/ui/loading";
import { useAccount } from "@/hooks/use-account";
import { SetMetadata } from "@/hooks/use-metadata";
import { usePaginatedAgenciesQuery } from "src/generated/graphql";
import { formatTimezoneDateString } from "src/utils/date";

export const Launch = () => (
  <>
    <SetMetadata title="Launch" icon="rocket_launch" crumb />
    <PaginationProvider storageKeyPrefix="launch">
      <ScrollPane>
        <Outlet />
      </ScrollPane>
    </PaginationProvider>
  </>
);

export const LaunchAgencies = () => {
  const { user } = useAccount();
  const { numRecords, setNumRecords, page, rowsPerPage } = usePagination();
  const [searchTerm, setSearchTerm] = useState("");
  const [term] = useDebounceValue(searchTerm, 200);

  const { data: { paginatedAgencies: { agencies } } = { paginatedAgencies: { agencies: [] } }, loading } =
    usePaginatedAgenciesQuery({
      variables: {
        args: {
          term,
          userId: user.id,
        },
        pagination: {
          page,
          rowsPerPage: Number(rowsPerPage),
        },
      },
      onCompleted: (data) => {
        setNumRecords(data.paginatedAgencies.numRecords);
      },
    });

  return (
    <>
      <SectionHeader>
        <SectionTitle>
          <h1 className="mr-auto">Agency Management</h1>
          {loading && <Spinner />}
          <SearchInput
            name="search"
            placeholder="Agencies or Agents"
            value={searchTerm}
            onValueChange={setSearchTerm}
          />
        </SectionTitle>
      </SectionHeader>
      <Grid className="grid-cols-[2fr_1fr_1fr]">
        <GridRowHeader position="sticky">
          <GridCell>Agency</GridCell>
          <GridCell>Owner</GridCell>
          <GridCell>Created</GridCell>
        </GridRowHeader>
        {agencies.map((agency) => {
          const { id, isTest, createdAt, name, owner } = agency;
          return (
            <GridRowLink key={id} to={id}>
              <GridCell className="flex gap-2">
                {name}
                {isTest && <Badge>Test</Badge>}
              </GridCell>
              <GridCell>{owner && `${owner.firstName} ${owner.lastName}`}</GridCell>
              <GridCell>{formatTimezoneDateString(createdAt, "MMM d, yyyy")}</GridCell>
            </GridRowLink>
          );
        })}
      </Grid>
      <Pagination label={numRecords === 1 ? "Agency" : "Agencies"} />
    </>
  );
};

import { partition } from "lodash";

import { Loading } from "@/components/ui/loading";
import { useBankan } from "@/hooks/use-bankan";
import { COLUMN_STATE_METADATA } from "@/metadata/bankan-columns";
import { MarketingPlanBankanColumn, useBankanMarketingPlanColumnQuery } from "src/generated/graphql";
import { MarketingPlansCard } from "../cards/marketing-plans-card";
import { BankanColumn } from "./bankan-column";

export const MarketingPlanColumn = ({ column }: { column: MarketingPlanBankanColumn }) => {
  const { agencyId, brokerId, ownerId, pinnedIds } = useBankan();

  const {
    data: { bankanMarketingPlanColumn: { groupedMarketingPlans } } = {
      bankanMarketingPlanColumn: { groupedMarketingPlans: [] },
    },
    loading,
  } = useBankanMarketingPlanColumnQuery({
    variables: {
      input: {
        agencyId,
        brokerId,
        ownerId,
        column,
      },
    },
    fetchPolicy: "cache-and-network",
  });

  const [pinned, unpinned] = partition(groupedMarketingPlans, ({ insured }) => pinnedIds.includes(insured.id));
  const cardClassName = COLUMN_STATE_METADATA[column]["cardClassName"];

  return (
    <BankanColumn id={column} count={groupedMarketingPlans.length} loading={loading}>
      {loading ? (
        <Loading />
      ) : (
        [...pinned, ...unpinned].map((group, key) => (
          <MarketingPlansCard key={`${group.insuredId}-${key}`} group={group} cardClassName={cardClassName} />
        ))
      )}
    </BankanColumn>
  );
};

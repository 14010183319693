import { SetMetadata } from "@/hooks/use-metadata";
import { BankanPolicyColumn, BankanQuoteColumn } from "src/generated/graphql";
import { PolicyColumn } from "./columns/policy-column";
import { QuoteColumn } from "./columns/quote-column";

const policyColumns = [
  BankanPolicyColumn.AwaitingBinderFromCarrier,
  BankanPolicyColumn.ReceivedBinderFromCarrier,
  BankanPolicyColumn.BinderProcessed,
  BankanPolicyColumn.IssuedEndorsementsRequested,
  BankanPolicyColumn.ExpiringSoon,
];

export const StsBankan = () => (
  <>
    <SetMetadata title="STS" />
    <QuoteColumn column={BankanQuoteColumn.BindRequestReceived} />
    {policyColumns.map((column) => (
      <PolicyColumn key={column} column={column} />
    ))}
  </>
);

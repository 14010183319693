import { type ComponentProps, type FC } from "react";

import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { cn } from "src/utils";

import { useField } from "../hooks/use-field";
import { FieldBase, FieldBaseProps } from "./field-base";
import { FIELD_BORDERLESS_CLASS_NAME, FIELD_READ_ONLY_CLASS_NAME } from "./field.constants";

export interface FieldSelectOption {
  type?: "label" | "item";
  label: string;
  value?: string;
  itemProps?: ComponentProps<typeof SelectItem>;
}

export type FieldSelectProps = FieldBaseProps & {
  placeholder?: string;
  borderless?: boolean;
  options: readonly FieldSelectOption[];
  onChange?: ComponentProps<typeof Select>["onValueChange"];
  onBlur?: ComponentProps<typeof SelectTrigger>["onBlur"];
  inputProps?: ComponentProps<typeof SelectTrigger>;
};

export const FieldSelect: FC<FieldSelectProps> = ({
  placeholder = "Select an option",
  borderless,
  readOnly,
  options,
  onChange,
  onBlur,
  inputProps,
  ...props
}) => {
  const { field, hasError } = useField({ name: props.name, disabled: props.disabled, inputProps });

  const handleChange: FieldSelectProps["onChange"] = (value) => {
    field.onChange(value);
    onChange?.(value);
  };

  const handleBlur: FieldSelectProps["onBlur"] = (event) => {
    field.onBlur();
    onBlur?.(event);
  };

  return (
    <FieldBase {...props}>
      <Select onValueChange={handleChange} name={field.name} value={field.value}>
        <SelectTrigger
          {...inputProps}
          ref={field.ref}
          onBlur={handleBlur}
          disabled={field.disabled}
          className={cn("text-left!", {
            "border-destructive": hasError && !field.disabled,
            [FIELD_BORDERLESS_CLASS_NAME]: borderless,
            [FIELD_READ_ONLY_CLASS_NAME]: readOnly,
          })}
        >
          <SelectValue placeholder={placeholder} />
        </SelectTrigger>

        {options.length > 0 && (
          <SelectContent>
            <SelectGroup>
              {options.map((option) => {
                if (option.type === "label") {
                  return (
                    <SelectLabel key={option.label} {...option.itemProps}>
                      {option.label}
                    </SelectLabel>
                  );
                }

                if (!option.value) {
                  return null;
                }

                return (
                  <SelectItem key={option.value} {...option.itemProps} value={option.value}>
                    {option.label}
                  </SelectItem>
                );
              })}
            </SelectGroup>
          </SelectContent>
        )}
      </Select>
    </FieldBase>
  );
};

import { redirect, RouteObject } from "react-router-dom";

import { PageNotFound } from "@/components/errors/page-not-found";
import { InsuredRedirect } from "@/insured/insured-redirect";
import { getAuthedUser } from "src/utils/get-authed-user";

const roleHomepageLoader = async () => {
  const user = await getAuthedUser();
  let path = "/login";

  if (user) {
    path = user.internal ? "/bankan" : "/agency";
  }

  return redirect(path);
};

export const redirectRoutes: RouteObject[] = [
  { index: true, loader: roleHomepageLoader },
  {
    path: "/insureds/*",
    loader: async ({ params }) => redirect(`/agency/${params["*"]}`),
  },
  {
    path: "/opportunity/:opportunityId",
    element: <InsuredRedirect />,
  },
  {
    path: "/insured/:insuredId/:opportunityId/*",
    loader: async ({ params }) => redirect(`/insured/${params.insuredId}/plans/${params.opportunityId}/${params["*"]}`),
  },
  {
    path: "*",
    element: <PageNotFound />,
  },
];

import { MutationFunctionOptions } from "@apollo/client";
import { useCallback } from "react";

import { useToast } from "@/hooks/use-toast";
import { AddAppetiteProductsToSegmentMutation, useAddAppetiteProductsToSegmentMutation } from "src/generated/graphql";

import { useSegment } from "./use-segment";

export const useAddSegmentAppetiteProducts = () => {
  const { segment } = useSegment();
  const { toast } = useToast();

  const [addAppetiteProductsMutation, { loading: isAddingAppetiteProducts }] = useAddAppetiteProductsToSegmentMutation({
    awaitRefetchQueries: true,
    onCompleted: () => toast({ title: "Appetite products added" }),
    refetchQueries: ["Segment"],
  });

  const addAppetiteProducts = useCallback(
    async (
      appetiteProductIds: string[],
      options?: Omit<MutationFunctionOptions<AddAppetiteProductsToSegmentMutation>, "variables">
    ) =>
      await addAppetiteProductsMutation({
        variables: { input: { id: segment!.id, appetiteProductIds } },
        ...options,
      }),
    [segment?.id]
  );

  return {
    addAppetiteProducts,
    isAddingAppetiteProducts,
  };
};

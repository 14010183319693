import { getAuthRequestHeaders } from "@cp/auth";

import { FileUploadFragment, File_Audience, File_Source, PolicyFileType } from "src/generated/graphql";

export const downloadBlob = (blob: Blob, name: string) => {
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.style.display = "none";
  a.href = url;
  a.download = name;
  document.body.append(a);
  a.click();
  window.URL.revokeObjectURL(url);
};

export const downloadFile = async (file: FileUploadFragment) => {
  // Not the best since it loads the entire file into memory before giving the user feedback.
  // But good enough for now
  const blob = await fetch(`/api/files/${file.id}`, {
    headers: getAuthRequestHeaders(),
  })
    .then((r) => r.blob())
    // Create a new blob with the mime type
    .then((b) => b.slice(0, b.size, file.mimeType));

  const url = URL.createObjectURL(blob);
  const anchor = document.createElement("a");
  anchor.href = url;
  anchor.download = file.filename;

  document.body.append(anchor);
  anchor.click();

  URL.revokeObjectURL(url);
  anchor.remove();
};

export interface UploadFileOptions {
  insuredId?: string;
  audience: File_Audience;
  source?: File_Source;
}

export async function uploadFiles(
  files: File[],
  options?: UploadFileOptions
): Promise<{ fileId: string[]; fileData: Array<{ id: string; filename: string }> }> {
  const fd = new FormData();
  const { insuredId, audience, source } = options || {};

  files.map((file) => fd.append("files", file));

  if (insuredId) {
    fd.set("insuredId", insuredId);
  }
  if (audience) {
    fd.set("audience", audience);
  }
  if (source) {
    fd.set("source", source);
  }

  const res = await fetch(`/api/files`, {
    method: "POST",
    headers: getAuthRequestHeaders(),
    body: fd,
  });

  const { fileId, fileData } = (await res.json()) || {};

  return { fileId, fileData };
}

export async function uploadRedactedQuote(file: File, submissionId: string) {
  const fd = new FormData();
  fd.set("file", file);
  fd.set("submissionId", submissionId);

  const res = await fetch(`/api/quotes`, {
    method: "POST",
    headers: getAuthRequestHeaders(),
    body: fd,
  });

  const json = await res.json();

  return { fileId: json.fileId, success: json.success };
}

export async function uploadProcessedQuote(file: File, quoteId: string) {
  const fd = new FormData();
  fd.set("file", file);
  fd.set("fileType", "ProcessedQuote");

  const res = await fetch(`/api/quotes/${quoteId}/files`, {
    method: "POST",
    headers: getAuthRequestHeaders(),
    body: fd,
  });

  const json = await res.json();

  return { fileId: json.fileId, success: json.success };
}

export async function uploadPolicyDocument(file: File, policyId: string, fileType: PolicyFileType) {
  const fd = new FormData();
  fd.set("file", file);
  fd.set("audience", File_Audience.External);
  fd.set("fileType", fileType);

  const res = await fetch(`/api/policies/${policyId}/files`, {
    method: "POST",
    headers: getAuthRequestHeaders(),
    body: fd,
  });

  const json = await res.json();

  return { fileId: json.fileId, success: json.success };
}

import { Outlet } from "react-router";

import { SectionDescription, SectionHeader, SectionTitle } from "@/components/section";
import { Bar, BarLink, BarLinks } from "@/components/ui/bar";
import { Loading, Spinner } from "@/components/ui/loading";
import { SetMetadata } from "@/hooks/use-metadata";
import { SegmentMenu } from "./components/segment-menu";
import { SegmentProvider, useSegment } from "./hooks/use-segment";

export const Segment = () => {
  const { segment, isLoadingSegment } = useSegment();

  if (isLoadingSegment && !segment) {
    return <Loading label={<>Loading segment&hellip;</>} className="m-6 " />;
  }

  if (!segment) {
    return <div className="p-6">Segment not found</div>;
  }

  return (
    <>
      <SetMetadata
        title={segment.name}
        crumb
        menu={
          <SegmentProvider>
            <SegmentMenu />
          </SegmentProvider>
        }
      />
      <SectionHeader>
        <SectionTitle>
          <h1>{segment.name}</h1>
          {isLoadingSegment && <Spinner />}
        </SectionTitle>
        <SectionDescription>{segment.description}</SectionDescription>
      </SectionHeader>

      <Bar>
        <BarLinks>
          <BarLink to="products">Products</BarLink>
          <BarLink to="product-rules">Product Rules</BarLink>
          <BarLink to="requirements">Requirements</BarLink>
          <BarLink to="assignees">Assignees</BarLink>
          <BarLink to="criteria">Details &amp; Criteria</BarLink>
        </BarLinks>
      </Bar>

      <Outlet />
    </>
  );
};

import { format } from "date-fns";

import { PrimaryLabelDataType } from "src/generated/graphql";

import { RuleBooleanValue } from "./rule-form.constants";

export const ruleValueToFormValue = (value?: string | number | Date | boolean) => {
  if (!value) {
    return undefined;
  }

  if (typeof value === "boolean") {
    return value ? RuleBooleanValue.Yes : RuleBooleanValue.No;
  }

  return value;
};

export const ruleValueToDisplayValue = (value: string | number | Date | boolean) => {
  if (typeof value === "boolean") {
    return value ? RuleBooleanValue.Yes : RuleBooleanValue.No;
  }

  if (value instanceof Date) {
    return format(value, "yyyy-MM-dd");
  }

  return value;
};

export const getRuleDefaultOperatorFromValueType = (valueType?: PrimaryLabelDataType | null) =>
  valueType === PrimaryLabelDataType.Boolean ? "==" : "";

export const getRuleDefaultValueFromValueType = (valueType?: PrimaryLabelDataType | null) => {
  if (!valueType || [PrimaryLabelDataType.String, PrimaryLabelDataType.Date].includes(valueType)) {
    return "";
  }

  return undefined;
};

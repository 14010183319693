import * as Sentry from "@sentry/react";
import { useEffect } from "react";
import { useRouteError } from "react-router";

import { SetMetadata } from "@/hooks/use-metadata";
import { BrokerFace } from "../errors/brokerface";

export const ErrorMessage = () => {
  const error = useRouteError() as Error;
  console.error(error);

  useEffect(() => {
    Sentry.captureException(error);
  }, [error]);

  return (
    <>
      <SetMetadata title="Error" />
      <div className="p-6 space-y-6">
        <h1 className="text-3xl">We&apos;re sorry, an error occured</h1>
        <BrokerFace />
      </div>
    </>
  );
};

import { MutationFunctionOptions } from "@apollo/client";
import { useCallback } from "react";

import { useOpenConfirmation } from "@/components/modal-provider";
import { useToast } from "@/hooks/use-toast";

import {
  DeleteSegmentAppetiteProductRuleInput,
  DeleteSegmentAppetiteProductRuleMutation,
  useDeleteSegmentAppetiteProductRuleMutation,
} from "src/generated/graphql";
import { useSegment } from "./use-segment";

export const useDeleteSegmentAppetiteProductRule = () => {
  const { segment } = useSegment();
  const { openConfirmation } = useOpenConfirmation();
  const { toast } = useToast();

  const [removeRuleMutation, { loading: isDeletingRule }] = useDeleteSegmentAppetiteProductRuleMutation({
    awaitRefetchQueries: true,
    onCompleted: () => toast({ title: "Rule deleted" }),
    refetchQueries: ["Segment"],
  });

  const deleteRule = useCallback(
    async (
      input: DeleteSegmentAppetiteProductRuleInput,
      options?: Omit<MutationFunctionOptions<DeleteSegmentAppetiteProductRuleMutation>, "variables">
    ) => {
      const isConfirmed = await openConfirmation({
        title: "Delete rule",
        description: `Are you sure you want to delete this rule?`,
        buttonProps: { theme: "destructive", children: "Delete" },
      });

      if (!isConfirmed) {
        return;
      }

      return await removeRuleMutation({ variables: { input }, ...options });
    },
    [segment?.id]
  );

  return {
    deleteRule,
    isDeletingRule,
  };
};

import { UUID } from "@cp/toolkit";

import { Activity } from "@/components/activity/activity";
import { HasInternalRole } from "@/components/has-role";
import { Notes } from "@/components/notes";
import { ScrollPaneColumns } from "@/components/scroll-pane";
import { SectionDescription, SectionHeader, SectionTitle } from "@/components/section";
import { Card } from "@/components/ui/card";
import { Separator } from "@/components/ui/separator";
import { SetMetadata } from "@/hooks/use-metadata";
import { useQuote } from "@/hooks/use-quote";
import { QuoteState } from "src/generated/graphql";
import { AGENT_MESSAGE_METADATA } from "../metadata/states";
import { QuoteActions } from "./actions/quote-actions";
import { QuoteNotes } from "./components/quote-notes";
import { QuoteFiles } from "./quote-files";
import { QuoteInfo } from "./quote-info";
import { QuoteMenu } from "./quote-menu";
import { QuoteProposalForm } from "./quote-proposal-form";

export const Quote = () => {
  const { quote } = useQuote();

  const {
    state,
    submission: { appetiteProduct, opportunityId },
  } = quote;

  const { displayQuoteNotes } = AGENT_MESSAGE_METADATA[state];

  return (
    <>
      <SetMetadata
        title={`${appetiteProduct.carrierName}: ${appetiteProduct.carrierProductName}`}
        crumb
        menu={<QuoteMenu quote={quote} />}
      />
      <SectionHeader>
        <SectionTitle>
          <h1>{appetiteProduct.carrierName}</h1>
        </SectionTitle>
        <SectionDescription>{appetiteProduct.carrierProductName}</SectionDescription>
      </SectionHeader>
      <Separator />
      <div className="p-6 space-y-6">
        {displayQuoteNotes && <QuoteNotes />}
        {state === QuoteState.Redacted ? <QuoteProposalForm /> : <QuoteInfo />}
        <ScrollPaneColumns className="flex-col-reverse grid-cols-[1fr_24em]">
          <Activity opportunityId={opportunityId} />
          <div className="space-y-6">
            <HasInternalRole>
              <Notes opportunityId={opportunityId as UUID} />
            </HasInternalRole>
            <Card>
              <QuoteFiles />
              <HasInternalRole>
                <QuoteActions />
              </HasInternalRole>
            </Card>
          </div>
        </ScrollPaneColumns>
      </div>
    </>
  );
};

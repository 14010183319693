import { SetMetadata } from "@/hooks/use-metadata";
import { BankanPolicyColumn, BankanQuoteColumn, MarketingPlanBankanColumn } from "src/generated/graphql";
import { MarketingPlanColumn } from "./columns/marketing-plan-column";
import { PolicyColumn } from "./columns/policy-column";
import { QuoteColumn } from "./columns/quote-column";

const marketingPlanColumns = [
  MarketingPlanBankanColumn.UnassignedInformationGathering,
  MarketingPlanBankanColumn.UnassignedWaitingForCoverages,
  MarketingPlanBankanColumn.UnassignedOther,
  MarketingPlanBankanColumn.WaitingForCoverages,
  MarketingPlanBankanColumn.InformationGathering,
  MarketingPlanBankanColumn.InformationReview,
  MarketingPlanBankanColumn.MarketingInfoRequired,
  MarketingPlanBankanColumn.MarketingReady,
];

const quoteColumns = [
  BankanQuoteColumn.ReadyToSend,
  BankanQuoteColumn.Delivered,
  BankanQuoteColumn.BindRequestReceived,
];

const policyColumns = [
  BankanPolicyColumn.AwaitingBinderFromCarrier,
  BankanPolicyColumn.ReceivedBinderFromCarrier,
  BankanPolicyColumn.BinderProcessed,
  BankanPolicyColumn.IssuedEndorsementsRequested,
  BankanPolicyColumn.ExpiringSoon,
];

export const ArmBankan = () => (
  <>
    <SetMetadata title="ARM" />
    {marketingPlanColumns.map((column) => (
      <MarketingPlanColumn key={column} column={column} />
    ))}
    {quoteColumns.map((column) => (
      <QuoteColumn key={column} column={column} />
    ))}
    {policyColumns.map((column) => (
      <PolicyColumn key={column} column={column} />
    ))}
  </>
);

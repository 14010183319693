import * as Popover from "@radix-ui/react-popover";

import { SectionDescription } from "@/components/section";
import { Calendar } from "@/components/ui/calendar";
import { Icon } from "@/components/ui/icon";
import { PopoverContent } from "@/components/ui/popover";
import { useToast } from "@/hooks/use-toast";
import { MarketingPlanState, useUpdateOpportunityMutation } from "src/generated/graphql";
import { cn } from "src/utils";
import { formatDateString } from "src/utils/date";

export const SetEffectiveDate = ({
  id,
  desiredEffectiveDate,
  state,
}: {
  id: string;
  desiredEffectiveDate: any;
  state: MarketingPlanState;
}) => {
  const { toast } = useToast();

  const [updateOpportunity] = useUpdateOpportunityMutation({
    onCompleted: () => {
      toast({ title: "Success", description: "Effective date updated." });
    },
    onError: () => {
      toast({ title: "Error", description: "An error occurred.", variant: "destructive" });
    },
  });

  const now = new Date().toISOString();
  const expired = now > desiredEffectiveDate;
  const complete = state === MarketingPlanState.Complete;

  const content = (
    <SectionDescription className={cn(!complete && "text-inherit")}>
      {desiredEffectiveDate
        ? `Effective ${formatDateString(desiredEffectiveDate, "MMMM d, yyyy")}`
        : "Set Desired Effective Date"}
    </SectionDescription>
  );

  if (complete) {
    return <>{content}</>;
  }

  return (
    <Popover.Root>
      <Popover.Trigger
        className={cn(
          "flex gap-1 items-center",
          expired || !desiredEffectiveDate ? "text-destructive" : "text-muted-foreground"
        )}
      >
        {content}
        <Icon icon="keyboard_arrow_down" />
      </Popover.Trigger>
      <PopoverContent className="p-0 w-auto">
        <Calendar
          mode="single"
          selected={desiredEffectiveDate}
          onSelect={(e) =>
            updateOpportunity({
              variables: {
                input: { id, desiredEffectiveDate: e },
              },
            })
          }
          initialFocus
        />
      </PopoverContent>
    </Popover.Root>
  );
};

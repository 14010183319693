import { MaterialSymbol } from "material-symbols";

import { UserAccountFragment } from "src/generated/graphql";
import { cn } from "src/utils";
import { Icon } from "./icon";
import { Tooltip, TooltipContent, TooltipTrigger } from "./tooltip";

export const Avatar = ({
  className,
  disableTooltip = false,
  icon,
  impersonated,
  user,
}: {
  className?: string;
  disableTooltip?: boolean;
  icon?: MaterialSymbol;
  impersonated?: boolean;
  user?: Pick<UserAccountFragment, "firstName" | "lastName"> | null;
}) => (
  <Tooltip>
    <TooltipTrigger asChild>
      <div
        className={cn(
          "bg-background flex flex-none h-[2em] items-center justify-center overflow-hidden relative rounded-full shadow-xs select-none w-[2em]",
          user ? "gradient-fuchs" : "bg-destructive/20 text-destructive",
          impersonated && "bg-amber-400 outline outline-offset-2 outline-amber-400 text-amber-900",
          disableTooltip && "pointer-events-none",
          className
        )}
      >
        {icon ? (
          <Icon icon={icon} className="filled -mt-[0.05em] text-[1.25em]" />
        ) : (
          <span className={cn("font-semibold -mt-[0.05em] text-[0.875em]")}>
            {user ? `${user.firstName[0]}${user.lastName[0]}` : "U"}
          </span>
        )}
      </div>
    </TooltipTrigger>
    {user && (
      <TooltipContent>
        {impersonated && "You are impersonating "}
        {user.firstName} {user.lastName}
      </TooltipContent>
    )}
  </Tooltip>
);

import { Card } from "@/components/ui/card";
import { Icon } from "@/components/ui/icon";
import { useQuote } from "@/hooks/use-quote";

export const QuoteNotes = () => {
  const {
    quote: { notes },
  } = useQuote();

  return notes[0] ? (
    <Card className="p-4 text-orange-600 text-sm">
      <h6 className="text-sm mb-1">Attention Items:</h6>
      <ul>
        {notes.map((note) => (
          <li key={note.id} className="flex items-center">
            <Icon icon="report" className="mr-2" />
            {note.text}
          </li>
        ))}
      </ul>
    </Card>
  ) : null;
};

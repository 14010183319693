import { SendEmailFormValues, useSendEmailForm } from "@/components/send-email-form";
import { Button } from "@/components/ui/button";
import { Spinner } from "@/components/ui/loading";
import { useInsured } from "@/hooks/use-insured";
import { useToast } from "@/hooks/use-toast";
import {
  AlbyActionType,
  QuoteAction,
  QuoteFragment,
  useResumableGraphQuery,
  useResumeGraphMutation,
  useSendQuoteEmailMutation,
  useTransitionQuoteMutation,
} from "src/generated/graphql";
import { parseError } from "src/utils";

export const Processed = ({ quote }: { quote: QuoteFragment }) => {
  const { insured } = useInsured();
  const { toast } = useToast();
  const openSendEmailForm = useSendEmailForm();

  const [sendQuote, { loading: sendQuoteEmailLoading }] = useSendQuoteEmailMutation();
  const [transition, { loading: transitionLoading }] = useTransitionQuoteMutation();

  const { data, loading: resumableGraphLoading } = useResumableGraphQuery({
    variables: {
      input: {
        name: "QuoteTransitionGraph",
        selectedTool: "send-quote-to-agent",
        insuredId: insured.id,
        invokeParams: JSON.stringify({ quoteId: quote.id }),
      },
    },
  });

  const [resumeGraph] = useResumeGraphMutation();

  const resumableGraphThreadId = data?.resumableGraph?.threadId;

  const handleSendEmail = async (values: SendEmailFormValues) => {
    const formattedValues = {
      emailComment: values.emailComment,
      fileIds: values.files?.map((file) => file.id),
    };

    if (resumableGraphThreadId) {
      await resumeGraph({
        variables: {
          input: {
            threadId: resumableGraphThreadId,
            actionType: AlbyActionType.Approve,
            brokerInput: JSON.stringify(formattedValues),
          },
        },
        onCompleted: () => toast({ title: "Email sent" }),
        onError: (error) => toast({ title: "Error sending email", description: parseError(error) }),
        refetchQueries: ["Quote"],
      });
      return;
    }

    await sendQuote({
      variables: { input: { quoteId: quote.id, ...formattedValues } },
      onCompleted: () => toast({ title: "Email sent" }),
      onError: (error) => toast({ title: "Error sending email", description: parseError(error) }),
      refetchQueries: ["Quote"],
    });
  };

  const handleSendEmailClick = async () =>
    await openSendEmailForm({
      title: "Send Email with Quote",
      onSubmit: handleSendEmail,
      defaultAttachments: quote.processedQuote ? [{ name: quote.processedQuote.filename }] : [],
    });

  const handleTransition = async (action: QuoteAction) => {
    await transition({
      variables: {
        input: {
          id: quote.id,
          expectedState: quote.state,
          action,
        },
      },
    });
  };

  const loading = sendQuoteEmailLoading || resumableGraphLoading;

  return (
    <>
      <Button
        onClick={handleSendEmailClick}
        variant="outline"
        size="sm"
        display="flex"
        disabled={!quote.processedQuote || loading}
      >
        Send email with quote attached {loading && <Spinner />}
      </Button>
      <Button onClick={() => handleTransition(QuoteAction.Deliver)} variant="outline" size="sm" display="flex">
        Mark as delivered outside of app {transitionLoading && <Spinner />}
      </Button>
      <Button onClick={() => handleTransition(QuoteAction.Invalidate)} variant="outline" size="sm" display="flex">
        Invalidate
      </Button>
    </>
  );
};

import { UUID } from "@cp/toolkit";

import { Autocomplete } from "@/components/ui/autocomplete";
import { CarrierContactFragment, useCarrierContactsQuery } from "src/generated/graphql";

interface Props {
  selected: CarrierContactFragment | null;
  setSelected: (contact: CarrierContactFragment | null) => void;
}

const createNew: CarrierContactFragment = {
  __typename: "CarrierContact",
  id: "new" as UUID,
  firstName: "Create New",
  lastName: "",
  email: "",
  phone: "",
  title: "",
};

export const CarrierContactSelector: React.FC<Props> = ({ selected, setSelected }) => {
  const { data } = useCarrierContactsQuery();

  const contacts = data?.carrierContacts ? [createNew, ...data.carrierContacts] : [createNew];

  return (
    <Autocomplete
      placeholder="Search Contacts"
      options={contacts}
      alwaysVisibleOptions={[createNew]}
      selected={selected}
      onSelect={(contact) => setSelected(contact)}
      toValue={(contact) => `${contact?.firstName} ${contact?.lastName} ${contact?.email}`}
      toLabel={(contact) => `${contact?.firstName} ${contact?.lastName} ${contact?.email}`}
    />
  );
};

import { MutationFunctionOptions } from "@apollo/client";
import { useCallback } from "react";

import { useToast } from "@/hooks/use-toast";
import {
  CreateSegmentRequirementInput,
  CreateSegmentRequirementMutation,
  useCreateSegmentRequirementMutation,
} from "src/generated/graphql";

import { useSegment } from "./use-segment";

export const useCreateSegmentRequirement = () => {
  const { segment } = useSegment();
  const { toast } = useToast();

  const [createSegmentRequirementMutation, { loading: isCreatingSegmentRequirement }] =
    useCreateSegmentRequirementMutation({
      awaitRefetchQueries: true,
      onCompleted: () => toast({ title: "Segment requirement added" }),
      refetchQueries: ["Segment"],
    });

  const createSegmentRequirement = useCallback(
    async (
      input: Omit<CreateSegmentRequirementInput, "segmentId">,
      options?: Omit<MutationFunctionOptions<CreateSegmentRequirementMutation>, "variables">
    ) =>
      await createSegmentRequirementMutation({
        variables: { input: { ...input, segmentId: segment!.id } },
        ...options,
      }),
    [segment?.id]
  );

  return {
    createSegmentRequirement,
    isCreatingSegmentRequirement,
  };
};

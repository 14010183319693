import { FC } from "react";
import { useSearchParam } from "react-use";

import { useOpenForm } from "@/components/modal-provider";
import { Button, ButtonProps } from "@/components/ui/button";

import { getRuleFormDefaultValues } from "../../components/rule-form/rule-form";
import { useCreateSegmentAppetiteProductRule } from "../../hooks/use-create-segment-appetite-product-rule";
import { useSegment } from "../../hooks/use-segment";
import {
  CreateSegmentAppetiteProductRuleFormSchema,
  SegmentAppetiteProductRuleForm,
} from "./segment-appetite-product-rule-form";

export interface CreateRuleButtonProps extends ButtonProps {
  segmentAppetiteProductId?: string;
}
export const CreateRuleButton: FC<CreateRuleButtonProps> = ({
  children,
  segmentAppetiteProductId: overrideSegmentAppetiteProductId = "",
  ...props
}) => {
  const segmentAppetiteProductId = useSearchParam("segmentAppetiteProductId") || overrideSegmentAppetiteProductId;
  const { openForm } = useOpenForm();
  const { segment } = useSegment();
  const { createRule } = useCreateSegmentAppetiteProductRule();

  const handleClick = async () =>
    await openForm(<SegmentAppetiteProductRuleForm segment={segment} />, {
      title: "Add Rule",
      defaultValues: { ...getRuleFormDefaultValues(), segmentAppetiteProductId },
      validationSchema: CreateSegmentAppetiteProductRuleFormSchema,
      onSubmit: async (values) => await createRule(values),
    });

  return (
    <Button variant="outline" size="sm" {...props} onClick={handleClick}>
      {children || "Add Rule"}
    </Button>
  );
};

import { FC } from "react";
import { Link } from "react-router-dom";
import { z } from "zod";

import { HasInternalRole } from "@/components/has-role";
import { useModal } from "@/components/modal-provider";
import { MoreMenu } from "@/components/more-menu";
import { Button } from "@/components/ui/button";
import { DropdownMenuItem } from "@/components/ui/dropdown-menu";
import { Icon } from "@/components/ui/icon";
import { FieldInput } from "@/forms/fields/field-input";
import { FieldSelect } from "@/forms/fields/field-select";
import { useToast } from "@/hooks/use-toast";
import {
  FileUploadFragment,
  File_Audience,
  useDeleteFileUploadMutation,
  useUpdateFileUploadMutation,
} from "src/generated/graphql";
import { downloadFile } from "src/utils/file";

import TagSelector from "./tag-selector";

export const FILE_MENU_OPTION_KEYS = [
  "editTags",
  "editPermissions",
  "renameFile",
  "deleteFile",
  "download",
  "viewInsured",
] as const;

const defaultInclude = FILE_MENU_OPTION_KEYS.filter((key) => key !== "viewInsured");

export type FileMenuOptionKeys = (typeof FILE_MENU_OPTION_KEYS)[number];

export interface FileMenuProps {
  file: FileUploadFragment & { labels: string[]; insuredId?: string | null };
  include?: FileMenuOptionKeys[];
}

export const FileMenu: FC<FileMenuProps> = ({ file, include = defaultInclude }) => (
  <HasInternalRole elseShow={<DownloadFileButton file={file} />}>
    <MoreMenu>
      {include.includes("editPermissions") && <EditPermissions file={file} />}
      {include.includes("renameFile") && <RenameFile file={file} />}
      {include.includes("editTags") && <EditTags file={file} />}
      {include.includes("deleteFile") && <DeleteFile file={file} />}
      {include.includes("viewInsured") && <ViewInsured file={file} />}
      {include.includes("download") && <DownloadFile file={file} />}
    </MoreMenu>
  </HasInternalRole>
);

const RenameFile = ({ file }: { file: FileMenuProps["file"] }) => {
  const { openForm } = useModal();
  const [update] = useUpdateFileUploadMutation();
  const { toast } = useToast();

  const validationSchema = z.object({ filename: z.string().min(1, { message: "Invalid name" }) });

  const defaultValues = { filename: file.filename ?? "" };

  const onSubmit = async (values: z.infer<typeof validationSchema>) =>
    await update({
      variables: { input: { id: file.id, ...values } },
      onError: (e) => toast({ title: e.message }),
      onCompleted: () => toast({ title: `Filename updated` }),
      refetchQueries: ["FilesByLabel"],
    });

  const handleClick = () =>
    openForm(<FieldInput name="filename" placeholder="Audience" />, {
      title: "Rename file",
      description: (
        <>
          Rename the selected file: {file.filename}
          <div>A file extension (.pdf, .png, etc) is not necessary</div>
        </>
      ),
      validationSchema,
      defaultValues,
      onSubmit,
    });

  return (
    <DropdownMenuItem onClick={handleClick}>
      <Icon icon="edit" />
      Edit filename
    </DropdownMenuItem>
  );
};

const EditTags = ({ file }: { file: FileMenuProps["file"] }) => {
  const { openModal } = useModal();

  const handleClick = () =>
    openModal(() => <TagSelector fileId={file.id} />, {
      type: "dialog",
      title: "Edit tags",
      description: (
        <>
          Add or remove tags for this file.
          <div>{file.filename}</div>
        </>
      ),
    });

  return (
    <DropdownMenuItem onClick={handleClick}>
      <Icon icon="style" />
      Edit tags
    </DropdownMenuItem>
  );
};

const EditPermissions = ({ file }: { file: FileMenuProps["file"] }) => {
  const { openForm } = useModal();
  const { toast } = useToast();
  const [update] = useUpdateFileUploadMutation();

  const validationSchema = z.object({ audience: z.nativeEnum(File_Audience) });

  const defaultValues = { audience: (file?.audience as File_Audience) ?? File_Audience.Internal };

  const options = [
    { value: File_Audience.Internal, label: "Internal users only" },
    { value: File_Audience.External, label: "All users" },
  ];

  const onSubmit = async ({ audience }: z.infer<typeof validationSchema>) =>
    await update({
      variables: { input: { id: file.id, audience } },
      onError: (e) => toast({ title: e.message }),
      onCompleted: () => toast({ title: `Permission updated` }),
      refetchQueries: ["FilesByLabel"],
    });

  const handleClick = () =>
    openForm(<FieldSelect name="audience" placeholder="Audience" options={options} />, {
      title: "Edit permissions",
      description: (
        <>
          Select who should have access to this file
          <div>{file.filename}</div>
        </>
      ),
      validationSchema,
      defaultValues,
      onSubmit,
    });

  return (
    <DropdownMenuItem onClick={handleClick}>
      <Icon icon="lock" />
      Edit permissions
    </DropdownMenuItem>
  );
};

const DeleteFile = ({ file }: { file: FileMenuProps["file"] & { deletedAt?: Date | null } }) => {
  const { openConfirmation } = useModal();
  const { toast } = useToast();
  const [deleteFile] = useDeleteFileUploadMutation();
  const restoreFile = file.deletedAt ? false : true;

  const handleClick = async () => {
    const isConfirmed = await openConfirmation({
      title: restoreFile ? "Delete file" : "Restore file",
      description: (
        <>
          {restoreFile ? "Delete file" : "Restore file"}: {file.filename}
          <div>This will {restoreFile ? "delete the file" : "restore the file"}.</div>
        </>
      ),
      buttonProps: { theme: "destructive", children: restoreFile ? "Delete file" : "Restore file" },
    });

    if (!isConfirmed) {
      return;
    }

    await deleteFile({
      variables: { input: { id: file.id } },
      onError: (e) => toast({ title: e.message }),
      onCompleted: () => toast({ title: `File ${restoreFile ? "deleted" : "restored"}` }),
      refetchQueries: ["FilesByLabel"],
    });
  };

  return (
    <DropdownMenuItem onClick={handleClick}>
      <Icon icon="delete" />
      {restoreFile ? "Delete file" : "Restore file"}
    </DropdownMenuItem>
  );
};

const ViewInsured = ({ file }: { file: FileMenuProps["file"] }) => (
  <DropdownMenuItem asChild>
    <Link to={`/insured/${file.insuredId}`}>
      <Icon icon="person" />
      View Insured
    </Link>
  </DropdownMenuItem>
);

const DownloadFile = ({ file }: { file: FileMenuProps["file"] }) => (
  <DropdownMenuItem>
    <a
      href={`/api/files/${file.id}`}
      download={file.filename}
      media={file.mimeType}
      onClick={async (e) => {
        e.preventDefault();
        await downloadFile(file);
      }}
      className="flex gap-3 items-center"
    >
      <Icon icon="download" />
      Download
    </a>
  </DropdownMenuItem>
);

const DownloadFileButton = ({ file }: { file: FileMenuProps["file"] }) => (
  <Button variant="ghost" size="xs" display="icon" asChild>
    <a
      href={`/api/files/${file.id}`}
      download={file.filename}
      media={file.mimeType}
      onClick={async (e) => {
        e.preventDefault();
        await downloadFile(file);
      }}
    >
      <Icon icon="download" />
    </a>
  </Button>
);

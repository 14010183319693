import { type ComponentProps, type FC, type ReactNode } from "react";

import { ToggleGroup, ToggleGroupItem } from "@/components/ui/toggle-group";
import { cn } from "src/utils";

import { useField } from "../hooks/use-field";
import { FieldBase, FieldBaseProps } from "./field-base";

export interface FieldToggleGroupOption {
  label: ReactNode;
  value: string;
  itemProps?: Omit<ComponentProps<typeof ToggleGroupItem>, "value">;
}

export type FieldToggleGroupProps = FieldBaseProps & {
  options: readonly FieldToggleGroupOption[];
  type?: ComponentProps<typeof ToggleGroup>["type"];
  onChange?: (value: string | string[]) => void;
  onBlur?: ComponentProps<typeof ToggleGroupItem>["onBlur"];
  inputProps?: Omit<ComponentProps<typeof ToggleGroup>, "type">;
};

export const FieldToggleGroup: FC<FieldToggleGroupProps> = ({
  options,
  type = "single",
  onChange,
  onBlur,
  inputProps,
  ...props
}) => {
  const { field, hasError } = useField({ name: props.name, disabled: props.disabled, inputProps });

  const handleChange: FieldToggleGroupProps["onChange"] = (value: string | string[]) => {
    field.onChange(value);
    onChange?.(value);
  };

  const handleBlur: FieldToggleGroupProps["onBlur"] = (event) => {
    field.onBlur();
    onBlur?.(event);
  };

  return (
    <FieldBase {...props}>
      {/* @ts-expect-error toggle group should accept both "single" and "multiple" for `type` */}
      <ToggleGroup
        ref={field.ref}
        type={type || "single"}
        onValueChange={handleChange}
        value={field.value}
        {...inputProps}
        className={cn("my-1", inputProps?.className)}
      >
        {options.length > 0 && (
          <>
            {options.map((option) => {
              const isDisabled = field.disabled || option.itemProps?.disabled;

              return (
                <ToggleGroupItem
                  {...option.itemProps}
                  key={option.value}
                  value={option.value}
                  onBlur={handleBlur}
                  disabled={isDisabled}
                  className={cn(
                    {
                      "border-destructive": !!hasError && !isDisabled,
                    },
                    option.itemProps?.className
                  )}
                >
                  {option.label}
                </ToggleGroupItem>
              );
            })}
          </>
        )}
      </ToggleGroup>
    </FieldBase>
  );
};

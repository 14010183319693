import { FieldComboboxOption } from "@/forms/fields/field-combobox";
import { FieldComboboxAsync, FieldComboboxAsyncProps } from "@/forms/fields/field-combobox-async";
import { FC, useCallback } from "react";
import { useFormContext } from "react-hook-form";
import { BusinessClassFragment, BusinessClassSystem, useSearchBusinessClassesLazyQuery } from "src/generated/graphql";

export interface CglCodeSelectorProps
  extends Omit<FieldComboboxAsyncProps, "name" | "loadOptions" | "loadDefaultOptions"> {
  name?: string;
  classSystems?: BusinessClassSystem[];
  useCodeAsDisplayValue?: boolean;
}

export const CglCodeSelector: FC<CglCodeSelectorProps> = ({
  name = "isoCglCodes",
  label = "CGL Code(s)",
  classSystems = [BusinessClassSystem.IsoGl],
  placeholder = "Search CGL classifications",
  useCodeAsDisplayValue = true,
  ...props
}) => {
  const [load] = useSearchBusinessClassesLazyQuery();
  const { watch } = useFormContext();

  const fieldValue = watch(name);

  const businessClassToOption = (c: BusinessClassFragment): FieldComboboxOption => ({
    displayValue: useCodeAsDisplayValue ? c.code : undefined,
    label: `${c.code} ${c.code && c.description ? `-` : ""} ${c.description}`,
    value: c.code,
  });

  const loadDefaultOptions = useCallback(
    async () =>
      await load({
        variables: { input: { classCodes: fieldValue || undefined, classSystems } },
      }).then((res) => (res.data?.searchBusinessClasses ?? []).map(businessClassToOption)),
    [fieldValue]
  );

  const loadOptions = useCallback(
    async (text?: string) =>
      await load({ variables: { input: { term: text, classSystems } } }).then((res) =>
        (res.data?.searchBusinessClasses ?? []).map(businessClassToOption)
      ),
    []
  );

  return (
    <FieldComboboxAsync
      name={name}
      label={label}
      placeholder={placeholder}
      {...props}
      loadDefaultOptions={loadDefaultOptions}
      loadOptions={loadOptions}
    />
  );
};

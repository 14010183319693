import { useImpersonateUser } from "@cp/auth";

import { Roles, UserAccountFragment } from "src/generated/graphql";
import { HasRole } from "./has-role";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "./ui/alert-dialog";

export const ImpersonateUser = ({
  children,
  elseShow = null,
  replace = false,
  roles = [Roles.Admin, Roles.Arm],
  user,
}: React.PropsWithChildren & {
  elseShow?: React.ReactNode;
  replace?: boolean;
  roles?: Roles[];
  user: UserAccountFragment;
}) => {
  const { mutate: impersonateUser } = useImpersonateUser();
  return (
    <HasRole roles={roles} elseShow={elseShow}>
      <AlertDialog>
        <AlertDialogTrigger asChild>{children}</AlertDialogTrigger>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>
              Impersonate {user.firstName} {user.lastName}?
            </AlertDialogTitle>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel>Cancel</AlertDialogCancel>
            <AlertDialogAction
              onClick={() => {
                impersonateUser(
                  { userIdToImpersonate: user.id },
                  {
                    onSuccess: () => {
                      const forward = replace ? window.location.origin : window.location.href;
                      window.location.replace(forward);
                    },
                  }
                );
              }}
            >
              Impersonate
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </HasRole>
  );
};

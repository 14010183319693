import { SidebarLink, SidebarSection } from "@/components/sidebar";
import {
  MarketingPlanState,
  QuoteState,
  useGroupedMarketingPlansQuery,
  useGroupedQuotesQuery,
} from "src/generated/graphql";

export const AgencyRequests = ({ id }: { id: string }) => {
  const { data: { groupedMarketingPlans: { groupedMarketingPlans = [] } = {} } = {} } = useGroupedMarketingPlansQuery({
    variables: {
      input: {
        agencyId: id,
        states: [MarketingPlanState.InformationGathering],
      },
    },
  });

  const { data: { groupedQuotes: { groupedQuotes = [] } = {} } = {} } = useGroupedQuotesQuery({
    variables: {
      input: {
        agencyId: id,
        states: [QuoteState.Delivered],
      },
    },
  });

  const quoteCount = groupedQuotes.flatMap((insured) => insured.quotes).length;
  const planCount = groupedMarketingPlans.flatMap((insured) => insured.marketingPlans).length;

  return (
    <>
      {groupedMarketingPlans[0] && (
        <SidebarSection>
          <h6 className="text-muted-foreground">
            {planCount} information request
            {planCount > 1 && "s"}
          </h6>
          <div className="space-y-1">
            {groupedMarketingPlans.map((group) =>
              group.marketingPlans.map((plan) => (
                <SidebarLink key={plan.id} to={`/insured/${group.insuredId}/plans/${plan.id}`}>
                  {group.insuredName}
                </SidebarLink>
              ))
            )}
          </div>
        </SidebarSection>
      )}
      {groupedQuotes[0] && (
        <SidebarSection>
          <h6 className="text-muted-foreground">
            {quoteCount} Quote{quoteCount > 1 && "s"} to Review
          </h6>
          <div className="space-y-1">
            {groupedQuotes.map((group) =>
              group.quotes.map((quote) => (
                <SidebarLink key={quote.id} to={`/insured/${group.insuredId}/quotes/${quote.id}`}>
                  {group.insuredName}
                </SidebarLink>
              ))
            )}
          </div>
        </SidebarSection>
      )}
    </>
  );
};

import { SnoozeButton } from "@/bankan/snooze/snooze-button";
import { Button } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { Icon } from "@/components/ui/icon";
import { Separator } from "@/components/ui/separator";
import { BankanMarketingPlanColumnQuery } from "src/generated/graphql";
import { daysDelta, formatDateString } from "src/utils/date";
import { BankanCard, BankanCardHeader, BankanLink, BankanLinkFooter, BankanLinkText, DaysSince } from "./bankan-card";
import { BankanScore } from "./bankan-score";

type Group = BankanMarketingPlanColumnQuery["bankanMarketingPlanColumn"]["groupedMarketingPlans"][number];

export const MarketingPlansCard = ({ group, cardClassName }: { group: Group; cardClassName: string }) => {
  const { insuredId, insured, marketingPlans } = group;
  return (
    <BankanCard className={cardClassName}>
      <BankanCardHeader insured={insured} />
      {marketingPlans.map((plan) => {
        const { id, createdAt, desiredEffectiveDate, selectedLinesOfBusiness, lastTransitionAt, score, snoozeUntil } =
          plan;
        const daysCreated = daysDelta(createdAt);
        const daysTransitioned = daysDelta(lastTransitionAt);

        return (
          <div key={id} className="relative">
            <Separator />
            <BankanLink to={`/insured/${insuredId}/plans/${id}`}>
              <BankanLinkText className="space-y-1">
                {selectedLinesOfBusiness[0]
                  ? selectedLinesOfBusiness.map((lob) => <div key={lob}>{lob}</div>)
                  : "No Coverage Selected"}
              </BankanLinkText>
              <BankanLinkFooter>{formatDateString(desiredEffectiveDate, "MMM d, yyyy")}</BankanLinkFooter>
            </BankanLink>
            <div className="absolute bottom-3 gap-3 inline-flex items-center right-4">
              <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  <Button variant="ghost" size="2xs" className="gap-3 -m-0.5 text-muted-foreground">
                    {snoozeUntil && <DaysSince date={snoozeUntil} icon="bedtime" />}
                    <DaysSince date={createdAt} icon="markunread_mailbox" />
                    <DaysSince date={lastTransitionAt} />
                  </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent align="end" className="filled space-y-1.5">
                  <DropdownMenuLabel>
                    <Icon icon="mode_heat" />
                    <BankanScore bankanScore={score} />
                  </DropdownMenuLabel>
                  <DropdownMenuSeparator />
                  <DropdownMenuLabel>
                    <Icon icon="markunread_mailbox" />
                    {daysCreated} day{daysCreated > 1 && "s"} since submission
                  </DropdownMenuLabel>
                  <DropdownMenuLabel>
                    <Icon icon="event" />
                    {daysTransitioned} day{daysTransitioned > 1 && "s"} in this column
                  </DropdownMenuLabel>
                  <DropdownMenuSeparator />
                  <SnoozeButton marketingPlan={{ id, desiredEffectiveDate, snoozeUntil }} />
                </DropdownMenuContent>
              </DropdownMenu>
            </div>
          </div>
        );
      })}
    </BankanCard>
  );
};

import { format } from "date-fns";
import type { ComponentProps, FC } from "react";

import { DatePicker, parseDatePickerValue } from "@/components/ui/date-picker";
import { Icon } from "@/components/ui/icon";
import { cn } from "src/utils";

import { useField } from "../hooks/use-field";
import { FieldBase, FieldBaseProps } from "./field-base";
import { FIELD_BORDERLESS_CLASS_NAME } from "./field.constants";

export type FieldDatePickerProps = FieldBaseProps & {
  placeholder?: string;
  borderless?: boolean;
  dateFormat?: string;
  hideCalendarIcon?: boolean;
  onChange?: ComponentProps<typeof DatePicker>["onChange"];
  onBlur?: ComponentProps<typeof DatePicker>["onBlur"];
  inputProps?: ComponentProps<typeof DatePicker>;
};

export const FieldDatePicker: FC<FieldDatePickerProps> = ({
  inputProps,
  borderless,
  placeholder = "Pick a date",
  dateFormat = "MMMM d, yyyy",
  onChange,
  onBlur,
  hideCalendarIcon,
  ...props
}) => {
  const { field, hasError } = useField({ name: props.name, disabled: props.disabled, inputProps });

  const handleChange: FieldDatePickerProps["onChange"] = (event) => {
    field.onChange(event);
    onChange?.(event);
  };

  const handleBlur: FieldDatePickerProps["onBlur"] = (event) => {
    field.onBlur();
    onBlur?.(event);
  };

  const fieldValueAsDate = parseDatePickerValue(field.value);

  return (
    <FieldBase {...props}>
      <DatePicker
        {...field}
        {...inputProps}
        onChange={handleChange}
        onBlur={handleBlur}
        variant="outline"
        className={cn(
          "min-w-[180px] justify-start text-left font-normal px-3 text-sm",
          !field.value && "text-muted-foreground",
          {
            "border-destructive": hasError && !field.disabled,
            [FIELD_BORDERLESS_CLASS_NAME]: borderless,
          },
          inputProps?.className
        )}
      >
        {!hideCalendarIcon && <Icon icon="calendar_today" className="size-4 text-base text-foreground opacity-50" />}
        {fieldValueAsDate ? format(fieldValueAsDate, dateFormat) : <span>{placeholder}</span>}
      </DatePicker>
    </FieldBase>
  );
};

import { Navigate, RouteObject } from "react-router-dom";

import { Alby } from "@/alby/alby";
import { AlbyApproval } from "@/alby/alby-approval";
import { ArmBankan } from "@/bankan/arm-bankan";
import { Bankan } from "@/bankan/bankan";
import { BankanRedirect } from "@/bankan/bankan-redirect";
import { BrokerBankan } from "@/bankan/broker-bankan";
import { DjBankan } from "@/bankan/dj-bankan";
import { StsBankan } from "@/bankan/sts-bankan";
import { ErrorMessage } from "@/components/errors/error-message";
import { AlbyProvider } from "@/hooks/use-alby";
import { BankanProvider } from "@/hooks/use-bankan";
import { InsuredProvider } from "@/hooks/use-insured";
import { Jobs } from "@/jobs/jobs";
import { Segments } from "@/segments";
import { SegmentProvider } from "@/segments/hooks/use-segment";
import { Segment } from "@/segments/segment";
import { SegmentAppetiteProducts } from "@/segments/segment-appetite-products/segment-appetite-products";
import { SegmentAssignmentPool } from "@/segments/segment-assignment-pool";
import { SegmentCriteria } from "@/segments/segment-criteria";
import { SegmentRequirements } from "@/segments/segment-requirements/segment-requirements";
import { SegmentsList } from "@/segments/segments";
import { VerticalProvider } from "@/verticals/components/use-vertical";
import { EditVertical } from "@/verticals/edit-vertical";
import { Vertical } from "@/verticals/vertical";
import { VerticalAppetite } from "@/verticals/vertical-appetite";
import { VerticalExclusionReasons } from "@/verticals/vertical-exclusion-reasons";
import { VerticalHierarchy } from "@/verticals/vertical-hierarchy";
import { VerticalRequirements } from "@/verticals/vertical-requirements";
import { VerticalRules } from "@/verticals/vertical-rules";
import { Verticals, VerticalsList } from "@/verticals/verticals";
import { SegmentAppetiteProductRules } from "../segments/segment-appetite-product-rules/segment-appetite-product-rules";

export const internalRoutes: RouteObject[] = [
  {
    path: "alby/:brokerId?",
    element: (
      <AlbyProvider>
        <Alby />
      </AlbyProvider>
    ),
    children: [
      {
        index: true,
        element: <div />,
      },
      {
        path: ":insuredId/:graphName/:selectedTool",
        element: (
          <InsuredProvider>
            <AlbyApproval />
          </InsuredProvider>
        ),
        errorElement: <ErrorMessage />,
      },
    ],
  },
  {
    path: "bankan",
    element: (
      <BankanProvider>
        <Bankan />
      </BankanProvider>
    ),
    children: [
      {
        index: true,
        element: <BankanRedirect />,
      },
      {
        path: ":brokerId",
        element: <BankanRedirect />,
      },
      {
        path: "arm/:ownerId?",
        element: <ArmBankan />,
      },
      {
        path: "broker/:brokerId?",
        element: <BrokerBankan />,
      },
      {
        path: "dj/:brokerId?",
        element: <DjBankan />,
      },
      {
        path: "sts/:brokerId?",
        element: <StsBankan />,
      },
    ],
  },
  {
    path: "jobs/:brokerId?",
    element: <Jobs />,
  },
  {
    path: "segments",
    element: <Segments />,
    errorElement: <ErrorMessage />,
    children: [
      {
        index: true,
        element: <SegmentsList />,
      },
      {
        path: ":segmentId",
        element: (
          <SegmentProvider>
            <Segment />
          </SegmentProvider>
        ),
        children: [
          {
            index: true,
            element: <Navigate to="products" replace />,
          },
          {
            path: "products",
            element: <SegmentAppetiteProducts />,
          },
          {
            path: "product-rules",
            element: <SegmentAppetiteProductRules />,
          },
          {
            path: "requirements",
            element: <SegmentRequirements />,
          },
          {
            path: "assignees",
            element: <SegmentAssignmentPool />,
          },
          {
            path: "criteria",
            element: <SegmentCriteria />,
          },
          {
            path: "exclusion-reasons",
            element: (
              <div className="p-6">
                <h3>Exclusion Reasons</h3>
              </div>
            ),
          },
        ],
      },
    ],
  },
  {
    path: "verticals",
    element: <Verticals />,
    errorElement: <ErrorMessage />,
    children: [
      {
        index: true,
        element: <VerticalsList />,
      },
      {
        path: "create",
        element: <EditVertical />,
      },
      {
        path: ":verticalId",
        element: (
          <VerticalProvider>
            <Vertical />
          </VerticalProvider>
        ),
        children: [
          {
            index: true,
            element: <Navigate to="hierarchy" />,
          },
          {
            path: "hierarchy",
            element: <VerticalHierarchy />,
          },
          {
            path: "rules",
            element: <VerticalRules />,
          },
          {
            path: "requirements",
            element: <VerticalRequirements />,
          },
          {
            path: "appetite",
            element: <VerticalAppetite />,
          },
          {
            path: "exclusion-reasons",
            element: <VerticalExclusionReasons />,
          },
          {
            path: "edit",
            element: <EditVertical />,
          },
        ],
      },
    ],
  },
];

import { ChangeEventHandler, ComponentProps, forwardRef, useEffect, useState } from "react";

import { Button } from "@/components/ui/button";
import { Icon } from "@/components/ui/icon";
import { cn } from "src/utils";

export interface SearchInputProps extends ComponentProps<"input"> {
  onValueChange?: (value: string) => void;
}

export const SearchInput = forwardRef<HTMLInputElement, SearchInputProps>(
  ({ className, type, onValueChange, ...props }, ref) => {
    const [value, setValue] = useState<HTMLInputElement["value"]>("");

    useEffect(() => {
      setValue((props.value || "") as HTMLInputElement["value"]);
    }, [props.value]);

    const handleValueChange = (value: string) => {
      setValue(value);
      onValueChange?.(value);
    };

    const onClear = () => handleValueChange("");

    const handleChange: ChangeEventHandler<HTMLInputElement> = (event) => handleValueChange(event.target.value);

    return (
      <div className="relative">
        <Icon icon="search" className="absolute bottom-0 left-3 m-auto text-muted-foreground text-sm top-0" />
        <input
          ref={ref}
          type="search"
          {...props}
          value={value}
          onChange={handleChange}
          className={cn(
            "rounded-3xl bg-foreground/5 border-0 font-normal h-8 pl-8 pr-8 py-2 text-xs focus-visible:outline-hidden placeholder:text-muted-foreground disabled:cursor-not-allowed disabled:opacity-50 [&::-webkit-search-decoration]:hidden [&::-webkit-search-cancel-button]:hidden [&::-webkit-search-results-button]:hidden [&::-webkit-search-results-decoration]:hidden",
            className
          )}
        />
        {!!value?.length && (
          <Button
            variant="unstyled"
            size="xs"
            display="icon"
            onClick={onClear}
            className="absolute bottom-0 right-1 m-auto top-0 text-sm text-muted-foreground"
          >
            <Icon icon="close" />
          </Button>
        )}
      </div>
    );
  }
);
SearchInput.displayName = "SearchInput";

import { useVertical } from "./components/use-vertical";
import { TemplateExclusionReasons } from "./template-exclusion-reasons";

export const VerticalExclusionReasons = () => {
  const { templates } = useVertical();

  return (
    <div className="divide-y">
      {templates.map((template) => (
        <div key={template.id} className="p-6">
          <TemplateExclusionReasons template={template} />
        </div>
      ))}
    </div>
  );
};

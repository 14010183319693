import { FC, useMemo } from "react";

import { FieldCombobox, FieldComboboxProps } from "@/forms/fields/field-combobox";
import { useAppetiteProductsQuery } from "src/generated/graphql";

export interface AppetiteProductSelectorProps extends Omit<FieldComboboxProps, "options"> {
  disabledProductIds: string[];
}

export const AppetiteProductSelector: FC<AppetiteProductSelectorProps> = ({ disabledProductIds, ...props }) => {
  const { data, loading } = useAppetiteProductsQuery();

  const options = useMemo(
    () =>
      [...(data?.appetiteProducts ?? [])].map((product) => ({
        label: `${product.carrierName} ${product.carrierProductName}`,
        value: product.id,
        displayValue: `${product.carrierName} ${product.carrierProductName}`,
        disabled: disabledProductIds.includes(product.id),
      })),
    [data?.appetiteProducts]
  );

  return <FieldCombobox placeholder="Search and select products" {...props} options={options} loading={loading} />;
};

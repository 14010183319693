import { Link } from "react-router-dom";

import { useModal } from "@/components/modal-provider";
import { MoreMenu } from "@/components/more-menu";
import { Button } from "@/components/ui/button";
import { DropdownMenuItem } from "@/components/ui/dropdown-menu";
import { Icon } from "@/components/ui/icon";
import { useMarketingPlan } from "@/hooks/use-marketing-plan";
import { MarketingPlanAction, useTransitionMarketingPlanMutation } from "src/generated/graphql";

import { RulesFormButton } from "./rules-form";

export const Marketing = () => {
  const { opportunity, refetchQueries } = useMarketingPlan();
  const [transition] = useTransitionMarketingPlanMutation();
  const { openConfirmation } = useModal();

  const handleTransition = async (action: MarketingPlanAction) => {
    const isConfirmed = await openConfirmation({
      title: "Confirm marketing complete",
    });

    if (!isConfirmed) {
      return;
    }

    await transition({
      variables: {
        input: {
          id: opportunity.id,
          expectedState: opportunity.state,
          action,
        },
      },
      refetchQueries,
    });
  };

  return (
    <>
      <Button variant="outline" size="xs" className="@max-xl/scrollpane:hidden" asChild>
        <Link to="markets">Select Markets</Link>
      </Button>
      <Button
        variant="outline"
        size="xs"
        className="@max-xl/scrollpane:hidden"
        onClick={() => handleTransition(MarketingPlanAction.CompleteMarketing)}
      >
        Complete Marketing
      </Button>
      <MoreMenu className="@xl/scrollpane:hidden">
        <DropdownMenuItem asChild>
          <Link to="markets">
            <Icon icon="checklist" />
            Select Markets
          </Link>
        </DropdownMenuItem>
        <DropdownMenuItem onClick={() => handleTransition(MarketingPlanAction.CompleteMarketing)}>
          <Icon icon="verified" />
          Complete Marketing
        </DropdownMenuItem>
        <RulesFormButton />
      </MoreMenu>
    </>
  );
};

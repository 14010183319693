import { RouteObject } from "react-router-dom";

import { ActivateAccount, activateAccountLoader } from "@/auth/activate-account";
import { ForgotPassword } from "@/auth/forgot-password";
import { Login } from "@/auth/login";
import { CenteredPage } from "@/components/page";

export const publicRoutes: RouteObject[] = [
  {
    element: <CenteredPage />,
    children: [
      {
        path: "login",
        element: <Login />,
      },
      {
        path: "forgot-password",
        element: <ForgotPassword />,
      },
      {
        path: "activate-account/:changePasswordId",
        loader: activateAccountLoader,
        element: <ActivateAccount />,
      },
    ],
  },
];

import { useAtom } from "jotai";
import { Fragment, useEffect } from "react";

import { eventsBotAtom, eventsCommentAtom, eventsDescAtom, eventsEmailAtom } from "@/atoms";
import {
  OnOpportunityEventAddedDocument,
  OnOpportunityEventAddedSubscription,
  OnOpportunityEventAddedSubscriptionVariables,
  OpportunityEventType,
  useOpportunityEventsQuery,
} from "src/generated/graphql";
import { cn } from "src/utils";
import { SectionHeader, SectionTitle } from "../section";
import { Card } from "../ui/card";
import { Separator } from "../ui/separator";
import { CommentForm } from "./comment-form";
import { EventFilter } from "./event-filter";
import { EventRenderer } from "./event-renderer";

export const eventTypes = {
  opportunity: [
    OpportunityEventType.ApplicationSubmitted,
    OpportunityEventType.AssignmentChange,
    OpportunityEventType.TriageAssignment,
    OpportunityEventType.Created,
    OpportunityEventType.DesiredEffectiveDateChange,
    OpportunityEventType.OutcomeChange,
    OpportunityEventType.StatusChange,
    OpportunityEventType.CoveragesChanged,
    OpportunityEventType.BusinessClassCodesChanged,
    OpportunityEventType.RequirementAdded,
    OpportunityEventType.SegmentIdentified,
  ],
  submission: [
    OpportunityEventType.ProductsAdded,
    OpportunityEventType.ProductsRemoved,
    OpportunityEventType.SubmissionStatusChange,
  ],
  bot: [OpportunityEventType.BotComment, OpportunityEventType.AutomatedFollowUp],
  comment: [OpportunityEventType.UserComment],
  email: [OpportunityEventType.Email, OpportunityEventType.GmailMessage, OpportunityEventType.GmailRelay],
  upload: [OpportunityEventType.FileUpload],
  alby: [OpportunityEventType.AlbyUpdate],
};

export const Activity = ({ className, opportunityId }: { className?: string; opportunityId: string }) => {
  const [comment] = useAtom(eventsCommentAtom);
  const [email] = useAtom(eventsEmailAtom);
  const [bot] = useAtom(eventsBotAtom);
  const [desc] = useAtom(eventsDescAtom);

  const types = [
    ...(bot ? eventTypes.bot : []),
    ...(email ? eventTypes.email : []),
    ...(comment ? eventTypes.comment : []),
  ];

  const { data: { opportunityEvents = [] } = {}, subscribeToMore } = useOpportunityEventsQuery({
    variables: {
      input: { opportunityId: opportunityId || "", types },
    },
    skip: !opportunityId,
  });

  useEffect(() => {
    return subscribeToMore<OnOpportunityEventAddedSubscription, OnOpportunityEventAddedSubscriptionVariables>({
      document: OnOpportunityEventAddedDocument,
      variables: { id: opportunityId || "" },
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data) {
          return prev;
        }

        const newEvent = subscriptionData.data.opportunityEventAdded;

        return {
          opportunityEvents: [...prev.opportunityEvents, newEvent],
        };
      },
    });
  }, []);

  if (!opportunityId) {
    return null;
  }

  return (
    <Card className={className}>
      <SectionHeader>
        <SectionTitle>
          <h3>Activity</h3>
          <EventFilter />
        </SectionTitle>
      </SectionHeader>
      <Separator />
      <div className={cn("flex", desc ? "flex-col" : "flex-col-reverse")} id="opportunityEvents">
        {opportunityEvents.map((event) => (
          <Fragment key={event.id}>
            <EventRenderer event={event} />
            <Separator />
          </Fragment>
        ))}
        <CommentForm opportunityId={opportunityId} />
      </div>
    </Card>
  );
};

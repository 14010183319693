import { useAtom } from "jotai";
import { createContext, useContext, useState } from "react";
import { useParams } from "react-router";

import { pinnedInsuredsAtom } from "@/atoms";
import { BankanMarketingPlanColumnQuery } from "src/generated/graphql";
import { adaptSearchParams } from "src/utils/adapt-search-params";
import { useUpsertSearchParams } from "./use-upsert-search-params";

type MarketingPlan =
  BankanMarketingPlanColumnQuery["bankanMarketingPlanColumn"]["groupedMarketingPlans"][number]["marketingPlans"][number];
export type SnoozableMarketingPlan = Pick<MarketingPlan, "id" | "desiredEffectiveDate" | "snoozeUntil">;

interface BankanContextValue {
  agencyId?: string;
  brokerId?: string;
  ownerId?: string;
  pinnedIds: string[];
  setSnoozableMarketingPlan: (marketingPlan: SnoozableMarketingPlan | null) => void;
  snoozableMarketingPlan: SnoozableMarketingPlan | null;
}

const BankanContext = createContext({} as BankanContextValue);

export const BankanProvider = ({ children }: { children: React.ReactNode }) => {
  const [pinnedInsureds] = useAtom(pinnedInsuredsAtom);
  const { brokerId, ownerId } = useParams();
  const [snoozableMarketingPlan, setSnoozableMarketingPlan] = useState<SnoozableMarketingPlan | null>(null);
  const [searchParams] = useUpsertSearchParams();

  const { agencyId } = adaptSearchParams(searchParams);
  const pinnedIds = Object.keys(pinnedInsureds);
  const value = { agencyId, brokerId, ownerId, pinnedIds, snoozableMarketingPlan, setSnoozableMarketingPlan };

  return <BankanContext.Provider value={value}>{children}</BankanContext.Provider>;
};

export const useBankan = () => {
  const context = useContext(BankanContext);

  if (!context) {
    throw new Error("useBankan must be used within a BankanProvider");
  }

  return context;
};

import { ComponentProps, forwardRef, useEffect, useRef, useState } from "react";

import { Button } from "@/components/ui/button";
import { Calendar } from "@/components/ui/calendar";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover";
import { setRefs } from "src/utils/set-refs";

export interface DatePickerProps extends Omit<ComponentProps<typeof Button>, "value" | "onChange"> {
  value?: Date | string;
  onChange?: (value?: Date) => void;
  calendarProps?: Omit<ComponentProps<typeof Calendar>, "mode">;
  closeOnSelect?: boolean;
}

export const DatePicker = forwardRef<HTMLButtonElement, DatePickerProps>(
  ({ value, onChange, onBlur, calendarProps, closeOnSelect = false, children, ...props }, ref) => {
    const buttonRef = useRef<HTMLButtonElement | null>(null);
    const dropdownRef = useRef<HTMLDivElement | null>(null);
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [date, setDate] = useState<Date | undefined>(parseDatePickerValue(value));

    const handleSelect = (date?: Date) => {
      setDate(date);
      onChange?.(date);
      closeOnSelect && setIsOpen(false);
    };

    const handleBlur = (event: any) => {
      setTimeout(() => {
        // Don't trigger blur If the focus is still within the field or dropdown.
        if (
          buttonRef.current?.contains(document.activeElement) ||
          dropdownRef.current?.contains(document.activeElement)
        ) {
          return;
        }

        onBlur?.(event);
      }, 0);
    };

    useEffect(() => {
      setDate(parseDatePickerValue(value));
    }, [value]);

    return (
      <Popover open={isOpen} onOpenChange={(open) => !props.disabled && setIsOpen(open)}>
        <PopoverTrigger onBlur={handleBlur} asChild>
          <Button ref={setRefs(ref, buttonRef)} {...props}>
            {children}
          </Button>
        </PopoverTrigger>

        <PopoverContent ref={dropdownRef} onBlur={handleBlur} className="w-auto p-0">
          <Calendar
            mode="single"
            initialFocus
            {...calendarProps}
            selected={date}
            defaultMonth={date || undefined}
            onSelect={handleSelect}
          />
        </PopoverContent>
      </Popover>
    );
  }
);
DatePicker.displayName = "DatePicker";

export function parseDatePickerValue(value?: Date | string) {
  return value ? (typeof value === "string" ? new Date(value) : value) : undefined;
}

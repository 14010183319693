import { useAtom } from "jotai";
import { useEffect } from "react";

import { correspondenceAtom, foldersVisibleAtom, selectTagsAtom } from "@/atoms";
import { GridRowHeader } from "@/components/grid";
import { HasInternalRole } from "@/components/has-role";
import { SectionHeader, SectionTitle } from "@/components/section";
import { Button } from "@/components/ui/button";
import { Icon } from "@/components/ui/icon";
import { Label } from "@/components/ui/label";
import { Switch } from "@/components/ui/switch";
import { FilesOptions } from "@/files/files-options";
import { TagRow } from "@/files/tag-row";
import { useFileTagsQuery } from "src/generated/graphql";
import { cn } from "src/utils";

export const FilesFolders = () => {
  const [foldersVisible, setFoldersVisible] = useAtom(foldersVisibleAtom);
  const [correspondence, setCorrespondence] = useAtom(correspondenceAtom);
  const [selectedTags, setSelectedTags] = useAtom(selectTagsAtom);

  let { data: { fileTags = [] } = {} } = useFileTagsQuery({
    variables: {
      input: {},
    },
  });

  fileTags = fileTags.filter((t) => t?.parentTags?.length === 0) || [];
  fileTags = correspondence ? fileTags : fileTags.filter((tag) => tag.label !== "Correspondence");

  useEffect(() => {
    setFoldersVisible(false);
  }, []);

  return (
    <HasInternalRole>
      <div className="@3xl:border-r col-span-1 flex flex-col">
        <SectionHeader className="hidden @3xl:block">
          <SectionTitle>
            <h3>Folders</h3>
          </SectionTitle>
        </SectionHeader>
        <SectionHeader className="bg-background flex @3xl:hidden items-center justify-between">
          <Button
            variant="outline"
            className="gap-3"
            onClick={() => {
              setFoldersVisible((curr) => !curr);
            }}
          >
            <Icon icon="folder_open" />
            {selectedTags || "All Files"}
            <Icon
              icon={foldersVisible ? "keyboard_arrow_up" : "keyboard_arrow_down"}
              className="bg-accent rounded-full"
            />
          </Button>
          <FilesOptions />
        </SectionHeader>
        <GridRowHeader className="border-b hidden @3xl:flex m-0">Name</GridRowHeader>
        <div className={cn(foldersVisible ? "flex" : "hidden", "@3xl:flex flex-auto flex-col")}>
          <div className="flex-auto overflow-auto px-6 py-2">
            {fileTags.map((t) => (
              <TagRow key={t.label} label={t.label} depth={0} />
            ))}
          </div>
          <div className="border-t flex items-center gap-3 px-4 py-4 text-2xs">
            <Switch
              id="correspondence"
              checked={correspondence}
              onCheckedChange={(val) => {
                setSelectedTags("");
                setCorrespondence(val);
              }}
            />
            <Label htmlFor="correspondence">Display Correspondence</Label>
          </div>
        </div>
      </div>
    </HasInternalRole>
  );
};

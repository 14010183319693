import { isNil, uniqBy } from "lodash";

import { Group } from "@/components/group";
import { useModal } from "@/components/modal-provider";
import { DialogFooter } from "@/components/ui/dialog";
import { DropdownMenuItem, DropdownMenuSeparator } from "@/components/ui/dropdown-menu";
import { Icon } from "@/components/ui/icon";
import { FieldCheckbox } from "@/forms/fields/field-checkbox";
import { FieldInput } from "@/forms/fields/field-input";
import { FieldNumber } from "@/forms/fields/field-number";
import { Form } from "@/forms/form";
import { FormReset } from "@/forms/form-reset";
import { FormSubmit } from "@/forms/form-submit";
import { useMarketingPlan } from "@/hooks/use-marketing-plan";
import { useToast } from "@/hooks/use-toast";
import { OpportunityDetailsFragment, useClientDataLazyQuery, useCreateClientDataMutation } from "src/generated/graphql";

interface Rule {
  type: "client-data" | "state" | "minimum-premium" | "cgl";
  valueType: "text" | "boolean" | "number";
  key: string;
}

export const RulesFormButton = () => {
  const { openModal } = useModal();
  const { opportunity } = useMarketingPlan();

  if (!opportunity.verticalMarketingPlanTemplate) {
    return null;
  }

  const { insuredId, verticalMarketingPlanTemplate } = opportunity;

  return (
    <>
      <DropdownMenuSeparator />
      <DropdownMenuItem
        onClick={() =>
          openModal(() => <RulesForm insuredId={insuredId} template={verticalMarketingPlanTemplate} />, {
            type: "dialog",
            title: "Carrier Eligibility",
            description: "Complete this form to help refine market selection.",
          })
        }
      >
        <Icon icon="database" />
        Carrier Eligibility
      </DropdownMenuItem>
    </>
  );
};

function RulesForm({
  insuredId,
  template,
}: {
  insuredId: string;
  template: NonNullable<OpportunityDetailsFragment["verticalMarketingPlanTemplate"]>;
}) {
  const { closeModal } = useModal();
  const { toast } = useToast();
  const [createClientData] = useCreateClientDataMutation({
    onCompleted: () => {
      toast({ title: "Saved" });
      closeModal();
    },
    onError: () => toast({ title: "Unable to save" }),
  });

  const rules = uniqBy(
    template.products
      .flatMap((p) => JSON.parse(p.rules) as Rule[])
      .flat()
      .filter((rule) => rule.type === "client-data"),
    (rule) => rule.key
  );

  const [load] = useClientDataLazyQuery({
    variables: { input: { insuredId, keys: rules.map((r) => r.key) } },
  });

  const handleSubmit = async (fd: Record<string, string | number | boolean>) =>
    await createClientData({
      variables: {
        input: {
          insuredId,
          source: "CARRIER_ELIGIBILITY_FORM",
          clientDataInputs: Object.entries(fd).map(([key, value]) => ({ key, value: `${isNil(value) ? "" : value}` })),
        },
      },
    });

  const defaultValues = async () => {
    const { data } = await load();

    if (!data) {
      return {};
    }

    const values = Object.fromEntries(data.clientData.map((cd) => mapClientDataValueToType([cd.key, cd.value], rules)));

    return values;
  };

  return (
    <Form defaultValues={defaultValues} onSubmit={handleSubmit}>
      <Group className="gap-6">
        <Group>
          {rules.map((rule) => (
            <RuleInput key={rule.key} rule={rule} />
          ))}
        </Group>

        <DialogFooter>
          <FormReset onClick={closeModal} />
          <FormSubmit />
        </DialogFooter>
      </Group>
    </Form>
  );
}

function RuleInput({ rule }: { rule: Rule }) {
  const fields = {
    text: FieldInput,
    number: FieldNumber,
    boolean: FieldCheckbox,
  };

  const Field = fields[rule.valueType];

  return Field ? <Field label={rule.key} name={rule.key} /> : null;
}

const mapClientDataValueToType = ([key, value]: [string, string], rules: Rule[]) => {
  const rule = rules.find((r) => r.key === key);

  switch (rule?.valueType) {
    case "number":
      return [key, value ? Number(value) : value];
    case "boolean":
      return [key, value === "true"];
    default:
      return [key, value];
  }
};

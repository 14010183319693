import { PolicyState } from "src/generated/graphql";
import { StateMetadata } from "./states";

export enum ExpiredPolicyState {
  Expired = "Expired",
}

export enum DisplayPolicyStateAwaitingFromCarrierActions {
  Receive = "Receive Policy from Carrier",
}

export enum DisplayPolicyStateIssuedActions {
  Cancel = "Cancel Policy",
}

export enum DisplayPolicyStateReceivedFromCarrierActions {
  Issue = "Issue Policy to Client",
}

export const POLICY_STATE_METADATA: Record<PolicyState, StateMetadata> = {
  [PolicyState.AwaitingBinderFromCarrier]: {
    className: "text-orange-600",
    cardClassName: "outline-orange-500",
    icon: "hourglass_empty",
    label: "Awaiting Binder",
    title: "Policy Coming Soon!",
    description: "Your bind order has been sent to the carrier, we'll update you as soon as we have updates.",
    actionDisplayNames: DisplayPolicyStateAwaitingFromCarrierActions,
  },
  [PolicyState.ReceivedBinderFromCarrier]: {
    className: "text-orange-600",
    cardClassName: "outline-orange-500",
    icon: "hourglass_empty",
    label: "Received Binder",
    title: "Policy Coming Soon!",
    description: "Your bind order has been sent to the carrier, we'll update you as soon as we have updates.",
    actionDisplayNames: DisplayPolicyStateAwaitingFromCarrierActions,
  },
  [PolicyState.BinderProcessed]: {
    className: "text-orange-600",
    cardClassName: "outline-orange-500",
    icon: "hourglass_empty",
    label: "Binder Processed",
    title: "Policy Coming Soon!",
    description: "Your bind order has been sent to the carrier, we'll update you as soon as we have updates.",
    actionDisplayNames: DisplayPolicyStateAwaitingFromCarrierActions,
  },
  [PolicyState.AwaitingPolicyFromCarrier]: {
    className: "text-orange-600",
    cardClassName: "outline-orange-500",
    icon: "hourglass_empty",
    label: "Awaiting Policy",
    title: "Policy Coming Soon!",
    description: "Your bind order has been sent to the carrier, we'll update you as soon as we have updates.",
    actionDisplayNames: DisplayPolicyStateAwaitingFromCarrierActions,
  },
  [PolicyState.ReceivedPolicyFromCarrier]: {
    className: "text-emerald-600",
    cardClassName: "outline-emerald-500",
    icon: "receipt_long",
    label: "Received Policy",
    title: "We're working on the final touches!",
    description: "Your broker is finalizing the policy and will have email it shortly.",
    actionDisplayNames: DisplayPolicyStateReceivedFromCarrierActions,
  },
  [PolicyState.Issued]: {
    className: "filled text-purple-600",
    cardClassName: "outline-purple-500",
    icon: "verified",
    label: "Issued",
    title: "Thank you for binding with QuoteWell!",
    description:
      "Your policy information is available below. Please email your broker directly for endorsements, cancellations, or other needs.",
    actionDisplayNames: DisplayPolicyStateIssuedActions,
  },
  [PolicyState.Canceled]: {
    className: "filled text-red-600",
    cardClassName: "outline-red-500",
    icon: "cancel",
    label: "Cancelled",
    title: "Policy Cancelled",
    actionDisplayNames: {}, // No specific actions for Canceled state
  },
};

export const EXPIRED_POLICY_STATE_METADATA: Record<ExpiredPolicyState, StateMetadata> = {
  [ExpiredPolicyState.Expired]: {
    className: "filled text-red-600",
    cardClassName: "border-red-500",
    icon: "warning",
    label: "Expired",
    title: "This policy has expired.",
    description: "Please email your broker directly for a renewal.",
    actionDisplayNames: {},
  },
};

import { Link } from "react-router-dom";

import { NoActions } from "@/components/no-actions";
import { Button } from "@/components/ui/button";
import { CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Separator } from "@/components/ui/separator";
import { PolicyFragment, PolicyState } from "src/generated/graphql";
import { AwaitingBinderFromCarrier } from "./awaiting-binder-from-carrier";
import { AwaitingPolicyFromCarrier } from "./awaiting-policy-from-carrier";
import { BinderProcessedActions } from "./binder-processed-actions";
import { Issued } from "./issued";
import { ReceivedBinderFromCarrierActions } from "./received-binder-from-carrier-actions";
import { ReceivedFromCarrier } from "./received-from-carrier";

export const PolicyActions = ({ policy, refetch }: { policy: PolicyFragment; refetch: () => void }) => {
  const renderPolicyState = () => {
    switch (policy.state) {
      case PolicyState.AwaitingBinderFromCarrier:
        return <AwaitingBinderFromCarrier policy={policy} refetch={refetch} />;
      case PolicyState.ReceivedBinderFromCarrier:
        return <ReceivedBinderFromCarrierActions policy={policy} refetch={refetch} />;
      case PolicyState.BinderProcessed:
        return <BinderProcessedActions policy={policy} refetch={refetch} />;
      case PolicyState.AwaitingPolicyFromCarrier:
        return <AwaitingPolicyFromCarrier policy={policy} refetch={refetch} />;
      case PolicyState.ReceivedPolicyFromCarrier:
        return <ReceivedFromCarrier policy={policy} />;
      case PolicyState.Issued:
        return <Issued policy={policy} />;
      default:
        return <NoActions />;
    }
  };

  const { bindHqPolicyId, insuredId, opportunityId, quoteId } = policy;

  return (
    <>
      <Separator />
      <CardHeader>
        <CardTitle>Actions</CardTitle>
      </CardHeader>
      <CardContent className="space-y-2">{renderPolicyState()}</CardContent>
      <Separator />
      <CardContent className="pt-5 space-y-2">
        <Button variant="outline" size="sm" display="flex" asChild>
          <Link to={`https://quotewell.bindhq.com/secure/policies/${bindHqPolicyId}`} target="_blank">
            View Policy in BindHQ
          </Link>
        </Button>
        <Button variant="outline" size="sm" display="flex" asChild>
          <Link to="https://dashboard.useascend.com/programs" target="_blank">
            View Invoice in Ascend
          </Link>
        </Button>
        <Button variant="outline" size="sm" display="flex" asChild>
          <Link to={`/insured/${insuredId}/quotes/${quoteId}`}>Go to Quote</Link>
        </Button>
        <Button variant="outline" size="sm" display="flex" asChild>
          <Link to={`/insured/${insuredId}/plans/${opportunityId}`}>Go to Marketing Plan</Link>
        </Button>
      </CardContent>
    </>
  );
};

import { FC, useState } from "react";
import { useDebounceValue } from "usehooks-ts";

import { FilterButton } from "@/components/filter-button";
import { Grid, GridCell, GridEmpty, GridLoading, GridRowHeader, GridRowLink } from "@/components/grid";
import { Group } from "@/components/group";
import { Pagination, PaginationProvider, usePagination } from "@/components/pagination";
import { SearchInput } from "@/components/search-input";
import { SectionHeader, SectionTitle } from "@/components/section";
import { Badge } from "@/components/ui/badge";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuRadioGroup,
  DropdownMenuRadioItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { Icon } from "@/components/ui/icon";
import { Spinner } from "@/components/ui/loading";
import { Tooltip, TooltipContent, TooltipTrigger } from "@/components/ui/tooltip";
import { FindSegmentsArgs, SegmentFragment, SegmentsSortBy, usePaginatedSegmentsQuery } from "src/generated/graphql";
import { cn } from "src/utils";
import { CreateSegmentButton } from "./components/create-segment-button";
import { SegmentContextMenu } from "./components/segment-context-menu";
import { SegmentProvider } from "./hooks/use-segment";

export type SegmentsGridSortValue = Exclude<FindSegmentsArgs["sort"], undefined | null>;

const initialSortValue: SegmentsGridSortValue = {
  sortBy: SegmentsSortBy.Name,
  sortOrder: "asc",
};

export const SegmentsList = () => (
  <PaginationProvider storageKeyPrefix="Segments">
    <SegmentsGrid />
  </PaginationProvider>
);

export const SegmentsGrid = () => {
  const { page, rowsPerPage, setNumRecords } = usePagination();
  const [sort, setSort] = useState<SegmentsGridSortValue>(initialSortValue);

  const [searchValue, setSearchValue] = useState("");
  const [query] = useDebounceValue(searchValue, 200);

  const { data, loading } = usePaginatedSegmentsQuery({
    variables: {
      pagination: { page, rowsPerPage: Number(rowsPerPage) },
      input: { query, sort },
    },
    onCompleted: ({ paginatedSegments }) => setNumRecords(paginatedSegments.numRecords),
  });

  const gridData = data?.paginatedSegments?.segments;

  return (
    <>
      <SectionHeader>
        <SectionTitle>
          <h1>Segments</h1>
          <div className="flex-1" />
          {loading && <Spinner />}
          <SegmentsGridSort onChange={setSort} initialValue={initialSortValue} />

          <SearchInput name="search" placeholder="Search segments" value={searchValue} onValueChange={setSearchValue} />
          <CreateSegmentButton variant="outline" size="sm" />
        </SectionTitle>
      </SectionHeader>

      <Grid className="grid-cols-[0.75rem_2fr_2fr_1.5fr_0.5fr_0.75rem_1rem]">
        <GridRowHeader position="sticky">
          <GridCell />
          <GridCell>Segment</GridCell>
          <GridCell>LOBs</GridCell>
          <GridCell>CGLs</GridCell>
          <GridCell>Products</GridCell>
          <GridCell />
          <GridCell />
        </GridRowHeader>

        <SegmentsGridBody data={gridData} loading={loading} />
      </Grid>

      <Pagination label="Segments" />
    </>
  );
};

export interface SegmentsGridBodyProps {
  data?: SegmentFragment[];
  loading: boolean;
}

export const SegmentsGridBody: FC<SegmentsGridBodyProps> = ({ data, loading }) => {
  if (!loading && !data?.length) {
    return <SegmentsGridEmpty />;
  }

  if (loading && !data?.length) {
    return <SegmentsGridLoading />;
  }

  return (
    <>
      {data?.map((row) => (
        <SegmentsGridRow key={row.id} row={row} />
      ))}
    </>
  );
};

export const SegmentsGridLoading: FC = () => {
  const { rowsPerPage } = usePagination();

  return <GridLoading rows={Number(rowsPerPage)} columns={7} />;
};

export const SegmentsGridEmpty: FC = () => (
  <GridEmpty
    title="No segments"
    description={
      <>
        <p>Create a segment to get started.</p>
        <CreateSegmentButton variant="secondary" size="sm" />
      </>
    }
  />
);

interface SegmentsGridRowProps {
  row: SegmentFragment;
}

const SegmentsGridRow: FC<SegmentsGridRowProps> = ({ row }) => (
  <SegmentProvider segment={row}>
    <SegmentContextMenu>
      <GridRowLink to={`/segments/${row.id}`}>
        <Tooltip>
          <TooltipTrigger className="leading-0 cursor-pointer">
            <Icon icon="circle" className={cn("text-sm", row.active ? "filled text-success" : "opacity-25")} />
          </TooltipTrigger>
          <TooltipContent>{row.active ? "Active" : "Inactive"}</TooltipContent>
        </Tooltip>
        <GridCell>{row.name}</GridCell>
        <GridCell>{row.segmentCriteria.linesOfBusiness?.join(", ")}</GridCell>
        <Pills items={row.segmentCriteria.isoCglCodes?.map((cgl) => cgl) || []} />
        <GridCell>
          {!!row.segmentAppetiteProducts?.length && (
            <Badge variant="secondary" type="square">
              {row.segmentAppetiteProducts?.length}
            </Badge>
          )}
        </GridCell>
        <Tooltip>
          <TooltipTrigger className="leading-0 cursor-pointer">
            <Icon
              icon="smart_toy"
              className={cn("text-sm", row.autoApproveAlby ? "filled text-primary" : "opacity-25")}
            />
          </TooltipTrigger>
          <TooltipContent>{row.autoApproveAlby ? "Auto-approve on" : "Auto-approve off"}</TooltipContent>
        </Tooltip>
        <GridCell />
      </GridRowLink>
    </SegmentContextMenu>
  </SegmentProvider>
);

export interface SegmentsGridSortProps {
  onChange?: (value: SegmentsGridSortValue) => void;
  initialValue: SegmentsGridSortValue;
}

export const SegmentsGridSort: FC<SegmentsGridSortProps> = ({ onChange, initialValue }) => {
  const { setPage } = usePagination();
  const [sort, setSort] = useState<SegmentsGridSortValue>(initialValue);

  const handleFiltersChange = (value: SegmentsGridSortValue) => {
    setPage(1);
    setSort({ ...sort, ...value });
    onChange?.(value);
  };

  const handleSortChange = (value: string) => {
    const [sortBy, sortOrder] = value.split(":") as [SegmentsSortBy, "asc" | "desc"];
    handleFiltersChange({ ...sort, sortBy, sortOrder });
  };

  const options = [
    { label: "Name (A-Z)", value: `${SegmentsSortBy.Name}:asc` },
    { label: "Most recent", value: `${SegmentsSortBy.CreatedAt}:desc` },
    { label: "Oldest to newest", value: `${SegmentsSortBy.CreatedAt}:asc` },
    { label: "Last updated", value: `${SegmentsSortBy.UpdatedAt}:desc` },
  ];

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <FilterButton />
      </DropdownMenuTrigger>
      <DropdownMenuContent>
        <DropdownMenuLabel>Sort by</DropdownMenuLabel>
        <DropdownMenuRadioGroup value={`${sort.sortBy}:${sort.sortOrder}`} onValueChange={handleSortChange}>
          {options.map((option) => (
            <DropdownMenuRadioItem key={option.value} value={option.value}>
              {option.label}
            </DropdownMenuRadioItem>
          ))}
        </DropdownMenuRadioGroup>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

// TODO: Create a reusable `Pills` component.
const Pills = ({ items }: { items: string[] }) => (
  <Group type="flex" direction="row" className="gap-1 truncate">
    {items.map((item) => (
      <Badge key={item} variant="secondary" className="tabular-nums">
        {item}
      </Badge>
    ))}
  </Group>
);

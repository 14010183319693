import { Link } from "react-router-dom";

import { StateCard } from "@/components/state";
import { Button } from "@/components/ui/button";
import { CardContent, CardFooter, CardHeader } from "@/components/ui/card";
import { Icon } from "@/components/ui/icon";
import { useInsured } from "@/hooks/use-insured";
import { CreateMarketingPlan } from "../insured-sidebar/create-marketing-plan";
import { OpportunityList } from "../insured-sidebar/insured-opportunities";
import { PolicyList } from "../insured-sidebar/insured-policies";
import { QuoteList } from "../insured-sidebar/insured-quotes";

export const InsuredLinksCard = () => {
  const { insured, activePolicies, sentQuotes, activeOpportunities, opportunities, quotes, policies } = useInsured();
  const newInsured = [...opportunities, ...quotes, ...policies].length === 0;

  return newInsured ? (
    <StateCard cardClassName="text-primary" icon="flare" title="New Insured Created">
      <CardContent className="ml-10 space-y-3 text-sm">
        <p>
          We&apos;ve created a page for <strong>{insured.name}</strong>. This is where you&apos;ll request coverage,
          submit information about the insured, check on the status of our marketing efforts, review quotes, and
          download policies.
        </p>
        <p>
          <strong>Let&apos;s get started!</strong> To begin, you&apos;ll need to request coverage &mdash; to do that,
          click the <strong>Create New Marketing Plan</strong> button below, or the link in the sidebar to the left.
        </p>
      </CardContent>
      <CardFooter className="justify-end">
        <CreateMarketingPlan>
          <Button theme="primary">
            Create New Marketing Plan <Icon icon="add_circle" className="filled" />
          </Button>
        </CreateMarketingPlan>
      </CardFooter>
    </StateCard>
  ) : (
    <StateCard cardClassName="text-primary" icon="whatshot" title={insured.name}>
      <div className="border-t divide-x @max-4xl/scrollpane:divide-y grid @4xl/scrollpane:grid-cols-3">
        <GridItem
          title="Active Marketing Plans"
          buttons={
            <>
              <CreateMarketingPlan>
                <Button variant="secondary" size="sm" theme="primary" display="flex">
                  Create New Marketing Plan <Icon icon="add_circle" className="filled" />
                </Button>
              </CreateMarketingPlan>
              <Button variant="secondary" size="sm" display="flex" asChild>
                <Link to="plans">View all Marketing Plans</Link>
              </Button>
            </>
          }
        >
          {activeOpportunities[0] ? (
            <OpportunityList opportunities={activeOpportunities} />
          ) : (
            <p className="italic text-muted-foreground text-xs">There are no active marketing plans.</p>
          )}
        </GridItem>
        <GridItem
          title="Quotes to Review"
          buttons={
            <Button variant="secondary" size="sm" display="flex" asChild>
              <Link to="quotes">View all Quotes</Link>
            </Button>
          }
        >
          {sentQuotes[0] ? (
            <QuoteList quotes={sentQuotes} />
          ) : (
            <p className="italic text-muted-foreground text-xs">There are no quotes to review.</p>
          )}
        </GridItem>
        <GridItem
          title="Active Policies"
          buttons={
            <Button variant="secondary" size="sm" display="flex" asChild>
              <Link to="policies">View all Policies</Link>
            </Button>
          }
        >
          {activePolicies[0] ? (
            <PolicyList policies={activePolicies} />
          ) : (
            <p className="italic text-muted-foreground text-xs">There are no active policies.</p>
          )}
        </GridItem>
      </div>
    </StateCard>
  );
};

const GridItem = ({
  children,
  buttons,
  title,
}: React.PropsWithChildren & { buttons: React.ReactNode; title: string }) => (
  <div className="flex flex-col justify-between">
    <CardHeader>
      <h4>{title}</h4>
    </CardHeader>
    <CardContent className="flex-auto">{children}</CardContent>
    <CardFooter className="flex-col gap-2 mt-auto">{buttons}</CardFooter>
  </div>
);

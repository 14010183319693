import { uniqBy } from "lodash";
import { FC, useMemo, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useNavigate, useParams } from "react-router";
import { z } from "zod";

import { ContinueButton } from "@/components/continue-button";
import { ScrollPaneColumns } from "@/components/scroll-pane";
import { SearchInput } from "@/components/search-input";
import { SectionHeader, SectionTitle } from "@/components/section";
import { StateCard } from "@/components/state";
import { Card, CardContent } from "@/components/ui/card";
import { Loading } from "@/components/ui/loading";
import { FieldCheckbox } from "@/forms/fields/field-checkbox";
import { FieldCurrency } from "@/forms/fields/field-number";
import { FieldRow } from "@/forms/fields/field-row";
import { Form } from "@/forms/form";
import { useInsured } from "@/hooks/use-insured";
import { useMarketingPlan } from "@/hooks/use-marketing-plan";
import { useToast } from "@/hooks/use-toast";
import { AGENT_MESSAGE_METADATA } from "@/metadata/states";
import {
  AppetiteFilterOptionsQuery,
  MarketingPlanState,
  useAppetiteFilterOptionsQuery,
  useSelectOpportunityCoveragesMutation,
} from "src/generated/graphql";

const PROPERTY_FIELD = "Property";

const CoverageFormSchema = z.object({
  selectedLinesOfBusiness: z.string().array().min(1, { message: "" }),
  propertyTIV: z.number().optional().nullable(),
});
// TODO: Bring back if we want this to be required
// .superRefine(({ selectedLinesOfBusiness, propertyTIV }, ctx) => {
//   if (selectedLinesOfBusiness.includes(PROPERTY_FIELD) && !propertyTIV) {
//     ctx.addIssue({
//       message: "Property TIV required to proceed",
//       code: z.ZodIssueCode.custom,
//       path: ["propertyTIV"],
//     });
//   }
// });

const CORE_LINES = new Set([
  "General Liability",
  "Property",
  "E&O/Professional Liability",
  // "Workers Compensation",
  "Commercial Auto",
  "Excess Liability/Umbrella",
]);

export const Coverage = ({ skipNavigation = false, navigateTo }: { skipNavigation?: boolean; navigateTo?: string }) => {
  const { insured } = useInsured();
  const { opportunityId } = useParams();
  const { toast } = useToast();
  const navigate = useNavigate();

  const { opportunity, loading: loadingOpportunity, refetchQueries } = useMarketingPlan();
  const { data: { appetiteFilterOptions } = {}, loading: loadingAppetiteFilterOptions } = useAppetiteFilterOptionsQuery(
    {}
  );

  const [selectCoveragesMutation] = useSelectOpportunityCoveragesMutation();

  const defaultValues = {
    selectedLinesOfBusiness: opportunity?.selectedLinesOfBusiness ?? [],
    propertyTIV: insured?.tiv ?? undefined,
  };

  const handleSubmit = async (values: z.infer<typeof CoverageFormSchema>) => {
    if (!opportunityId) {
      return;
    }

    await selectCoveragesMutation({
      variables: { input: { id: opportunityId, ...values } },
      onCompleted: () => {
        toast({ title: "Coverage updated" });

        if (!skipNavigation) {
          navigate(navigateTo ?? `/insured/${opportunity.insuredId}/plans/${opportunityId}`);
        }
      },
      refetchQueries,
    });
  };

  const { state } = opportunity;
  const stateProps = AGENT_MESSAGE_METADATA[state];

  return (
    <div className="p-6 space-y-6">
      {state === MarketingPlanState.WaitingForCoverages && <StateCard {...stateProps} />}
      <Form validationSchema={CoverageFormSchema} defaultValues={defaultValues} onSubmit={handleSubmit}>
        <CoverageForm
          isLoading={loadingAppetiteFilterOptions || loadingOpportunity}
          appetiteFilterOptions={appetiteFilterOptions}
        />
      </Form>
    </div>
  );
};

interface CoverageFormProps {
  isLoading?: boolean;
  appetiteFilterOptions?: AppetiteFilterOptionsQuery["appetiteFilterOptions"];
}

const CoverageForm: FC<CoverageFormProps> = ({ isLoading, appetiteFilterOptions }) => {
  const [coverageTerm, setCoverageTerm] = useState("");
  const { watch } = useFormContext();

  const selectedLinesOfBusiness = watch("selectedLinesOfBusiness");

  const sortedVerticals = useMemo(
    () =>
      [...(appetiteFilterOptions?.verticals || [])].sort(
        (a, b) => (b?.linesOfBusiness.length || 0) - (a?.linesOfBusiness.length || 0)
      ),
    [appetiteFilterOptions?.verticals]
  );

  const linesOfBusiness = useMemo(
    () =>
      uniqBy(
        sortedVerticals.flatMap((vertical) => vertical.linesOfBusiness),
        (v) => v.name
      ).filter((l) => l.name.toLowerCase().includes(coverageTerm.toLowerCase())),
    [sortedVerticals, coverageTerm]
  );

  const coreLines = useMemo(() => linesOfBusiness.filter((l) => CORE_LINES.has(l.name)), [linesOfBusiness]);
  const otherLines = useMemo(() => linesOfBusiness.filter((l) => !CORE_LINES.has(l.name)), [linesOfBusiness]);

  const hasCoreLines = !isLoading && !!coreLines?.length;
  const hasOtherLines = !isLoading && !!otherLines?.length;

  const isCoreLinesEmpty = !isLoading && !hasCoreLines;
  const isOtherLinesEmpty = !isLoading && !hasOtherLines;

  return (
    <>
      <SectionHeader className="pt-0">
        <SectionTitle>
          <h2>Coverage</h2>
          <SearchInput
            name="search"
            placeholder="Search Coverage"
            value={coverageTerm}
            onValueChange={setCoverageTerm}
          />
        </SectionTitle>
      </SectionHeader>
      <ScrollPaneColumns className="grid-cols-2">
        <Card className="divide-y overflow-hidden">
          <h3 className="px-6 py-4">Core Lines</h3>

          {isLoading && (
            <CardContent>
              <Loading />
            </CardContent>
          )}

          {hasCoreLines && coreLines.map(({ name }) => <FieldCheckboxRow key={name} name={name} />)}

          {selectedLinesOfBusiness.includes(PROPERTY_FIELD) && (
            <FieldRow icon="paid">
              <FieldCurrency
                name="propertyTIV"
                placeholder="Total Insurable Value"
                borderless
                inputProps={{ className: "placeholder-destructive!" }}
              />
            </FieldRow>
          )}

          {isCoreLinesEmpty && (
            <CardContent className="italic pt-5 text-muted-foreground text-sm">
              No lines found matching <strong>{coverageTerm}</strong>.
            </CardContent>
          )}
        </Card>
        <Card className="divide-y overflow-hidden">
          <h3 className="px-6 py-4">Additional Lines</h3>

          {isLoading && (
            <CardContent>
              <Loading />
            </CardContent>
          )}

          {hasOtherLines && otherLines.map(({ name }) => <FieldCheckboxRow key={name} name={name} />)}

          {isOtherLinesEmpty && (
            <CardContent className="italic pt-5 text-muted-foreground text-sm">
              No lines found matching <strong>{coverageTerm}</strong>.
            </CardContent>
          )}
        </Card>
      </ScrollPaneColumns>
      <footer className="bottom-5 flex justify-end px-0 pt-5 sticky">
        <ContinueButton className="ml-auto" />
      </footer>
    </>
  );
};

export const FieldCheckboxRow = ({ name }: { name: string }) => (
  <FieldRow asChild className="items-center">
    <FieldCheckbox
      name="selectedLinesOfBusiness"
      label={name}
      value={name}
      multiple
      className="[&_label]:gap-6 [&_label]:text-base [&_label]:text-muted-foreground [&_button]:border-muted-foreground [&_button[data-state=checked]]:border-primary"
    />
  </FieldRow>
);

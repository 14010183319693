import { Group } from "@/components/group";
import { FieldInput } from "@/forms/fields/field-input";
import { FieldTextarea } from "@/forms/fields/field-textarea";

export const UpdateAppetiteForm = () => (
  <Group>
    <FieldInput label="State" name="state" />
    <FieldInput label="Naics" name="naicsCode" />
    <FieldTextarea label="Reason" name="approvedReason" />
  </Group>
);

import { useState } from "react";

import { MoreMenu } from "@/components/more-menu";
import { DropdownMenuItem, DropdownMenuSeparator } from "@/components/ui/dropdown-menu";
import { Icon } from "@/components/ui/icon";
import { SubmissionFragment } from "src/generated/graphql";
import { cn } from "src/utils";
import { CarrierContacts } from "./carrier-contacts/carrier-contacts";

export const SubmissionMenu = ({
  children,
  submission,
}: {
  children?: React.ReactNode;
  submission: SubmissionFragment;
}) => {
  const [contactsOpen, setContactsOpen] = useState(false);

  const portalLink = submission.appetiteProduct.portalLink ?? undefined;
  const contacts = submission.appetiteProduct.submissionCarrierContacts ?? [];
  const contactsWarning = !portalLink && !contacts[0];

  return (
    <>
      <MoreMenu
        icon={contacts[0] ? undefined : "warning"}
        className={cn("filled", contactsWarning && "text-destructive!")}
      >
        <DropdownMenuItem onClick={() => setContactsOpen(true)} className={cn(contactsWarning && "text-destructive!")}>
          <Icon icon="account_circle" />
          {contacts[0] ? "Carrier Contacts" : "Add Carrier Contacts"}
        </DropdownMenuItem>
        {portalLink && (
          <a href={portalLink} target="_blank" rel="noopener noreferrer">
            <DropdownMenuItem>
              <Icon icon="language" />
              Carrier Portal
            </DropdownMenuItem>
          </a>
        )}
        {children && (
          <>
            <DropdownMenuSeparator />
            {children}
          </>
        )}
      </MoreMenu>
      <CarrierContacts submission={submission} open={contactsOpen} setOpen={setContactsOpen} />
    </>
  );
};

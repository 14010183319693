import { getAuthRequestHeaders } from "@cp/auth";
import { format } from "date-fns";
import { useState } from "react";

import { Button } from "@/components/ui/button";
import { Spinner } from "@/components/ui/loading";
import { useInsured } from "@/hooks/use-insured";
import { useToast } from "@/hooks/use-toast";

export const InsuredSOV = () => {
  const { insured } = useInsured();
  const [isLoading, setIsLoading] = useState(false);
  const { toast } = useToast();

  const downloadSOV = async (e: any) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const blob = await fetch(`/api/insureds/${insured.id}/sov`, {
        headers: getAuthRequestHeaders(),
        method: "POST",
      })
        .then((r) => r.blob())
        .then((b) => b.slice(0, b.size, "text/csv"));

      const url = URL.createObjectURL(blob);
      const anchor = document.createElement("a");
      anchor.href = url;
      anchor.download = `${insured.name} - SOV - ${format(new Date(), "MM-dd-yy")}`;

      document.body.append(anchor);
      anchor.click();

      URL.revokeObjectURL(url);
      anchor.remove();
    } catch (error_: any) {
      toast({ title: "Error", description: `Failed to download: ${error_.message}`, variant: "destructive" });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <form onSubmit={downloadSOV}>
      <Button type="submit" variant="secondary" display="flex" disabled={isLoading}>
        Download CSV {isLoading && <Spinner />}
      </Button>
    </form>
  );
};

import * as CheckboxPrimitive from "@radix-ui/react-checkbox";
import * as React from "react";

import { cn } from "src/utils";
import { Icon } from "./icon";

const Checkbox = React.forwardRef<
  React.ElementRef<typeof CheckboxPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof CheckboxPrimitive.Root>
>(({ className, ...props }, ref) => (
  <CheckboxPrimitive.Root
    ref={ref}
    className={cn(
      "peer size-[calc(1em_+_4px)] inline-flex items-center justify-center shrink-0 rounded-sm border border-input ring-offset-background focus-visible:outline-hidden focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 data-[state=checked]:bg-primary data-[state=checked]:border-primary data-[state=checked]:text-primary-foreground data-[state=indeterminate]:bg-transparent data-[state=indeterminate]:border-primary data-[state=indeterminate]:text-primary",
      className
    )}
    {...props}
  >
    <CheckboxPrimitive.Indicator className={cn("flex text-[calc(1em_+_3px)] items-center justify-center text-current")}>
      {props.checked === "indeterminate" && <Icon icon="remove" />}
      {props.checked === true && <Icon icon="check" style={{ fontVariationSettings: `"wght" 700` }} />}
    </CheckboxPrimitive.Indicator>
  </CheckboxPrimitive.Root>
));
Checkbox.displayName = CheckboxPrimitive.Root.displayName;

export { Checkbox };

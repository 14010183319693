import { useNodes } from "@xyflow/react";
import cloneDeep from "lodash/cloneDeep";
import { useMemo, type FC } from "react";
import { useNavigate } from "react-router";
import { z } from "zod";

import { ButtonGroup } from "@/components/button-group";
import { Group } from "@/components/group";
import { useModal } from "@/components/modal-provider";
import { Loading } from "@/components/ui/loading";
import { Separator } from "@/components/ui/separator";
import { FieldHidden } from "@/forms/fields/field-hidden";
import { FieldInput } from "@/forms/fields/field-input";
import { Form } from "@/forms/form";
import { FormReset } from "@/forms/form-reset";
import { FormSubmit } from "@/forms/form-submit";
import { toast } from "@/hooks/use-toast";
import {
  CreatePipelineVersionInput,
  FileProcessingPipelineQuery,
  FileProcessorCategory,
  FileProcessorNodeType,
  useCheckUpstreamFileProcessorMutation,
  useCreateFilePipelineVersionMutation,
  useFileProcessingPipelineQuery,
  useFileProcessorQuery,
} from "src/generated/graphql";

import { useFormContext } from "react-hook-form";
import {
  convertPipelineDataToNodesAndEdges,
  findNodeById,
  findParentNodeByChildId,
} from "../../file-processing-pipeline.helpers";

export interface EditProcessorFormValues {
  id: string;
  previousId: string;
  type: FileProcessorCategory;
  name: string;
  prompt: string;
  startPage: string;
  endPage: string;
}

export interface EditProcessorFormProps {
  processorId: string;
}

export const EditProcessorFormContent: FC<EditProcessorFormProps> = () => {
  const { closeModal } = useModal();
  const formContext = useFormContext();
  const type = formContext.watch("type");

  return (
    <Group className="gap-6 mt-2">
      <Group>
        <FieldInput name="id" label="File processor ID" placeholder="Enter processor ID" />
        <FieldInput
          name="type"
          label="Type"
          inputProps={{ readOnly: true }}
          onChange={() => formContext.formState.errors.id && formContext.trigger("id")}
        />
        <FieldInput name="name" label="Name" placeholder="Name unavailable" inputProps={{ readOnly: true }} />
        {type === FileProcessorCategory.PromptExtractor && (
          <FieldInput name="prompt" label="Prompt" placeholder="Enter prompt for extraction" type="text" />
        )}
        <FieldHidden name="previousId" />
      </Group>

      <div>
        <h4 className="text-base!">Page range</h4>
        <p className="text-muted-foreground text-sm mt-1.5">
          Enter the page range to use to limit the number of pages processed by this processor with each re-run.
        </p>
      </div>

      <Group direction="row">
        <FieldInput name="startPage" label="Start page" type="number" placeholder="Defaults to first page" />
        <FieldInput name="endPage" label="End page" type="number" placeholder="Defaults to last page" />
      </Group>

      <Separator />

      <ButtonGroup>
        <FormReset onClick={closeModal}>Cancel</FormReset>
        <FormSubmit>Save processor</FormSubmit>
      </ButtonGroup>
    </Group>
  );
};

export const EditProcessorForm: FC<EditProcessorFormProps> = (props) => {
  const navigate = useNavigate();
  const { closeModal } = useModal();
  const { data: pipelineData, loading: pipelineDataLoading } = useFileProcessingPipelineQuery();
  const { data: fileProcessorData, loading: fileProcessorDataLoading } = useFileProcessorQuery({
    variables: { id: props.processorId },
  });

  const [checkUpstreamFileProcessor] = useCheckUpstreamFileProcessorMutation();

  const [createPipelineVersion] = useCreateFilePipelineVersionMutation({
    // TODO: We need to figure out the `optimisticResponse` for this mutation.
    // update: (cache, { data }) => {
    //   cache.writeQuery({
    //     query: FileProcessingPipelineDocument,
    //     data: { fileProcessingPipeline: data?.createPipelineVersion },
    //   });
    // },
  });

  const { nodes } = useMemo(
    () => (pipelineData ? convertPipelineDataToNodesAndEdges(pipelineData?.fileProcessingPipeline) : { nodes: [] }),
    [pipelineData]
  );

  const validationSchema = z
    .object({
      id: z
        .string()
        .min(1, { message: "Please enter the processor ID" })
        .regex(/^[\da-z]{16}$/, { message: "The processor ID should be a 16-character alphanumeric string." })
        .refine(
          (value) => {
            const existingNode = nodes.find(
              (node) =>
                node.data.id !== props.processorId &&
                node.type === FileProcessorNodeType.FileProcessor &&
                node.data.id === value
            );

            return !existingNode;
          },
          {
            message: "There is already a processor with that id. Please enter a unique id.",
          }
        ),
      previousId: z.string().min(1, { message: "Previous ID is required" }),
      type: z.string().optional(),
      name: z.string().optional(),
      prompt: z.string().optional(),
      startPage: z
        .number({ coerce: true })
        .int()
        .refine((value) => Number(value) >= 0, { message: "Must be a positive number" })
        .optional(),
      endPage: z
        .number({ coerce: true })
        .int()
        .refine((value) => Number(value) >= 0, { message: "Must be a positive number" })
        .optional(),
    })
    .superRefine(async (values, context) => {
      if (values.startPage && values.endPage && values.startPage > values.endPage) {
        context.addIssue({
          code: z.ZodIssueCode.custom,
          message: "End page must be greater than or equal to start page",
          path: ["endPage"],
        });
      }

      if (values.type === FileProcessorCategory.PromptExtractor && !values.prompt) {
        context.addIssue({
          code: z.ZodIssueCode.custom,
          message: "Please enter a prompt for the prompt extractor",
          path: ["prompt"],
        });
      }
      if (values.type !== FileProcessorCategory.PromptExtractor) {
        const { data } = await checkUpstreamFileProcessor({ variables: { id: values.id } });

        if (!data?.checkUpstreamFileProcessor?.exists) {
          context.addIssue({
            code: z.ZodIssueCode.custom,
            message: "There is no upstream processor that matches that ID.",
            path: ["id"],
          });
        }
      }
    });

  const defaultValues = {
    id: props.processorId,
    previousId: props.processorId,
    name: fileProcessorData?.fileProcessor?.name || "",
    type: (fileProcessorData?.fileProcessor?.category || "") as FileProcessorCategory,
    prompt: fileProcessorData?.fileProcessor?.prompt || "",
    startPage: `${fileProcessorData?.fileProcessor?.startPage || ""}`,
    endPage: `${fileProcessorData?.fileProcessor?.endPage || ""}`,
  };

  const handleSubmit = async (values: EditProcessorFormValues) => {
    if (!pipelineData) {
      return;
    }

    const input = getEditProcessorInput(values, pipelineData.fileProcessingPipeline);

    if (!input) {
      return;
    }

    await createPipelineVersion({
      variables: { input },
      refetchQueries: ["FileProcessingPipeline", "FileProcessor"],
    });

    navigate(`/file-processing-pipeline/file-processor/${values.id}`);
    toast({ title: `${values.name} processor updated` });
    closeModal();
  };

  if (!pipelineData && pipelineDataLoading && !fileProcessorData && fileProcessorDataLoading) {
    return <Loading />;
  }

  return (
    <Form validationSchema={validationSchema} onSubmit={handleSubmit} defaultValues={defaultValues}>
      <EditProcessorFormContent {...props} />
    </Form>
  );
};

export interface UseEditProcessorFormModalOptions {
  processorId?: string;
}

export const useEditProcessorFormModal = ({ processorId }: UseEditProcessorFormModalOptions) => {
  const { openModal } = useModal();
  const nodes = useNodes();

  const processor = findNodeById(processorId, nodes);

  return {
    openEditProcessorForm: async () => {
      if (!processorId || !processor) {
        return;
      }

      await openModal(() => <EditProcessorForm processorId={processorId} />, {
        type: "dialog",
        title: "Edit processor",
        description: "Update the file processor details.",
      });
    },
  };
};

function getEditProcessorInput(
  values: EditProcessorFormValues,
  pipelineData: FileProcessingPipelineQuery["fileProcessingPipeline"]
): CreatePipelineVersionInput | null {
  const pipelineDataCopy = cloneDeep(pipelineData);
  // QUESTION: Can we just use the `useNodes` and `useEdges` hooks here?
  const { nodes, edges } = convertPipelineDataToNodesAndEdges(pipelineDataCopy);

  const currentNode = findNodeById(values.previousId, nodes);

  if (!currentNode) {
    return null;
  }

  const isInitialProcessor = pipelineDataCopy.pipeline.initial.id === values.previousId;

  if (isInitialProcessor) {
    pipelineDataCopy.pipeline.initial.id = values.id;
    pipelineDataCopy.pipeline.initial.startPage = Number(values.startPage);
    pipelineDataCopy.pipeline.initial.endPage = Number(values.endPage);
    if (values.type === FileProcessorCategory.PromptExtractor && values.prompt) {
      pipelineDataCopy.pipeline.initial.prompt = values.prompt;
    }

    return {
      name: "FileUploadPipeline",
      pipeline: {
        initial: pipelineDataCopy.pipeline.initial,
        transitions: pipelineDataCopy.pipeline.transitions,
      },
    };
  }

  const parentNode = findParentNodeByChildId(currentNode.id, nodes, edges);

  if (!parentNode) {
    return null;
  }

  const existingLabel = pipelineDataCopy.pipeline.transitions.find(
    (transition) => transition.label === parentNode.data.name && transition.sourceNodeName === parentNode.data.category
  );

  const existingProcessor = existingLabel?.destinationNodes.find((node) => node.id === values.previousId);

  if (!existingProcessor) {
    return null;
  }

  existingProcessor.id = values.id;
  existingProcessor.startPage = Number(values.startPage);
  existingProcessor.endPage = Number(values.endPage);
  if (values.type === FileProcessorCategory.PromptExtractor && values.prompt) {
    existingProcessor.prompt = values.prompt;
  }

  return {
    name: "FileUploadPipeline",
    pipeline: {
      initial: pipelineDataCopy.pipeline.initial,
      transitions: pipelineDataCopy.pipeline.transitions,
    },
  };
}

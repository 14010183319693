import { useAtom } from "jotai";
import { sortBy } from "lodash";
import { useEffect } from "react";
import { useParams } from "react-router";

import {
  eventsAlbyAtom,
  eventsBotAtom,
  eventsCommentAtom,
  eventsDescAtom,
  eventsEmailAtom,
  eventsOpportunityAtom,
  eventsSubmissionAtom,
  eventsUploadAtom,
  stateTransitionAtom,
} from "@/atoms";
import { eventTypes } from "@/components/activity/activity";
import { EventFilter } from "@/components/activity/event-filter";
import { EventRenderer } from "@/components/activity/event-renderer";
import { SectionHeader, SectionTitle } from "@/components/section";
import {
  OnOpportunityEventAddedDocument,
  OnOpportunityEventAddedSubscription,
  OnOpportunityEventAddedSubscriptionVariables,
  useOpportunityEventsQuery,
  useStateTransitionsQuery,
} from "src/generated/graphql";
import { cn } from "src/utils";

export const History = () => {
  const { opportunityId } = useParams<"opportunityId">();
  const [comment] = useAtom(eventsCommentAtom);
  const [opportunity] = useAtom(eventsOpportunityAtom);
  const [submission] = useAtom(eventsSubmissionAtom);
  const [email] = useAtom(eventsEmailAtom);
  const [bot] = useAtom(eventsBotAtom);
  const [upload] = useAtom(eventsUploadAtom);
  const [desc] = useAtom(eventsDescAtom);
  const [transition] = useAtom(stateTransitionAtom);
  const [alby] = useAtom(eventsAlbyAtom);

  const types = [
    ...(opportunity ? eventTypes.opportunity : []),
    ...(submission ? eventTypes.submission : []),
    ...(bot ? eventTypes.bot : []),
    ...(email ? eventTypes.email : []),
    ...(comment ? eventTypes.comment : []),
    ...(upload ? eventTypes.upload : []),
    ...(alby ? eventTypes.alby : []),
  ];

  const { data: { opportunityEvents = [] } = {}, subscribeToMore } = useOpportunityEventsQuery({
    variables: {
      input: { opportunityId: opportunityId || "", types },
    },
    skip: !opportunityId,
  });

  const { data = {} } = useStateTransitionsQuery({
    variables: {
      id: opportunityId || "",
    },
    skip: !opportunityId,
  });

  function processStateTransitions(
    opportunity: NonNullable<ReturnType<typeof useStateTransitionsQuery>["data"]>["opportunity"]
  ) {
    if (!opportunity) {
      return [];
    }
    const allTransitions = [];

    allTransitions.push(
      ...opportunity.stateTransitions.map((t) => ({
        ...t,
      }))
    );

    data?.opportunity?.submissions.forEach((submission) => {
      allTransitions.push(
        ...submission.stateTransitions.map((t) => ({
          ...t,
          appetiteProduct: submission.appetiteProduct,
        }))
      );

      submission.quotes.forEach((quote) => {
        allTransitions.push(
          ...quote.stateTransitions.map((t) => ({
            ...t,
            appetiteProduct: submission.appetiteProduct,
          }))
        );

        if (quote.policy) {
          allTransitions.push(
            ...quote.policy.stateTransitions.map((t) => ({
              ...t,
              appetiteProduct: submission.appetiteProduct,
            }))
          );
        }
      });
    });

    return transition ? allTransitions : [];
  }

  const transitions = processStateTransitions(data.opportunity) ?? [];

  useEffect(() => {
    return subscribeToMore<OnOpportunityEventAddedSubscription, OnOpportunityEventAddedSubscriptionVariables>({
      document: OnOpportunityEventAddedDocument,
      variables: { id: opportunityId || "" },
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data) {
          return prev;
        }

        const newEvent = subscriptionData.data.opportunityEventAdded;

        return {
          opportunityEvents: [...prev.opportunityEvents, newEvent],
        };
      },
    });
  }, []);

  return (
    <>
      <SectionHeader>
        <SectionTitle>
          <h3>History</h3>
          <EventFilter allActivity />
        </SectionTitle>
      </SectionHeader>
      <div
        className={cn(
          "border-t divide-y flex",
          desc ? "flex-col *:last:rounded-b-lg" : "flex-col-reverse *:first:rounded-b-lg"
        )}
        id="history"
      >
        {sortBy([...opportunityEvents, ...transitions], "createdAt").map((event) => (
          <EventRenderer key={event.id} event={event} />
        ))}
        {!desc && <div className="h-0" />}
      </div>
    </>
  );
};

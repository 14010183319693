import { FC, Fragment } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { z } from "zod";

import { Group } from "@/components/group";
import { Button } from "@/components/ui/button";
import { Card, CardContent } from "@/components/ui/card";
import { Icon } from "@/components/ui/icon";
import { Separator } from "@/components/ui/separator";
import { FieldInput } from "@/forms/fields/field-input";
import { FieldSelect } from "@/forms/fields/field-select";

const RequirementGroupsSchema = z.array(
  z.object({
    title: z.string(),
    description: z.string().optional(),
    elements: z.array(
      z.object({
        element: z.string(),
        props: z.object({ label: z.string() }),
        clientDataKey: z.string(),
        array: z.boolean(),
      })
    ),
  })
);

export const RequirementFormSchema = z.object({
  title: z.string(),
  description: z.string(),
  groups: RequirementGroupsSchema,
});

export const RequirementForm = () => (
  <Group className="gap-6">
    <Group>
      <FieldInput name="title" label="Requirement Title" />
      <FieldInput name="description" label="Requirement Description" />
    </Group>

    <RequirementGroups />
  </Group>
);

const RequirementGroups = () => {
  const { control } = useFormContext();
  const { fields, append } = useFieldArray({ control, name: "groups" });

  return (
    <>
      {fields.map((field, index) => (
        <Card key={field.id}>
          <CardContent className="p-6">
            <Group className="gap-6">
              <Group>
                <FieldInput name={`groups.${index}.title`} label="Group title" />
                <FieldInput name={`groups.${index}.description`} label="Group Description" />
              </Group>

              <RequirementGroupElements groupIndex={index} />
            </Group>
          </CardContent>
        </Card>
      ))}

      <Button type="button" onClick={() => append({ title: "", description: "", elements: [] })}>
        <Icon icon="add_2" />
        Add Group
      </Button>
    </>
  );
};

interface RequirementGroupElementsProps {
  groupIndex: number;
}

const RequirementGroupElements: FC<RequirementGroupElementsProps> = ({ groupIndex }) => {
  const { control } = useFormContext();
  const { fields, append } = useFieldArray({ control, name: `groups.${groupIndex}.elements` });

  return (
    <>
      {fields.map((field, elementIndex) => (
        <Fragment key={field.id}>
          <Separator />

          <Group>
            <FieldSelect
              name={`groups.${groupIndex}.elements.${elementIndex}.element`}
              label="Element Type"
              placeholder="Select type"
              options={[
                { label: "Input", value: "input" },
                { label: "Textarea", value: "textarea" },
                { label: "Checkbox", value: "checkbox" },
              ]}
            />
            <FieldInput name={`groups.${groupIndex}.elements.${elementIndex}.props.label`} label="Label" />
            <FieldInput name={`groups.${groupIndex}.elements.${elementIndex}.clientDataKey`} label="Client Data Key" />
          </Group>
        </Fragment>
      ))}

      <Button
        type="button"
        onClick={() => append({ element: "", props: { label: "" }, clientDataKey: "", array: false })}
      >
        <Icon icon="add_2" />
        Add Element
      </Button>
    </>
  );
};

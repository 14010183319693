// import { DevTool } from "@hookform/devtools";
import { Maps, UUID } from "@cp/toolkit";
import { SentryLogger } from "@qw/sentry";
import { useState } from "react";
import { useParams } from "react-router";
import { Link } from "react-router-dom";

import { ClientDataContext } from "@/client-data/client-data-context";
import { ScrollPane } from "@/components/scroll-pane";
import { Autocomplete } from "@/components/ui/autocomplete";
import { Bar } from "@/components/ui/bar";
import { Button } from "@/components/ui/button";
import { Spinner } from "@/components/ui/loading";
import { Form } from "@/forms/form";
import { useToast } from "@/hooks/use-toast";
import { useUpsertSearchParams } from "@/hooks/use-upsert-search-params";
import { AutoGarage } from "@/supplementals/pdfs/auto-garage";
import { BuildersRisk } from "@/supplementals/pdfs/builders-risk";
import { ContractorsGeneralLiability } from "@/supplementals/pdfs/contractors-general-liability";
import { ContractorsPollutionLiability } from "@/supplementals/pdfs/contractors-pollution-liability";
import { Cyber } from "@/supplementals/pdfs/cyber";
import { Daycare } from "@/supplementals/pdfs/daycare";
import { SubmissionTemplate } from "@/supplementals/pdfs/submission-template";
import { SupplementalForm, useClientDataLazyQuery } from "src/generated/graphql";
import { GenerateAnvilButton } from "./generate-anvil-button";
import { SubmitSupplementalButton } from "./submit-supplemental-button";

import { SetMetadata } from "@/hooks/use-metadata";
import "./anvil-pdf-generation.css";

type FormProps = Array<{ displayName: string; key: string; element: React.ReactNode; filename: string }>;

export const SUPPLEMENTAL_FORMS: FormProps = [
  {
    key: SupplementalForm.ContractorsGeneralLiability,
    displayName: "Contractors General Liability",
    element: <ContractorsGeneralLiability />,
    filename: "QuoteWell-Contractors-GL-Supplemental",
  },
  {
    key: SupplementalForm.ContractorsPollutionLiability,
    displayName: "Contractors Pollution Liability",
    element: <ContractorsPollutionLiability />,
    filename: "QuoteWell-CPL-Supplemental",
  },
  {
    key: SupplementalForm.Cyber,
    displayName: "Cyber",
    element: <Cyber />,
    filename: "QuoteWell-Cyber-Supplemental",
  },
  {
    key: SupplementalForm.Daycare,
    displayName: "Daycare",
    element: <Daycare />,
    filename: "QuoteWell-Daycare-Supplemental",
  },
  {
    key: SupplementalForm.BuildersRisk,
    displayName: "Builder's Risk",
    element: <BuildersRisk />,
    filename: "QuoteWell-Builders-Risk-Supplemental",
  },
  {
    key: SupplementalForm.Garage,
    displayName: "Garage",
    element: <AutoGarage />,
    filename: "QuoteWell-Garage-Supplemental",
  },
  {
    key: SupplementalForm.SubmissionSupplemental,
    displayName: "Submission Supplemental",
    element: <SubmissionTemplate />,
    filename: "QuoteWell-Submission-Supplemental",
  },
];

export const Supplementals = () => {
  const [changed, setChanged] = useState(false);
  const [blurred, setBlurred] = useState(false);
  const [searchParams, setSearchParams] = useUpsertSearchParams();
  const { form, opportunityId } = Object.fromEntries(searchParams);
  const selectedForm = SUPPLEMENTAL_FORMS.find((f) => f.key === form) ?? SUPPLEMENTAL_FORMS[0];
  const { toast } = useToast();

  const { clientId } = useParams();

  const [clientData] = useClientDataLazyQuery();

  const defaultValues = async () => {
    if (!clientId) {
      console.warn("No clientId present. Data won't be saved.");
      return {};
    }
    const { data: supplementalResult, error } = await clientData({
      variables: { input: { insuredId: clientId as UUID } },
    });
    if (!supplementalResult) {
      SentryLogger.warn(`Supplemental result was undefined for Client(${clientId})`);
      return {};
    }
    if (error) {
      SentryLogger.exception(error);
      toast({
        title: "Error",
        description: "There was an error loading your supplemental data.",
        variant: "destructive",
      });
      return {};
    }

    const supplementalData = Maps.collectBy(
      supplementalResult.clientData,
      (val) => `${val.key}${val.index === null ? "" : `--${val.index}`}`,
      (val) => val.value
    );
    return Object.fromEntries(supplementalData.entries());
  };

  const handleBlur = () => {
    setBlurred(true);
    if (changed) {
      const timeout = setTimeout(function () {
        setBlurred(false);
        setChanged(false);
      }, 1000);

      return function () {
        clearTimeout(timeout);
      };
    }
    setBlurred(false);
    return;
  };

  return (
    <>
      <SetMetadata title="Supplementals" />
      <ScrollPane id="Supplementals">
        <Bar>
          <div className="mr-auto">
            <Autocomplete
              placeholder="Search Forms"
              options={SUPPLEMENTAL_FORMS}
              selected={selectedForm}
              onSelect={(o) => setSearchParams({ form: o.key })}
              toValue={(o) => o.key}
              toLabel={(o) => o.displayName}
              buttonProps={{ variant: "outline", size: "sm" }}
            />
          </div>

          {clientId && blurred && changed && <Spinner />}

          {opportunityId && <SubmitSupplementalButton filename={selectedForm.filename} />}

          <GenerateAnvilButton filename={selectedForm.filename}>Download PDF</GenerateAnvilButton>

          {opportunityId && (
            <Button size="sm" asChild>
              <Link to={`/insured/${clientId}/plans/${opportunityId}`}>Save &amp; Return</Link>
            </Button>
          )}
        </Bar>
        <div className="p-6">
          <ClientDataContext.Provider value={{ insuredId: clientId }}>
            <Form
              id="pdf-input"
              defaultValues={defaultValues}
              onChange={() => setChanged(true)}
              onBlur={() => handleBlur()}
            >
              {selectedForm.element}
            </Form>
          </ClientDataContext.Provider>
        </div>
      </ScrollPane>
    </>
  );
};

import compact from "lodash/compact";
import { FC, useState } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { Link } from "react-router-dom";
import { z } from "zod";

import { ButtonGroup } from "@/components/button-group";
import { Grid, GridCell, GridEmpty, GridRow, GridRowAction, GridRowHeader } from "@/components/grid";
import { Group } from "@/components/group";
import { Button } from "@/components/ui/button";
import { Card } from "@/components/ui/card";
import { Icon } from "@/components/ui/icon";
import { Sheet, SheetContent, SheetFooter, SheetHeader, SheetTitle, SheetTrigger } from "@/components/ui/sheet";
import { FieldGrid, FieldGridRow } from "@/forms/fields/field-grid";
import { FieldCurrency, FieldNumber } from "@/forms/fields/field-number";
import { FieldTextarea } from "@/forms/fields/field-textarea";
import { Form, FormSubmitHandler } from "@/forms/form";
import { FormReset } from "@/forms/form-reset";
import { FormSubmit } from "@/forms/form-submit";
import { useToast } from "@/hooks/use-toast";
import { SegmentAppetiteProductFragment } from "src/generated/graphql";

import { RuleFormSchema } from "../../components/rule-form/rule-form";
import { ruleValueToDisplayValue } from "../../components/rule-form/rule-form.helpers";
import { useUpdateSegmentAppetiteProduct } from "../../hooks/use-update-segment-appetite-product";

const validationSchema = z.object({
  minBrokerFee: z.number().min(0).max(2000).int().optional(),
  maxBrokerFee: z.number().min(0).max(2000).int().optional(),
  targetYield: z.number().min(0).max(100).optional(),
  generalExclusions: z.array(z.string()).optional(),
});

type FormValues = z.infer<typeof validationSchema>;

interface EditAppetiteProductButtonProps {
  segmentAppetiteProduct: SegmentAppetiteProductFragment;
}

export const EditAppetiteProductButton: FC<EditAppetiteProductButtonProps> = ({ segmentAppetiteProduct }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { toast } = useToast();

  const { updateAppetiteProduct } = useUpdateSegmentAppetiteProduct();

  const onSubmit: FormSubmitHandler<FormValues> = async (values, methods) =>
    await updateAppetiteProduct(
      { id: segmentAppetiteProduct.id, ...values },
      {
        onCompleted: () => {
          toast({ title: "Product settings updated" });
          methods.reset(values);
        },
      }
    );

  const defaultValues = {
    minBrokerFee: segmentAppetiteProduct.minBrokerFee ?? undefined,
    maxBrokerFee: segmentAppetiteProduct.maxBrokerFee ?? undefined,
    targetYield: segmentAppetiteProduct.targetYield ? segmentAppetiteProduct.targetYield * 100 : undefined,
    generalExclusions: compact(segmentAppetiteProduct.generalExclusions ?? []),
  };

  return (
    <Sheet open={isOpen} onOpenChange={setIsOpen}>
      <SheetTrigger asChild>
        <GridRowAction label="Edit">
          <Icon icon="edit" />
        </GridRowAction>
      </SheetTrigger>

      <SheetContent className="sm:max-w-4xl">
        <Group>
          <SheetHeader>
            <SheetTitle>
              Edit settings for {segmentAppetiteProduct.appetiteProduct.carrierName}{" "}
              {segmentAppetiteProduct.appetiteProduct.carrierProductName}
            </SheetTitle>
          </SheetHeader>

          <Form validationSchema={validationSchema} defaultValues={defaultValues} onSubmit={onSubmit}>
            <Group className="gap-6">
              <FieldGrid>
                <SegmentAppetiteProductBrokerFeesFormFields />
                <SegmentAppetiteProductGeneralExclusionsFormFields />
                <SegmentAppetiteProductRules segmentAppetiteProduct={segmentAppetiteProduct} />
              </FieldGrid>
            </Group>

            <SheetFooter>
              <ButtonGroup>
                <FormReset onlyEnableIfDirty />
                <FormSubmit onlyEnableIfDirty />
              </ButtonGroup>
            </SheetFooter>
          </Form>
        </Group>
      </SheetContent>
    </Sheet>
  );
};

const SegmentAppetiteProductBrokerFeesFormFields: FC = () => (
  <>
    <FieldGridRow label="Min broker fee ($)">
      <FieldCurrency name="minBrokerFee" />
    </FieldGridRow>

    <FieldGridRow label="Max broker fee ($)">
      <FieldCurrency name="maxBrokerFee" />
    </FieldGridRow>

    <FieldGridRow label="Target yield (%)">
      <FieldNumber
        name="targetYield"
        description="Enter a value between 0 and 100. (Example: 14 for 14%)"
        inputProps={{ decimalScale: 2 }}
      />
    </FieldGridRow>
  </>
);

const SegmentAppetiteProductGeneralExclusionsFormFields: FC = () => {
  const { control } = useFormContext();
  const { fields, append, remove } = useFieldArray({ control, name: "generalExclusions" });

  return (
    <FieldGridRow label="General exclusions">
      <Group>
        {!fields?.length && (
          <GridEmpty orientation="horizontal" description="No general exclusions have been added." className="p-0">
            <Button type="button" variant="outline" size="sm" onClick={() => append("")}>
              <Icon icon="add" /> Add exclusion
            </Button>
          </GridEmpty>
        )}

        {!!fields?.length && (
          <>
            <Group type="flex" direction="row" className="items-center">
              <span className="flex-1" />
              <Button type="button" variant="ghost" size="sm" onClick={() => append("")} className="-mt-2 -mr-2">
                <Icon icon="add" /> Add exclusion
              </Button>
            </Group>

            <Group className="gap-2">
              {fields.map((field, index) => (
                <Group key={field.id} type="flex" direction="row" className="gap-2">
                  <FieldTextarea
                    name={`generalExclusions.${index}`}
                    placeholder="Enter a general exclusion prompt"
                    rows={2}
                    inputProps={{ className: "resize-none" }}
                  />
                  <Button
                    type="button"
                    variant="secondary"
                    size="xs"
                    display="icon"
                    onClick={() => remove(index)}
                    className="h-full"
                  >
                    <Icon icon="close" />
                  </Button>
                </Group>
              ))}
            </Group>
          </>
        )}
      </Group>
    </FieldGridRow>
  );
};

interface SegmentAppetiteProductRulesProps {
  segmentAppetiteProduct: SegmentAppetiteProductFragment;
}

const SegmentAppetiteProductRules: FC<SegmentAppetiteProductRulesProps> = ({ segmentAppetiteProduct }) => (
  <FieldGridRow label="Rules">
    <Group>
      {!segmentAppetiteProduct.ruleSet?.rules?.length && (
        <GridEmpty orientation="horizontal" description="No rules have been added." className="p-0">
          <Button type="button" variant="outline" size="sm" asChild>
            <Link to={`../product-rules?segmentAppetiteProductId=${segmentAppetiteProduct.id}`}>
              <Icon icon="rule" /> Manage rules
            </Link>
          </Button>
        </GridEmpty>
      )}

      {!!segmentAppetiteProduct.ruleSet?.rules.length && (
        <>
          <Group type="flex" direction="row" className="items-center">
            <span className="flex-1" />
            <Button type="button" variant="ghost" size="sm" asChild className="-mt-2 -mr-2">
              <Link to={`../product-rules?segmentAppetiteProductId=${segmentAppetiteProduct.id}`}>
                <Icon icon="rule" /> Manage rules
              </Link>
            </Button>
          </Group>

          <Card>
            <Grid className="bg-none! grid-cols-[1fr_1fr_1fr]">
              <GridRowHeader>
                <GridCell>Key</GridCell>
                <GridCell>Operator</GridCell>
                <GridCell>Value</GridCell>
              </GridRowHeader>

              {segmentAppetiteProduct.ruleSet?.rules?.map((rule) => (
                <GridRow key={rule.id}>
                  <GridCell>{rule.inputKey}</GridCell>
                  <GridCell>{rule.operator}</GridCell>
                  <GridCell>{ruleValueToDisplayValue(rule.value as z.infer<typeof RuleFormSchema>["value"])}</GridCell>
                </GridRow>
              ))}
            </Grid>
          </Card>
        </>
      )}
    </Group>
  </FieldGridRow>
);

import { Link } from "react-router-dom";

import { AgencyAffiliations } from "@/components/agency-affiliations";
import { ImpersonateUser } from "@/components/impersonate-user";
import { SidebarFooter } from "@/components/sidebar";
import { useInsured } from "@/hooks/use-insured";

export const InsuredAgency = () => {
  const { insured } = useInsured();

  if (!insured.agent) {
    return null;
  }

  const {
    agent,
    agent: { firstName, lastName, agency, agencyId },
  } = insured;

  return (
    <SidebarFooter>
      <div className="space-y-1">
        <div className="font-semibold text-xs truncate">
          <ImpersonateUser user={agent} elseShow={`${firstName} ${lastName}`}>
            <span className="cursor-pointer">
              {firstName} {lastName}
            </span>
          </ImpersonateUser>
        </div>
        <div className="text-2xs text-muted-foreground truncate">
          <Link to={`/agency/${agencyId}`} className="truncate">
            {agency.name}
          </Link>
        </div>
      </div>
      <AgencyAffiliations id={agency.id} />
    </SidebarFooter>
  );
};

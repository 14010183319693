import { zodResolver } from "@hookform/resolvers/zod";
import { FormEventHandler, HTMLAttributes } from "react";
import {
  DefaultValues,
  FieldErrors,
  FieldValues,
  FormProvider,
  Mode,
  SubmitErrorHandler,
  SubmitHandler,
  useForm,
  useFormContext,
  UseFormProps,
  UseFormReturn,
} from "react-hook-form";
import { z } from "zod";

type AsyncDefaultValues<TFieldValues> = (payload?: unknown) => Promise<TFieldValues>;

export type FormDefaultSchema = z.ZodObject<z.ZodRawShape> | z.ZodEffects<z.ZodObject<z.ZodRawShape>>;

export type FormSubmitHandler<TFieldValues extends FieldValues> = (
  values: TFieldValues,
  methods: UseFormReturn<TFieldValues>,
  event?: React.BaseSyntheticEvent<object, any, any>
) => unknown | Promise<unknown>;

export type FormSubmitErrorHandler<TFieldValues extends FieldValues> = (
  errors: FieldErrors<TFieldValues>,
  methods: UseFormReturn<TFieldValues>,
  event?: React.BaseSyntheticEvent<object, any, any>
) => unknown | Promise<unknown>;

export interface FormProps<TFieldValues extends FieldValues, TSchema extends FormDefaultSchema>
  extends Omit<HTMLAttributes<HTMLFormElement>, "onSubmit" | "onError"> {
  defaultValues?: AsyncDefaultValues<TFieldValues> | DefaultValues<TFieldValues>;
  validationSchema?: TSchema;
  onSubmit?: FormSubmitHandler<TFieldValues>;
  onError?: FormSubmitErrorHandler<TFieldValues>;
  mode?: Mode;
  useFormProps?: UseFormProps<TFieldValues>;
  providerOnly?: boolean;
}

export function Form<TFieldValues extends FieldValues, TSchema extends FormDefaultSchema>({
  mode = "onTouched",
  defaultValues = {} as any,
  validationSchema,
  useFormProps,
  providerOnly,
  children,
  ...props
}: FormProps<TFieldValues, TSchema>) {
  const methods = useForm<TFieldValues>({
    mode,
    defaultValues,
    resolver: validationSchema ? zodResolver(validationSchema) : undefined,
    ...useFormProps,
  });

  return (
    <FormProvider {...methods}>
      {providerOnly && children}

      {!providerOnly && <FormElement {...props}>{children}</FormElement>}
    </FormProvider>
  );
}

export interface FormElementProps<TFieldValues extends FieldValues>
  extends Omit<HTMLAttributes<HTMLFormElement>, "onSubmit" | "onError"> {
  onSubmit?: FormProps<TFieldValues, any>["onSubmit"];
  onError?: FormProps<TFieldValues, any>["onError"];
}

export function FormElement<TFieldValues extends FieldValues>({
  onSubmit,
  onError,
  ...props
}: FormElementProps<TFieldValues>) {
  const methods = useFormContext<TFieldValues>();

  const handleSubmit: SubmitHandler<TFieldValues> = async (values, event) => {
    return await onSubmit?.(values, methods, event);
  };

  const handleError: SubmitErrorHandler<TFieldValues> = async (errors, event) => {
    return await onError?.(errors, methods, event);
  };

  // NOTE: This is a workaround when using nested forms to prevent form submission from propagating to parent forms.
  // SEE: https://codesandbox.io/p/sandbox/react-hook-form-nested-portal-bw8m75?file=/src/App.tsx:88,1
  const handleSubmitWithoutPropagation: FormEventHandler<HTMLFormElement> = async (event) => {
    event?.preventDefault();
    event?.stopPropagation();
    return await methods.handleSubmit(handleSubmit, handleError)(event);
  };

  return <form onSubmit={handleSubmitWithoutPropagation} {...props} />;
}

import { HasInternalRole } from "@/components/has-role";
import { LinkList, ListLink, SidebarSection } from "@/components/sidebar";
import { StateIndicator } from "@/components/state";
import { Spinner } from "@/components/ui/loading";
import { useInsured } from "@/hooks/use-insured";
import { QuoteFragment } from "src/generated/graphql";
import { formatDollars } from "src/utils/strings";

export const InsuredQuotes = () => {
  const { draftQuotes, sentQuotes, quotesLoading } = useInsured();

  if (quotesLoading) {
    return <Spinner className="m-6 " />;
  }

  return (
    <>
      <HasInternalRole>
        {draftQuotes[0] && (
          <SidebarSection>
            <h6 className="text-muted-foreground">Quote Drafts</h6>
            <QuoteList quotes={draftQuotes} />
          </SidebarSection>
        )}
      </HasInternalRole>
      {sentQuotes[0] && (
        <SidebarSection>
          <h6 className="text-muted-foreground">Quotes</h6>
          <QuoteList quotes={sentQuotes} />
        </SidebarSection>
      )}
    </>
  );
};

export const QuoteList = ({ quotes }: { quotes: QuoteFragment[] }) => (
  <LinkList>
    {quotes.map((quote) => {
      const { id, insuredId, submission, premium, state } = quote;
      const { appetiteProduct } = submission;
      const { carrierName, carrierProductName } = appetiteProduct;
      return (
        <ListLink key={id} to={`/insured/${insuredId}/quotes/${id}`} subheader={premium && formatDollars(premium)}>
          <span className="truncate">
            {carrierName}: {carrierProductName}
          </span>
          <StateIndicator state={state} displayLabel={false} />
        </ListLink>
      );
    })}
  </LinkList>
);

import { GraphQLErrorResponse } from "@cp/auth";

import { Alert, AlertDescription } from "@/components/ui/alert";
import { humanReadableStatus, isGraphQLErrorResponse } from "src/utils/errors";

interface AuthErrorProps {
  error: Error | GraphQLErrorResponse;
}

export const AuthError = ({ error }: AuthErrorProps) => {
  if (isGraphQLErrorResponse(error)) {
    const {
      response: { errors, status },
    } = error;

    if (!errors || errors.length === 0) {
      return (
        <Error>
          {status}: {humanReadableStatus(status)}
        </Error>
      );
    }

    return (
      <Error>
        {errors.map((err, i) => (
          <div key={i}>{err.message}</div>
        ))}
      </Error>
    );
  }

  return <Error>{error.message}</Error>;
};

const Error = ({ children }: { children: React.ReactNode }) => (
  <Alert
    variant="destructive"
    className="bg-[color-mix(in_oklab,var(--color-background),var(--color-destructive)_10%)] max-w-xs mb-4"
  >
    <AlertDescription>{children}</AlertDescription>
  </Alert>
);

import { FC, useMemo } from "react";
import { Link } from "react-router-dom";

import { ButtonGroup } from "@/components/button-group";
import {
  Grid,
  GridCell,
  GridData,
  GridEmpty,
  GridForm,
  GridLoading,
  GridRow,
  GridRowAction,
  GridRowCheckbox,
  GridRowHeader,
  GridSelectAllCheckbox,
} from "@/components/grid";
import { Group } from "@/components/group";
import { Badge } from "@/components/ui/badge";
import { Card, CardHeader, CardTitle } from "@/components/ui/card";
import { Icon } from "@/components/ui/icon";
import { SegmentAppetiteProductFragment } from "src/generated/graphql";
import { formatDollars } from "src/utils/strings";

import { useSegment } from "../hooks/use-segment";
import { AddAppetiteProductsButton } from "./components/add-appetite-products-button";
import { BulkEditAppetiteProductsButton } from "./components/bulk-edit-appetite-products-button";
import { EditAppetiteProductButton } from "./components/edit-appetite-product-button";
import { RemoveAppetiteProductButton } from "./components/remove-appetite-product-button";

export const SegmentAppetiteProducts = () => {
  const { segment, isLoadingSegment } = useSegment();

  const gridData = useMemo(
    () =>
      [...(segment?.segmentAppetiteProducts || [])]?.sort((a, b) => {
        const aName = `${a?.appetiteProduct?.carrierName} ${a?.appetiteProduct?.carrierProductName}`;
        const bName = `${b?.appetiteProduct?.carrierName} ${b?.appetiteProduct?.carrierProductName}`;

        return aName.localeCompare(bName);
      }),
    [segment?.segmentAppetiteProducts]
  );

  return (
    <div className="p-6">
      <GridForm>
        <Card>
          <CardHeader className="pb-3">
            <Group type="flex" direction="row" className="items-center justify-between">
              <CardTitle>Products</CardTitle>
              <span className="flex-1" />
              <ButtonGroup className="-my-1">
                <BulkEditAppetiteProductsButton />
                <AddAppetiteProductsButton />
              </ButtonGroup>
            </Group>
          </CardHeader>

          <Grid className="bg-none! grid-cols-[1.5rem_1.5fr_3fr_0.75fr_0.75fr_0.75fr_0.75fr_0.75fr_5.5rem]">
            <GridRowHeader>
              <GridCell>
                <GridSelectAllCheckbox data={gridData as GridData} />
              </GridCell>
              <GridCell>Carrier</GridCell>
              <GridCell>Product</GridCell>
              <GridCell>Min Fee ($)</GridCell>
              <GridCell>Max Fee ($)</GridCell>
              <GridCell>Yield (%)</GridCell>
              <GridCell>Exclusions</GridCell>
              <GridCell>Rules</GridCell>
            </GridRowHeader>

            <SegmentAppetiteProductsGridBody data={gridData} loading={isLoadingSegment} />
          </Grid>
        </Card>
      </GridForm>
    </div>
  );
};

export interface SegmentAppetiteProductsGridBodyProps {
  data?: SegmentAppetiteProductFragment[];
  loading: boolean;
}

export const SegmentAppetiteProductsGridBody: FC<SegmentAppetiteProductsGridBodyProps> = ({ data, loading }) => {
  if (!loading && !data?.length) {
    return <SegmentAppetiteProductsGridEmpty />;
  }

  if (loading && !data?.length) {
    return <SegmentAppetiteProductsGridLoading />;
  }

  return (
    <>
      {data?.map((row) => (
        <SegmentAppetiteProductsGridRow key={row.id} row={row} />
      ))}
    </>
  );
};

export const SegmentAppetiteProductsGridLoading: FC = () => <GridLoading rows={1} columns={9} />;

export const SegmentAppetiteProductsGridEmpty: FC = () => (
  <GridEmpty
    title="No appetite products"
    description={
      <>
        <p>Add an appetite product to get started.</p>
        <AddAppetiteProductsButton variant="secondary" />
      </>
    }
  />
);

interface SegmentsGridRowProps {
  row: SegmentAppetiteProductFragment;
}

const SegmentAppetiteProductsGridRow: FC<SegmentsGridRowProps> = ({ row }) => (
  <GridRow>
    <GridCell>
      <GridRowCheckbox row={row} />
    </GridCell>
    <GridCell>{row.appetiteProduct.carrierName}</GridCell>
    <GridCell>{row.appetiteProduct.carrierProductName}</GridCell>
    <GridCell>{formatDollars(row.minBrokerFee)}</GridCell>
    <GridCell>{formatDollars(row.maxBrokerFee)}</GridCell>
    <GridCell>{formatPercentage(row.targetYield)}</GridCell>
    <GridCell>
      {!!row.generalExclusions?.length && (
        <Badge type="square" variant="secondary">
          {row.generalExclusions.length}
        </Badge>
      )}
    </GridCell>
    <GridCell>
      {!!row.ruleSet?.rules?.length && (
        <Badge type="square" variant="secondary">
          {row.ruleSet.rules.length}
        </Badge>
      )}
    </GridCell>
    <ButtonGroup>
      <GridRowAction label="Manage rules" asChild>
        <Link to={`../product-rules?segmentAppetiteProductId=${row.id}`}>
          <Icon icon="rule" />
        </Link>
      </GridRowAction>
      <EditAppetiteProductButton segmentAppetiteProduct={row} />
      <RemoveAppetiteProductButton appetiteProduct={row.appetiteProduct} />
    </ButtonGroup>
  </GridRow>
);

const formatPercentage = (value?: number | null) => {
  if (value === undefined || value === null) {
    return "-";
  }
  return `${(value * 100).toFixed(2)}%`;
};

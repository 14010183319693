import { Button } from "@/components/ui/button";
import { Icon } from "@/components/ui/icon";
import { FieldCheckbox } from "@/forms/fields/field-checkbox";
import { FieldInput } from "@/forms/fields/field-input";
import { FieldNumber } from "@/forms/fields/field-number";
import { FieldSelect } from "@/forms/fields/field-select";
import { STRICT_STATE_OPTIONS } from "@cp/toolkit";
import { zodResolver } from "@hookform/resolvers/zod";
import { useFieldArray, useForm, useFormContext } from "react-hook-form";
import { z } from "zod";
import { AlbyActionType, useResumableGraphQuery, useResumeGraphMutation } from "../../../generated/graphql";
import { Group } from "../../components/group";
import { FieldCombobox } from "../../forms/fields/field-combobox";
import { FieldDatePicker } from "../../forms/fields/field-date-picker";
import { Form } from "../../forms/form";
import { FormSubmit } from "../../forms/form-submit";
import { useInsured } from "../../hooks/use-insured";
import { toast } from "../../hooks/use-toast";

// Form options
const FORM_OPTIONS = {
  form: ["Special", "Basic"],
  occupancy: ["StoreClothing", "Office", "Restaurant", "Warehouse", "Retail", "Hotel", "Apartment"],
  rentalType: ["NotRented", "SingleTenant", "MultiTenant"],
  construction: ["Frame", "JoistedMasonry", "NonCombustible", "MasonryNonCombustible", "Modified", "FireResistive"],
  fireSafeguards: ["None", "CentralFireAlarm", "LocalFireAlarm", "Extinguishers", "Sprinklers"],
  burglarySafeguards: ["None", "CentralBurglarAlarm", "LocalBurglarAlarm", "Guards", "Cameras"],
  fireProtectionClass: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"],
  electricType: ["CircuitBreaker", "FuseBox", "Knob&Tube", "Aluminum"],
  heatingType: ["Electric", "Gas", "Oil", "Other"],
  plumbingType: ["Copper", "PVC", "Galvanized", "Lead", "PolyButylene"],
  basementType: ["None", "Finished", "Unfinished", "Partial"],
  roofGeometry: ["Flat", "Gable", "Hip", "Gambrel", "Mansard", "Shed"],
  roofCoveringMaterial: ["Asphalt", "BuiltUpRoof", "Metal", "Slate", "Tile", "Wood"],
  roofAnchorageMethod: ["Unknown", "ToeNailing", "ClipsOrStraps", "NailedWroughtStraps", "BoltedWroughtStraps"],
  claddingType: ["Unknown", "None", "EIFS", "Brick", "Stucco", "WoodSiding", "VinylSiding", "MetalSiding"],
  windowProtectionType: ["Unknown", "None", "Shutters", "ImpactResistant", "FilmProtection"],
  floodZone: ["X", "A", "AE", "AH", "AO", "AR", "A99", "V", "VE"],
  buildingValuationOptions: ["RCV", "ACV"],
  coInsuranceOptions: ["CoInsurance80", "CoInsurance90", "CoInsurance100"],
  windDedType: ["WindHail", "Named", "Hurricane"],
  quakeOptions: ["5%", "10%", "15%", "20%", "25%"],
  windDedOptions: ["1%", "2%", "3%", "5%", "10%"],
  peakSeasonCoveredProperty: ["Stock", "BusinessPersonalProperty"],
  enhancementEndorsementOptions: [
    "None",
    "CommercialPropertyEnh",
    "PropertyBroadeningEndorsement",
    "PropertyEliteEndorsement",
  ],
  claimType: ["Fire", "Water", "Wind", "Theft", "Liability", "Glass", "Treefall", "Hail", "Other"],
};

// Define Address schema
const AddressSchema = z.object({
  StreetAddress: z.string().min(1, "Street address is required"),
  City: z.string().min(1, "City is required"),
  State: z.string().length(2, "State code should be 2 characters"),
  ZipCode: z.string().regex(/^\d{5}(-\d{4})?$/, "Must be a valid 5-digit zip or zip+4 format"),
});

// Flood information schema
const FloodInfoSchema = z.object({
  FloodZone: z.string(),
  PropertyOverWater: z.boolean(),
});

// Earthquake information schema
const EarthquakeInfoSchema = z.object({
  SoftStory: z.boolean(),
  RetrofittedMasonry: z.boolean(),
});

// Building attributes schema
const BuildingAttributesSchema = z.object({
  Occupancy: z.string().min(1, "Occupancy is required"),
  RentalType: z.string().min(1, "Rental type is required"),
  NumberOfUnitsInBuilding: z.number().int().positive("Must be a positive number"),
  Construction: z.string().min(1, "Construction is required"),
  NumberOfStories: z.number().int().positive("Must be a positive number"),
  FloorAreaSqFt: z.number().int().positive("Must be a positive number"),
  FireProtectionClass: z.string().min(1, "Fire protection class is required"),
  BurglarySafeguards: z.array(z.string()),
  FireSafeguards: z.array(z.string()),
  Sprinklers: z.boolean(),
  AutomaticWaterShutOff: z.boolean(),
  ElectricType: z.string().min(1, "Electric type is required"),
  HeatingType: z.string().min(1, "Heating type is required"),
  PlumbingType: z.string().min(1, "Plumbing type is required"),
  OriginalYearBuilt: z.number().int().positive("Must be a positive number"),
  StructuralUpgradeYear: z.number().int().positive().nullable(),
  ElectricUpgradeYear: z.number().int().positive().nullable(),
  ElectricUpgradeType: z.string().optional(),
  HeatingUpgradeYear: z.number().int().positive().nullable(),
  HeatingUpgradeType: z.string().optional(),
  PlumbingUpgradeYear: z.number().int().positive().nullable(),
  PlumbingUpgradeType: z.string().optional(),
  RoofUpgradeYear: z.number().int().positive().nullable(),
  RoofUpgradeType: z.string().optional(),
  BasementType: z.string(),
  RoofGeometry: z.string(),
  RoofCoveringMaterial: z.string(),
  RoofAnchorageMethod: z.string(),
  CladdingType: z.string(),
  WindowProtectionType: z.string(),
  FloodInfo: FloodInfoSchema,
  EarthquakeInfo: EarthquakeInfoSchema,
});

// Building coverages schema
const BuildingCoveragesSchema = z.object({
  BuildingValueLimit: z.number().nonnegative("Must be a non-negative number"),
  BuildingValueCoInsurance: z.string().min(1, "Building value co-insurance is required"),
  BuildingValueValuation: z.string().min(1, "Building value valuation is required"),
  BusinessPersonalPropertyLimit: z.number().nonnegative("Must be a non-negative number"),
  BusinessPersonalPropertyCoInsurance: z.string().min(1, "BPP co-insurance is required"),
  BusinessPersonalPropertyValuation: z.string().min(1, "BPP valuation is required"),
  BusinessIncomeLimit: z.number().nonnegative("Must be a non-negative number"),
  BIExtraExpenseIncluded: z.boolean(),
  BusinessIncomeCoInsurance: z.string().min(1, "Business income co-insurance is required"),
});

// Limits and deductibles schema
const LimitsAndDeductiblesSchema = z.object({
  AOPDeductibleValue: z.number().positive("Must be a positive number"),
  IncludeWindCover: z.boolean(),
  WindDed: z.string(),
  WindDedType: z.string(),
  IncludeWaterDamageCover: z.boolean(),
  WaterDamageSublimit: z.number().nonnegative("Must be a non-negative number"),
  IncludeSprinklerLeakageCover: z.boolean(),
  IncludeSinkholeCover: z.boolean(),
  IncludeVandalismCover: z.boolean(),
  IncludeAopCover: z.boolean(),
  IncludeTheftCover: z.boolean(),
  TheftSublimit: z.number().nonnegative("Must be a non-negative number"),
  TheftDeductible: z.number().nonnegative("Must be a non-negative number"),
  IncludeEarthquakeCover: z.boolean(),
  EarthquakeDed: z.string(),
  IncludeFloodCover: z.boolean(),
  FloodDeductible: z.number().nonnegative("Must be a non-negative number"),
  FloodBuildingLimit: z.number().nonnegative("Must be a non-negative number"),
  FloodContentsLimit: z.number().nonnegative("Must be a non-negative number"),
  ExcludeRoofCover: z.boolean(),
  ACVOnRoof: z.boolean(),
});

// Peak season coverage schema
const PeakSeasonCoverSchema = z.object({
  CoveredProperty: z.string(),
  Limit: z.number().nonnegative("Must be a non-negative number"),
  From: z.string().regex(/^\d{4}-\d{2}-\d{2}$/, "Date must be in YYYY-MM-DD format"),
  To: z.string().regex(/^\d{4}-\d{2}-\d{2}$/, "Date must be in YYYY-MM-DD format"),
});

// Optional endorsements schema
const OptionalEndorsementsSchema = z.object({
  OrdinanceAndLawCover: z.any().nullable(),
  OutdoorSignsCover: z.any().nullable(),
  LossAssessmentForCondo: z.any().nullable(),
  PeakSeasonCover: z.array(PeakSeasonCoverSchema).nullable().default([]),
});

// Claims schema
const ClaimSchema = z.object({
  Amount: z.number().nonnegative("Must be a non-negative number"),
  Description: z.string().min(1, "Description is required"),
  ClaimDate: z.string().regex(/^\d{4}-\d{2}-\d{2}$/, "Date must be in YYYY-MM-DD format"),
  Type: z.string().min(1, "Claim type is required"),
  FullyRepaired: z.boolean(),
});

// Building information schema
const BuildingInformationSchema = z.object({
  BuildingNo: z.number().int().positive("Must be a positive number"),
  BuildingName: z.string().min(1, "Building name is required"),
  BuildingDescription: z.string().min(1, "Building description is required"),
  BuildingId: z.string().optional(),
  Form: z.string().min(1, "Form is required"),
  Address: AddressSchema,
  Attributes: BuildingAttributesSchema,
  Coverages: BuildingCoveragesSchema,
  LimitsAndDeductibles: LimitsAndDeductiblesSchema,
  OptionalEndorsements: OptionalEndorsementsSchema,
  ClaimsLast3Years: z.array(ClaimSchema).nullable().default([]),
});

// Main quote request schema
const VaveQuoteRequestSchema = z.object({
  PolicyholderName: z.string().min(1, "Policyholder name is required"),
  IsCommercialCondoUnit: z.boolean(),
  IsLessorsRisk: z.boolean(),
  Inception: z.date(),
  Expiry: z.date(),
  DomainName: z.string().url("Must be a valid URL"),
  Address: AddressSchema,
  TRIACoverage: z.boolean(),
  EquipmentBreakdown: z.boolean(),
  EnhancementEndorsement: z.string(),
  BuildingInformation: z.array(BuildingInformationSchema).min(1, "At least one building is required"),
});

// Component for Flood Info section
const FloodInfoForm = ({ prefix }: { prefix: string }) => (
  <div className="col-span-6 space-y-4 border-t pt-4">
    <h4 className="font-semibold">Flood Information</h4>
    <div className="grid grid-cols-6 gap-4">
      <FieldSelect
        name={`${prefix}.FloodInfo.FloodZone`}
        label="Flood Zone"
        options={FORM_OPTIONS.floodZone.map((z) => ({ label: z, value: z }))}
        className="col-span-2"
      />
      <FieldCheckbox name={`${prefix}.FloodInfo.PropertyOverWater`} label="Property Over Water" />
    </div>
  </div>
);

// Component for Earthquake Info section
const EarthquakeInfoForm = ({ prefix }: { prefix: string }) => (
  <div className="col-span-6 space-y-4 border-t pt-4">
    <h4 className="font-semibold">Earthquake Information</h4>
    <div className="grid grid-cols-6 gap-4">
      <FieldCheckbox name={`${prefix}.EarthquakeInfo.SoftStory`} label="Soft Story" />
      <FieldCheckbox name={`${prefix}.EarthquakeInfo.RetrofittedMasonry`} label="Retrofitted Masonry" />
    </div>
  </div>
);

// Component for Limits and Deductibles section
const LimitsAndDeductiblesForm = ({ prefix }: { prefix: string }) => (
  <div className="col-span-6 space-y-4 border-t pt-4">
    <h4 className="font-semibold">Limits and Deductibles</h4>
    <Group>
      <FieldNumber
        name={`${prefix}.LimitsAndDeductibles.AOPDeductibleValue`}
        label="AOP Deductible"
        className="col-span-2"
      />
      <FieldCheckbox name={`${prefix}.LimitsAndDeductibles.IncludeWindCover`} label="Include Wind Cover" />
      <FieldSelect
        name={`${prefix}.LimitsAndDeductibles.WindDed`}
        label="Wind Deductible"
        options={FORM_OPTIONS.windDedOptions.map((w) => ({ label: w, value: w }))}
        className="col-span-1"
      />
      <FieldSelect
        name={`${prefix}.LimitsAndDeductibles.WindDedType`}
        label="Wind Deductible Type"
        options={FORM_OPTIONS.windDedType.map((w) => ({ label: w, value: w }))}
        className="col-span-2"
      />
      <FieldCheckbox name={`${prefix}.LimitsAndDeductibles.IncludeWaterDamageCover`} label="Include Water Damage" />
      <FieldNumber
        name={`${prefix}.LimitsAndDeductibles.WaterDamageSublimit`}
        label="Water Damage Sublimit"
        className="col-span-2"
      />
      <FieldCheckbox
        name={`${prefix}.LimitsAndDeductibles.IncludeSprinklerLeakageCover`}
        label="Include Sprinkler Leakage"
      />
      <FieldCheckbox name={`${prefix}.LimitsAndDeductibles.IncludeSinkholeCover`} label="Include Sinkhole" />
      <FieldCheckbox name={`${prefix}.LimitsAndDeductibles.IncludeVandalismCover`} label="Include Vandalism" />
      <FieldCheckbox name={`${prefix}.LimitsAndDeductibles.IncludeAopCover`} label="Include AOP" />
      <FieldCheckbox name={`${prefix}.LimitsAndDeductibles.IncludeTheftCover`} label="Include Theft" />
      <FieldNumber
        name={`${prefix}.LimitsAndDeductibles.TheftSublimit`}
        label="Theft Sublimit"
        className="col-span-2"
      />
      <FieldNumber
        name={`${prefix}.LimitsAndDeductibles.TheftDeductible`}
        label="Theft Deductible"
        className="col-span-2"
      />
      <FieldCheckbox name={`${prefix}.LimitsAndDeductibles.IncludeEarthquakeCover`} label="Include Earthquake" />
      <FieldSelect
        name={`${prefix}.LimitsAndDeductibles.EarthquakeDed`}
        label="Earthquake Deductible"
        options={FORM_OPTIONS.quakeOptions.map((q) => ({ label: q, value: q }))}
        className="col-span-2"
      />
      <FieldCheckbox name={`${prefix}.LimitsAndDeductibles.IncludeFloodCover`} label="Include Flood" />
      <FieldNumber
        name={`${prefix}.LimitsAndDeductibles.FloodDeductible`}
        label="Flood Deductible"
        className="col-span-2"
      />
      <FieldNumber
        name={`${prefix}.LimitsAndDeductibles.FloodBuildingLimit`}
        label="Flood Building Limit"
        className="col-span-2"
      />
      <FieldNumber
        name={`${prefix}.LimitsAndDeductibles.FloodContentsLimit`}
        label="Flood Contents Limit"
        className="col-span-2"
      />
      <FieldCheckbox name={`${prefix}.LimitsAndDeductibles.ExcludeRoofCover`} label="Exclude Roof Cover" />
      <FieldCheckbox name={`${prefix}.LimitsAndDeductibles.ACVOnRoof`} label="ACV On Roof" />
    </Group>
  </div>
);

// Component for Optional Endorsements section
const OptionalEndorsementsForm = ({ prefix }: { prefix: string }) => {
  const { control } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name: `${prefix}.OptionalEndorsements.PeakSeasonCover`,
  });

  return (
    <div className="col-span-6 space-y-4 border-t pt-4">
      <div className="flex justify-between items-center">
        <h4 className="font-semibold">Optional Endorsements</h4>
        <Button
          type="button"
          variant="outline"
          onClick={() =>
            append({
              CoveredProperty: FORM_OPTIONS.peakSeasonCoveredProperty[0],
              Limit: 0,
              From: new Date().toISOString().split("T")[0],
              To: new Date().toISOString().split("T")[0],
            })
          }
        >
          <Icon icon="add" />
          Add Peak Season Cover
        </Button>
      </div>

      {fields.map((field, index) => (
        <div key={field.id} className="grid grid-cols-6 gap-4 border p-4 rounded-md">
          <FieldSelect
            name={`${prefix}.OptionalEndorsements.PeakSeasonCover.${index}.CoveredProperty`}
            label="Covered Property"
            options={FORM_OPTIONS.peakSeasonCoveredProperty.map((p) => ({ label: p, value: p }))}
            className="col-span-2"
          />
          <FieldNumber
            name={`${prefix}.OptionalEndorsements.PeakSeasonCover.${index}.Limit`}
            label="Limit"
            className="col-span-2"
          />
          <FieldInput
            name={`${prefix}.OptionalEndorsements.PeakSeasonCover.${index}.From`}
            label="From (YYYY-MM-DD)"
            className="col-span-1"
          />
          <FieldInput
            name={`${prefix}.OptionalEndorsements.PeakSeasonCover.${index}.To`}
            label="To (YYYY-MM-DD)"
            className="col-span-1"
          />
          <div className="col-span-6 flex justify-end">
            <Button type="button" variant="default" onClick={() => remove(index)}>
              <Icon icon="delete" className="mr-2" />
              Remove
            </Button>
          </div>
        </div>
      ))}
    </div>
  );
};

// Component for Claims section
const ClaimsForm = ({ prefix }: { prefix: string }) => {
  const { control } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name: `${prefix}.ClaimsLast3Years`,
  });

  return (
    <div className="col-span-6 space-y-4 border-t pt-4">
      <div className="flex justify-between items-center">
        <h4 className="font-semibold">Claims Last 3 Years</h4>
        <Button
          type="button"
          variant="outline"
          onClick={() =>
            append({
              Amount: 0,
              Description: "",
              ClaimDate: new Date().toISOString().split("T")[0],
              Type: FORM_OPTIONS.claimType[0],
              FullyRepaired: false,
            })
          }
        >
          <Icon icon="add" />
          Add Claim
        </Button>
      </div>

      {fields.map((field, index) => (
        <div key={field.id} className="grid grid-cols-6 gap-4 border p-4 rounded-md">
          <FieldNumber name={`${prefix}.ClaimsLast3Years.${index}.Amount`} label="Amount" className="col-span-1" />
          <FieldInput
            name={`${prefix}.ClaimsLast3Years.${index}.Description`}
            label="Description"
            className="col-span-2"
          />
          <FieldInput
            name={`${prefix}.ClaimsLast3Years.${index}.ClaimDate`}
            label="Claim Date (YYYY-MM-DD)"
            className="col-span-1"
          />
          <FieldSelect
            name={`${prefix}.ClaimsLast3Years.${index}.Type`}
            label="Type"
            options={FORM_OPTIONS.claimType.map((t) => ({ label: t, value: t }))}
            className="col-span-1"
          />
          <FieldCheckbox name={`${prefix}.ClaimsLast3Years.${index}.FullyRepaired`} label="Fully Repaired" />
          <div className="col-span-6 flex justify-end">
            <Button type="button" variant="default" onClick={() => remove(index)}>
              <Icon icon="delete" className="mr-2" />
              Remove
            </Button>
          </div>
        </div>
      ))}
    </div>
  );
};

// Component for Building Form
const BuildingForm = ({ index, onRemove }: { index: number; onRemove: (() => void) | undefined }) => {
  const prefix = `BuildingInformation.${index}`;

  return (
    <div className="border rounded-lg p-4 space-y-6 mb-6">
      <div className="flex justify-between items-center">
        <h3 className="text-lg font-semibold">Building {index + 1}</h3>
        {onRemove && (
          <Button type="button" variant="default" onClick={onRemove}>
            <Icon icon="delete" className="mr-2" />
            Remove Building
          </Button>
        )}
      </div>

      {/* Basic Building Information */}
      <div className="grid grid-cols-6 gap-4">
        <FieldInput name={`${prefix}.BuildingName`} label="Building Name" className="col-span-2" />
        <FieldNumber name={`${prefix}.BuildingNo`} label="Building Number" />
        <FieldInput name={`${prefix}.BuildingDescription`} label="Description" className="col-span-3" />
        <FieldSelect
          name={`${prefix}.Form`}
          label="Form"
          options={FORM_OPTIONS.form.map((f) => ({ label: f, value: f }))}
          className="col-span-2"
        />
      </div>

      {/* Building Address */}
      <div className="col-span-6 space-y-4 border-t pt-4">
        <h4 className="font-semibold">Building Address</h4>
        <div className="grid grid-cols-6 gap-4">
          <FieldInput name={`${prefix}.Address.StreetAddress`} label="Street" className="col-span-3" />
          <FieldInput name={`${prefix}.Address.City`} label="City" className="col-span-2" />
          <FieldSelect name={`${prefix}.Address.State`} label="State" options={STRICT_STATE_OPTIONS} />
          <FieldInput name={`${prefix}.Address.ZipCode`} label="Zip Code" />
        </div>
      </div>

      {/* Building Attributes */}
      <div className="col-span-6 space-y-4 border-t pt-4">
        <h4 className="font-semibold">Building Attributes</h4>
        <div className="grid grid-cols-6 gap-4">
          <FieldSelect
            name={`${prefix}.Attributes.Occupancy`}
            label="Occupancy"
            options={FORM_OPTIONS.occupancy.map((o) => ({ label: o, value: o }))}
            className="col-span-2"
          />
          <FieldSelect
            name={`${prefix}.Attributes.RentalType`}
            label="Rental Type"
            options={FORM_OPTIONS.rentalType.map((r) => ({ label: r, value: r }))}
            className="col-span-2"
          />
          <FieldNumber name={`${prefix}.Attributes.NumberOfUnitsInBuilding`} label="Number of Units" />
          <FieldSelect
            name={`${prefix}.Attributes.Construction`}
            label="Construction"
            options={FORM_OPTIONS.construction.map((c) => ({ label: c, value: c }))}
            className="col-span-2"
          />
          <FieldNumber name={`${prefix}.Attributes.NumberOfStories`} label="Stories" />
          <FieldNumber name={`${prefix}.Attributes.FloorAreaSqFt`} label="Floor Area (sq ft)" className="col-span-2" />
          <FieldSelect
            name={`${prefix}.Attributes.FireProtectionClass`}
            label="Fire Protection Class"
            options={FORM_OPTIONS.fireProtectionClass.map((f) => ({ label: f, value: f }))}
          />
          <FieldCombobox
            multiple
            name={`${prefix}.Attributes.FireSafeguards`}
            label="Fire Safeguards"
            options={FORM_OPTIONS.fireSafeguards.map((f) => ({ label: f, value: f }))}
            className="col-span-2"
          />
          <FieldCombobox
            multiple
            name={`${prefix}.Attributes.BurglarySafeguards`}
            label="Burglary Safeguards"
            options={FORM_OPTIONS.burglarySafeguards.map((b) => ({ label: b, value: b }))}
            className="col-span-2"
          />
          <FieldCheckbox name={`${prefix}.Attributes.Sprinklers`} label="Sprinklers" />
          <FieldCheckbox name={`${prefix}.Attributes.AutomaticWaterShutOff`} label="Auto Water Shutoff" />
          <FieldSelect
            name={`${prefix}.Attributes.ElectricType`}
            label="Electric Type"
            options={FORM_OPTIONS.electricType.map((e) => ({ label: e, value: e }))}
            className="col-span-2"
          />
          <FieldSelect
            name={`${prefix}.Attributes.HeatingType`}
            label="Heating Type"
            options={FORM_OPTIONS.heatingType.map((h) => ({ label: h, value: h }))}
            className="col-span-2"
          />
          <FieldSelect
            name={`${prefix}.Attributes.PlumbingType`}
            label="Plumbing Type"
            options={FORM_OPTIONS.plumbingType.map((p) => ({ label: p, value: p }))}
            className="col-span-2"
          />
          <FieldNumber name={`${prefix}.Attributes.OriginalYearBuilt`} label="Year Built" />

          <FieldNumber
            name={`${prefix}.Attributes.StructuralUpgradeYear`}
            label="Structural Upgrade Year"
            className="col-span-1"
          />
          <FieldNumber
            name={`${prefix}.Attributes.ElectricUpgradeYear`}
            label="Electric Upgrade Year"
            className="col-span-1"
          />
          <FieldInput
            name={`${prefix}.Attributes.ElectricUpgradeType`}
            label="Electric Upgrade Type"
            className="col-span-1"
          />
          <FieldNumber
            name={`${prefix}.Attributes.HeatingUpgradeYear`}
            label="Heating Upgrade Year"
            className="col-span-1"
          />
          <FieldInput
            name={`${prefix}.Attributes.HeatingUpgradeType`}
            label="Heating Upgrade Type"
            className="col-span-1"
          />
          <FieldNumber
            name={`${prefix}.Attributes.PlumbingUpgradeYear`}
            label="Plumbing Upgrade Year"
            className="col-span-1"
          />
          <FieldInput
            name={`${prefix}.Attributes.PlumbingUpgradeType`}
            label="Plumbing Upgrade Type"
            className="col-span-1"
          />
          <FieldNumber name={`${prefix}.Attributes.RoofUpgradeYear`} label="Roof Upgrade Year" className="col-span-1" />
          <FieldInput name={`${prefix}.Attributes.RoofUpgradeType`} label="Roof Upgrade Type" className="col-span-1" />

          <FieldSelect
            name={`${prefix}.Attributes.BasementType`}
            label="Basement Type"
            options={FORM_OPTIONS.basementType.map((b) => ({ label: b, value: b }))}
            className="col-span-2"
          />
          <FieldSelect
            name={`${prefix}.Attributes.RoofGeometry`}
            label="Roof Geometry"
            options={FORM_OPTIONS.roofGeometry.map((r) => ({ label: r, value: r }))}
            className="col-span-2"
          />
          <FieldSelect
            name={`${prefix}.Attributes.RoofCoveringMaterial`}
            label="Roof Material"
            options={FORM_OPTIONS.roofCoveringMaterial.map((r) => ({ label: r, value: r }))}
            className="col-span-2"
          />
          <FieldSelect
            name={`${prefix}.Attributes.RoofAnchorageMethod`}
            label="Roof Anchorage"
            options={FORM_OPTIONS.roofAnchorageMethod.map((r) => ({ label: r, value: r }))}
            className="col-span-2"
          />
          <FieldSelect
            name={`${prefix}.Attributes.CladdingType`}
            label="Cladding Type"
            options={FORM_OPTIONS.claddingType.map((c) => ({ label: c, value: c }))}
            className="col-span-2"
          />
          <FieldSelect
            name={`${prefix}.Attributes.WindowProtectionType`}
            label="Window Protection"
            options={FORM_OPTIONS.windowProtectionType.map((w) => ({ label: w, value: w }))}
            className="col-span-2"
          />
        </div>
      </div>

      {/* Flood and Earthquake Info */}
      <FloodInfoForm prefix={`${prefix}.Attributes`} />
      <EarthquakeInfoForm prefix={`${prefix}.Attributes`} />

      {/* Building Coverages */}
      <div className="col-span-6 space-y-4 border-t pt-4">
        <h4>Building Coverages</h4>
        <div className="grid grid-cols-6 gap-4">
          <FieldNumber
            name={`${prefix}.Coverages.BuildingValueLimit`}
            label="Building Value Limit"
            className="col-span-2"
          />
          <FieldSelect
            name={`${prefix}.Coverages.BuildingValueCoInsurance`}
            label="Building Co-Insurance"
            options={FORM_OPTIONS.coInsuranceOptions.map((c) => ({ label: c, value: c }))}
            className="col-span-2"
          />
          <FieldSelect
            name={`${prefix}.Coverages.BuildingValueValuation`}
            label="Building Valuation"
            options={FORM_OPTIONS.buildingValuationOptions.map((v) => ({ label: v, value: v }))}
            className="col-span-2"
          />
          <FieldNumber
            name={`${prefix}.Coverages.BusinessPersonalPropertyLimit`}
            label="BPP Limit"
            className="col-span-2"
          />
          <FieldSelect
            name={`${prefix}.Coverages.BusinessPersonalPropertyCoInsurance`}
            label="BPP Co-Insurance"
            options={FORM_OPTIONS.coInsuranceOptions.map((c) => ({ label: c, value: c }))}
            className="col-span-2"
          />
          <FieldSelect
            name={`${prefix}.Coverages.BusinessPersonalPropertyValuation`}
            label="BPP Valuation"
            options={FORM_OPTIONS.buildingValuationOptions.map((v) => ({ label: v, value: v }))}
            className="col-span-2"
          />
          <FieldNumber
            name={`${prefix}.Coverages.BusinessIncomeLimit`}
            label="Business Income Limit"
            className="col-span-2"
          />
          <FieldSelect
            name={`${prefix}.Coverages.BusinessIncomeCoInsurance`}
            label="BI Co-Insurance"
            options={FORM_OPTIONS.coInsuranceOptions.map((c) => ({ label: c, value: c }))}
            className="col-span-2"
          />
          <FieldCheckbox name={`${prefix}.Coverages.BIExtraExpenseIncluded`} label="BI Extra Expense Included" />
        </div>
      </div>

      {/* Limits and Deductibles */}
      <LimitsAndDeductiblesForm prefix={prefix} />

      {/* Optional Endorsements */}
      <OptionalEndorsementsForm prefix={prefix} />

      {/* Claims */}
      <ClaimsForm prefix={prefix} />
    </div>
  );
};

// Main VaveQuoteRequestForm component
const VaveQuoteRequestForm = () => {
  const defaultValues = {
    PolicyholderName: "",
    IsCommercialCondoUnit: false,
    IsLessorsRisk: false,
    Inception: new Date(),
    Expiry: new Date(new Date().setFullYear(new Date().getFullYear() + 1)),
    DomainName: "https://www.example.com",
    Address: {
      StreetAddress: "",
      City: "",
      State: "",
      ZipCode: "",
    },
    TRIACoverage: false,
    EquipmentBreakdown: false,
    EnhancementEndorsement: "None",
    BuildingInformation: [
      {
        BuildingNo: 1,
        BuildingName: "",
        BuildingDescription: "",
        Form: "Special",
        Address: {
          StreetAddress: "",
          City: "",
          State: "",
          ZipCode: "",
        },
        Attributes: {
          Occupancy: "Office",
          RentalType: "NotRented",
          NumberOfUnitsInBuilding: 1,
          Construction: "Frame",
          NumberOfStories: 1,
          FloorAreaSqFt: 1000,
          FireProtectionClass: "1",
          BurglarySafeguards: [],
          FireSafeguards: [],
          Sprinklers: false,
          AutomaticWaterShutOff: false,
          ElectricType: "CircuitBreaker",
          HeatingType: "Electric",
          PlumbingType: "Copper",
          OriginalYearBuilt: new Date().getFullYear(),
          StructuralUpgradeYear: null,
          ElectricUpgradeYear: null,
          ElectricUpgradeType: "",
          HeatingUpgradeYear: null,
          HeatingUpgradeType: "",
          PlumbingUpgradeYear: null,
          PlumbingUpgradeType: "",
          RoofUpgradeYear: null,
          RoofUpgradeType: "",
          BasementType: "None",
          RoofGeometry: "Flat",
          RoofCoveringMaterial: "Asphalt",
          RoofAnchorageMethod: "Unknown",
          CladdingType: "Unknown",
          WindowProtectionType: "Unknown",
          FloodInfo: {
            FloodZone: "X",
            PropertyOverWater: false,
          },
          EarthquakeInfo: {
            SoftStory: false,
            RetrofittedMasonry: false,
          },
        },
        Coverages: {
          BuildingValueLimit: 0,
          BuildingValueCoInsurance: "CoInsurance80",
          BuildingValueValuation: "RCV",
          BusinessPersonalPropertyLimit: 0,
          BusinessPersonalPropertyCoInsurance: "CoInsurance80",
          BusinessPersonalPropertyValuation: "RCV",
          BusinessIncomeLimit: 0,
          BIExtraExpenseIncluded: false,
          BusinessIncomeCoInsurance: "CoInsurance80",
        },
        LimitsAndDeductibles: {
          AOPDeductibleValue: 1000,
          IncludeWindCover: true,
          WindDed: "1%",
          WindDedType: "WindHail",
          IncludeWaterDamageCover: true,
          WaterDamageSublimit: 0,
          IncludeSprinklerLeakageCover: true,
          IncludeSinkholeCover: false,
          IncludeVandalismCover: true,
          IncludeAopCover: true,
          IncludeTheftCover: true,
          TheftSublimit: 0,
          TheftDeductible: 1000,
          IncludeEarthquakeCover: false,
          EarthquakeDed: "5%",
          IncludeFloodCover: false,
          FloodDeductible: 1000,
          FloodBuildingLimit: 0,
          FloodContentsLimit: 0,
          ExcludeRoofCover: false,
          ACVOnRoof: false,
        },
        OptionalEndorsements: {
          OrdinanceAndLawCover: null,
          OutdoorSignsCover: null,
          LossAssessmentForCondo: null,
          PeakSeasonCover: [],
        },
        ClaimsLast3Years: [],
      },
    ],
  };

  const { insured } = useInsured();

  const { control } = useForm({
    resolver: zodResolver(VaveQuoteRequestSchema),
    defaultValues,
  });

  const { data: graphData } = useResumableGraphQuery({
    variables: {
      input: {
        name: "SubmissionTransitionGraph",
        selectedTool: "submit-to-vave-api",
        insuredId: insured.id,
        invokeParams: JSON.stringify({}),
      },
    },
  });

  const [resumeGraph] = useResumeGraphMutation();

  const { fields, remove } = useFieldArray({
    control,
    name: "BuildingInformation",
  });

  const onSubmit = async (data: any) => {
    if (graphData?.resumableGraph?.threadId) {
      await resumeGraph({
        variables: {
          input: {
            threadId: graphData.resumableGraph.threadId,
            actionType: AlbyActionType.Approve,
            brokerInput: JSON.stringify(data),
          },
        },
      });
    } else {
      toast({
        title: "Error",
        description: "Failed to submit quote request",
      });
    }
  };

  return (
    <Group className="gap-6">
      <Form
        onSubmit={onSubmit}
        onReset={() => close()}
        defaultValues={defaultValues}
        validationSchema={VaveQuoteRequestSchema}
        className="space-y-8"
      >
        <div className="p-6 rounded-lg shadow-md">
          <h2 className="mb-4">Policy Information</h2>

          {/* Policyholder Information */}
          <div className="grid grid-cols-6 gap-4 mb-6">
            <FieldInput name="PolicyholderName" label="Policyholder Name" className="col-span-3" />
            <FieldCheckbox name="IsCommercialCondoUnit" label="Is Commercial Condo Unit" />
            <FieldCheckbox name="IsLessorsRisk" label="Is Lessors Risk" />
          </div>

          {/* Policy Dates */}
          <div className="grid grid-cols-6 gap-4 mb-6">
            <FieldDatePicker name="Inception" label="Inception Date" className="col-span-2" />
            <FieldDatePicker name="Expiry" label="Expiry Date" className="col-span-2" />
            <FieldInput name="DomainName" label="Domain Name" className="col-span-2" />
          </div>

          {/* Address */}
          <div className="mb-6">
            <h3 className="mb-2">Primary Address</h3>
            <div className="grid grid-cols-6 gap-4">
              <FieldInput name="Address.StreetAddress" label="Street" className="col-span-3" />
              <FieldInput name="Address.City" label="City" className="col-span-2" />
              <FieldSelect name="Address.State" label="State" options={STRICT_STATE_OPTIONS} />
              <FieldInput name="Address.ZipCode" label="Zip Code" />
            </div>
          </div>

          {/* Additional Policy Options */}
          <Group className="grid-cols-6">
            <FieldCheckbox name="TRIACoverage" label="TRIA Coverage" />
            <FieldCheckbox name="EquipmentBreakdown" label="Equipment Breakdown" />
            <FieldSelect
              name="EnhancementEndorsement"
              label="Enhancement Endorsement"
              options={FORM_OPTIONS.enhancementEndorsementOptions.map((e) => ({ label: e, value: e }))}
              className="col-span-2"
            />
          </Group>
        </div>

        {/* Buildings */}
        <div className="p-6 rounded-lg shadow-md">
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-xl font-semibold">Buildings</h2>
            <AddBuildingButton />
          </div>

          {fields.map((field, index) => (
            <BuildingForm key={field.id} index={index} onRemove={fields.length > 1 ? () => remove(index) : undefined} />
          ))}
        </div>

        {/* Form Submission */}
        <div className="flex justify-end">
          <FormSubmit>Submit Quote Request</FormSubmit>
        </div>
      </Form>
    </Group>
  );
};

const AddBuildingButton = () => {
  const { fields, append } = useFieldArray({
    name: "BuildingInformation",
  });
  return (
    <Button
      type="button"
      onClick={() =>
        append({
          BuildingNo: fields.length + 1,
          BuildingName: "",
          BuildingDescription: "",
          Form: "Special",
          Address: {
            StreetAddress: "",
            City: "",
            State: "",
            ZipCode: "",
          },
          Attributes: {
            Occupancy: "Office",
            RentalType: "NotRented",
            NumberOfUnitsInBuilding: 1,
            Construction: "Frame",
            NumberOfStories: 1,
            FloorAreaSqFt: 1000,
            FireProtectionClass: "1",
            BurglarySafeguards: [],
            FireSafeguards: [],
            Sprinklers: false,
            AutomaticWaterShutOff: false,
            ElectricType: "CircuitBreaker",
            HeatingType: "Electric",
            PlumbingType: "Copper",
            OriginalYearBuilt: new Date().getFullYear(),
            StructuralUpgradeYear: null,
            ElectricUpgradeYear: null,
            ElectricUpgradeType: "",
            HeatingUpgradeYear: null,
            HeatingUpgradeType: "",
            PlumbingUpgradeYear: null,
            PlumbingUpgradeType: "",
            RoofUpgradeYear: null,
            RoofUpgradeType: "",
            BasementType: "None",
            RoofGeometry: "Flat",
            RoofCoveringMaterial: "Asphalt",
            RoofAnchorageMethod: "Unknown",
            CladdingType: "Unknown",
            WindowProtectionType: "Unknown",
            FloodInfo: {
              FloodZone: "X",
              PropertyOverWater: false,
            },
            EarthquakeInfo: {
              SoftStory: false,
              RetrofittedMasonry: false,
            },
          },
          Coverages: {
            BuildingValueLimit: 0,
            BuildingValueCoInsurance: "CoInsurance80",
            BuildingValueValuation: "RCV",
            BusinessPersonalPropertyLimit: 0,
            BusinessPersonalPropertyCoInsurance: "CoInsurance80",
            BusinessPersonalPropertyValuation: "RCV",
            BusinessIncomeLimit: 0,
            BIExtraExpenseIncluded: false,
            BusinessIncomeCoInsurance: "CoInsurance80",
          },
          LimitsAndDeductibles: {
            AOPDeductibleValue: 1000,
            IncludeWindCover: true,
            WindDed: "1%",
            WindDedType: "WindHail",
            IncludeWaterDamageCover: true,
            WaterDamageSublimit: 0,
            IncludeSprinklerLeakageCover: true,
            IncludeSinkholeCover: false,
            IncludeVandalismCover: true,
            IncludeAopCover: true,
            IncludeTheftCover: true,
            TheftSublimit: 0,
            TheftDeductible: 1000,
            IncludeEarthquakeCover: false,
            EarthquakeDed: "5%",
            IncludeFloodCover: false,
            FloodDeductible: 1000,
            FloodBuildingLimit: 0,
            FloodContentsLimit: 0,
            ExcludeRoofCover: false,
            ACVOnRoof: false,
          },
          OptionalEndorsements: {
            OrdinanceAndLawCover: null,
            OutdoorSignsCover: null,
            LossAssessmentForCondo: null,
            PeakSeasonCover: [],
          },
          ClaimsLast3Years: [],
        })
      }
    >
      <Icon icon="add" />
      Add Building
    </Button>
  );
};

export default VaveQuoteRequestForm;

import { FC, useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { z } from "zod";

import { useOpenForm } from "@/components/modal-provider";
import { Button, ButtonProps } from "@/components/ui/button";
import { FieldCombobox } from "@/forms/fields/field-combobox";
import { FieldCurrency, FieldNumber } from "@/forms/fields/field-number";

import { useBulkUpdateSegmentAppetiteProducts } from "../../hooks/use-bulk-update-segment-appetite-products";
import { useSegment } from "../../hooks/use-segment";

export interface BulkEditAppetiteProductsButtonProps extends ButtonProps {}

const validationSchema = z.object({
  ids: z.array(z.string()).min(1, { message: "Please select at least one product" }),
  minBrokerFee: z.number().min(0).max(2000).int().optional(),
  maxBrokerFee: z.number().min(0).max(2000).int().optional(),
  targetYield: z.number().min(0).max(100).optional(),
});

export const BulkEditAppetiteProductsButton: FC<BulkEditAppetiteProductsButtonProps> = (props) => {
  const { openForm } = useOpenForm();
  const { segment } = useSegment();
  const { watch } = useFormContext();
  const values = watch();

  const { updateAppetiteProducts } = useBulkUpdateSegmentAppetiteProducts();

  const selected = values?.rowIds?.length;

  const options = useMemo(
    () =>
      segment?.segmentAppetiteProducts.map(({ id, appetiteProduct }) => ({
        label: `${appetiteProduct.carrierName} ${appetiteProduct.carrierProductName}`,
        value: id,
      })) || [],
    [segment?.segmentAppetiteProducts]
  );

  const handleSubmit = (values: any) => updateAppetiteProducts(values);

  const handleClick = async () => {
    const defaultValues = {
      ids: values?.rowIds || [],
      minBrokerFee: undefined,
      maxBrokerFee: undefined,
      targetYield: undefined,
    };

    console.log("");

    await openForm(
      <>
        <FieldCombobox name="ids" label="Products" options={options} multiple />
        <FieldCurrency name="minBrokerFee" label="Min broker fee ($)" />
        <FieldCurrency name="maxBrokerFee" label="Max broker fee ($)" />
        <FieldNumber
          name="targetYield"
          label="Target yield (%)"
          description="Enter a value between 0 and 100. (Example: 14 for 14%)"
          inputProps={{ decimalScale: 2 }}
        />
      </>,
      {
        title: "Bulk Edit Products",
        description: "Edit multiple products at once.",
        defaultValues,
        validationSchema,
        onSubmit: handleSubmit,
      }
    );
  };

  if (!selected) {
    return null;
  }

  return (
    <Button
      variant="outline"
      size="sm"
      disabled={!segment?.segmentAppetiteProducts.length}
      {...props}
      onClick={handleClick}
    >
      Bulk Edit
    </Button>
  );
};

import { SetMetadata } from "@/hooks/use-metadata";
import { BankanQuoteColumn, MarketingPlanBankanColumn } from "src/generated/graphql";
import { MarketingPlanColumn } from "./columns/marketing-plan-column";
import { QuoteColumn } from "./columns/quote-column";

const marketingPlanColumns = [
  MarketingPlanBankanColumn.WaitingForCoverages,
  MarketingPlanBankanColumn.InformationGathering,
  MarketingPlanBankanColumn.InformationReview,
  MarketingPlanBankanColumn.MarketingInfoRequired,
  MarketingPlanBankanColumn.MarketingReady,
];

const quoteColumns = [
  BankanQuoteColumn.ReadyToSend,
  BankanQuoteColumn.Delivered,
  BankanQuoteColumn.BindRequestReceived,
];

export const BrokerBankan = () => (
  <>
    <SetMetadata title="Broker" />
    {marketingPlanColumns.map((column) => (
      <MarketingPlanColumn key={column} column={column} />
    ))}
    {quoteColumns.map((column) => (
      <QuoteColumn key={column} column={column} />
    ))}
  </>
);

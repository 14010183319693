import { Dialog, DialogContent, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { FilesByLabelQuery, FileUploadFragment } from "src/generated/graphql";
import { ViewPdfIframe } from "./view-pdf-iframe";
import { ViewPdfNav } from "./view-pdf-nav";

export const ViewPdfDialog = ({
  file,
  files,
  setSelectedFile,
}: {
  file?: FileUploadFragment;
  files: FilesByLabelQuery["filesByLabel"];
  setSelectedFile: (file: any) => void;
}) => {
  if (!file) {
    return null;
  }

  return (
    <Dialog open={!!file} onOpenChange={() => setSelectedFile(undefined)}>
      <DialogHeader className="hidden">
        <DialogTitle>{file.filename}</DialogTitle>
      </DialogHeader>
      <DialogContent className="flex items-center justify-center min-h-dvh min-w-dvw p-0 rounded-none shadow-none">
        <ViewPdfIframe file={file} />
        <ViewPdfNav files={files} selectedFile={file} setSelectedFile={setSelectedFile} />
      </DialogContent>
    </Dialog>
  );
};

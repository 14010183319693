import { useEffect } from "react";
import { Link, LinkProps, NavLink, NavLinkProps } from "react-router-dom";

import { SIDEBAR_WIDTH, usePage } from "@/hooks/use-page";
import { cn } from "src/utils";
import { Bar } from "./ui/bar";
import { Button } from "./ui/button";
import { Icon } from "./ui/icon";

export const SidebarTrigger = () => {
  const { sidebarOpen, setSidebarOpen, sidebarSide } = usePage();

  if (!sidebarSide) {
    return null;
  }

  return (
    <Button
      variant="ghost"
      size="xs"
      display="icon"
      onClick={() => setSidebarOpen(!sidebarOpen)}
      className={cn(sidebarSide === "right" ? "-mr-1.5 order-last" : "-ml-1.5")}
    >
      <Icon icon={sidebarOpen ? `${sidebarSide}_panel_close` : `${sidebarSide}_panel_open`} />
    </Button>
  );
};

export const Sidebar = ({
  children,
  bar,
  width = SIDEBAR_WIDTH,
  ...props
}: React.HTMLAttributes<HTMLDivElement> & { bar?: React.ReactNode; width?: string }) => {
  const { sidebarOpen, setSidebarOpen, sidebarSide, setSidebarWidth } = usePage();

  useEffect(() => {
    setSidebarWidth(width);
  }, []);

  return (
    <div
      className={cn(
        "flex flex-none inset-0 overflow-hidden transition-all",
        "max-md:absolute max-md:z-10",
        sidebarSide === "right" && "justify-end",
        sidebarOpen
          ? "max-md:backdrop-blur-xs max-md:backdrop-saturate-150 md:w-[var(--sidebar-width)]"
          : "max-md:pointer-events-none max-md:opacity-0 md:w-0"
      )}
    >
      <aside
        className={cn(
          "divide-foreground/10 divide-y flex flex-col overflow-hidden relative w-[var(--sidebar-width)] z-10",
          "max-md:bg-accent max-md:m-2 max-md:shadow-xl max-md:transition-transform max-md:duration-300 max-md:max-h-(--portrait-max-sidebar-height)",
          sidebarSide === "left"
            ? "max-md:rounded-md max-md:-translate-x-[var(--sidebar-width)]"
            : "max-md:rounded-md max-md:translate-x-[var(--sidebar-width)]",
          sidebarOpen && "max-md:translate-x-0"
        )}
        {...props}
      >
        {children}
      </aside>
      <div
        className={cn("absolute md:hidden inset-0 w-0 z-0", sidebarOpen && "w-full")}
        onClick={() => setSidebarOpen(false)}
      />
    </div>
  );
};

export const SidebarHeader = ({ children, className, ...props }: React.HTMLAttributes<HTMLDivElement>) => (
  <Bar className={cn("bg-transparent", className)} {...props}>
    {children}
  </Bar>
);

export const SidebarFooter = ({ children, className, ...props }: React.HTMLAttributes<HTMLDivElement>) => (
  <footer className={cn("px-6 py-3 space-y-3", className)} {...props}>
    {children}
  </footer>
);

export const SidebarContent = ({ children, className, ...props }: React.HTMLAttributes<HTMLDivElement>) => (
  <div className={cn("divide-foreground/10 divide-y flex-auto overflow-auto", className)} {...props}>
    {children}
  </div>
);

export const SidebarSection = ({ children, className, ...props }: React.HTMLAttributes<HTMLDivElement>) => (
  <section className={cn("p-6 space-y-4", className)} {...props}>
    {children}
  </section>
);

export const SidebarLinkClassName = "flex gap-3 items-center justify-between text-xs truncate";

export const SidebarLink = ({ children, className, to, ...props }: LinkProps) => {
  const { setSidebarOpen, widePage } = usePage();
  return (
    <Link to={to} className={cn(SidebarLinkClassName, className)} onClick={() => setSidebarOpen(widePage)} {...props}>
      {children}
    </Link>
  );
};

export const SidebarNavLink = ({ children, className, to, ...props }: NavLinkProps) => {
  const { setSidebarOpen, widePage } = usePage();
  return (
    <NavLink
      to={to}
      className={({ isPending, isActive }) =>
        cn(SidebarLinkClassName, isPending && "opacity-75", isActive && "filled font-semibold", className)
      }
      onClick={() => setSidebarOpen(widePage)}
      {...props}
    >
      {children}
    </NavLink>
  );
};

export const LinkList = ({ children }: React.PropsWithChildren) => (
  <div className="-mx-3 -my-2 space-y-0">{children}</div>
);

export const ListLink = ({
  children,
  className,
  to,
  subheader,
  ...props
}: NavLinkProps & { children: React.ReactNode; subheader?: React.ReactNode }) => {
  const { setSidebarOpen, widePage } = usePage();
  return (
    <NavLink
      to={to}
      className={({ isPending, isActive }) =>
        cn(
          "block px-3 py-2 text-xs rounded space-y-1 transition-colors",
          isPending && "opacity-50",
          isActive && "bg-accent filled",
          className
        )
      }
      onClick={() => setSidebarOpen(widePage)}
      {...props}
    >
      <div className="flex gap-3 items-center justify-between">{children}</div>
      {subheader && <div className="text-2xs text-muted-foreground">{subheader}</div>}
    </NavLink>
  );
};

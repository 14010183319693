import { FC } from "react";

import { ButtonGroup } from "@/components/button-group";
import { Grid, GridCell, GridEmpty, GridLoading, GridRow, GridRowHeader } from "@/components/grid";
import { Group } from "@/components/group";
import { Badge } from "@/components/ui/badge";
import { Card, CardHeader, CardTitle } from "@/components/ui/card";
import { SegmentRequirementFragment } from "src/generated/graphql";

import { useSegment } from "../hooks/use-segment";
import { CreateSegmentRequirementButton } from "./components/create-segment-requirement-button";
import { DeleteSegmentRequirementButton } from "./components/delete-segment-requirement-button";
import { UpdateSegmentRequirementButton } from "./components/update-segment-requirement-button";

export const SegmentRequirements = () => {
  const { segment, isLoadingSegment } = useSegment();

  const gridData = segment?.segmentRequirements || [];

  return (
    <div className="p-6">
      <Card>
        <CardHeader className="pb-3">
          <Group type="flex" direction="row" className="items-center justify-between">
            <CardTitle>Requirements</CardTitle>
            <span className="flex-1" />
            <CreateSegmentRequirementButton className="-my-1" />
          </Group>
        </CardHeader>

        <Grid className="bg-none! grid-cols-[1fr_2fr_3fr_3.5rem]">
          <GridRowHeader>
            <GridCell>Title</GridCell>
            <GridCell>Label</GridCell>
            <GridCell>Action Type</GridCell>
            <GridCell />
          </GridRowHeader>

          <SegmentRequirementsGridBody data={gridData} loading={isLoadingSegment} />
        </Grid>
      </Card>
    </div>
  );
};

export interface SegmentRequirementsGridBodyProps {
  data?: SegmentRequirementFragment[];
  loading: boolean;
}

export const SegmentRequirementsGridBody: FC<SegmentRequirementsGridBodyProps> = ({ data, loading }) => {
  if (!loading && !data?.length) {
    return <SegmentRequirementsGridEmpty />;
  }

  if (loading && !data?.length) {
    return <SegmentRequirementsGridLoading />;
  }

  return (
    <>
      {data?.map((row) => (
        <SegmentRequirementsGridRow key={row.id} row={row} />
      ))}
    </>
  );
};

export const SegmentRequirementsGridLoading: FC = () => <GridLoading rows={1} columns={3} />;

export const SegmentRequirementsGridEmpty: FC = () => (
  <GridEmpty
    title="No requirements"
    description={
      <>
        <p>Add a requirement to get started.</p>
        <CreateSegmentRequirementButton variant="secondary" />
      </>
    }
  />
);

interface SegmentsGridRowProps {
  row: SegmentRequirementFragment;
}

const SegmentRequirementsGridRow: FC<SegmentsGridRowProps> = ({ row }) => (
  <GridRow>
    <GridCell>{row.title}</GridCell>
    <GridCell>
      <Badge type="square" variant="secondary">
        {row.label}
      </Badge>
    </GridCell>
    <GridCell>{row.actionType.split(/(?=[A-Z])/).join(" ")}</GridCell>

    <ButtonGroup>
      <UpdateSegmentRequirementButton segmentRequirement={row} />
      <DeleteSegmentRequirementButton segmentRequirement={row} />
    </ButtonGroup>
  </GridRow>
);

import { Button } from "@/components/ui/button";
import { useMarketingPlanRequirements } from "@/hooks/use-marketing-plan-requirements";
import { MarketingPlanAction, useTransitionMarketingPlanMutation } from "src/generated/graphql";

export const InformationGathering = () => {
  const { opportunity, refetch } = useMarketingPlanRequirements();
  const [transition] = useTransitionMarketingPlanMutation();

  const handleTransition = async (action: MarketingPlanAction) => {
    await transition({
      variables: {
        input: {
          id: opportunity.id,
          expectedState: opportunity.state,
          action,
        },
      },
      onCompleted: () => refetch(),
    });
  };

  return (
    <Button variant="outline" size="xs" onClick={() => handleTransition(MarketingPlanAction.ReviewInformation)}>
      Info Gathering Complete
    </Button>
  );
};

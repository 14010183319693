import { FC } from "react";
import { z } from "zod";

import { ButtonGroup } from "@/components/button-group";
import { Group } from "@/components/group";
import { Alert, AlertDescription } from "@/components/ui/alert";
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { Icon } from "@/components/ui/icon";
import { Label } from "@/components/ui/label";
import { FieldInput } from "@/forms/fields/field-input";
import { FieldSelect } from "@/forms/fields/field-select";
import { FieldTextarea } from "@/forms/fields/field-textarea";
import { Form } from "@/forms/form";
import { FormReset } from "@/forms/form-reset";
import { FormSubmit } from "@/forms/form-submit";
import { useToast } from "@/hooks/use-toast";
import { CreatePrimaryLabelInput, ExtractedLabelFragment, useCreatePrimaryLabelMutation } from "src/generated/graphql";
import { parseError } from "src/utils";

import { PrimaryLabelDataTypeOptions } from "../../label-management.constants";

export interface CreatePrimaryLabelDialogProps {
  open: boolean;
  onOpenChange: (value: boolean) => void;
  defaultValues?: Omit<CreatePrimaryLabelInput, "extractedLabels">;
  extractedLabels?: ExtractedLabelFragment[];
  onSubmit?: (values: CreatePrimaryLabelInput) => void;
}

const validationSchema = z.object({
  primaryKey: z.string().min(1, { message: "Please enter a primary key." }),
  displayName: z.string().optional(),
  dataType: z.string().min(1, { message: "Please select a data type." }),
  description: z.string().optional(),
  extractedLabels: z.array(z.string()).optional(),
});

// TODO: Migrate this to use the `useOpenForm` hook.
// SEE: applications/web/src/brokerage/segments/components/create-segment-button.tsx
export const CreatePrimaryLabelDialog: FC<CreatePrimaryLabelDialogProps> = ({
  open,
  onOpenChange,
  onSubmit,
  extractedLabels,
  ...props
}) => {
  const { toast } = useToast();
  const [createPrimaryLabelMutation] = useCreatePrimaryLabelMutation();

  const defaultValues = {
    primaryKey: "",
    displayName: "",
    dataType: "",
    description: "",
    extractedLabels: extractedLabels?.map(({ id }) => id),
    ...props.defaultValues,
  };

  const handleSubmit = async (values: CreatePrimaryLabelInput) => {
    await createPrimaryLabelMutation({
      variables: { input: { ...values, extractedLabels: defaultValues.extractedLabels } },
      refetchQueries: ["PrimaryLabels", "PaginatedPrimaryLabels"],
      onCompleted: () => {
        onSubmit?.(values);
        onOpenChange(false);
      },
      onError: (error) => toast({ title: "Error", description: parseError(error.message), variant: "destructive" }),
    });
  };

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent>
        <Form
          onSubmit={handleSubmit}
          defaultValues={defaultValues as CreatePrimaryLabelInput}
          validationSchema={validationSchema}
        >
          <Group className="gap-6">
            <DialogHeader>
              <DialogTitle>Create primary label</DialogTitle>
            </DialogHeader>

            <Group>
              <FieldInput name="primaryKey" label="Primary key" />
              <FieldInput name="displayName" label="Display name" />
              <FieldSelect name="dataType" label="Data type" options={PrimaryLabelDataTypeOptions} />
              <FieldTextarea name="description" label="Description" />
            </Group>

            {!!extractedLabels?.length && (
              <div>
                <Label className="block text-sm mb-2">Assigning to</Label>
                <Alert>
                  <AlertDescription className="flex gap-3">
                    <Icon icon="label" className="text-lg" />
                    <div className="flex flex-col">
                      <span className="break-words">{extractedLabels?.[0].key}</span>
                      <span className="break-words text-xs text-muted-foreground">{extractedLabels?.[0].source}</span>
                    </div>
                  </AlertDescription>
                </Alert>
              </div>
            )}

            <ButtonGroup>
              <FormReset onClick={() => onOpenChange(false)} />
              <FormSubmit />
            </ButtonGroup>
          </Group>
        </Form>
      </DialogContent>
    </Dialog>
  );
};

import { MutationFunctionOptions } from "@apollo/client";
import compact from "lodash/compact";
import { useCallback } from "react";

import { useToast } from "@/hooks/use-toast";
import {
  UpdateSegmentAppetiteProductInput,
  UpdateSegmentAppetiteProductMutation,
  useUpdateSegmentAppetiteProductMutation,
} from "src/generated/graphql";

import { useSegment } from "./use-segment";

export const useUpdateSegmentAppetiteProduct = () => {
  const { segment } = useSegment();
  const { toast } = useToast();

  const [updateAppetiteProductsMutation, { loading: isUpdatingAppetiteProduct }] =
    useUpdateSegmentAppetiteProductMutation({
      awaitRefetchQueries: true,
      onCompleted: () => toast({ title: "Appetite product updated" }),
      refetchQueries: ["Segment"],
    });

  const updateAppetiteProduct = useCallback(
    async (
      input: Omit<UpdateSegmentAppetiteProductInput, "segmentId">,
      options?: Omit<MutationFunctionOptions<UpdateSegmentAppetiteProductMutation>, "variables">
    ) =>
      await updateAppetiteProductsMutation({
        variables: {
          input: {
            ...input,
            generalExclusions: compact(input.generalExclusions ?? []),
            targetYield: input.targetYield ? input.targetYield / 100 : undefined,
            segmentId: segment!.id,
          },
        },
        ...options,
      }),
    [segment?.id]
  );

  return {
    updateAppetiteProduct,
    isUpdatingAppetiteProduct,
  };
};

import { Loading } from "@/components/ui/loading";
import { useBankan } from "@/hooks/use-bankan";
import { UserState } from "@/metadata/bankan-columns";
import { useUserAccountsQuery } from "src/generated/graphql";
import { UserCard } from "../cards/user-card";
import { BankanColumn } from "./bankan-column";

export const UserColumn = () => {
  const { agencyId } = useBankan();

  const { data: { userAccounts } = { userAccounts: [] }, loading } = useUserAccountsQuery({
    variables: {
      input: {
        agencyId,
        emailDomainVerified: false,
      },
    },
    fetchPolicy: "cache-and-network",
  });

  return (
    <BankanColumn id={UserState.EmailDomainUnverified} count={userAccounts.length} loading={loading}>
      {loading ? <Loading /> : userAccounts.map((user) => <UserCard key={user.id} user={user} />)}
    </BankanColumn>
  );
};

import { ChangeEvent, useState } from "react";

import { SendEmailFormValues, useSendEmailForm } from "@/components/send-email-form";
import { Button } from "@/components/ui/button";
import { Spinner } from "@/components/ui/loading";
import { toast } from "@/hooks/use-toast";
import {
  PolicyAction,
  PolicyFileType,
  PolicyFragment,
  useDeliverBinderMutation,
  useTransitionPolicyMutation,
} from "src/generated/graphql";
import { cn, parseError } from "src/utils";
import { uploadPolicyDocument } from "src/utils/file";

export const BinderProcessedActions = ({ policy, refetch }: { policy: PolicyFragment; refetch: () => void }) => {
  const { id: policyId, state, processedBinderFile } = policy;
  const openSendEmailForm = useSendEmailForm();
  const [submitting, setSubmitting] = useState(false);

  const [deliverBinder, { loading }] = useDeliverBinderMutation();
  const [transition, { loading: transitionLoading }] = useTransitionPolicyMutation();

  const handleDeliverBinder = async (values: SendEmailFormValues) => {
    const formattedValues = {
      emailComment: values.emailComment,
      fileIds: values.files?.map((file) => file.id),
    };

    await deliverBinder({
      variables: { input: { policyId, ...formattedValues } },
      onCompleted: () => toast({ title: "Email sent" }),
      onError: (error) => toast({ title: "Error sending email", description: parseError(error) }),
    });
  };

  const handleSendEmailClick = async () =>
    await openSendEmailForm({
      title: "Send Email with Binder",
      onSubmit: handleDeliverBinder,
      defaultAttachments: processedBinderFile ? [{ name: processedBinderFile.filename }] : [],
    });

  const handleUploadNewBinderClick = async (e: ChangeEvent<HTMLInputElement>) => {
    setSubmitting(true);
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];

      if (file.type !== "application/pdf") {
        toast({ title: "We only accept PDF files" });
        setSubmitting(false);
        return;
      }

      await uploadPolicyDocument(file, policyId, PolicyFileType.RedactedBinder).then((res) => {
        if (res.success) {
          toast({ title: "Redacted Quote Uploaded" });
          refetch();
        } else {
          toast({ title: "Error" });
        }
      });
    }
    setSubmitting(false);
  };

  const handleTransition = async () =>
    await transition({
      variables: {
        input: {
          id: policyId,
          expectedState: state,
          action: PolicyAction.DeliverBinder,
        },
      },
    });

  return (
    <>
      <Button
        onClick={handleSendEmailClick}
        variant="outline"
        size="sm"
        display="flex"
        disabled={!processedBinderFile || loading}
      >
        Send Email with Binder Attached {loading && <Spinner />}
      </Button>
      <Button onClick={handleTransition} variant="outline" size="sm" display="flex" disabled={transitionLoading}>
        Binder was delivered outside app
      </Button>
      <Button asChild variant="outline" size="sm" display="flex">
        <label className={cn(submitting ? "cursor-wait opacity-60" : "cursor-pointer")}>
          <input type="file" name="file" className="hidden" onChange={handleUploadNewBinderClick} />
          Upload New Redacted Binder {submitting && <Spinner />}
        </label>
      </Button>
    </>
  );
};

import { Link, useParams } from "react-router-dom";

import { EmptyState } from "@/components/empty-state";
import { Grid } from "@/components/grid";
import { Button } from "@/components/ui/button";
import { Card, CardHeader, CardTitle } from "@/components/ui/card";
import { Spinner } from "@/components/ui/loading";
import { SetMetadata } from "@/hooks/use-metadata";
import { useGroupedMarketingPlansQuery } from "src/generated/graphql";
import { InsuredsHeader, InsuredsRow } from "./insureds-row";

export const AgencyAll = () => {
  const { agencyId } = useParams();

  const {
    data: { groupedMarketingPlans: { groupedMarketingPlans } } = {
      groupedMarketingPlans: { groupedMarketingPlans: [] },
    },
    loading,
  } = useGroupedMarketingPlansQuery({
    variables: {
      input: { agencyId },
    },
    skip: !agencyId,
    fetchPolicy: "cache-first",
  });

  return (
    <>
      <SetMetadata title="All Named Insureds" crumb />
      <div className="p-6">
        {groupedMarketingPlans[0] ? (
          <Card>
            <CardHeader>
              <CardTitle>
                All Named Insureds
                {loading ? (
                  <Spinner />
                ) : (
                  <Button variant="outline" size="sm" asChild>
                    <Link to="/insured/new">Create Insured</Link>
                  </Button>
                )}
              </CardTitle>
            </CardHeader>
            <Grid className="bg-transparent grid-cols-[1.5rem_1fr_1fr_1fr_1fr_1fr]">
              <InsuredsHeader />
              {groupedMarketingPlans.map((group) => (
                <InsuredsRow key={group.insuredId} group={group} />
              ))}
            </Grid>
          </Card>
        ) : (
          <EmptyState loading={loading} title={<h2>No insureds found.</h2>} className="m-0" />
        )}
      </div>
    </>
  );
};

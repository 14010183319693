import { sortBy } from "lodash";

import { useMarketingPlanRequirements } from "@/hooks/use-marketing-plan-requirements";
import { useToast } from "@/hooks/use-toast";
import {
  useCompleteAgentActionMutation,
  useDeleteAgentActionMutation,
  useUncompleteAgentActionMutation,
} from "src/generated/graphql";
import { AgentAction } from "./agent-action";

export const RequirementsList = () => {
  const { opportunity, refetch } = useMarketingPlanRequirements();
  const { toast } = useToast();

  const [triggerCompleteAgentAction] = useCompleteAgentActionMutation({
    onCompleted: () => {
      toast({ title: "Agent Action marked complete" });
      return refetch();
    },
    onError: (e) => toast({ title: `Error marking agent action complete: ${e}` }),
  });

  const [triggerUncompleteAgentAction] = useUncompleteAgentActionMutation({
    onCompleted: () => {
      toast({ title: "Agent Action marked incomplete" });
      return refetch();
    },
    onError: (e) => toast({ title: `Error marking agent action incomplete: ${e}` }),
  });

  const [triggerDeleteAgentAction] = useDeleteAgentActionMutation({
    onCompleted: () => {
      toast({ title: "Agent Action deleted" });
      return refetch();
    },
    onError: (e) => toast({ title: `Error deleting agent action: ${e}` }),
  });

  const completeAgentAction = async (id: string) => {
    return triggerCompleteAgentAction({ variables: { input: { id } } });
  };

  const uncompleteAgentAction = async (id: string) => {
    return triggerUncompleteAgentAction({ variables: { input: { id } } });
  };

  const deleteAgentAction = async (id: string) => {
    return triggerDeleteAgentAction({ variables: { id } });
  };

  return (
    <div className="divide-y">
      {/* Using order of the array for the key. Good enough for now */}
      {sortBy(opportunity.agentActions, [
        (action) => !action.title.includes("Acord"),
        (action) => !action.expeditedRequirement,
        "title",
      ]).map((action, i) => (
        <AgentAction
          key={i}
          action={action}
          opportunity={opportunity}
          clientId={opportunity.insured.id}
          onCompleteAgentAction={completeAgentAction}
          onUncompleteAgentAction={uncompleteAgentAction}
          onDeleteAgentAction={deleteAgentAction}
        />
      ))}
    </div>
  );
};

import {
  forwardRef,
  KeyboardEventHandler,
  ReactNode,
  useMemo,
  useRef,
  useState,
  type ComponentProps,
  type FC,
} from "react";

import { Group } from "@/components/group";
import { Button, ButtonProps } from "@/components/ui/button";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  CommandLoading,
} from "@/components/ui/command";
import { Icon } from "@/components/ui/icon";
import { Loading } from "@/components/ui/loading";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover";
import { Separator } from "@/components/ui/separator";
import { cn } from "src/utils";

import { useField } from "../hooks/use-field";
import { FieldBase, FieldBaseProps } from "./field-base";
import { FIELD_BORDERLESS_CLASS_NAME, FIELD_READ_ONLY_CLASS_NAME } from "./field.constants";

export interface FieldComboboxOption {
  label: string;
  value: string;
  keywords?: string[];
  displayLabel?: ReactNode;
  displayValue?: string;
  disabled?: boolean;
  irremovable?: boolean;
  itemProps?: ComponentProps<typeof CommandItem>;
  includeValueInSearch?: boolean;
}

export type FieldComboboxProps = FieldBaseProps & {
  placeholder?: string;
  borderless?: boolean;
  multiple?: boolean;
  loading?: boolean;
  includeValueInSearch?: boolean;
  options?: readonly FieldComboboxOption[];
  onChange?: (value: string | string[]) => void;
  onBlur?: (event: any) => void;
  inputProps?: ComponentProps<typeof Button>;
  popoverProps?: ComponentProps<typeof Popover>;
  // NOTE: This is primarily for use with the FieldComboboxAsync component.
  onCommandInputValueChange?: (value: string) => void;
  async?: boolean;
};

export const FieldCombobox: FC<FieldComboboxProps> = ({
  placeholder = "Select an option",
  borderless,
  readOnly,
  multiple,
  loading,
  async,
  options,
  onChange,
  onBlur,
  inputProps,
  popoverProps,
  includeValueInSearch,
  onCommandInputValueChange,
  ...props
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const fieldRef = useRef<HTMLDivElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const { field, hasError } = useField({ name: props.name, disabled: props.disabled, inputProps });

  const hasValue = useMemo(
    () => (multiple ? !!field.value?.length : !!options?.some((option) => option.value === field.value)),
    [multiple, field.value, options]
  );

  const handleChange: FieldComboboxProps["onChange"] = (value) => {
    if (multiple) {
      const values = field.value?.includes(value)
        ? field.value.filter((v: string) => v !== value)
        : [...(field.value || []), value];

      field.onChange(values);
      onChange?.(values);
      return;
    }

    // Only update the field value if it has changed. We need to do this because this is called
    // any time an item is "selected" in the dropdown, even if it's the same value. So we don't
    // want to trigger the change event if the value hasn't changed
    if (value !== field.value) {
      field.onChange(value);
      onChange?.(value);
    }

    setIsOpen(false);
  };

  const handleBlur: FieldComboboxProps["onBlur"] = (event) => {
    setTimeout(() => {
      // Don't trigger blur If the focus is still within the field or dropdown.
      if (fieldRef.current?.contains(document.activeElement) || dropdownRef.current?.contains(document.activeElement)) {
        return;
      }

      field.onBlur();
      onBlur?.(event);
    }, 0);
  };

  const handleKeyUp: KeyboardEventHandler<HTMLButtonElement> = (event) => {
    if (event.target === event.currentTarget && ["Enter", "Space"].includes(event.code)) {
      setIsOpen(true);
    }
  };

  return (
    <FieldBase ref={fieldRef} {...props}>
      <Popover modal {...popoverProps} onOpenChange={setIsOpen} open={isOpen}>
        <PopoverTrigger onBlur={handleBlur} asChild>
          <Button
            {...inputProps}
            ref={field.ref}
            variant="outline"
            type="button"
            role="combobox"
            disabled={field.disabled}
            onKeyUp={handleKeyUp}
            asChild={multiple}
            className={cn(
              "w-full justify-between font-normal gap-2 px-3",
              {
                "text-muted-foreground": !hasValue,
                "border-destructive": hasError && !field.disabled,
                [FIELD_BORDERLESS_CLASS_NAME]: borderless,
                [FIELD_READ_ONLY_CLASS_NAME]: readOnly,
              },
              inputProps?.className
            )}
          >
            <FieldComboboxValue
              value={field.value}
              hasValue={hasValue}
              options={options}
              onChange={handleChange}
              placeholder={placeholder}
              borderless={borderless}
              async={async}
              multiple={multiple}
            />
          </Button>
        </PopoverTrigger>

        <PopoverContent
          ref={dropdownRef}
          style={{ minWidth: "calc(var(--radix-popover-trigger-width) + 2px)" }}
          className="p-0"
        >
          <Command>
            <CommandInput placeholder="Search..." onValueChange={onCommandInputValueChange} onBlur={handleBlur} />

            {loading && !options?.length && (
              <>
                <Separator />

                <CommandLoading>
                  <Loading label={<>Loading options&hellip;</>} />
                </CommandLoading>
              </>
            )}

            {!!options?.length && (
              <>
                <Separator />

                <CommandList className="max-h-60">
                  <CommandEmpty>No items found.</CommandEmpty>

                  <CommandGroup>
                    {options.map((option) => (
                      <FieldComboboxCommandItem
                        key={option.value}
                        value={field.value}
                        option={option}
                        multiple={multiple}
                        includeValueInSearch={includeValueInSearch}
                        onChange={handleChange}
                      />
                    ))}
                  </CommandGroup>
                </CommandList>
              </>
            )}
          </Command>
        </PopoverContent>
      </Popover>
    </FieldBase>
  );
};

interface FieldComboboxCommandItemProps {
  value: string;
  option: FieldComboboxOption;
  multiple?: boolean;
  includeValueInSearch?: boolean;
  onChange: NonNullable<FieldComboboxProps["onChange"]>;
}

const FieldComboboxCommandItem: FC<FieldComboboxCommandItemProps> = ({
  option,
  value,
  multiple,
  includeValueInSearch,
  onChange,
}) => {
  const isSelected = multiple ? value?.includes(option.value) : option.value === value;
  const optionValue = useMemo(() => {
    return [option.label, ...(includeValueInSearch ? [option.value] : []), ...(option.keywords || [])].join(" ");
  }, []);

  return (
    <CommandItem
      key={option.value}
      disabled={option.disabled}
      {...option.itemProps}
      hidden={option.itemProps?.hidden || (multiple && option.irremovable)}
      // Adding `keywords` to the value allows the command to search by more than
      // just the value. The latest version of `cmdk` supports searching by the
      // `keywords` property, but our current version does not. This acts as a
      // workaround until we can upgrade.
      value={optionValue}
      onSelect={() => onChange(option.value)}
      className="flex justify-start"
    >
      <Icon icon="check" className={cn("shrink-0 grow-0 size-4 text-base", { "opacity-0": !isSelected })} />
      {option.displayLabel || option.label}
    </CommandItem>
  );
};
interface FieldComboboxValueProps {
  value: string | string[];
  hasValue: boolean;
  borderless?: boolean;
  multiple?: boolean;
  async?: boolean;
  options: FieldComboboxProps["options"];
  placeholder: FieldComboboxProps["placeholder"];
  onChange: NonNullable<FieldComboboxProps["onChange"]>;
  className?: string;
}

const FieldComboboxValue: FC<FieldComboboxValueProps> = forwardRef<HTMLDivElement, FieldComboboxValueProps>(
  ({ multiple, async, value, ...props }, ref) => {
    if (async) {
      return <AsyncSelectTriggerContent ref={ref} {...props} multiple={multiple} value={value} />;
    }

    if (multiple) {
      return <MultiSelectTriggerContent ref={ref} {...props} value={value as string[]} />;
    }

    return <SingleSelectTriggerContent {...props} value={value as string} />;
  }
);
FieldComboboxValue.displayName = "FieldComboboxValue";

interface AsyncSelectTriggerContentProps extends FieldComboboxValueProps {}

const AsyncSelectTriggerContent = forwardRef<HTMLDivElement, AsyncSelectTriggerContentProps>(
  ({ value, options, multiple, ...props }, ref) =>
    multiple ? (
      <MultiSelectTriggerContent
        ref={ref}
        {...props}
        options={options || (value as string[])?.map?.((v) => ({ label: v, value: v }))}
        value={value as string[]}
      />
    ) : (
      <SingleSelectTriggerContent
        {...props}
        options={options || [{ label: value as string, value: value as string }]}
        value={value as string}
      />
    )
);
AsyncSelectTriggerContent.displayName = "AsyncSelectTriggerContent";

interface SingleSelectTriggerContentProps {
  value: string;
  hasValue: boolean;
  options: FieldComboboxProps["options"];
  placeholder: FieldComboboxProps["placeholder"];
}

const SingleSelectTriggerContent: FC<SingleSelectTriggerContentProps> = ({ value, hasValue, options, placeholder }) => {
  const selectedOption = useMemo(() => options?.find((option) => option.value === value), [options, value]);

  return (
    <>
      <span className="truncate">{hasValue ? selectedOption?.displayValue || selectedOption?.label : placeholder}</span>
      <span className="flex-1" />
      <Icon icon="unfold_more" className="ml-2 size-4 text-foreground opacity-50" />
    </>
  );
};

interface MultiSelectTriggerContentProps {
  value: string[];
  hasValue: boolean;
  borderless?: boolean;
  options: FieldComboboxProps["options"];
  onChange?: NonNullable<FieldComboboxProps["onChange"]>;
  placeholder?: FieldComboboxProps["placeholder"];
  className?: string;
}

const MultiSelectTriggerContent = forwardRef<HTMLDivElement, MultiSelectTriggerContentProps>(
  ({ value, hasValue, borderless, options, onChange, placeholder, ...props }, ref) => {
    const addIcon = (
      <Button
        tabIndex={-1}
        variant="ghost"
        type="button"
        size="xs"
        display="icon"
        className={cn("rounded-sm h-[26px] w-[26px] -mx-1.5", { "mx-0": borderless && hasValue })}
      >
        <Icon icon="add_2" className="text-foreground opacity-50" />
      </Button>
    );

    return (
      <div
        ref={ref}
        tabIndex={0}
        {...props}
        className={cn(
          props.className,
          "z-10 h-auto min-h-10 py-1.5 gap-1.5 text-muted-foreground text-sm font-normal text-left",
          "w-auto pl-3 justify-start pr-3",
          { "pl-1.5": hasValue }
        )}
      >
        {!hasValue && <span className="flex-1 pointer-events-none pr-1.5">{placeholder}</span>}

        {hasValue && (
          <>
            <Group type="flex" direction="row" className="flex-wrap gap-1.5">
              {value.map((v: string) => {
                const option = options?.find((o: FieldComboboxOption) => o.value === v);

                if (!option) {
                  return null;
                }

                return (
                  <Pill
                    key={option.value}
                    onClick={(event) => {
                      event.stopPropagation();
                      !option.irremovable && onChange?.(option.value);
                    }}
                  >
                    {option.displayValue || option.label}
                    {!option.irremovable && <Icon icon="close" />}
                  </Pill>
                );
              })}

              {borderless && addIcon}
            </Group>

            <span className="flex-1" />
          </>
        )}

        {(!borderless || (borderless && !hasValue)) && addIcon}
      </div>
    );
  }
);
MultiSelectTriggerContent.displayName = "MultiSelectTriggerContent";

const Pill: FC<ButtonProps> = (props) => (
  <Button variant="secondary" type="button" size="xs" className="h-[26px] text-xs" {...props} />
);

import { PrimaryLabelDataType } from "src/generated/graphql";

export const RuleValueTypeToOperatorMap = {
  [PrimaryLabelDataType.String]: ["==", "!=" /*, "contains", "notContains", "startsWith", "endsWith"*/],
  [PrimaryLabelDataType.Number]: ["==", "!=", ">", ">=", "<", "<="],
  // We don't have a use case for dates right now, and we need input
  // from product about how we intend to use dates with rules.
  [PrimaryLabelDataType.Date]: ["==", "!=", ">", ">=", "<", "<="],
  [PrimaryLabelDataType.Percent]: ["==", "!=", ">", ">=", "<", "<="],
  // Always use equals for boolean
  [PrimaryLabelDataType.Boolean]: ["=="],
};

export enum RuleBooleanValue {
  Yes = "Yes",
  No = "No",
}

export const getRuleOperatorOptions = (valueType: PrimaryLabelDataType) =>
  RuleValueTypeToOperatorMap[valueType].map((o) => ({ label: o, value: o }));

import { MarketingPlanState } from "src/generated/graphql";
import { StateMetadata } from "./states";

export const MARKETING_PLAN_STATE_METADATA: Record<MarketingPlanState, StateMetadata> = {
  [MarketingPlanState.WaitingForCoverages]: {
    className: "text-slate-600",
    cardClassName: "outline-slate-500",
    icon: "shield",
    label: "Waiting for Coverage",
    title: "No coverage selected.",
    description: "Please select the coverage required by the Insured.",
  },
  [MarketingPlanState.InformationGathering]: {
    className: "filled text-red-600",
    cardClassName: "outline-red-500",
    icon: "warning",
    label: "Information Required",
    title: "We need some more information.",
    description: "Please upload the required information we need to go to market.",
  },
  [MarketingPlanState.InformationReview]: {
    className: "text-orange-600",
    cardClassName: "outline-orange-500",
    icon: "checklist",
    label: "Broker Review",
    title: "Your broker is reviewing your submission now.",
    description: "We'll let you know if we need anything else to get started on quotes!",
  },
  [MarketingPlanState.Marketing]: {
    className: "filled text-emerald-600",
    cardClassName: "outline-emerald-500",
    icon: "campaign",
    label: "Marketing",
    title: "We're out to market!",
    description: "Your broker is working on getting quotes. Check back here for updates as we get results.",
  },
  [MarketingPlanState.Complete]: {
    className: "filled text-purple-600",
    cardClassName: "outline-purple-500",
    icon: "verified",
    label: "Marketing Complete",
    title: "Thank you for giving QuoteWell a chance to win your business!",
    description:
      "Your broker has completed marketing for this plan. Check the Quotes and Marketing Plans sections for applicable quotes and alternatives.",
  },
};

import { LinkList, ListLink, SidebarSection } from "@/components/sidebar";
import { StateIndicator } from "@/components/state";
import { Spinner } from "@/components/ui/loading";
import { useInsured } from "@/hooks/use-insured";
import { ExpiredPolicyState } from "@/metadata/policies";
import { PolicyFragment, PolicyState } from "src/generated/graphql";

const now = Date.now();

export const InsuredPolicies = () => {
  const { activePolicies, activePoliciesLoading } = useInsured();

  if (activePoliciesLoading) {
    return <Spinner className="m-6" />;
  }

  if (!activePolicies[0]) {
    return null;
  }

  return (
    <SidebarSection>
      <h6 className="text-muted-foreground">Active Policies</h6>
      <PolicyList policies={activePolicies} />
    </SidebarSection>
  );
};

export const PolicyList = ({ policies }: { policies: PolicyFragment[] }) => (
  <LinkList>
    {policies.map((policy) => {
      const { id, insuredId, expirationDate, state, policyNumber, appetiteProduct } = policy;
      const { carrierName, carrierProductName } = appetiteProduct;
      const expired = Date.parse(expirationDate) < now;
      return (
        <ListLink key={id} to={`/insured/${insuredId}/policies/${id}`} subheader={`#${policyNumber}`}>
          <span className="truncate">
            {carrierName}: {carrierProductName}
          </span>
          <StateIndicator
            state={expired && state == PolicyState.Issued ? ExpiredPolicyState.Expired : state}
            displayLabel={false}
          />
        </ListLink>
      );
    })}
  </LinkList>
);

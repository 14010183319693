import { FC } from "react";

import { Group, GroupProps } from "@/components/group";
import { cn } from "src/utils";

export interface ButtonGroupProps extends GroupProps {}

export const ButtonGroup: FC<ButtonGroupProps> = ({ className, ...props }) => (
  <Group type="flex" direction="row" className={cn("flex-1 gap-2 items-center justify-end", className)} {...props} />
);

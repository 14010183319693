import { QuoteProvider } from "@/hooks/use-quote";
import { QuoteProposalForm } from "@/quotes/quote-proposal-form";
import { QuoteNotes } from "../../quotes/components/quote-notes";

export default function QuoteTransitionGraphGenerateCoverLetter() {
  return (
    <QuoteProvider>
      <div className="space-y-5">
        <QuoteNotes />
        <QuoteProposalForm />
      </div>
    </QuoteProvider>
  );
}

import { SubmissionState } from "src/generated/graphql";
import { StateMetadata } from "./states";

export enum DisplaySubmissionStateMarketBlockedActions {
  Revert = "Revert to Previous State",
}

export enum DisplaySubmissionStatePendingActions {
  RuleOut = "Rule Out Submission",
  Submit = "Submit for Review",
}

export enum DisplaySubmissionStateReferredActions {
  BlockMarket = "Block Market",
  Decline = "Decline Submission",
  Quote = "Provide Quote",
  RuleOut = "Rule Out Submission",
}

export enum DisplaySubmissionStateRuledOutActions {
  Revert = "Revert to Previous State",
}

export enum DisplaySubmissionStateSubmittedActions {
  BlockMarket = "Block Market",
  Decline = "Decline Submission",
  Quote = "Provide Quote",
  Refer = "Refer to Another Party",
  RuleOut = "Rule Out Submission",
}

export const SUBMISSION_STATE_METADATA: Record<SubmissionState, StateMetadata> = {
  [SubmissionState.Pending]: {
    className: "text-slate-600",
    cardClassName: "outline-slate-500",
    icon: "shield",
    label: "Pending",
    actionDisplayNames: DisplaySubmissionStatePendingActions,
  },
  [SubmissionState.ReadyToSubmit]: {
    className: "text-emerald-600",
    cardClassName: "outline-emerald-500",
    icon: "send",
    label: "Ready to Submit",
    actionDisplayNames: DisplaySubmissionStatePendingActions,
  },
  [SubmissionState.Submitted]: {
    className: "filled text-emerald-600",
    cardClassName: "outline-emerald-500",
    icon: "campaign",
    label: "Submitted",
    actionDisplayNames: DisplaySubmissionStateSubmittedActions,
  },
  [SubmissionState.MarketBlocked]: {
    className: "text-slate-600",
    cardClassName: "outline-slate-500",
    icon: "front_hand",
    label: "Blocked",
    actionDisplayNames: DisplaySubmissionStateMarketBlockedActions,
  },
  [SubmissionState.Declined]: {
    className: "text-red-600",
    cardClassName: "outline-red-500",
    icon: "thumb_down",
    label: "Declined",
    actionDisplayNames: {}, // No actions for Declined state
  },
  [SubmissionState.Referred]: {
    className: "filled text-orange-600",
    cardClassName: "outline-orange-500",
    icon: "send",
    label: "Referred",
    actionDisplayNames: DisplaySubmissionStateReferredActions,
  },
  [SubmissionState.RuledOut]: {
    className: "text-slate-600",
    cardClassName: "outline-slate-500",
    icon: "block",
    label: "Ruled Out",
    actionDisplayNames: DisplaySubmissionStateRuledOutActions,
  },
  [SubmissionState.Quoted]: {
    className: "filled text-purple-600",
    cardClassName: "outline-purple-500",
    icon: "verified",
    label: "Quoted",
    actionDisplayNames: {}, // No specific actions for Quoted state
  },
  [SubmissionState.Revert]: {
    className: "text-slate-600",
    cardClassName: "outline-slate-500",
    icon: "thumb_up",
    label: "Reverted",
    actionDisplayNames: {}, // No specific actions for Revert state
  },
};

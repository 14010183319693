import { FC } from "react";

import { Icon } from "@/components/ui/icon";

import { useLoadFromDoc } from "./use-load-from-doc";

export const DraggingOverlay: FC = () => {
  const { dragging } = useLoadFromDoc();

  if (!dragging) {
    return null;
  }

  return (
    <div className="absolute inset-0 opacity-70 bg-popover flex justify-center items-center z-20 pointer-events-none">
      <p className="mb-48 flex items-center gap-2 text-xl font-semibold ">
        <Icon icon="upload" className="animate-bounce" />
        Drop file to start upload
      </p>
    </div>
  );
};

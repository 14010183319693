import { useFragment } from "@apollo/client";
import { Outlet, useNavigate, useParams } from "react-router";

import { MoreMenu } from "@/components/more-menu";
import { SectionDescription, SectionHeader, SectionTitle } from "@/components/section";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@/components/ui/alert-dialog";
import { Badge } from "@/components/ui/badge";
import { Bar, BarLink, BarLinks } from "@/components/ui/bar";
import {
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuRadioGroup,
  DropdownMenuRadioItem,
  DropdownMenuSeparator,
} from "@/components/ui/dropdown-menu";
import { Icon } from "@/components/ui/icon";
import { Spinner } from "@/components/ui/loading";
import { SetMetadata } from "@/hooks/use-metadata";
import { toast } from "@/hooks/use-toast";
import {
  useDeleteVerticalMutation,
  useUpdateVerticalMutation,
  VerticalCrumbFragment,
  VerticalCrumbFragmentDoc,
} from "src/generated/graphql";
import { parseError } from "src/utils";
import { AddTemplate } from "./components/add-template";
import { useVertical } from "./components/use-vertical";

export const Vertical = () => {
  const { vertical, loading } = useVertical();

  return (
    <>
      <SetMetadata title={vertical.name} crumb menu={<VerticalMenu />} />
      <SectionHeader>
        <SectionTitle>
          <h1>{vertical.name}</h1>
          {loading && <Spinner />}
        </SectionTitle>
        <SectionDescription>{vertical.description}</SectionDescription>
      </SectionHeader>
      <Bar>
        <BarLinks>
          <BarLink to="hierarchy">Hierarchy</BarLink>
          <BarLink to="rules">Rules</BarLink>
          <BarLink to="requirements">Requirements</BarLink>
          <BarLink to="exclusion-reasons">
            Exclusion Reasons
            {!!vertical.exclusionReasonsCount && <Badge variant="secondary">{vertical.exclusionReasonsCount}</Badge>}
          </BarLink>
          <BarLink to="appetite">Appetite</BarLink>
        </BarLinks>
        <AddTemplate />
      </Bar>
      <Outlet />
    </>
  );
};

export const VerticalMenu = () => {
  const { verticalId } = useParams();
  const navigate = useNavigate();

  const { complete, data } = useFragment<VerticalCrumbFragment>({
    fragment: VerticalCrumbFragmentDoc,
    from: {
      __typename: "Vertical",
      id: verticalId,
    },
  });

  const [update] = useUpdateVerticalMutation({
    refetchQueries: ["VerticalQuery"],
  });

  if (!complete) {
    return null;
  }

  const { id, enabled, name } = data;

  return (
    <AlertDialog>
      <MoreMenu>
        <DropdownMenuItem onClick={() => navigate(`${id}/edit`)}>
          <Icon icon="edit" />
          Edit Vertical
        </DropdownMenuItem>
        <DropdownMenuSeparator />
        <DropdownMenuLabel>
          <Icon icon="visibility" /> Visibility
        </DropdownMenuLabel>
        <DropdownMenuRadioGroup
          value={enabled ? "enabled" : "disabled"}
          onValueChange={(v) =>
            update({
              variables: { input: { id, name, enabled: v === "enabled" ? true : false } },
            })
          }
        >
          <DropdownMenuRadioItem value="enabled">Enabled</DropdownMenuRadioItem>
          <DropdownMenuRadioItem value="disabled">Disabled</DropdownMenuRadioItem>
        </DropdownMenuRadioGroup>
        <DropdownMenuSeparator />
        <AlertDialogTrigger asChild>
          <DropdownMenuItem>
            <Icon icon="delete" />
            Delete Vertical
          </DropdownMenuItem>
        </AlertDialogTrigger>
      </MoreMenu>
      <DeleteVertical id={id} />
    </AlertDialog>
  );
};

const DeleteVertical = ({ id }: { id: string }) => {
  const navigate = useNavigate();
  const [deleteVertical] = useDeleteVerticalMutation({
    onCompleted: () => navigate("/verticals"),
    onError: (error) =>
      toast({ title: "Error deleting vertical", description: parseError(error), variant: "destructive" }),
  });

  return (
    <>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Are you sure?</AlertDialogTitle>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel>No</AlertDialogCancel>
          <AlertDialogAction
            theme="destructive"
            onClick={() => {
              void deleteVertical({ variables: { id } });
            }}
          >
            Yes
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </>
  );
};

import { NodeProps } from "@xyflow/react";
import { FC, useEffect, useMemo, useRef } from "react";
import { useMatch, useNavigate } from "react-router";

import { BadgeProps } from "@/components/ui/badge";
import { useFileProcessorQuery } from "src/generated/graphql";

import { useAddProcessorFormModal } from "../../../components/forms/add-processor-form";
import { getDocumentLabelStatus } from "../../../file-processing-pipeline.helpers";
import { useFileProcessingPipelineState } from "../../../file-processing-pipeline.provider";
import { NodeBase } from "./node-base";
import { NodeAction, NodeActionType } from "./node-toolbar";

export interface DocumentLabelNodeProps extends NodeProps {
  data: {
    id: string;
    name: string;
    category: string;
    totalProcessedFiles: number;
    isRoot: boolean;
    hasChildren: boolean;
  };
}

export const DocumentLabelNode: FC<DocumentLabelNodeProps> = ({ data, ...props }) => {
  const abortRef = useRef<AbortController>(new AbortController());
  const navigate = useNavigate();
  const { toggleExpandedNode, expandedNodes, isInitialized, isTransitioning } = useFileProcessingPipelineState();
  const { openAddProcessorForm } = useAddProcessorFormModal({ sourceNodeId: data?.id });
  const { data: documentLabelData, loading } = useFileProcessorQuery({
    variables: { id: data.id },
    skip: !isInitialized || isTransitioning,
    context: { fetchOptions: { signal: abortRef.current.signal } },
    fetchPolicy: "cache-first",
  });

  // Abort the pending query when the component unmounts.
  useEffect(() => () => abortRef.current.abort(), []);

  const detailUrlBase = "/file-processing-pipeline/document-label";
  const detailUrl = `${detailUrlBase}/${encodeURIComponent(data.id)}`;
  const match = useMatch(`${detailUrlBase}/:documentLabelId`);

  const totalProcessedFiles = documentLabelData?.fileProcessor?.totalProcessedFiles;
  const status = useMemo(() => getDocumentLabelStatus(totalProcessedFiles || 0), [totalProcessedFiles]);

  const badge = {
    variant: status.variant,
    children: Intl.NumberFormat("en-us", { notation: "compact", maximumFractionDigits: 1 }).format(
      documentLabelData?.fileProcessor?.totalProcessedFiles || 0
    ),
  };
  const badgeLoading = loading ? { variant: "secondary" as BadgeProps["variant"], children: "..." } : undefined;
  const isExpanded = expandedNodes.includes(props.id);

  const actions: NodeAction[] = [
    {
      id: "viewDetails",
      icon: "visibility",
      label: "View details",
      onClick: () => navigate(detailUrl),
      isDefault: true,
      isHidden: true,
    },
    {
      id: "addProcessor",
      icon: "add_box",
      label: "Add Processor",
      onClick: openAddProcessorForm,
    },
    {
      id: "expandCollapse",
      icon: isExpanded ? "collapse_content" : "expand_content",
      label: `${isExpanded ? "Collapse" : "Expand"}`,
      onClick: () => toggleExpandedNode(props.id),
      isHidden: !data.hasChildren,
    },
    { type: NodeActionType.Separator },
    {
      id: "close",
      icon: "close",
      label: "Close",
      onClick: () => navigate(`/file-processing-pipeline`),
    },
  ];

  return (
    <NodeBase
      label={data?.name}
      icon="folder"
      actions={actions}
      isActive={data.id === decodeURIComponent(match?.params.documentLabelId || "")}
      isRoot={data.isRoot}
      hasChildren={data.hasChildren}
      badge={documentLabelData?.fileProcessor ? badge : badgeLoading}
      {...props}
    />
  );
};

import { ApolloError } from "@apollo/client";
import { createContext, useContext, useMemo } from "react";
import { useParams } from "react-router";

import { PageNotFound } from "@/components/errors/page-not-found";
import { Spinner } from "@/components/ui/loading";
import {
  InsuredWithDuplicateFlagQuery,
  MarketingPlanState,
  OpportunitiesQuery,
  PoliciesQuery,
  PolicyState,
  QuotesQuery,
  QuoteState,
  ResumableGraphsQuery,
  useInsuredWithDuplicateFlagQuery,
  useOpportunitiesQuery,
  usePoliciesQuery,
  useQuotesQuery,
  useResumableGraphsQuery,
  useVerticalByCglQuery,
  VerticalByCglQuery,
} from "src/generated/graphql";
import { sentToAgent } from "src/utils/sent-to-agent";

type Opportunities = OpportunitiesQuery["opportunities"];
type Policies = PoliciesQuery["policies"];
type Quotes = QuotesQuery["quotes"];

interface InsuredContextValue {
  error?: ApolloError;
  insured: NonNullable<InsuredWithDuplicateFlagQuery["insured"]>;
  insuredId: string;
  loading: boolean;
  opportunities: Opportunities;
  activeOpportunities: Opportunities;
  opportunitiesLoading: boolean;
  policies: Policies;
  activePolicies: Policies;
  policiesLoading: boolean;
  activePoliciesLoading: boolean;
  quotes: Quotes;
  draftQuotes: Quotes;
  sentQuotes: Quotes;
  quotesLoading: boolean;
  refetch: () => void;
  verticalByCGL?: VerticalByCglQuery["verticalByCGL"];
  approvals: ResumableGraphsQuery["resumableGraphs"];
  approvalsLoading: boolean;
}

const InsuredContext = createContext({} as InsuredContextValue);

export const InsuredProvider = ({ children }: { children: React.ReactNode }) => {
  const { insuredId } = useParams<"insuredId">();

  const {
    data: { insured } = {},
    error,
    loading,
    refetch,
  } = useInsuredWithDuplicateFlagQuery({
    variables: {
      id: insuredId ?? "",
    },
    skip: !insuredId,
    fetchPolicy: "cache-and-network",
  });

  const { data: { verticalByCGL } = {} } = useVerticalByCglQuery({
    variables: { input: { isoCglCode: insured?.isoCglCodes[0] || "" } },
    skip: !insured?.isoCglCodes[0],
    fetchPolicy: "cache-and-network",
  });

  const { data: { resumableGraphs: approvals } = { resumableGraphs: [] }, loading: approvalsLoading } =
    useResumableGraphsQuery({
      variables: { input: { insuredId: insuredId ?? "" } },
      skip: !insuredId,
      pollInterval: 1000,
    });

  const { data: { opportunities } = { opportunities: [] }, loading: opportunitiesLoading } = useOpportunitiesQuery({
    variables: {
      input: {
        insuredId: insuredId ?? "",
      },
    },
    skip: !insuredId,
    fetchPolicy: "cache-and-network",
  });

  const { data: { quotes } = { quotes: [] }, loading: quotesLoading } = useQuotesQuery({
    variables: {
      input: {
        insuredId: insuredId,
      },
    },
    skip: !insuredId,
    fetchPolicy: "cache-and-network",
  });

  const { data: { policies: activePolicies } = { policies: [] }, loading: activePoliciesLoading } = usePoliciesQuery({
    variables: {
      input: {
        insuredId,
        active: true,
      },
    },
    skip: !insuredId,
    fetchPolicy: "cache-and-network",
  });

  const { data: { policies } = { policies: [] }, loading: policiesLoading } = usePoliciesQuery({
    variables: {
      input: {
        insuredId,
      },
    },
    skip: !insuredId,
    fetchPolicy: "cache-and-network",
  });

  const activeOpportunities = useMemo(
    () => opportunities.filter((opp) => opp.state !== MarketingPlanState.Complete),
    [opportunities]
  );

  const draftQuotes = useMemo(
    () =>
      quotes.filter(
        (quote) =>
          quote.state === QuoteState.Created ||
          quote.state === QuoteState.Unredacted ||
          quote.state === QuoteState.Redacted ||
          quote.state === QuoteState.Processed
      ),
    [quotes]
  );

  const sentQuotes = useMemo(() => quotes.filter((quote) => sentToAgent(quote)), [quotes]);

  const filteredActivePolicies = useMemo(
    () => activePolicies.filter((policy) => policy.state !== PolicyState.Canceled),
    [activePolicies]
  );

  if (loading) {
    return <Spinner className="mx-6 my-4" />;
  }

  if (!insuredId || !insured) {
    return <PageNotFound />;
  }

  const value = {
    error,
    insured,
    insuredId,
    loading,
    opportunities,
    opportunitiesLoading,
    activeOpportunities,
    policies,
    policiesLoading,
    activePolicies: filteredActivePolicies,
    activePoliciesLoading,
    quotes,
    draftQuotes,
    sentQuotes,
    quotesLoading,
    refetch,
    verticalByCGL,
    approvals,
    approvalsLoading,
  };

  return <InsuredContext.Provider value={value}>{children}</InsuredContext.Provider>;
};

export const useInsured = () => {
  const context = useContext(InsuredContext);

  if (!context) {
    throw new Error("useInsured must be used within an InsuredProvider");
  }

  return context;
};

import { UUID } from "@cp/toolkit";
import { useParams } from "react-router";

import { Activity } from "@/components/activity/activity";
import { HasInternalRole } from "@/components/has-role";
import { Notes } from "@/components/notes";
import { ScrollPaneColumns } from "@/components/scroll-pane";
import { SectionDescription, SectionHeader, SectionTitle } from "@/components/section";
import { Card } from "@/components/ui/card";
import { Separator } from "@/components/ui/separator";
import { useInsured } from "@/hooks/use-insured";
import { SetMetadata } from "@/hooks/use-metadata";
import { usePolicyQuery } from "src/generated/graphql";
import { Team } from "../marketing-plans/team";
import { PolicyActions } from "./actions/policy-actions";
import { PolicyFiles } from "./policy-files";
import { PolicyInfo } from "./policy-info";
import { PolicyMenu } from "./policy-menu";

export const Policy = () => {
  const { policyId } = useParams<"policyId">();
  const { insured } = useInsured();

  const { data: { policy } = {}, refetch } = usePolicyQuery({
    variables: {
      id: policyId ?? policyId!,
    },
    skip: !policyId,
    fetchPolicy: "cache-and-network",
  });

  if (!policy) {
    return null;
  }

  const { appetiteProduct, opportunityId } = policy;

  return (
    <>
      <SetMetadata
        title={`${appetiteProduct.carrierName}: ${appetiteProduct.carrierProductName}`}
        crumb
        menu={<PolicyMenu policy={policy} />}
      />
      <SectionHeader>
        <SectionTitle>
          <h1>{appetiteProduct.carrierName}</h1>
        </SectionTitle>
        <SectionDescription>{appetiteProduct.carrierProductName}</SectionDescription>
      </SectionHeader>
      <Separator />
      <div className="p-6 space-y-6">
        <PolicyInfo policy={policy} />
        <ScrollPaneColumns className="flex-col-reverse grid-cols-[1fr_24em]">
          <Activity opportunityId={opportunityId} />
          <div className="space-y-6">
            <HasInternalRole>
              <Notes opportunityId={opportunityId as UUID} />
            </HasInternalRole>
            <Card>
              <PolicyFiles policy={policy} />
              <HasInternalRole>
                <PolicyActions policy={policy} refetch={refetch} />
                <Team broker={undefined} agent={insured.agent} />
              </HasInternalRole>
            </Card>
          </div>
        </ScrollPaneColumns>
      </div>
    </>
  );
};

import { ComponentProps, FC, useRef } from "react";

import { Checkbox } from "@/components/ui/checkbox";
import { Label } from "@/components/ui/label";
import { cn } from "src/utils";

import { useField } from "../hooks/use-field";
import { FieldBase, FieldBaseProps } from "./field-base";
import { FieldCheckboxProps } from "./field-checkbox";

export interface FieldCheckboxGroupOption {
  label: string;
  value: string;
  itemProps?: ComponentProps<typeof Checkbox>;
}

export type FieldCheckboxGroupProps = FieldBaseProps & {
  options: FieldCheckboxGroupOption[];
  onChange?: (value: string[]) => void;
  onBlur?: ComponentProps<typeof Checkbox>["onBlur"];
  inputProps?: ComponentProps<typeof Checkbox>;
};

export const FieldCheckboxGroup: FC<FieldCheckboxGroupProps> = ({
  options,
  onChange,
  onBlur,
  inputProps,
  ...props
}) => {
  const fieldRef = useRef<HTMLDivElement>(null);

  const { field, hasError } = useField({ name: props.name, disabled: props.disabled, inputProps });

  const handleChange = (value: string) => {
    const values = field.value.includes(value)
      ? field.value.filter((v: string) => v !== value)
      : [...field.value, value];

    field.onChange(values);
    onChange?.(values);
  };

  const handleBlur: FieldCheckboxProps["onBlur"] = (event) => {
    setTimeout(() => {
      // If the focus is still within the field, don't trigger blur.
      if (fieldRef.current?.contains(document.activeElement)) {
        return;
      }

      field.onBlur();
      onBlur?.(event);
    }, 0);
  };

  // TODO: Add keyboard navigation to cycle focus through the checkboxes.

  return (
    <FieldBase ref={fieldRef} {...props}>
      <div className="grid gap-2 my-1">
        {options.map((option) => {
          const isDisabled = field.disabled || option.itemProps?.disabled;

          return (
            <Label
              key={option.value}
              className="flex items-center gap-2 text-sm font-normal group-data-[disabled]/form-field:cursor-not-allowed group-data-[disabled]/form-field:opacity-70"
            >
              <Checkbox
                key={option.value}
                {...field}
                {...inputProps}
                disabled={isDisabled}
                value={option.value}
                checked={field.value.includes(option.value)}
                onCheckedChange={() => handleChange(option.value)}
                onBlur={handleBlur}
                className={cn({ "border border-destructive": hasError && !isDisabled }, inputProps?.className)}
              />

              {option.label}
            </Label>
          );
        })}
      </div>
    </FieldBase>
  );
};

import { useMarketingPlan } from "@/hooks/use-marketing-plan";
import { MarketingPlanState } from "src/generated/graphql";
import { Complete } from "./complete";
import { InformationReview } from "./information-review";
import { Marketing } from "./marketing";

export const MarketingPlanActions = () => {
  const { opportunity } = useMarketingPlan();
  let content;

  switch (opportunity.state) {
    case MarketingPlanState.InformationReview:
      content = <InformationReview />;
      break;
    case MarketingPlanState.Marketing:
      content = <Marketing />;
      break;
    case MarketingPlanState.Complete:
      content = <Complete />;
      break;
    default:
      return null;
  }

  return content;
};

import { useMatches } from "react-router";
import { Fragment } from "react/jsx-runtime";

import { usePage } from "@/hooks/use-page";
import { Link } from "react-router-dom";
import { cn } from "src/utils";
import { Icon } from "./ui/icon";

export const Breadcrumbs = ({ className }: { className?: string }) => {
  const { crumbs } = usePage();
  const matches = useMatches();

  return (
    <div
      className={cn(
        "flex flex-auto gap-1.5 items-center @max-xl/scrollpane:hidden mr-auto text-muted-foreground text-2xs truncate",
        className
      )}
    >
      {crumbs.map((page, index) => {
        const { icon, title } = page;
        const path = matches[index + 2]?.pathname;
        const Comp = path ? Link : "span";
        return (
          <Fragment key={index}>
            <Icon icon="chevron_right" className="first:hidden flex-none mt-px opacity-50 text-sm" />
            <Comp to={path} className="inline-flex items-center truncate">
              {icon ? <Icon icon={icon} className="filled text-sm" /> : title}
            </Comp>
          </Fragment>
        );
      })}
    </div>
  );
};

import { Dates, US_STATES, UUID } from "@cp/toolkit";
import { KnownClientData } from "@qw/qw-common";
import { add } from "date-fns";
import { intersection, sortBy } from "lodash";
import { FC, useEffect, useMemo } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";

import { Grid, GridRow, GridRowHeader } from "@/components/grid";
import { Group } from "@/components/group";
import { SectionDescription, SectionHeader, SectionTitle } from "@/components/section";
import { Button } from "@/components/ui/button";
import { Card } from "@/components/ui/card";
import { Icon } from "@/components/ui/icon";
import { Label } from "@/components/ui/label";
import { Loading } from "@/components/ui/loading";
import { Separator } from "@/components/ui/separator";
import { FieldCombobox } from "@/forms/fields/field-combobox";
import { FieldDatePicker } from "@/forms/fields/field-date-picker";
import { FieldInput } from "@/forms/fields/field-input";
import { FieldCurrency } from "@/forms/fields/field-number";
import { FieldSelect } from "@/forms/fields/field-select";
import { mapEnumToFieldOptions } from "@/forms/fields/field.helpers";
import { Form } from "@/forms/form";
import { FormSubmit } from "@/forms/form-submit";
import { useInsured } from "@/hooks/use-insured";
import {
  BindHqApplicationType,
  BindHqInsuredType,
  BindHqLineOfBusiness,
  BindHqQuoteBillingType,
  BindHqQuoteBusinessType,
  BindHqQuoteLineItemType,
  BindHqQuoteTaxesCollectedBy,
  MarketTypes,
  QuoteFragment,
  useBindHqAgenciesQuery,
  useBindHqAgentsLazyQuery,
  useBindHqCarriersQuery,
  useBindHqInsuredsQuery,
  useBindHqMarketingCompaniesQuery,
  useClientDataQuery,
} from "src/generated/graphql";

import { useQuote } from "@/hooks/use-quote";
import { BindHQProducerAlert } from "../components/bindhq-producer-alert";
import { QuoteInfo } from "../quote-info";
import { BindHqLegalEntityMap, BindHqLineOfBusinessDisplayName, QwLobToBindHqLob } from "./bind-maps";
import { BindFormValues, BindHqPolicyRequirementsSchema } from "./bind-schema";
import { useBindQuote } from "./use-bind-quote";

export const Bind = () => {
  const { quoteId, quote, loading: quoteLoading } = useQuote();
  const { insuredId, loading: insuredLoading } = useInsured();
  const submitUpdates = useBindQuote(insuredId, quoteId);

  const handleSubmit = async (values: BindFormValues) => await submitUpdates(values);

  if (quoteLoading || insuredLoading) {
    return <Loading className="m-6" />;
  }

  if (!quote || !insuredId) {
    return <div className="p-6">Something&apos;s not right! Please notify EPD.</div>;
  }

  const appetiteProduct = quote.submission.appetiteProduct;

  return (
    <>
      <SectionHeader>
        <SectionTitle>
          <h1>{appetiteProduct.carrierName}</h1>
        </SectionTitle>
        <SectionDescription>{appetiteProduct.carrierProductName}</SectionDescription>
      </SectionHeader>
      <Separator />

      <Group className="p-6 gap-6">
        <QuoteInfo />

        <Form validationSchema={BindHqPolicyRequirementsSchema} onSubmit={handleSubmit}>
          <BindForm quote={quote} />
        </Form>
      </Group>
    </>
  );
};

interface BindFormProps {
  quote: QuoteFragment;
}

export const BindForm: FC<BindFormProps> = ({ quote }) => {
  const formMethods = useFormContext<BindFormValues>();
  const { insuredId, insured } = useInsured();

  // Store selected BindHQ agency ID in state so we can use it to fetch BindHQ agents for that agency only
  const preSelectedBindHqAgencyId = useMemo(() => insured.agency?.bindHqId, [insured.agency?.bindHqId]);

  const { data: { clientData } = {} } = useClientDataQuery({
    variables: { input: { insuredId: insuredId as UUID } },
    skip: !insuredId,
  });
  const { data: { bindHqAgencies = [] } = {}, loading: bindHqAgenciesLoading } = useBindHqAgenciesQuery();
  const [fetchBindHqAgents, { data: { bindHqAgents = [] } = {}, loading: bindHqAgentsLoading }] =
    useBindHqAgentsLazyQuery();
  const { data: { bindHqInsureds = [] } = {}, loading: bindHqInsuredsLoading } = useBindHqInsuredsQuery();
  const { data: { bindHqCarriers = [] } = {}, loading: bindHqCarriersLoading } = useBindHqCarriersQuery();
  const { data: { bindHqMarketingCompanies = [] } = {}, loading: bindHqMarketingCompaniesLoading } =
    useBindHqMarketingCompaniesQuery();

  let bhqAgency = bindHqAgencies.find((a) => a.id === preSelectedBindHqAgencyId);
  const submission = quote?.submission;
  const opportunity = submission?.opportunity;
  const appetiteProduct = submission?.appetiteProduct;
  const { agent, agency } = insured ?? {};

  // Find the lines of business that are both in the submission and the appetite product
  const bindHqLinesOnSubmission = intersection(
    submission?.appetiteProduct?.coreLines,
    opportunity?.selectedLinesOfBusiness
  )
    .map((lob) => QwLobToBindHqLob[lob]) // Convert the QW lines of business to BindHQ lines of business
    .filter((v): v is BindHqLineOfBusiness => v !== undefined); // Remove undefined values

  const bindHqInsuredOptions = [{ id: "", name: "Create new insured" }, ...bindHqInsureds];

  const createNewInsuredSelected = !formMethods.watch("insured.bindHqId");

  const requireAscendFields = useMemo(
    () =>
      preSelectedBindHqAgencyId &&
      (!bhqAgency?.phone || !bhqAgency?.email || !bhqAgency?.city || !bhqAgency?.state || !bhqAgency?.zip),
    [preSelectedBindHqAgencyId, bhqAgency]
  );

  // TODO: Refactor how we are setting defaultValues. Leaving this the way it is for now to avoid breaking changes.
  useEffect(() => {
    if (opportunity && insured && submission && quote && clientData && !formMethods.formState.isSubmitting) {
      formMethods.reset({
        agency: {
          agencyId: agency?.id,
          bindHqId: agency?.bindHqId ?? undefined,
        },
        insured: {
          id: insured.id,
          bindHqId: insured.bindHqId ?? "",
          name: insured.name,
          dba: insured.dba ?? undefined,
          legalEntityType:
            insured.legalEntityType && Object.keys(BindHqLegalEntityMap).includes(insured.legalEntityType)
              ? BindHqLegalEntityMap[insured.legalEntityType]
              : undefined,
        },
        clientData: {
          [KnownClientData.MailingAddress.AddressLine1]: clientData.find(
            (d) => d.key === KnownClientData.MailingAddress.AddressLine1
          )?.value,
          [KnownClientData.MailingAddress.AddressLine2]: clientData.find(
            (d) => d.key === KnownClientData.MailingAddress.AddressLine2
          )?.value,
          [KnownClientData.MailingAddress.City]: clientData.find((d) => d.key === KnownClientData.MailingAddress.City)
            ?.value,
          [KnownClientData.MailingAddress.State]: clientData.find((d) => d.key === KnownClientData.MailingAddress.State)
            ?.value,
          [KnownClientData.MailingAddress.Zip]: clientData.find((d) => d.key === KnownClientData.MailingAddress.Zip)
            ?.value,
        },
        updateBindHqAgency: {
          billingContactFirstName: undefined,
          billingContactLastName: undefined,
          phone: bhqAgency?.phone ?? undefined,
          email: bhqAgency?.email ?? undefined,
          line1: bhqAgency?.line1 ?? undefined,
          line2: bhqAgency?.line2 ?? undefined,
          city: bhqAgency?.city ?? undefined,
          state: bhqAgency?.state ?? undefined,
          zip: bhqAgency?.zip ?? undefined,
        },
        bindHqInput: {
          createApplication: {
            agentBindHqId: agent?.bindHqId ?? undefined,
            linesOfBusiness: bindHqLinesOnSubmission,
            type: opportunity.renewalOf ? BindHqApplicationType.Renewal : BindHqApplicationType.New,
          },
          createQuote: {
            marketingCompanyId: appetiteProduct?.bindHqMarketingCompanyId ?? undefined,
            carrierId: appetiteProduct?.bindHqCarrierIds.length === 1 ? appetiteProduct.bindHqCarrierIds[0] : undefined,
            businessType:
              submission.appetiteProduct.marketType === MarketTypes.BindingAuthority
                ? BindHqQuoteBusinessType.Binding
                : BindHqQuoteBusinessType.Brokerage,
            taxationHomeState: insured.primaryState ?? undefined,
            minimumEarnedPremiumPercentage: quote.mep?.toString(),
            agentCommissionPercentage: quote.agentCommission?.toString(),
            grossCommissionPercentage: quote.grossCommission?.toString(),
            lineItems: [
              {
                type: BindHqQuoteLineItemType.Premium,
                amount: quote?.premium?.toString(),
                lineOfBusiness: bindHqLinesOnSubmission.length === 1 ? bindHqLinesOnSubmission[0] : undefined,
              },
              {
                type: BindHqQuoteLineItemType.MgaFee,
                amount: quote.brokerFee?.toString(),
                lineOfBusiness: bindHqLinesOnSubmission.length === 1 ? bindHqLinesOnSubmission[0] : undefined,
                description:
                  appetiteProduct?.marketType === MarketTypes.BindingAuthority ? "Policy Fee" : "Wholesale Broker Fee",
              },
              ...(quote.carrierFee
                ? [
                    {
                      type: BindHqQuoteLineItemType.CarrierFee,
                      amount: quote.carrierFee?.toString(),
                      lineOfBusiness: bindHqLinesOnSubmission.length === 1 ? bindHqLinesOnSubmission[0] : undefined,
                    },
                  ]
                : []),
              ...(quote.inspectionFee
                ? [
                    {
                      type: BindHqQuoteLineItemType.InspectionFee,
                      amount: quote.inspectionFee?.toString(),
                      lineOfBusiness: bindHqLinesOnSubmission.length === 1 ? bindHqLinesOnSubmission[0] : undefined,
                    },
                  ]
                : []),
            ],
          },
          createPolicy: {
            effectiveDate: quote.effectiveDate ? new Date(quote.effectiveDate) : undefined,
            expirationDate:
              quote.expirationDate ?? quote.effectiveDate
                ? add(new Date(quote.effectiveDate), { years: 1 })
                : undefined,
            // try to use quote effective date, if not available, use opportunity desired effective date
            invoiceDueDate: quote.effectiveDate
              ? Dates.incrementDate({ date: quote.effectiveDate, addDays: 10 })
              : opportunity.desiredEffectiveDate
              ? Dates.incrementDate({ date: opportunity.desiredEffectiveDate, addDays: 10 })
              : undefined,
          },
        },
      });
    }
  }, [opportunity, insured, clientData]);

  // Note (David): I think Josh wrote this to update the bindhq_agency before we submit some billing stuff to Ascend.
  //               However, I don't like this stuff here. Let's consider removing it.
  //               If we need fresh data before submitting to Ascend, we should hit the bindHQ api on the backend before submitting to Ascend.
  useEffect(() => {
    bhqAgency = bindHqAgencies.find((a) => a.id === formMethods.getValues("agency.bindHqId"));
    fetchBindHqAgents({ variables: { input: { bindHqAgencyId: bhqAgency?.id } } });

    formMethods.setValue("updateBindHqAgency.phone", bhqAgency?.phone ?? "");
    formMethods.setValue("updateBindHqAgency.email", bhqAgency?.email ?? "");
    formMethods.setValue("updateBindHqAgency.line1", bhqAgency?.line1 ?? "");
    formMethods.setValue("updateBindHqAgency.line2", bhqAgency?.line2 ?? "");
    formMethods.setValue("updateBindHqAgency.city", bhqAgency?.city ?? "");
    formMethods.setValue("updateBindHqAgency.state", bhqAgency?.state ?? "");
    formMethods.setValue("updateBindHqAgency.zip", bhqAgency?.zip ?? "");
  }, [formMethods.getValues("agency.bindHqId"), bhqAgency]);

  return (
    <Card>
      <Group className="p-6">
        <BindHQProducerAlert
          bindHqProducer={opportunity.bindHqProducer}
          insuredId={opportunity.insuredId}
          opportunityId={opportunity.id}
        />
      </Group>

      <Group className="border-t p-6 grid-cols-3">
        <FieldCombobox
          name="agency.bindHqId"
          label="BindHQ Agency*"
          placeholder={bindHqAgenciesLoading ? "Loading..." : "Select an agency from BindHQ"}
          options={bindHqAgencies.map((option) => ({ label: option.name, value: option.id }))}
          className="col-span-full lg:col-span-1"
        />
        <FieldCombobox
          name="bindHqInput.createApplication.agentBindHqId"
          label="BindHQ Agent*"
          placeholder={bindHqAgentsLoading ? "Loading..." : "Select an agent from BindHQ"}
          options={bindHqAgents.map((option) => ({
            label: `${option.firstName} ${option.lastName}`,
            value: option.id,
          }))}
          className="col-span-full lg:col-span-1"
        />
        <FieldCombobox
          name="insured.bindHqId"
          label="BindHQ Insured"
          placeholder={bindHqInsuredsLoading ? "Loading..." : undefined}
          options={bindHqInsuredOptions.map((option) => ({ label: option.name, value: option.id || "" }))}
          className="col-span-full lg:col-span-1"
        />
      </Group>

      {requireAscendFields && (
        <Group className="border-t p-6 grid-cols-6">
          <FieldInput
            name="updateBindHqAgency.billingContactFirstName"
            label="Agency Billing Contact First Name"
            className="col-span-full lg:col-span-3"
          />
          <FieldInput
            name="updateBindHqAgency.billingContactFirstName"
            label="Agency Billing Contact Last Name"
            className="col-span-full lg:col-span-3"
          />

          <FieldInput
            name="updateBindHqAgency.phone"
            label="Agency Billing Phone*"
            className="col-span-full lg:col-span-3"
          />
          <FieldInput
            name="updateBindHqAgency.email"
            label="Agency Billing Email*"
            className="col-span-full lg:col-span-3"
          />

          <FieldInput
            name="updateBindHqAgency.line1"
            label="Agency Billing Address Line 1*"
            className="col-span-full lg:col-span-2"
          />
          <FieldInput
            name="updateBindHqAgency.line2"
            label="Agency Billing Address Line 2"
            className="col-span-full lg:col-span-2"
          />
          <FieldInput
            name="updateBindHqAgency.city"
            label="Agency Billing Address City*"
            className="col-span-full lg:col-span-2"
          />
          <FieldSelect
            name="updateBindHqAgency.state"
            label="Agency Billing Address State*"
            placeholder="Select a state"
            options={US_STATES.map((state) => ({ label: state, value: state }))}
            className="col-span-full lg:col-span-2"
          />
          <FieldInput
            name="updateBindHqAgency.zip"
            label="Agency Billing Address Zip*"
            className="col-span-full lg:col-span-2"
          />
        </Group>
      )}

      {createNewInsuredSelected && (
        <Group className="border-t p-6 grid-cols-12">
          <h4 className="col-span-full">Create New Insured</h4>

          <FieldInput
            name="insured.name"
            label="Business Name*"
            placeholder="Business Name"
            className="col-span-full lg:col-span-4"
          />
          <FieldInput name="insured.dba" label="DBA" placeholder="DBA" className="col-span-full lg:col-span-4" />
          <FieldSelect
            label="Legal Entity Type"
            name="insured.legalEntityType"
            options={mapEnumToFieldOptions(BindHqInsuredType)}
            className="col-span-full lg:col-span-4"
          />

          <Label className="col-span-full text-xs text-muted-foreground font-semibold -mb-2.5">
            Client Exposure Address
          </Label>
          <FieldInput
            placeholder="Street Line 1"
            name={`clientData.${KnownClientData.MailingAddress.AddressLine1}`}
            className="col-span-full lg:col-span-6"
          />
          <FieldInput
            placeholder="Street Line 2"
            name={`clientData.${KnownClientData.MailingAddress.AddressLine2}`}
            className="col-span-full lg:col-span-6"
          />
          <FieldInput
            placeholder="City"
            name={`clientData.${KnownClientData.MailingAddress.City}`}
            className="col-span-full lg:col-span-6"
          />
          <FieldSelect
            name={`clientData.${KnownClientData.MailingAddress.State}`}
            placeholder="State"
            options={US_STATES.map((state) => ({ label: state, value: state }))}
            className="col-span-full lg:col-span-3"
          />
          <FieldInput
            placeholder="Zip Code"
            name={`clientData.${KnownClientData.MailingAddress.Zip}`}
            className="col-span-full lg:col-span-3"
          />
        </Group>
      )}

      <Group className="border-t p-6 grid-cols-3">
        <FieldDatePicker
          label="Effective Date*"
          dateFormat="MMMM d, yyyy"
          name="bindHqInput.createPolicy.effectiveDate"
          onChange={(value) => {
            if (value) {
              // Set the expiration date to one year after the effective date
              const oneYearLater = Dates.incrementDate({ date: value, addYears: 1 });
              !formMethods.getValues("bindHqInput.createPolicy.expirationDate") &&
                formMethods.setValue("bindHqInput.createPolicy.expirationDate", oneYearLater, { shouldValidate: true });
              // Set policy invoice due date based on effective date
              const tenDaysLater = Dates.incrementDate({ date: value, addDays: 10 });
              const twentyDaysLater = Dates.incrementDate({ date: value, addDays: 20 });
              const invoiceDueDate =
                appetiteProduct?.marketType === MarketTypes.BindingAuthority ? twentyDaysLater : tenDaysLater;
              !formMethods.getValues("bindHqInput.createPolicy.invoiceDueDate") &&
                formMethods.setValue("bindHqInput.createPolicy.invoiceDueDate", invoiceDueDate, {
                  shouldValidate: true,
                });
            }
          }}
          className="col-span-full lg:col-span-1"
        />
        <FieldDatePicker
          label="Expiration Date*"
          dateFormat="MMMM d, yyyy"
          name="bindHqInput.createPolicy.expirationDate"
          className="col-span-full lg:col-span-1"
        />
        <FieldSelect
          label="Application Type*"
          name="bindHqInput.createApplication.type"
          options={mapEnumToFieldOptions(BindHqApplicationType)}
          className="col-span-full lg:col-span-1"
        />
      </Group>

      <Group className="border-t p-6 grid-cols-12">
        <h4 className="col-span-full">BindHQ</h4>

        <FieldCombobox
          label="BindHQ Lines of Business*"
          name="bindHqInput.createApplication.linesOfBusiness"
          placeholder="Select lines"
          options={sortBy(Object.values(BindHqLineOfBusiness), (l) =>
            bindHqLinesOnSubmission.includes(l) || l === BindHqLineOfBusiness.Pkg ? -1 : 0
          ).map((option) => ({ label: BindHqLineOfBusinessDisplayName[option], value: option }))}
          multiple
          className="col-span-full"
        />
        <FieldCombobox
          label="BindHQ Marketing Company*"
          placeholder={bindHqMarketingCompaniesLoading ? "Loading..." : "Search BindHQ marketing companies"}
          name="bindHqInput.createQuote.marketingCompanyId"
          options={bindHqMarketingCompanies.map((c) => ({ label: c.name, value: c.id }))}
          className="col-span-full lg:col-span-6"
        />
        <FieldCombobox
          label="BindHQ Carrier*"
          placeholder={bindHqCarriersLoading ? "Loading..." : "Search BindHQ carriers"}
          name="bindHqInput.createQuote.carrierId"
          options={sortBy(bindHqCarriers, (c) => (appetiteProduct?.bindHqCarrierIds.includes(c.id) ? -1 : 0)).map(
            (c) => ({ label: c.name, value: c.id })
          )}
          className="col-span-full lg:col-span-6"
        />

        <FieldSelect
          label="Business Type*"
          name="bindHqInput.createQuote.businessType"
          options={mapEnumToFieldOptions(BindHqQuoteBusinessType)}
          className="col-span-full lg:col-span-3"
        />
        <FieldSelect
          label="Billing Type*"
          name="bindHqInput.createQuote.billingType"
          options={mapEnumToFieldOptions(BindHqQuoteBillingType)}
          onChange={(value) => {
            if (value === BindHqQuoteBillingType.AgencyBill) {
              formMethods.setValue("bindHqInput.createQuote.taxesCollectedBy", BindHqQuoteTaxesCollectedBy.Mga, {
                shouldValidate: true,
              });
            }
            if (value === BindHqQuoteBillingType.DirectBill) {
              formMethods.setValue(
                "bindHqInput.createQuote.taxesCollectedBy",
                BindHqQuoteTaxesCollectedBy.MarketingCompany,
                { shouldValidate: true }
              );
            }
          }}
          className="col-span-full lg:col-span-3"
        />
        <FieldSelect
          label="Taxes Collected By*"
          name="bindHqInput.createQuote.taxesCollectedBy"
          options={mapEnumToFieldOptions(BindHqQuoteTaxesCollectedBy)}
          className="col-span-full lg:col-span-3"
        />
        <FieldSelect
          label="Taxation Home State*"
          name="bindHqInput.createQuote.taxationHomeState"
          placeholder="Select a state"
          options={US_STATES.map((state) => ({ label: state, value: state }))}
          className="col-span-full lg:col-span-3"
        />

        <FieldInput
          label="Minimum Earned Premium %*"
          placeholder="Enter a percentage"
          name="bindHqInput.createQuote.minimumEarnedPremiumPercentage"
          className="col-span-full lg:col-span-4"
        />
        <FieldInput
          label="Gross Commission %*"
          placeholder="Enter a percentage"
          name="bindHqInput.createQuote.grossCommissionPercentage"
          className="col-span-full lg:col-span-4"
        />
        <FieldInput
          label="Agent Commission %*"
          placeholder="Enter a percentage"
          name="bindHqInput.createQuote.agentCommissionPercentage"
          className="col-span-full lg:col-span-4"
        />
      </Group>

      <QuoteLineItems />

      <Group className="border-t p-6 grid-cols-3">
        <FieldInput
          label="Policy Number*"
          placeholder="Policy Number"
          name="bindHqInput.createPolicy.policyNumber"
          className="col-span-full lg:col-span-1"
        />
        <FieldDatePicker
          label="Invoice Due Date*"
          name="bindHqInput.createPolicy.invoiceDueDate"
          className="col-span-full lg:col-span-1"
        />
        <FormSubmit disabled={false} className="col-span-full lg:col-span-1 mt-5.5">
          Submit to BindHQ
        </FormSubmit>
      </Group>
    </Card>
  );
};

const QuoteLineItems: FC = () => {
  const { control, watch, setValue } = useFormContext<BindFormValues>();
  const { fields, append, remove } = useFieldArray({ name: "bindHqInput.createQuote.lineItems", control });

  const selectedBindHqLines = watch("bindHqInput.createApplication.linesOfBusiness") || [];

  const lobOptions = useMemo(
    () =>
      selectedBindHqLines.map((line) => ({
        label: BindHqLineOfBusinessDisplayName[line],
        value: line,
      })),
    [selectedBindHqLines]
  );

  return (
    <div className="border-t py-6">
      <h4 className="mx-6">Quote Line Items / Fees</h4>

      <Grid className="grid-cols-[1fr_1fr_1fr_1fr_1.5rem]">
        <GridRowHeader className="border-0! min-h-0! pb-1.5 items-end">
          <div>Type</div>
          <div>Amount ($)</div>
          <div>BindHQ Line of Business</div>
          <div>Description</div>
          <Button
            variant="ghost"
            size="sm"
            display="icon"
            type="button"
            onClick={() => {
              append({
                type: BindHqQuoteLineItemType.Premium,
                amount: "",
                lineOfBusiness:
                  selectedBindHqLines.length === 1 ? selectedBindHqLines[0] : ("" as unknown as BindHqLineOfBusiness), // If there are multiple lines, don't pre-select a line
              });
            }}
          >
            <Icon icon="add_2" />
          </Button>
        </GridRowHeader>

        {fields.map((_lineItem, index) => (
          <GridRow className="border-0! items-start py-2" key={index}>
            <FieldSelect
              name={`bindHqInput.createQuote.lineItems.${index}.type`}
              options={mapEnumToFieldOptions(BindHqQuoteLineItemType)}
              onChange={(value) => {
                if (value === BindHqQuoteLineItemType.MgaFee) {
                  setValue(`bindHqInput.createQuote.lineItems.${index}.description`, "Wholesale Broker Fee");
                }
              }}
            />
            <FieldCurrency placeholder="Amount" name={`bindHqInput.createQuote.lineItems.${index}.amount`} />
            <FieldSelect
              placeholder="Select a Line"
              name={`bindHqInput.createQuote.lineItems.${index}.lineOfBusiness`}
              options={lobOptions}
            />
            <FieldInput placeholder="Description" name={`bindHqInput.createQuote.lineItems.${index}.description`} />
            <Button
              type="button"
              variant="ghost"
              size="sm"
              display="icon"
              className="mt-1"
              onClick={() => remove(index)}
            >
              <Icon icon="delete" />
            </Button>
          </GridRow>
        ))}
      </Grid>
    </div>
  );
};

import { useParams } from "react-router";

import { EmptyState } from "@/components/empty-state";
import { Grid } from "@/components/grid";
import { Card, CardHeader, CardTitle } from "@/components/ui/card";
import { Spinner } from "@/components/ui/loading";
import { SetMetadata } from "@/hooks/use-metadata";
import { MarketingPlanState, useGroupedMarketingPlansQuery } from "src/generated/graphql";
import { InsuredsHeader, InsuredsRow } from "./insureds-row";

export const AgencyActive = () => {
  const { agencyId } = useParams();

  const {
    data: { groupedMarketingPlans: { groupedMarketingPlans } } = {
      groupedMarketingPlans: { groupedMarketingPlans: [] },
    },
    loading,
  } = useGroupedMarketingPlansQuery({
    variables: {
      input: {
        agencyId,
        states: [
          MarketingPlanState.WaitingForCoverages,
          MarketingPlanState.InformationGathering,
          MarketingPlanState.InformationReview,
          MarketingPlanState.Marketing,
        ],
      },
    },
    skip: !agencyId,
    fetchPolicy: "cache-first",
  });

  return (
    <>
      <SetMetadata title="Active Marketing Plans" crumb />
      <div className="p-6">
        {groupedMarketingPlans[0] ? (
          <Card>
            <CardHeader>
              <CardTitle>
                Active Marketing Plans
                {loading && <Spinner />}
              </CardTitle>
            </CardHeader>
            <Grid className="bg-transparent grid-cols-[1.5rem_1fr_1fr_1fr_1fr_1fr]">
              <InsuredsHeader />
              {groupedMarketingPlans.map((group) => (
                <InsuredsRow key={group.insuredId} group={group} />
              ))}
            </Grid>
          </Card>
        ) : (
          <EmptyState loading={loading} title={<h2>No active marketing plans found.</h2>} className="m-0" />
        )}
      </div>
    </>
  );
};

import { FilesFolders } from "@/files/files-folders";
import { SetMetadata } from "@/hooks/use-metadata";
import { ExternalDropzone } from "./external-dropzone";
import { FilesList } from "./files-list";

export const Files = () => (
  <>
    <SetMetadata title="Files" crumb />
    <ExternalDropzone className="border-t flex-auto @3xl:grid grid-cols-4">
      <FilesFolders />
      <FilesList />
    </ExternalDropzone>
  </>
);

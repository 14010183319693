import { useNavigate } from "react-router";
import { z } from "zod";

import { useToast } from "@/hooks/use-toast";
import { Roles, useCreateInsuredAndAgentMutation, useCreateInsuredMutation } from "src/generated/graphql";

const InsuredFormSchema = z.object({
  clientName: z.string({ message: "" }).min(1, { message: "" }),
  state: z.string({ message: "" }).length(2, { message: "" }),
  revenue: z.number({ message: "" }).optional(),
  description: z.string({ message: "" }).min(1, { message: "" }),
});

export const AgentFormSchema = InsuredFormSchema.extend({
  firstName: z.string({ message: "" }),
  lastName: z.string({ message: "" }),
  agentEmail: z.string({ message: "" }).email({ message: "" }),
  agencyId: z.string({ message: "" }),
});

const insuredFormDefaultValues = {
  clientName: "",
  state: "",
  revenue: undefined,
  description: "",
};

const agentFormDefaultValues = {
  ...insuredFormDefaultValues,
  firstName: "",
  lastName: "",
  agentEmail: "",
  agencyId: "",
};

const useCreateInsured = () => {
  const [mutation] = useCreateInsuredMutation();
  const { toast } = useToast();
  const navigate = useNavigate();

  return async (data: z.infer<typeof InsuredFormSchema>) => {
    return await mutation({
      variables: {
        input: {
          name: data.clientName,
          primaryState: data.state,
          revenue: data.revenue,
          description: data.description,
        },
      },
      onCompleted: (data) => {
        navigate(`/insured/${data.createInsured.id}`);
      },
      onError: (error) => {
        if (error?.message) {
          const errorDetails = JSON.parse(error.message);
          if (errorDetails.id) {
            navigate(`/insured/${errorDetails.id}`);
            toast({ title: "Error", description: errorDetails.message, variant: "default" });
          }
        } else {
          toast({ title: "Error", description: "An error occurred.", variant: "destructive" });
        }
      },
    });
  };
};

const useCreateInsuredAndAgent = () => {
  const [mutation] = useCreateInsuredAndAgentMutation();
  const { toast } = useToast();
  const navigate = useNavigate();

  return async (data: z.infer<typeof AgentFormSchema>) => {
    return await mutation({
      variables: {
        input: {
          name: data.clientName,
          primaryState: data.state,
          revenue: data.revenue,
          description: data.description,
          agentInput: {
            firstName: data.firstName,
            lastName: data.lastName,
            email: data.agentEmail,
            agencyId: data.agencyId,
          },
        },
      },
      onCompleted: (data) => {
        navigate(`/insured/${data.createInsuredAndAgent.id}`);
      },
      onError: (error) => {
        if (error?.message) {
          const errorDetails = JSON.parse(error.message);
          if (errorDetails.id) {
            navigate(`/insured/${errorDetails.id}`);
            toast({ title: "Error", description: errorDetails.message, variant: "default" });
          }
        } else {
          toast({ title: "Error", description: "An error occurred.", variant: "destructive" });
        }
      },
    });
  };
};

export const useCreateInsured2 = (user: { roles: Roles[]; internal: boolean } | undefined) => {
  return {
    defaultValues: user?.internal ? agentFormDefaultValues : insuredFormDefaultValues,
    validationSchema: user?.internal ? AgentFormSchema : InsuredFormSchema,
    createInsured: user?.internal ? useCreateInsuredAndAgent() : useCreateInsured(),
  };
};

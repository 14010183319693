import { useNavigate } from "react-router";
import { z } from "zod";

import { toast } from "@/hooks/use-toast";
import {
  useBindQuoteMutation,
  useCreateClientDataMutation,
  useUpdateAgencyMutation,
  useUpdateBindHqAgencyMutation,
  useUpdateInsuredMutation,
} from "src/generated/graphql";
import { parseError } from "src/utils";

import { BindHqPolicyRequirementsSchema } from "./bind-schema";

export const useBindQuote = (insuredId: string | undefined, quoteId: string | undefined, onCompleted?: () => void) => {
  const navigate = useNavigate();

  const [updateAgency] = useUpdateAgencyMutation({
    onError: (error) => {
      let description = parseError(error);
      if (error.message.includes("Unique constraint failed on the fields: (`bind_hq_id`)")) {
        console.log(JSON.stringify(error));
        description =
          "Another agency is already connected to the selected BindHQ agency. If the selected BindHQ agency is correct, please contact EPD.";
      }
      toast({ title: "Error updating Agency", description, variant: "destructive" });
    },
  });

  const [updateBindHqAgency] = useUpdateBindHqAgencyMutation({
    onError: (error) =>
      toast({ title: "Error updating BindHQ Agency", description: parseError(error), variant: "destructive" }),
  });

  const [updateInsured] = useUpdateInsuredMutation({
    onError: (error) =>
      toast({ title: "Error updating Insured", description: parseError(error), variant: "destructive" }),
  });

  const [createClientData] = useCreateClientDataMutation({
    onError: (error) =>
      toast({ title: "Error updating ClientData", description: parseError(error), variant: "destructive" }),
  });

  const [bindQuote] = useBindQuoteMutation({
    refetchQueries: ["Policies"],
    onError: (error) =>
      toast({ title: "Error creating Policy in BindHQ", description: parseError(error), variant: "destructive" }),
    onCompleted: ({ bindQuote: quote }) => {
      const { policy } = quote;
      onCompleted?.();
      navigate(`/insured/${insuredId}/policies/${policy?.id}`);
    },
  });

  return async (data: z.infer<typeof BindHqPolicyRequirementsSchema>) => {
    // QUESTION: can we use Promise.all for these requests?
    const agencyResponse = await updateAgency({
      variables: {
        input: data.agency,
      },
    });

    const bindHqAgencyResponse = await updateBindHqAgency({
      variables: {
        input: {
          id: data.agency.agencyId,
          bindHqId: data.agency.bindHqId,
          billingContactFirstName: data.updateBindHqAgency.billingContactFirstName,
          billingContactLastName: data.updateBindHqAgency.billingContactLastName,
          phone: data.updateBindHqAgency.phone,
          email: data.updateBindHqAgency.email,
          line1: data.updateBindHqAgency.line1,
          line2: data.updateBindHqAgency.line2,
          city: data.updateBindHqAgency.city,
          state: data.updateBindHqAgency.state,
          zip: data.updateBindHqAgency.zip,
        },
      },
    });

    const insuredResponse = await updateInsured({
      variables: {
        input: data.insured,
      },
    });

    let clientDataResponse = undefined;
    if (!data.insured.bindHqId && data.clientData) {
      clientDataResponse = await createClientData({
        variables: {
          input: {
            insuredId: data.insured.id,
            source: "BIND_HQ_POLICY_SUBMISSION", // ClientDataSources.BindHqPolicySubmission enum value
            clientDataInputs: Object.entries(data.clientData)
              .filter(([, value]) => Boolean(value))
              .map(([key, value]) => ({
                key: key,
                value: value!,
                index: null,
              })),
          },
        },
      });
    }

    if (agencyResponse.errors || bindHqAgencyResponse.errors || insuredResponse.errors || clientDataResponse?.errors) {
      return;
    }

    await bindQuote({
      variables: {
        input: {
          quoteId,
          ...data.bindHqInput,
        },
      },
    });
  };
};

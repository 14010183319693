import { intersection } from "lodash";
import { PropsWithChildren } from "react";

import { useAccount } from "@/hooks/use-account";
import { Roles, UserAccountFragment } from "src/generated/graphql";

interface Props {
  roles: Roles[];
  internal?: boolean;
  elseShow?: React.ReactNode;
}

export const HasRole: React.FC<PropsWithChildren<Props>> = ({ roles, children, elseShow = null }) => {
  const { user } = useAccount();

  // does the user have one of the specified roles?
  const validateRoles = intersection(user.roles, roles).length > 0;

  return validateRoles ? <>{children}</> : <>{elseShow}</>;
};

export const HasInternalRole: React.FC<PropsWithChildren<Pick<Props, "elseShow">>> = ({ children, elseShow }) => {
  const { user } = useAccount();
  return user.internal ? <>{children}</> : <>{elseShow}</>;
};

export const HasExternalRole: React.FC<PropsWithChildren<Pick<Props, "elseShow">>> = ({ children, elseShow }) => {
  const { user } = useAccount();
  return user.internal ? <>{elseShow}</> : <>{children}</>;
};

export const hasRole = (user: { roles: Roles[]; internal: boolean } | undefined, hasRoles: Roles[]) => {
  return user && intersection(user.roles, hasRoles).length > 0;
};

export const hasInternalRoleOrInternalAdmin = (user: Pick<UserAccountFragment, "roles" | "internal" | "email">) => {
  return user.internal || (user.roles.includes(Roles.Agent) && user.email.endsWith("@quotewell.com"));
};

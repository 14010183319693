import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { useDocumentTitle } from "usehooks-ts";
import { PageMetadata, useMetadata } from "./use-metadata";

export const SIDEBAR_WIDTH = "16rem";

export type Side = "right" | "left" | null;

interface PageState {
  crumbs: PageMetadata[];
  menus: PageMetadata[];
  sidebarOpen: boolean;
  sidebarSide: Side;
  sidebarWidth: string;
  widePage: boolean;
  setSidebarOpen: (open: boolean) => void;
  setSidebarSide: (sidebarSide: Side) => void;
  setSidebarWidth: (sidebarWidth: string) => void;
  setWidePage: (wide: boolean) => void;
}

const defaultPageState: PageState = {
  crumbs: [],
  menus: [],
  sidebarOpen: true,
  sidebarSide: null,
  sidebarWidth: SIDEBAR_WIDTH,
  widePage: true,
  setSidebarOpen: () => {},
  setSidebarSide: () => {},
  setSidebarWidth: () => {},
  setWidePage: () => {},
};

const PageContext = createContext<PageState>(defaultPageState);

export function PageProvider({ children }: { children: React.ReactNode }) {
  const [pageState, setPageState] = useState<PageState>(defaultPageState);
  const { metadata } = useMetadata();

  useEffect(() => {
    const mediaQuery = window.matchMedia("(min-width: 768px)");

    const handleOrientationChange = (e: any) => {
      setSidebarOpen(e.matches);
      setWidePage(e.matches);
    };

    mediaQuery.addEventListener("change", handleOrientationChange);

    handleOrientationChange(mediaQuery);

    return () => mediaQuery.removeEventListener("change", handleOrientationChange);
  }, []);

  useDocumentTitle(metadata.map((page) => page.title).join(" : "));

  const crumbs = useMemo(() => metadata.filter((page) => page.crumb), [metadata]);
  const menus = useMemo(() => metadata.filter((page) => page.menu), [metadata]);

  const { sidebarOpen, sidebarSide, sidebarWidth, widePage } = pageState;
  const setSidebarOpen = (sidebarOpen: boolean) => setPageState((pageState) => ({ ...pageState, sidebarOpen }));
  const setSidebarSide = (sidebarSide: Side) => setPageState((pageState) => ({ ...pageState, sidebarSide }));
  const setSidebarWidth = (sidebarWidth: string) => setPageState((pageState) => ({ ...pageState, sidebarWidth }));
  const setWidePage = (widePage: boolean) => setPageState((pageState) => ({ ...pageState, widePage }));

  const value = {
    crumbs,
    menus,
    sidebarOpen,
    sidebarSide,
    sidebarWidth,
    widePage,
    setSidebarOpen,
    setSidebarSide,
    setSidebarWidth,
    setWidePage,
  };

  return <PageContext.Provider value={value}>{children}</PageContext.Provider>;
}

export const usePage = () => {
  const context = useContext(PageContext);

  if (context === undefined) {
    throw new Error("usePage must be used within a PageProvider");
  }

  return context;
};

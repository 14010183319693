import { SendEmailFormValues, useSendEmailForm } from "@/components/send-email-form";
import { Button } from "@/components/ui/button";
import { Spinner } from "@/components/ui/loading";
import { useToast } from "@/hooks/use-toast";
import {
  PolicyAction,
  PolicyFragment,
  useIssuePolicyMutation,
  useTransitionPolicyMutation,
} from "src/generated/graphql";
import { parseError } from "src/utils";

export const ReceivedFromCarrier = ({ policy }: { policy: PolicyFragment }) => {
  const { toast } = useToast();
  const openSendEmailForm = useSendEmailForm();

  const [sendPolicy, { loading }] = useIssuePolicyMutation();
  const [transition, { loading: transitionLoading }] = useTransitionPolicyMutation();

  const handleSendEmailClick = async () =>
    await openSendEmailForm({
      title: "Issue Policy to Agent",
      onSubmit: handleSendEmail,
      defaultAttachments: policy.policyFile ? [{ name: policy.policyFile.filename }] : [],
    });

  const handleSendEmail = async (values: SendEmailFormValues) => {
    const formattedValues = {
      emailComment: values.emailComment,
      fileIds: values.files?.map((file) => file.id),
    };

    await sendPolicy({
      variables: { input: { policyId: policy.id, ...formattedValues } },
      onCompleted: () => toast({ title: "Email sent" }),
      onError: (error) => toast({ title: "Error sending email", description: parseError(error) }),
    });
  };

  const handleTransition = async (action: PolicyAction) =>
    await transition({ variables: { input: { id: policy.id, expectedState: policy.state, action } } });

  return (
    <>
      <Button variant="outline" size="sm" display="flex" onClick={handleSendEmailClick}>
        Issue Policy to Agent {loading && <Spinner />}
      </Button>
      <Button onClick={() => handleTransition(PolicyAction.Issue)} variant="outline" size="sm" display="flex">
        Mark as issued outside of app {transitionLoading && <Spinner />}
      </Button>
    </>
  );
};

import { Clippy } from "@/components/clippy";
import { StateCard } from "@/components/state";
import { Separator } from "@/components/ui/separator";
import { Toggle } from "@/components/ui/toggle";
import { AGENT_MESSAGE_METADATA } from "@/metadata/states";
import { PolicyFragment, useUpdatePolicyMutation } from "src/generated/graphql";
import { formatDateString, formatTimezoneDateString } from "src/utils/date";

export const PolicyInfo = ({ policy }: { policy: PolicyFragment }) => {
  const {
    id: policyId,
    policyNumber,
    awaitingSubjectivities,
    endorsementsRequested,
    state,
    stateTransitions,
    createdAt,
    effectiveDate,
    expirationDate,
  } = policy;

  const [mutation, { loading }] = useUpdatePolicyMutation();

  const handleAwaitingSubjectivitiesChange = async (checked: boolean) => {
    await mutation({
      variables: {
        input: {
          policyId,
          awaitingSubjectivities: checked,
        },
      },
    });
  };

  const handleEndorsementsRequestedChange = async (checked: boolean) => {
    await mutation({
      variables: {
        input: {
          policyId,
          endorsementsRequested: checked,
        },
      },
    });
  };

  const stateProps = AGENT_MESSAGE_METADATA[state];
  const lastTransition = stateTransitions.length > 0 ? stateTransitions[stateTransitions.length - 1] : { createdAt };

  return (
    <StateCard {...stateProps}>
      <Separator />
      <div className="gap-6 grid grid-cols-3 p-6">
        <dl>
          <dt>Policy Number</dt>
          <Clippy content={policyNumber} label={`#${policyNumber}`} />
        </dl>
        <dl>
          <dt>Awaiting Subjectivities</dt>
          <Toggle size="sm" onPressedChange={handleAwaitingSubjectivitiesChange} disabled={loading}>
            {awaitingSubjectivities ? "Yes" : "No"}
          </Toggle>
        </dl>
        <dl>
          <dt>Endorsements Requested</dt>
          <Toggle size="sm" onPressedChange={handleEndorsementsRequestedChange} disabled={loading}>
            {endorsementsRequested ? "Yes" : "No"}
          </Toggle>
        </dl>
        <dl>
          <dt>Effective Date</dt>
          {/* Remember: BindHQ dates do not include timestamps */}
          <dd>{formatDateString(effectiveDate, "MMMM d, yyyy")}</dd>
        </dl>
        <dl>
          <dt>Expiration Date</dt>
          {/* Remember: BindHQ dates do not include timestamps */}
          <dd>{formatDateString(expirationDate, "MMMM d, yyyy")}</dd>
        </dl>
        <dl>
          <dt>Last Updated</dt>
          <dd>{formatTimezoneDateString(lastTransition.createdAt, "MMMM d, yyyy")}</dd>
        </dl>
      </div>
    </StateCard>
  );
};

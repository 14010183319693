import { HasInternalRole } from "@/components/has-role";
import { InsuredPin } from "@/components/insured-pin";
import { Sidebar, SidebarContent, SidebarHeader, SidebarLinkClassName, SidebarNavLink } from "@/components/sidebar";
import { Badge } from "@/components/ui/badge";
import { Icon } from "@/components/ui/icon";
import { useInsured } from "@/hooks/use-insured";
import { cn } from "src/utils";
import { CreateMarketingPlan } from "./create-marketing-plan";
import { DuplicateInsured } from "./duplicate-insured";
import { InsuredAgency } from "./insured-agency";
import { InsuredMarketingPlans } from "./insured-opportunities";
import { InsuredPolicies } from "./insured-policies";
import { InsuredQuotes } from "./insured-quotes";

export const InsuredSidebar = () => {
  const { insured, approvals } = useInsured();
  return (
    <Sidebar>
      <SidebarHeader>
        <SidebarNavLink to=".">
          <span className="truncate">{insured.name}</span>
        </SidebarNavLink>
        <InsuredPin id={insured.id} className="-mr-1.5" />
      </SidebarHeader>
      <SidebarContent>
        <div className="p-6 space-y-3">
          <HasInternalRole>
            <DuplicateInsured />
            {approvals[0] && (
              <SidebarNavLink to="alby" className="text-primary -mr-0.5">
                Alby Approvals
                <Badge variant="secondary" size="3xs" className="bg-primary/7.5 text-primary text-2xs">
                  {approvals.length}
                </Badge>
              </SidebarNavLink>
            )}
          </HasInternalRole>
          <SidebarNavLink to="." end>
            Insured Details
            <Icon icon="business_center" />
          </SidebarNavLink>
          <SidebarNavLink to="plans">
            Marketing Plans
            <Icon icon="campaign" />
          </SidebarNavLink>
          <SidebarNavLink to="quotes">
            Quotes
            <Icon icon="request_quote" />
          </SidebarNavLink>
          <SidebarNavLink to="policies">
            Policies
            <Icon icon="shield" />
          </SidebarNavLink>
          <SidebarNavLink to="files">
            Files
            <Icon icon="folder_open" />
          </SidebarNavLink>
          <CreateMarketingPlan>
            <div className={cn(SidebarLinkClassName, "cursor-pointer font-semibold text-primary w-full")}>
              Create Marketing Plan
              <Icon icon="add_circle" className="filled" />
            </div>
          </CreateMarketingPlan>
        </div>
        <InsuredMarketingPlans />
        <InsuredQuotes />
        <InsuredPolicies />
      </SidebarContent>
      <InsuredAgency />
    </Sidebar>
  );
};

import { Slot } from "@radix-ui/react-slot";
import { cva, type VariantProps } from "class-variance-authority";
import * as React from "react";

import { cn } from "src/utils";

const buttonVariants = cva(
  [
    "flex-none font-medium inline-flex items-center justify-center leading-none! rounded-md -tracking-[0.01em] transition-colors whitespace-nowrap cursor-pointer",
    "[&>.icon]:-mx-[0.2em] [&>.icon]:text-[1.25em] hover:filled",
    "ring-offset-background focus-visible:outline-hidden focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50",
  ],
  {
    variants: {
      variant: {
        default: "",
        secondary: "",
        outline: "border bg-background",
        ghost: "",
        link: "h-auto! p-0! decoration-1 cursor-pointer hover:decoration-2 underline underline-offset-4 hover:underline-offset-[6px] transition-all",
        unstyled: "rounded-none",
      },
      theme: {
        default: "",
        primary: "",
        destructive: "",
      },
      size: {
        default: "gap-3 h-10 px-4 text-sm",
        lg: "gap-4 h-12 px-5 text-xl",
        sm: "gap-2 h-8 px-3 text-xs",
        xs: "gap-2 h-6 rounded-sm px-2 text-2xs",
        "2xs": "gap-1.5 h-5 rounded-sm px-1.5 text-3xs",
      },
      display: {
        default: "",
        flex: "flex flex-auto w-full",
        icon: "[&>.icon]:text-[1em] p-0",
      },
    },
    compoundVariants: [
      {
        variant: "default",
        theme: "default",
        className: "bg-foreground text-background hover:bg-foreground/95",
      },
      {
        variant: "default",
        theme: "primary",
        className: "bg-primary text-primary-foreground hover:bg-primary/95",
      },
      {
        variant: "default",
        theme: "destructive",
        className: "bg-destructive text-destructive-foreground hover:bg-destructive/95",
      },
      {
        variant: "secondary",
        theme: "default",
        className: "bg-foreground/7.5 text-foreground hover:bg-foreground/10",
      },
      {
        variant: "secondary",
        theme: "primary",
        className: "bg-primary/7.5 text-primary hover:bg-primary/10",
      },
      {
        variant: "secondary",
        theme: "destructive",
        className: "bg-destructive/7.5 text-destructive hover:bg-destructive/10",
      },
      {
        variant: "outline",
        theme: "default",
        className: "border-input text-foreground hover:border-foreground/50",
      },
      {
        variant: "outline",
        theme: "primary",
        className: "border-primary/25 text-primary hover:border-primary/50",
      },
      {
        variant: "outline",
        theme: "destructive",
        className: "border-destructive/25 text-destructive hover:border-destructive/50",
      },
      {
        variant: "ghost",
        theme: "default",
        className: "bg-foreground/0 text-foreground/75 hover:bg-foreground/5 hover:text-foreground",
      },
      {
        variant: "ghost",
        theme: "primary",
        className: "bg-primary/0 text-primary/75 hover:bg-primary/5 hover:text-primary",
      },
      {
        variant: "ghost",
        theme: "destructive",
        className: "bg-destructive/0 text-destructive/75 hover:bg-destructive/5 hover:text-destructive",
      },
      {
        variant: "link",
        theme: "default",
        className: "text-foreground",
      },
      {
        variant: "link",
        theme: "primary",
        className: "text-primary",
      },
      {
        variant: "link",
        theme: "destructive",
        className: "text-destructive",
      },
      {
        variant: "unstyled",
        theme: "default",
        className: "bg-inherit text-inherit",
      },
      {
        variant: "unstyled",
        theme: "primary",
        className: "bg-inherit text-primary",
      },
      {
        variant: "unstyled",
        theme: "destructive",
        className: "bg-inherit text-destructive",
      },
      {
        display: "icon",
        size: "lg",
        className: "w-12 text-3xl",
      },
      {
        display: "icon",
        size: "default",
        className: "w-10 text-xl",
      },
      {
        display: "icon",
        size: "sm",
        className: "w-8 text-lg",
      },
      {
        display: "icon",
        size: "xs",
        className: "w-6 text-base",
      },
      {
        display: "icon",
        size: "2xs",
        className: "w-5 text-sm",
      },
    ],
    defaultVariants: {
      variant: "default",
      theme: "default",
      display: "default",
      size: "default",
    },
  }
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, theme, size, display, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : "button";
    return <Comp className={cn(buttonVariants({ variant, theme, size, display, className }))} ref={ref} {...props} />;
  }
);
Button.displayName = "Button";

export { Button, buttonVariants };

import { FC } from "react";
import { z } from "zod";

import { CglCodeSelector } from "@/components/cgl-code-selector";
import { Group } from "@/components/group";
import { LinesOfBusinessSelector } from "@/components/lines-of-business-selector";
import { useOpenForm } from "@/components/modal-provider";
import { Button, ButtonProps } from "@/components/ui/button";
import { Separator } from "@/components/ui/separator";
import { FieldInput } from "@/forms/fields/field-input";
import { FieldTextarea } from "@/forms/fields/field-textarea";
import { useCreateSegmentMutation } from "src/generated/graphql";

const validationSchema = z.object({
  name: z.string().min(1, "Please enter a name"),
  description: z.string().optional(),
  segmentCriteria: z.object({
    linesOfBusiness: z.array(z.string()).min(1, { message: "Please select at least one line of business" }),
    isoCglCodes: z.array(z.string()).optional(),
  }),
});

const defaultValues = {
  name: "",
  description: "",
  segmentCriteria: {
    linesOfBusiness: [],
    isoCglCodes: [],
  },
};

export interface CreateSegmentButtonProps extends ButtonProps {}

export const CreateSegmentButton: FC<CreateSegmentButtonProps> = ({ children, ...props }) => {
  const { openForm, closeForm } = useOpenForm();
  const [createSegment] = useCreateSegmentMutation({ refetchQueries: ["Segments", "PaginatedSegments"] });

  const handleSubmit = async (input: z.infer<typeof validationSchema>) =>
    await createSegment({ variables: { input }, onCompleted: closeForm });

  const handleClick = async () =>
    await openForm(<CreateSegmentFormFields />, {
      title: "Create segment",
      autoClose: false,
      defaultValues,
      validationSchema,
      onSubmit: handleSubmit,
    });

  return (
    <Button type="button" {...props} onClick={handleClick}>
      {children || "Create segment"}
    </Button>
  );
};

export const CreateSegmentFormFields: FC = () => (
  <Group>
    <FieldInput name="name" label="Name" />
    <FieldTextarea name="description" label="Description" rows={3} optional />

    <Separator />

    <h5>Criteria</h5>
    <LinesOfBusinessSelector label="Lines of business" name="segmentCriteria.linesOfBusiness" multiple />
    <CglCodeSelector name="segmentCriteria.isoCglCodes" optional multiple />
  </Group>
);

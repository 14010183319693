import { HasInternalRole } from "@/components/has-role";
import { StateCard } from "@/components/state";
import { CardFooter } from "@/components/ui/card";
import { Icon } from "@/components/ui/icon";
import { Loading, Spinner } from "@/components/ui/loading";
import { InternalDropzone } from "@/files/internal-dropzone";
import { useMarketingPlanRequirements } from "@/hooks/use-marketing-plan-requirements";
import { MARKETING_PLAN_STATE_METADATA } from "@/metadata/marketing-plans";
import { File_Audience, MarketingPlanState } from "src/generated/graphql";
import { cn } from "src/utils";
import { RequirementsActions } from "./actions/requirements-actions";
import { AddRequirements } from "./add-requirements";
import { RequirementsList } from "./requirements-list";

export const Requirements = () => {
  const { hasRequirements, loading, opportunity, refetch } = useMarketingPlanRequirements();
  return (
    <StateCard
      actions={
        <>
          {opportunity.filesProcessing && <Loading label="Processing upload(s)..." className="text-muted-foreground" />}
          {loading ? (
            <Spinner />
          ) : (
            <HasInternalRole>
              <div className="flex gap-2 items-center">
                <AddRequirements opportunityId={opportunity.id} />
                <RequirementsActions />
              </div>
            </HasInternalRole>
          )}
        </>
      }
      cardClassName={cn("divide-y", hasRequirements ? "outline-current text-destructive" : "outline-transparent")}
      description={
        hasRequirements && MARKETING_PLAN_STATE_METADATA[MarketingPlanState.InformationGathering].description
      }
      icon={hasRequirements ? "warning" : "check_circle"}
      iconClassName={cn("filled", !hasRequirements && "text-success")}
      title="Required Information"
    >
      <RequirementsList />
      <InternalDropzone audience={File_Audience.External} onDrop={() => refetch()}>
        <CardFooter className="border-t p-2">
          <div className="bg-accent border border-dashed cursor-pointer flex flex-auto gap-4 items-center p-4 rounded-md text-muted-foreground">
            <Icon icon="cloud_upload" className="flex-none text-lg w-6" />
            <div className="text-sm">Click here, or drag and drop files to start an upload.</div>
          </div>
        </CardFooter>
      </InternalDropzone>
    </StateCard>
  );
};

import { useState } from "react";

import { Button } from "@/components/ui/button";
import { Card, CardContent } from "@/components/ui/card";
import { Icon } from "@/components/ui/icon";
import { Tooltip, TooltipContent, TooltipTrigger } from "@/components/ui/tooltip";
import { useMarketingPlan } from "@/hooks/use-marketing-plan";
import { useToast } from "@/hooks/use-toast";
import {
  CarrierContactFragment,
  CarrierContactType,
  useRemoveCarrierContactFromAppetiteProductMutation,
} from "src/generated/graphql";

import { CarrierContactForm } from "./carrier-contact-form";

interface Props {
  contact: CarrierContactFragment;
  type: CarrierContactType;
  appetiteProductId: string;
}

export const CarrierContactCard: React.FC<Props> = ({ contact, type, appetiteProductId }) => {
  const { toast } = useToast();
  const { refetchQueries } = useMarketingPlan();
  const [removeCarrierContact] = useRemoveCarrierContactFromAppetiteProductMutation();

  const [editMode, setEditMode] = useState(false);

  const { id, firstName, lastName, email, phone, title } = contact;

  const toggleEdit = () => {
    setEditMode(!editMode);
  };

  const handleDelete = () =>
    removeCarrierContact({
      variables: { input: { id, appetiteProductId, type } },
      onCompleted: () => toast({ title: "Contact removed" }),
      refetchQueries,
    });

  return (
    <Card>
      <CardContent className="p-6">
        {editMode ? (
          <CarrierContactForm
            carrierContact={contact}
            type={type}
            appetiteProductId={appetiteProductId}
            onCompleted={() => setEditMode(false)}
          />
        ) : (
          <div className="flex items-start justify-between">
            <dl>
              <dt>Name</dt>
              <dd>
                {firstName} {lastName}
              </dd>

              <dt>Email</dt>
              <dd>{email}</dd>

              {title && (
                <>
                  <dt>Title</dt>
                  <dd>{title}</dd>
                </>
              )}

              {phone && (
                <>
                  <dt>Phone</dt>
                  <dd>{phone}</dd>
                </>
              )}
            </dl>
            <div className="flex gap-1 -mr-3 -mt-3">
              <Tooltip>
                <TooltipTrigger>
                  <Button variant="ghost" size="xs" display="icon" onClick={toggleEdit}>
                    <Icon icon="edit" />
                  </Button>
                </TooltipTrigger>
                <TooltipContent>Edit Contact</TooltipContent>
              </Tooltip>
              <Tooltip>
                <TooltipTrigger>
                  <Button variant="ghost" size="xs" display="icon" onClick={handleDelete}>
                    <Icon icon="delete" />
                  </Button>
                </TooltipTrigger>
                <TooltipContent>Remove Contact</TooltipContent>
              </Tooltip>
            </div>
          </div>
        )}
      </CardContent>
    </Card>
  );
};

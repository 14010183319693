import { KnownClientData } from "@qw/qw-common";
import { z } from "zod";

import {
  BindHqApplicationType,
  BindHqInsuredType,
  BindHqLineOfBusiness,
  BindHqQuoteBillingType,
  BindHqQuoteBusinessType,
  BindHqQuoteLineItemType,
  BindHqQuoteTaxesCollectedBy,
} from "src/generated/graphql";

const bindHqPercentageRegex =
  "^(?<sign>[-+])?(?:(?:(?<integral>[0-9]+)?(?<point>.)?(?<fractional>[0-9]+)?(?:[eE](?<exponent>[-+]?[0-9]+))?)|(?:(?<numerator>[0-9]+)/?(?<denominator>[0-9]+)))$";

export const BindHqPolicyRequirementsSchema = z
  .object({
    agency: z.object({
      agencyId: z.string().min(1),
      bindHqId: z.string().min(1, { message: "Required" }),
    }),
    insured: z.object({
      id: z.string().min(1),
      bindHqId: z
        .string()
        .optional()
        .transform((v) => (v === "" ? undefined : v)),
      name: z.string().min(1),
      dba: z.string().optional(),
      legalEntityType: z.enum(Object.values(BindHqInsuredType) as [BindHqInsuredType]).optional(),
    }),
    clientData: z.object({
      [KnownClientData.MailingAddress.AddressLine1]: z.string().optional(),
      [KnownClientData.MailingAddress.AddressLine2]: z.string().optional(),
      [KnownClientData.MailingAddress.City]: z.string().optional(),
      [KnownClientData.MailingAddress.State]: z.string().optional(),
      [KnownClientData.MailingAddress.Zip]: z.string().optional(),
    }),
    updateBindHqAgency: z.object({
      billingContactFirstName: z.string().optional(),
      billingContactLastName: z.string().optional(),
      phone: z.string().min(10),
      email: z.string().email(),
      line1: z.string().min(1, "Required"),
      line2: z.string().optional(),
      city: z.string().min(1, "Required"),
      state: z.string().min(2, "Required").max(2, "Required"),
      zip: z.string().min(5, "Required").max(5, "Required"),
    }),
    bindHqInput: z.object({
      createApplication: z.object({
        agentBindHqId: z.string().min(1),
        linesOfBusiness: z.array(z.enum(Object.values(BindHqLineOfBusiness) as [BindHqLineOfBusiness])).min(1),
        type: z.enum(Object.values(BindHqApplicationType) as [BindHqApplicationType]),
      }),
      createQuote: z.object({
        marketingCompanyId: z.string().min(1, "Required"),
        carrierId: z.string().min(1),
        businessType: z.enum(Object.values(BindHqQuoteBusinessType) as [BindHqQuoteBusinessType]),
        billingType: z.enum(Object.values(BindHqQuoteBillingType) as [BindHqQuoteBillingType]),
        taxesCollectedBy: z.enum(Object.values(BindHqQuoteTaxesCollectedBy) as [BindHqQuoteTaxesCollectedBy]),
        taxationHomeState: z.string().min(2).max(2),
        minimumEarnedPremiumPercentage: z
          .string()
          .min(1, { message: "Required" })
          .regex(new RegExp(bindHqPercentageRegex)),
        grossCommissionPercentage: z.string().min(1, { message: "Required" }).regex(new RegExp(bindHqPercentageRegex)),
        agentCommissionPercentage: z.string().min(1, { message: "Required" }).regex(new RegExp(bindHqPercentageRegex)),
        lineItems: z.array(
          z.object({
            type: z.enum(Object.values(BindHqQuoteLineItemType) as [BindHqQuoteLineItemType]),
            amount: z.union([z.string().min(1), z.number()]).transform((v) => v.toString()),
            lineOfBusiness: z.enum(Object.values(BindHqLineOfBusiness) as [BindHqLineOfBusiness]),
            description: z.string().optional(),
          })
        ),
      }),
      createPolicy: z.object({
        policyNumber: z.string().min(1, { message: "Required" }),
        invoiceDueDate: z.date({ required_error: "Required", invalid_type_error: "Invalid date" }),
        effectiveDate: z.date({ required_error: "Required", invalid_type_error: "Invalid date" }),
        expirationDate: z.date({ required_error: "Required", invalid_type_error: "Invalid date" }),
      }),
    }),
  })
  .superRefine((values, context) => {
    if (!values.insured.bindHqId && !values.insured.legalEntityType) {
      context.addIssue({
        code: z.ZodIssueCode.custom,
        message: "Required",
        path: ["insured", "legalEntityType"],
      });
    }
  });

export type BindFormValues = z.infer<typeof BindHqPolicyRequirementsSchema>;

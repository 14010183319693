import { sortBy } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { z } from "zod";

import { ButtonGroup } from "@/components/button-group";
import { Group } from "@/components/group";
import { Button } from "@/components/ui/button";
import { Sheet, SheetContent, SheetDescription, SheetHeader, SheetTitle, SheetTrigger } from "@/components/ui/sheet";
import { FieldCombobox } from "@/forms/fields/field-combobox";
import { FieldInput } from "@/forms/fields/field-input";
import { FieldSelect } from "@/forms/fields/field-select";
import { FieldTextarea } from "@/forms/fields/field-textarea";
import { Form } from "@/forms/form";
import { FormReset } from "@/forms/form-reset";
import { FormSubmit } from "@/forms/form-submit";
import { useMarketingPlanRequirements } from "@/hooks/use-marketing-plan-requirements";
import { toast } from "@/hooks/use-toast";
import { useFormContext } from "react-hook-form";
import { AgentActionType, useAgentActionTitlesQuery, useBrokerCreateAgentActionMutation } from "src/generated/graphql";
import { parseError } from "src/utils";

const validationSchema = z.object({
  submissionId: z.string().optional(),
  title: z.string().min(2, { message: "Please select or enter a requirement" }),
  reason: z.string().min(2, { message: "Please provide a reason" }),
  externalReason: z.string().optional(),
  agentActionType: z.nativeEnum(AgentActionType),
});

const defaultValues = {
  submissionId: "",
  title: "",
  reason: "",
  externalReason: "",
  agentActionType: AgentActionType.UploadFile,
};

export const AddRequirements = ({ opportunityId }: { opportunityId: string }) => {
  const [createAgentAction] = useBrokerCreateAgentActionMutation();
  const { refetch } = useMarketingPlanRequirements();
  const [open, setOpen] = useState(false);

  const onSubmit = async (formData: z.infer<typeof validationSchema>) => {
    void createAgentAction({
      variables: {
        input: {
          opportunityId,
          submissionId: formData.submissionId || undefined,
          title: formData.title,
          reason: formData.reason,
          externalReason: formData.externalReason,
        },
      },
      onError: (e) => toast({ title: `Error`, description: parseError(e), variant: "destructive" }),
      onCompleted: () => {
        toast({ title: "Requirement Added" });
        setOpen(false);
        refetch();
      },
    });
  };

  return (
    <Sheet open={open} onOpenChange={() => setOpen((open) => !open)}>
      <SheetTrigger asChild>
        <Button variant="outline" size="xs">
          Add Requirement
        </Button>
      </SheetTrigger>
      <SheetContent>
        <SheetHeader>
          <SheetTitle>Add Requirement</SheetTitle>
          <SheetDescription className="hidden">Add Requirement</SheetDescription>
        </SheetHeader>

        <Form validationSchema={validationSchema} onSubmit={onSubmit} defaultValues={defaultValues}>
          <Group>
            <FieldSelect
              name="agentActionType"
              label="Type"
              placeholder="Select an option"
              options={Object.keys(AgentActionTypeDisplay).map((type) => ({
                label: AgentActionTypeDisplay[type as keyof typeof AgentActionTypeDisplay] as string,
                value: type,
              }))}
            />

            <SelectRequirement />

            <FieldTextarea
              label="Reason (Required)"
              name="reason"
              placeholder="Please describe the reason we need this item. (Example: 'X market requires this item if revenue is above $Y'). This will help our models learn when to request this automatically in the future!"
              rows={5}
            />

            <FieldTextarea
              label="External Reason (Optional)"
              name="externalReason"
              placeholder="This is agent facing and will be used to explain why we are requesting this information!"
              rows={5}
            />

            <ButtonGroup>
              <FormReset onClick={() => setOpen(false)} />
              <FormSubmit>Add</FormSubmit>
            </ButtonGroup>
          </Group>
        </Form>
      </SheetContent>
    </Sheet>
  );
};

export const SelectRequirement = () => {
  const formMethods = useFormContext();
  const agentActionType = formMethods.watch("agentActionType");

  const { data: { agentActionTitles = [] } = {} } = useAgentActionTitlesQuery({
    variables: { actionType: agentActionType },
  });

  const options = useMemo(
    () => sortBy(agentActionTitles).map((title) => ({ label: title, value: title })),
    [agentActionTitles]
  );

  useEffect(() => {
    formMethods.resetField("title", { defaultValue: "" });
  }, [agentActionType]);

  if (agentActionType === AgentActionType.Custom) {
    return <FieldInput name="title" label="Title (how it will display to the agent)" />;
  }

  return <FieldCombobox name="title" label="Select the item you want to add" placeholder="Search" options={options} />;
};

export const AgentActionTypeDisplay: Partial<Record<AgentActionType, string>> = {
  [AgentActionType.UploadFile]: "Document",
  [AgentActionType.ClientData]: "Specific Info",
  [AgentActionType.Custom]: "Custom",
};

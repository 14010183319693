import { z } from "zod";

import { Dialog, DialogContent } from "@/components/ui/dialog";
import { Form } from "@/forms/form";
import { useBankan } from "@/hooks/use-bankan";
import { useToast } from "@/hooks/use-toast";
import { useSnoozeOpportunityMutation } from "src/generated/graphql";
import { SnoozeForm } from "./snooze-form";

export const SnoozeDialog = () => {
  const { snoozableMarketingPlan, setSnoozableMarketingPlan } = useBankan();
  const { toast } = useToast();
  const [snooze] = useSnoozeOpportunityMutation();

  if (!snoozableMarketingPlan) {
    return null;
  }

  const { id, desiredEffectiveDate } = snoozableMarketingPlan;

  const validationSchema = z.object({
    desiredSnoozeUntil: z.union([
      z.string({ message: "Please select a date" }).min(1, "Please select a date"),
      z.date({ message: "Please select a date" }).transform((val) => val.toISOString()),
    ]),
  });

  const handleSubmit = async ({ desiredSnoozeUntil }: z.infer<typeof validationSchema>) =>
    await snooze({
      variables: { input: { id, snoozeUntil: desiredSnoozeUntil } },
      onCompleted: () => {
        setSnoozableMarketingPlan(null);
        toast({ title: "Snoozed", description: "The marketing plan has been snoozed." });
      },
    });

  return (
    <Dialog open={Boolean(id)} onOpenChange={() => setSnoozableMarketingPlan(null)}>
      <DialogContent onOpenAutoFocus={(e) => e.preventDefault()} className="max-w-xl">
        <Form validationSchema={validationSchema} onSubmit={handleSubmit}>
          <SnoozeForm desiredEffectiveDate={desiredEffectiveDate} />
        </Form>
      </DialogContent>
    </Dialog>
  );
};

import { MaterialSymbol } from "material-symbols";
import { Link, LinkProps } from "react-router-dom";

import { InsuredPin } from "@/components/insured-pin";
import { Icon } from "@/components/ui/icon";
import { Tooltip, TooltipContent, TooltipTrigger } from "@/components/ui/tooltip";
import { internalCohortMetadata, internalCohorts } from "@/metadata/affiliations";
import { AgencyAffiliationFragment, BankanMarketingPlanColumnQuery } from "src/generated/graphql";
import { cn } from "src/utils";
import { daysDelta } from "src/utils/date";

type Insured = BankanMarketingPlanColumnQuery["bankanMarketingPlanColumn"]["groupedMarketingPlans"][number]["insured"];

export const BankanCard = ({ children, className }: { children?: React.ReactNode; className?: string }) => (
  <div className={cn("bg-background hover-outline outline-input rounded-lg shadow-sm", className)}>{children}</div>
);

export const BankanCardHeader = ({ insured }: { insured: Insured }) => {
  const {
    id,
    name,
    agent,
    agent: {
      agency: { affiliations },
    },
  } = insured;

  return (
    <header className="px-4 py-3 space-y-1.5">
      <div className="flex gap-1 items-center justify-between text-xs truncate">
        <span className="flex-auto font-semibold mr-2 truncate">{name}</span>
        <InsuredPin id={id} className="-my-1 -mr-1.5" />
      </div>
      <div className="flex gap-3 items-center text-muted-foreground text-2xs">
        <span className="flex-auto truncate">
          {agent.firstName} {agent.lastName}, {agent.agency.name}
        </span>
        <Cohorts affiliations={affiliations ?? []} />
      </div>
    </header>
  );
};

const Cohorts = ({ affiliations }: { affiliations: AgencyAffiliationFragment[] }) => {
  const cohorts = affiliations.filter((aff) => internalCohorts.includes(aff.id)) ?? [];
  return (
    <span className="flex flex-none gap-1.5 -mr-0.5">
      {cohorts.map((c) => {
        const { name, icon, iconClassName } = internalCohortMetadata[c.id];
        return (
          <Tooltip key={c.id}>
            <TooltipTrigger asChild>
              <div
                className={cn(
                  "bg-linear-150 flex flex-none filled items-center justify-center rounded-full text-white size-4",
                  iconClassName
                )}
              >
                <Icon icon={icon} className="drop-shadow-[0_2px_4px_rgba(0_0_0_/_0.5)] text-[11.5px]" />
              </div>
            </TooltipTrigger>
            <TooltipContent>{name}</TooltipContent>
          </Tooltip>
        );
      })}
    </span>
  );
};

export const BankanLink = ({ children, className, to, ...rest }: LinkProps & { cardClassName?: string }) => (
  <Link to={to} target="_blank" className={cn("block px-4 py-3 space-y-1.5", className)} {...rest}>
    {children}
  </Link>
);

export const BankanLinkText = ({ children, className, ...rest }: React.HTMLAttributes<HTMLDivElement>) => (
  <div className={cn("font-semibold text-xs truncate", className)} {...rest}>
    {children}
  </div>
);

export const BankanLinkFooter = ({ children, className, ...rest }: React.HTMLAttributes<HTMLDivElement>) => (
  <footer className={cn("flex gap-3 items-center justify-between text-2xs text-muted-foreground", className)} {...rest}>
    {children}
  </footer>
);

export const DaysSince = ({ icon = "event", date }: { icon?: MaterialSymbol; date: string }) => (
  <div className="filled flex font-semibold gap-1 items-center text-2xs tracking-tight">
    <Icon icon={icon} />
    {daysDelta(date)}
  </div>
);

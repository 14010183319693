import { Outlet, useParams } from "react-router-dom";

import { ScrollPane } from "@/components/scroll-pane";
import { SectionHeader, SectionTitle } from "@/components/section";
import { Bar, BarLink, BarLinks } from "@/components/ui/bar";
import { SetMetadata } from "@/hooks/use-metadata";
import { useAgencyQuery } from "src/generated/graphql";
import { AgencySidebar } from "./agency-sidebar";

export const Agency = () => {
  const { agencyId } = useParams();

  const { data: { agency } = {} } = useAgencyQuery({
    variables: {
      id: agencyId ?? "",
    },
    skip: !agencyId,
  });

  if (!agency) {
    return null;
  }

  return (
    <>
      <SetMetadata icon="home" title={agency.name} crumb />
      <ScrollPane sidebarPosition="right">
        <SectionHeader>
          <SectionTitle>
            <h1>{agency.name}</h1>
          </SectionTitle>
        </SectionHeader>
        <Bar>
          <BarLinks>
            <BarLink to="." end>
              Active Marketing Plans
            </BarLink>
            <BarLink to="all">All Named Insureds</BarLink>
          </BarLinks>
        </Bar>
        <Outlet />
      </ScrollPane>
      <AgencySidebar id={agency.id} />
    </>
  );
};

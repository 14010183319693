import { useMarketingPlanRequirements } from "@/hooks/use-marketing-plan-requirements";
import { MarketingPlanState } from "src/generated/graphql";
import { InformationGathering } from "./information-gathering";

export const RequirementsActions = () => {
  const { opportunity } = useMarketingPlanRequirements();

  const renderMarketingPlanState = () => {
    switch (opportunity.state) {
      case MarketingPlanState.InformationGathering:
        return <InformationGathering />;
      default:
        return null;
    }
  };

  return renderMarketingPlanState();
};

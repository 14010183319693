import { BankanMarketingPlanColumnQuery } from "src/generated/graphql";
import { formatTimezoneDateString } from "src/utils/date";
import { BankanCard, BankanLink, BankanLinkFooter, BankanLinkText } from "./bankan-card";

type Insured = BankanMarketingPlanColumnQuery["bankanMarketingPlanColumn"]["groupedMarketingPlans"][number]["insured"];

export const InsuredCard = ({ insured }: { insured: Insured }) => {
  const { id, name, createdAt } = insured;
  return (
    <BankanCard>
      <BankanLink to={`/insured/${id}`}>
        <BankanLinkText>{name}</BankanLinkText>
        <BankanLinkFooter>Created {formatTimezoneDateString(createdAt, "MMM d, yyyy")}</BankanLinkFooter>
      </BankanLink>
    </BankanCard>
  );
};

import { useModal } from "@/components/modal-provider";
import { Button } from "@/components/ui/button";
import { QUOTE_STATE_METADATA } from "@/metadata/quotes";
import { QuoteAction, QuoteFragment, useTransitionQuoteMutation } from "src/generated/graphql";
import { BindRequestReceivedButton } from "./components/bind-request-received-button";

export const Delivered = ({ quote }: { quote: QuoteFragment }) => {
  const { openConfirmation } = useModal();
  const [transition] = useTransitionQuoteMutation();

  const handleTransition = async (action: QuoteAction) => {
    const isConfirmed = await openConfirmation({
      title: `Confirm ${QUOTE_STATE_METADATA[quote?.state].actionDisplayNames?.[action] ?? action}`,
    });

    if (!isConfirmed) {
      return;
    }

    await transition({
      variables: {
        input: {
          id: quote.id,
          expectedState: quote.state,
          action,
        },
      },
    });
  };

  return (
    <>
      <BindRequestReceivedButton quote={quote} />
      <Button onClick={() => handleTransition(QuoteAction.ReceiveNotTaken)} variant="outline" size="sm" display="flex">
        Quote Not Taken
      </Button>
      <Button onClick={() => handleTransition(QuoteAction.Invalidate)} variant="outline" size="sm" display="flex">
        Invalidate
      </Button>
    </>
  );
};

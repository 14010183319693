import { StatePill } from "@/components/state";
import { Avatar } from "@/components/ui/avatar";
import { QuoteFragment, useOpportunityQuery } from "src/generated/graphql";

export const QuoteMenu = ({ quote }: { quote: QuoteFragment }) => {
  const { data: { opportunity } = {} } = useOpportunityQuery({
    variables: {
      id: quote?.submission.opportunityId ?? "",
    },
    skip: !quote.id || !quote?.submission.opportunityId,
    fetchPolicy: "cache-first",
  });

  if (!opportunity || !quote) {
    return null;
  }

  return (
    <>
      <Avatar user={opportunity.broker} />
      <StatePill state={quote.state} />
    </>
  );
};

import { Group } from "@/components/group";
import { CarrierContactFragment, CarrierContactType } from "src/generated/graphql";

import { CarrierContactCard } from "./carrier-contact-card";
import { CreateCarrierContactButton } from "./carrier-contact-modal";

interface Props {
  type: CarrierContactType;
  contacts: CarrierContactFragment[];
  appetiteProductId: string;
}

export const CarrierContactList: React.FC<Props> = ({ type, contacts, appetiteProductId }) => {
  const showEmptyState = contacts.length === 0;

  const label =
    type === CarrierContactType.Submission ? "Submission Contacts (Inboxes & Underwriters)" : "Reference Contacts";

  return (
    <Group className="gap-2">
      <div className="flex justify-between items-center">
        <h5>{label}</h5>
        <CreateCarrierContactButton type={type} contacts={contacts} appetiteProductId={appetiteProductId} />
      </div>

      {!showEmptyState && (
        <Group className="mt-2">
          {contacts.map((contact) => (
            <CarrierContactCard key={contact.id} type={type} contact={contact} appetiteProductId={appetiteProductId} />
          ))}
        </Group>
      )}
      {showEmptyState && <div className="text-muted-foreground text-sm">No contacts</div>}
    </Group>
  );
};

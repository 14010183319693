import { UUID } from "@cp/toolkit";
import { useState } from "react";
import { useParams } from "react-router";

import { Autocomplete } from "@/components/ui/autocomplete";
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogTrigger } from "@/components/ui/dialog";
import { Select, SelectContent, SelectItem, SelectTrigger } from "@/components/ui/select";
import { useInsured } from "@/hooks/use-insured";
import { toast } from "@/hooks/use-toast";
import { Roles, useUpdateInsuredMutation, useUserAccountsQuery } from "src/generated/graphql";

export const SelectAgent = () => {
  const { insured } = useInsured();
  const [open, setOpen] = useState(false);

  const { data: agents } = useUserAccountsQuery({
    variables: { input: { agencyId: insured.agency.id, roles: [Roles.Agent] } },
    fetchPolicy: "cache-first",
  });

  const [updateInsured] = useUpdateInsuredMutation({
    onCompleted: () => toast({ title: "Success", description: "Insured updated." }),
    onError: () => toast({ title: "Error", description: "An error occurred.", variant: "destructive" }),
  });

  const { id, agent } = insured;

  return (
    <Dialog open={open} onOpenChange={() => setOpen(!open)}>
      <Select
        value={agent.id}
        onValueChange={async (e) => {
          if (e === "agentAtAnotherAgency") {
            setOpen(true);
          } else {
            await updateInsured({
              variables: {
                input: {
                  id,
                  agentId: e as UUID,
                },
              },
            });
          }
        }}
      >
        <SelectTrigger className="h-auto text-left">
          <div className="space-y-1">
            <div className="text-xs">
              {agent.firstName} {agent.lastName}
            </div>
            <div className="text-2xs text-muted-foreground">{agent.agency.name}</div>
          </div>
        </SelectTrigger>
        <SelectContent>
          {agents?.userAccounts.map((u) => (
            <SelectItem key={u.id} value={u.id}>
              {u.firstName} {u.lastName}
            </SelectItem>
          ))}
          <DialogTrigger asChild>
            <SelectItem key={"agentAtAnotherAgency"} value={"agentAtAnotherAgency"} className="font-bold">
              Agent at another agency
            </SelectItem>
          </DialogTrigger>
        </SelectContent>
      </Select>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Re-assign to Agent</DialogTitle>
        </DialogHeader>
        <AllAgentsAutocomplete onSelect={() => setOpen(false)} />
      </DialogContent>
    </Dialog>
  );
};

const AllAgentsAutocomplete = ({ onSelect }: { onSelect: () => void }) => {
  const { insuredId } = useParams();
  const { data: agents } = useUserAccountsQuery({
    variables: { input: { roles: [Roles.Agent] } },
    fetchPolicy: "cache-first",
  });
  const [updateInsured] = useUpdateInsuredMutation({
    onCompleted: () => toast({ title: "Success", description: "Insured updated." }),
    onError: () => toast({ title: "Error", description: "An error occurred.", variant: "destructive" }),
  });

  if (!agents) {
    return null;
  }

  return (
    <Autocomplete
      options={agents.userAccounts}
      onSelect={async (agent) => {
        await updateInsured({
          variables: {
            input: {
              id: insuredId || "",
              agentId: agent.id as UUID,
            },
          },
        });

        onSelect();
      }}
      toValue={(agent) => `${agent.firstName} ${agent.lastName}`}
      toLabel={(agent) => `${agent.firstName} ${agent.lastName}`}
      placeholder="Search for an Agent"
    />
  );
};

import { useMarketingPlanById } from "@/hooks/use-marketing-plan";
import { Navigate } from "react-router";

export const InsuredRedirect = () => {
  const { opportunity } = useMarketingPlanById();

  if (!opportunity) {
    return null;
  }

  const { id, insured } = opportunity;

  return <Navigate to={`/insured/${insured.id}/plans/${id}`} />;
};

import { MutationFunctionOptions } from "@apollo/client";
import { useCallback } from "react";

import { useToast } from "@/hooks/use-toast";
import {
  CreateSegmentAppetiteProductRuleInput,
  CreateSegmentAppetiteProductRuleMutation,
  useCreateSegmentAppetiteProductRuleMutation,
} from "src/generated/graphql";

import { useSegment } from "./use-segment";

export const useCreateSegmentAppetiteProductRule = () => {
  const { segment } = useSegment();
  const { toast } = useToast();

  const [createRuleMutation, { loading: isCreatingRule }] = useCreateSegmentAppetiteProductRuleMutation({
    awaitRefetchQueries: true,
    onCompleted: () => toast({ title: "Rule added" }),
    refetchQueries: ["Segment"],
  });

  const createRule = useCallback(
    async (
      input: CreateSegmentAppetiteProductRuleInput,
      options?: Omit<MutationFunctionOptions<CreateSegmentAppetiteProductRuleMutation>, "variables">
    ) => await createRuleMutation({ variables: { input }, ...options }),
    [segment?.id]
  );

  return {
    createRule,
    isCreatingRule,
  };
};

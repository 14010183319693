import * as React from "react";

import { cn } from "src/utils";

const Input = React.forwardRef<HTMLInputElement, React.ComponentProps<"input">>(
  ({ className, type, ...props }, ref) => {
    return (
      <input
        type={type}
        className={cn(
          "flex h-10 w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-hidden focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 read-only:bg-accent read-only:text-muted-foreground",
          className
        )}
        ref={ref}
        {...props}
        onWheel={(e) => (e.target as HTMLElement).blur()}
      />
    );
  }
);
Input.displayName = "Input";

export { Input };

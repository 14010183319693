import { useParams } from "react-router-dom";

import { HasRole } from "@/components/has-role";
import { useModal } from "@/components/modal-provider";
import { Badge } from "@/components/ui/badge";
import { DropdownMenuItem, DropdownMenuSeparator } from "@/components/ui/dropdown-menu";
import { Icon } from "@/components/ui/icon";
import { Spinner } from "@/components/ui/loading";
import { Roles, useTestAlbyMutation } from "src/generated/graphql";

export const TestAlby = () => {
  const { insuredId, opportunityId } = useParams();
  const { openModal } = useModal();

  const [testAlby, { loading }] = useTestAlbyMutation({
    variables: {
      input: {
        insuredId: insuredId!,
        opportunityId: opportunityId!,
      },
    },
  });

  // NOTE (David): temporarily enabled in production for testing; ping David to disable
  // return process.env.NODE_ENV === "development" ? (
  return (
    <HasRole roles={[Roles.Admin]}>
      <DropdownMenuItem
        onClick={async () => {
          const res = await testAlby();
          const messages = JSON.parse(res.data?.testAlby ?? "{}").messages ?? [];
          void openModal(() => (
            <div>
              {messages.map((msg: any) => (
                <>
                  <pre className="whitespace-pre-wrap w-full">{msg.kwargs.content}</pre>
                  <Badge>{msg.kwargs.tool_calls?.[0]?.name ?? "no tools call"}</Badge>
                </>
              ))}
            </div>
          ));
        }}
        disabled={loading}
      >
        {loading ? <Spinner /> : <Icon icon="smart_toy" />}
        Test Alby
      </DropdownMenuItem>
      <DropdownMenuSeparator />
    </HasRole>
  );
  // ) : null;
};

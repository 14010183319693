import { FC, useMemo } from "react";

import { FieldCombobox, FieldComboboxProps } from "@/forms/fields/field-combobox";
import { PrimaryLabelCommandItemLabel } from "@/label-management/extracted-labels/components/assign-primary-label-popover";
import { useKnownPrimaryLabelsQuery } from "src/generated/graphql";

export interface KnownPrimaryLabelsSelectorProps extends FieldComboboxProps {}

export const KnownPrimaryLabelsSelector: FC<KnownPrimaryLabelsSelectorProps> = (props) => {
  const { data } = useKnownPrimaryLabelsQuery();

  const options = useMemo(
    () =>
      data?.knownPrimaryLabels.map((pl) => ({
        label: pl.displayName || pl.primaryKey,
        value: pl.primaryKey,
        displayLabel: <PrimaryLabelCommandItemLabel primaryLabel={pl} />,
        displayValue: pl.displayName || pl.primaryKey,
        keywords: pl.displayName ? [pl.primaryKey] : undefined,
      })),
    [data?.knownPrimaryLabels]
  );

  return <FieldCombobox {...props} options={options} />;
};

import { EmptyState } from "@/components/empty-state";
import { Grid, GridCell, GridRowLink } from "@/components/grid";
import { Icon } from "@/components/ui/icon";
import { useInsured } from "@/hooks/use-insured";
import { adaptGraph } from "src/utils/adapt-graph";
import { formatTimezoneDateStringDistance } from "src/utils/date";

export const InsuredAlby = () => {
  const { approvals, approvalsLoading } = useInsured();
  return approvals[0] ? (
    <Grid className="border-t grid-cols-[1rem_1fr_8rem_1rem]">
      {approvals.map((graph) => {
        const { id, createdAt, insuredId, graphName, toolName, header, invokeParams } = adaptGraph(graph);
        return (
          <GridRowLink key={id} to={`/insured/${insuredId}/alby/${graphName}/tool/${toolName}?${invokeParams}`}>
            <Icon icon="smart_toy" className="text-base text-muted-foreground" />
            <GridCell>{header?.text.text ?? `${graphName} - ${toolName}`}</GridCell>
            <GridCell className="text-right">{formatTimezoneDateStringDistance(createdAt)} ago</GridCell>
          </GridRowLink>
        );
      })}
    </Grid>
  ) : (
    <EmptyState icon="smart_toy" title={<h2>No approvals found.</h2>} loading={approvalsLoading} />
  );
};

import { orderBy } from "lodash";
import { Link, Outlet } from "react-router-dom";

import { EmptyState } from "@/components/empty-state";
import { Grid, GridCell, GridRowHeader, GridRowLink } from "@/components/grid";
import { Group } from "@/components/group";
import { ScrollPane } from "@/components/scroll-pane";
import { SectionHeader, SectionTitle } from "@/components/section";
import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import { Icon } from "@/components/ui/icon";
import { Spinner } from "@/components/ui/loading";
import { SetMetadata } from "@/hooks/use-metadata";
import { useVerticalsQuery } from "src/generated/graphql";
import { cn } from "src/utils";

export const Verticals = () => (
  <>
    <SetMetadata title="Verticals" icon="layers" crumb />
    <ScrollPane>
      <Outlet />
    </ScrollPane>
  </>
);

export const VerticalsList = () => {
  const { data: { verticals = [] } = {}, loading } = useVerticalsQuery();
  return (
    <>
      <SectionHeader>
        <SectionTitle>
          <h1>Verticals</h1>
          <div className="flex-1" />
          {loading && <Spinner />}
          <Button type="button" variant="outline" size="sm" asChild>
            <Link to="create">Create Vertical</Link>
          </Button>
        </SectionTitle>
      </SectionHeader>

      {verticals[0] ? (
        <Grid className="grid-cols-[0.75rem_1fr_6rem_3fr_8rem_4rem]">
          <GridRowHeader position="sticky">
            <GridCell />
            <GridCell>Vertical</GridCell>
            <GridCell>Templates</GridCell>
            <GridCell>CGL(s)</GridCell>
            <GridCell className="text-right">Exclusion Reasons</GridCell>
          </GridRowHeader>
          {orderBy(verticals, [(vertical) => !vertical.enabled, (vertical) => vertical.name.toLowerCase()]).map(
            (vertical) => (
              <GridRowLink key={vertical.id} to={`${vertical.id}/hierarchy`}>
                <Icon icon="circle" className={cn(vertical.enabled ? "filled text-success" : "opacity-25")} />
                <GridCell>{vertical.name}</GridCell>
                <GridCell>{vertical.marketingPlanTemplates.length}</GridCell>
                <Pills items={vertical.isoCglCodes.map((cgl) => cgl.classCode)} />
                <GridCell className="text-right">
                  {!!vertical.exclusionReasonsCount && (
                    <Badge variant="secondary">{vertical.exclusionReasonsCount}</Badge>
                  )}
                </GridCell>
              </GridRowLink>
            )
          )}
        </Grid>
      ) : (
        <EmptyState loading={loading} title={<h2>No verticals found.</h2>} />
      )}
    </>
  );
};

const Pills = ({ items }: { items: string[] }) => (
  <Group type="flex" direction="row" className="gap-1 truncate">
    {items.map((item) => (
      <Badge key={item} variant="secondary" className="tabular-nums">
        {item}
      </Badge>
    ))}
  </Group>
);

import { useModal } from "@/components/modal-provider";
import { Button } from "@/components/ui/button";
import { useMarketingPlan } from "@/hooks/use-marketing-plan";
import { MarketingPlanAction, useTransitionMarketingPlanMutation } from "src/generated/graphql";

export const Complete = () => {
  const { opportunity, refetchQueries } = useMarketingPlan();
  const [transition] = useTransitionMarketingPlanMutation();
  const { openConfirmation } = useModal();

  const handleTransition = async (action: MarketingPlanAction) => {
    const isConfirmed = await openConfirmation({
      title: "Confirm reopen marketing",
    });

    if (!isConfirmed) {
      return;
    }

    await transition({
      variables: {
        input: {
          id: opportunity.id,
          expectedState: opportunity.state,
          action,
        },
      },
      refetchQueries,
    });
  };

  return (
    <Button variant="outline" size="xs" onClick={() => handleTransition(MarketingPlanAction.Reopen)}>
      Re-open Marketing
    </Button>
  );
};

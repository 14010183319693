import { Navigate, RouteObject } from "react-router-dom";

import { Agency } from "@/agency/agency";
import { AgencyActive } from "@/agency/agency-active";
import { AgencyAll } from "@/agency/agency-all";
import { AgencyRedirect } from "@/agency/agency-redirect";
import { ErrorMessage } from "@/components/errors/error-message";
import { Supplementals } from "@/supplementals/supplementals";

export const agencyRoutes: RouteObject[] = [
  {
    path: "agency",
    children: [
      {
        index: true,
        element: <AgencyRedirect />,
      },
      {
        path: ":agencyId",
        element: <Agency />,
        errorElement: <ErrorMessage />,
        children: [
          {
            index: true,
            element: <Navigate to="active" replace />,
          },
          {
            path: "active",
            element: <AgencyActive />,
          },
          {
            path: "all",
            element: <AgencyAll />,
          },
        ],
      },
    ],
  },
  {
    // Note: without a clientId, the form won't save
    path: "supplementals/:clientId?",
    element: <Supplementals />,
  },
];

import {
  Background,
  Node,
  OnSelectionChangeParams,
  ReactFlow,
  useEdgesState,
  useNodesState,
  Viewport,
} from "@xyflow/react";
import { easeExpInOut, timer } from "d3";
import { FC, useCallback, useEffect, useMemo } from "react";

import { Loading } from "@/components/ui/loading";
import { FileProcessingPipelineQuery, useFileProcessingPipelineQuery } from "src/generated/graphql";

import { prepareLayout } from "../file-processing-pipeline.helpers";
import { useFileProcessingPipelineState } from "../file-processing-pipeline.provider";
import { Controls } from "./components/controls";
import { DocumentLabelNode } from "./components/nodes/document-label-node";
import { FileProcessorNode } from "./components/nodes/file-processor-node";
import { Settings } from "./components/settings";

import "@xyflow/react/dist/style.css";

const nodeTypes = {
  FileProcessor: FileProcessorNode,
  DocumentLabel: DocumentLabelNode,
};

// NOTE: Disabling edgeTypes for now as we don't need any actions on the edges at the moment.
// const edgeTypes = {
//   default: DefaultEdge,
// };

export interface FileProcessingPipelineGraphProps {
  data: FileProcessingPipelineQuery["fileProcessingPipeline"];
}

export const FileProcessingPipelineGraph: FC<FileProcessingPipelineGraphProps> = ({ data }) => {
  const { defaultViewport, expandedNodes, setIsInitialized, setIsTransitioning, setDefaultViewport, setSelectedNodes } =
    useFileProcessingPipelineState();

  const { nodes: initialNodes, edges: initialEdges } = useMemo(() => prepareLayout(data, { expandedNodes }), [data]);

  const [nodes, setNodes, handleNodesChange] = useNodesState(initialNodes);
  const [edges, setEdges, handleEdgesChange] = useEdgesState(initialEdges);

  const handleViewportChange = (viewport: Viewport) => setDefaultViewport(viewport);

  const handleSelectionChange = ({ nodes }: OnSelectionChangeParams) => setSelectedNodes(nodes);

  useEffect(() => {
    const { nodes: updatedNodes, edges: updatedEdges } = prepareLayout(data, { expandedNodes });

    animateNodePositions(nodes, updatedNodes);
    setEdges(updatedEdges);
  }, [data, expandedNodes]);

  const animateNodePositions = useCallback(
    (prevNodes: Node[], nodes: Node[]) => {
      setIsTransitioning(true);

      const t = timer((elapsed) => {
        const progress = easeExpInOut(Math.min(1, elapsed / 500));

        const interpolatedNodes = nodes.map((node) => {
          const prevNode = prevNodes.find((prevNode) => prevNode.id === node.id);

          if (!prevNode) {
            return node;
          }

          return {
            ...node,
            position: {
              x: prevNode.position.x + (node.position.x - prevNode.position.x) * progress,
              y: prevNode.position.y + (node.position.y - prevNode.position.y) * progress,
            },
          };
        });

        setNodes(interpolatedNodes);

        if (progress === 1) {
          t.stop();
          setIsTransitioning(false);
        }
      });
    },
    [nodes]
  );

  return (
    <ReactFlow
      nodes={nodes}
      edges={edges}
      nodeTypes={nodeTypes}
      // NOTE: Disabling edgeTypes for now as we don't need any actions on the edges at the moment.
      // edgeTypes={edgeTypes}
      onInit={() => setIsInitialized(true)}
      onNodesChange={handleNodesChange}
      onEdgesChange={handleEdgesChange}
      nodesDraggable={false}
      minZoom={0.25}
      panOnScroll
      panOnScrollSpeed={1}
      onSelectionChange={handleSelectionChange}
      onViewportChange={handleViewportChange}
      defaultViewport={defaultViewport}
      onlyRenderVisibleElements
      className="[&_.react-flow\_\_edge-path]:stroke-input! [&_.react-flow\_\_edge.selected_.react-flow\_\_edge-path]:stroke-muted-foreground! [&_.react-flow\_\_attribution]:hidden [&_.react-flow\_\_attribution]:bg-accent [&_.react-flow\_\_attribution_a]:bg-accent [&_.react-flow\_\_attribution_a]:text-muted-foreground [&_.react-flow\_\_attribution_a]:border-none"
    >
      <Settings />
      <Controls />
      <Background />
    </ReactFlow>
  );
};

export const FileProcessingPipelineGraphContainer = () => {
  const { data, loading } = useFileProcessingPipelineQuery();

  if (loading && !data) {
    return <Loading label="Loading pipeline data..." />;
  }

  if (!data || !data.fileProcessingPipeline) {
    return <p>The file processor pipeline data is not available.</p>;
  }

  return <FileProcessingPipelineGraph data={data.fileProcessingPipeline} />;
};

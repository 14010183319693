import { FC } from "react";

import { Grid, GridCell, GridEmpty, GridRow } from "@/components/grid";
import { Card, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import { Switch } from "@/components/ui/switch";
import { Tooltip, TooltipContent, TooltipTrigger } from "@/components/ui/tooltip";
import { useBrokerAccountsQuery, useUpdateSegmentAssignmentPoolMutation } from "src/generated/graphql";

import { useSegment } from "./hooks/use-segment";

export const SegmentAssignmentPool: FC = () => {
  const { segment } = useSegment();
  const { data: brokerData } = useBrokerAccountsQuery();
  const [addBrokerToAssignmentPool] = useUpdateSegmentAssignmentPoolMutation({
    awaitRefetchQueries: true,
    refetchQueries: ["Segment"],
  });

  const handleBrokerToggle = async (brokerId: string, checked: boolean) =>
    await addBrokerToAssignmentPool({
      variables: {
        input: {
          segmentId: segment!.id,
          brokerId,
          type: checked ? "add" : "remove",
        },
      },
      onError: (error) => console.error("Failed to update broker assignment:", error),
    });

  return (
    <div className="p-6">
      <Card>
        <CardHeader>
          <CardTitle>Assignment Pool</CardTitle>
          <CardDescription>Use the toggles below to assign brokers to this segment.</CardDescription>
        </CardHeader>
        <Grid className="bg-none!">
          {brokerData?.userAccounts.map((broker) => {
            const isAssigned = segment?.assignmentPool?.some((assignedBroker) => assignedBroker.id === broker.id);

            return (
              <GridRow key={broker.id}>
                <GridCell>
                  <Tooltip>
                    <TooltipTrigger>
                      <Switch
                        checked={isAssigned}
                        onCheckedChange={(checked) => handleBrokerToggle(broker.id, checked as boolean)}
                        className="mr-4"
                      />
                    </TooltipTrigger>
                    <TooltipContent>{isAssigned ? "Assigned" : "Unassigned"}</TooltipContent>
                  </Tooltip>
                  {broker.firstName} {broker.lastName}
                </GridCell>
              </GridRow>
            );
          })}

          {!brokerData?.userAccounts.length && (
            <GridEmpty title="No brokers found" description="You may need reload the page to see active brokers." />
          )}
        </Grid>
      </Card>
    </div>
  );
};

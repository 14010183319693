import { ColumnStates, COLUMN_STATE_METADATA } from "@/metadata/bankan-columns";
import { StateMetadata, States, STATE_METADATA } from "@/metadata/states";
import { MaterialSymbol } from "material-symbols";
import { cn } from "src/utils";
import { SectionDescription, SectionHeader, SectionTitle } from "./section";
import { Badge } from "./ui/badge";
import { Card } from "./ui/card";
import { Icon } from "./ui/icon";
import { Tooltip, TooltipContent, TooltipTrigger } from "./ui/tooltip";

export const StatePill = ({ state }: { state: States }) => {
  const { cardClassName } = STATE_METADATA[state];
  return (
    <Badge className={cn("bg-background h-6 outline-1 select-none text-2xs", cardClassName)}>
      <StateIndicator state={state} className="gap-1.5 pr-0.5" />
    </Badge>
  );
};

export const StateIcon = ({ state }: { state: States }) => {
  const { className: stateClassName, icon, label } = STATE_METADATA[state];
  return (
    <Tooltip>
      <TooltipTrigger className={cn("flex items-center justify-center", stateClassName)}>
        <Icon icon={icon} />
      </TooltipTrigger>
      <TooltipContent>{label}</TooltipContent>
    </Tooltip>
  );
};

export const StateIndicator = ({
  state,
  displayLabel = true,
  className,
}: {
  state: States;
  displayLabel?: boolean;
  className?: string;
}) => {
  const { className: stateClassName, icon, label } = STATE_METADATA[state];
  return (
    <span className={cn("flex gap-[0.5em] items-center", stateClassName, className)}>
      <Icon icon={icon} />
      {displayLabel && <span className="truncate">{label}</span>}
    </span>
  );
};

export const StateLabel = ({ state, className }: { state: States; className?: string }) => {
  const { className: stateClassName, label } = STATE_METADATA[state];
  return <span className={cn(stateClassName, className)}>{label}</span>;
};

export const ColumnStateIndicator = ({
  state,
  displayLabel = true,
  className,
}: {
  state: ColumnStates;
  displayLabel?: boolean;
  className?: string;
}) => {
  const { className: stateClassName, icon, label } = COLUMN_STATE_METADATA[state];
  return (
    <span className={cn("flex gap-[0.5em] items-center", stateClassName, className)}>
      <Icon icon={icon} />
      {displayLabel && <span className="truncate">{label}</span>}
    </span>
  );
};

export const StateCard = ({
  actions,
  cardClassName,
  children,
  className,
  description,
  icon,
  iconClassName,
  title,
}: Omit<StateMetadata, "icon" | "label" | "actionDisplayNames"> & {
  icon?: MaterialSymbol;
  actions?: React.ReactNode;
  children?: React.ReactNode;
  iconClassName?: string;
}) => (
  <Card className={cn("hover-outline", cardClassName)}>
    <SectionHeader className="space-y-3">
      <SectionTitle className={cn("items-start", className)}>
        {icon && <Icon icon={icon} className={cn("flex-none -mx-0.5 text-2xl size-7", iconClassName)} />}
        <h3 className={cn("flex-auto truncate", className)}>{title}</h3>
        <div className="flex gap-2 items-center">{actions}</div>
      </SectionTitle>
      {description && <SectionDescription className={cn(icon && "ml-10")}>{description}</SectionDescription>}
    </SectionHeader>
    <div className="text-foreground">{children}</div>
  </Card>
);

import { ComponentProps, FC, forwardRef } from "react";

import { Icon } from "@/components/ui/icon";
import { useNavigate } from "react-router";
import { useSegment } from "../../hooks/use-segment";

export const SegmentMenuItemEdit: FC = forwardRef<HTMLDivElement, ComponentProps<"div">>((props, ref) => {
  const { segmentId } = useSegment();
  const navigate = useNavigate();

  return (
    <div ref={ref} {...props} onClickCapture={() => navigate(`/segments/${segmentId}/criteria`)}>
      <Icon icon="edit" className="text-sm" />
      <span>Edit segment</span>
    </div>
  );
});

SegmentMenuItemEdit.displayName = "SegmentMenuItemEdit";

import { Navigate, useParams } from "react-router";

import { useAccount } from "@/hooks/use-account";
import { Roles } from "src/generated/graphql";

export const BankanRedirect = () => {
  const { user } = useAccount();
  const { brokerId } = useParams();

  const { id, roles } = user;
  let path = "/bankan/broker";

  if (roles.includes(Roles.UnderwritingAssistant) && !roles.includes(Roles.Broker)) {
    path = `/bankan/sts`;
  } else if (roles.includes(Roles.Arm)) {
    path = `/bankan/arm/${brokerId ?? id}`;
  } else if (roles.includes(Roles.Broker)) {
    path = `/bankan/broker/${brokerId ?? id}`;
  }

  return <Navigate to={path} replace />;
};

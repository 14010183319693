import { useEffect } from "react";

import { useAlbyApproval } from "@/hooks/use-alby-approval";
import { useUpsertSearchParams } from "@/hooks/use-upsert-search-params";
import { SubmissionTemplateAttachments } from "@/submission-template/submission-template-attachments";
import { SubmissionTemplateForm } from "@/submission-template/submission-template-form";
import { SubmissionTemplatePdf } from "@/submission-template/submission-template-pdf";
import { SubmissionTemplateProvider, useSubmissionTemplate } from "@/submission-template/use-submission-template";
import { useSubmissionDetailsQuery } from "src/generated/graphql";
import { cn } from "src/utils";

export default function SubmissionTransitionGraphSubmitToCarrier() {
  const [searchParams] = useUpsertSearchParams();
  const submissionId = searchParams.get("submissionId");

  const { threadId } = useAlbyApproval();

  if (!submissionId) {
    return null;
  }

  return (
    <SubmissionTemplateProvider>
      <SubmitToCarrierForm id={submissionId} threadId={threadId} />
    </SubmissionTemplateProvider>
  );
}

const SubmitToCarrierForm = ({ id }: { id: string; threadId: string | null }) => {
  const { data: { submission } = {} } = useSubmissionDetailsQuery({ variables: { id } });
  const { selectedFile, setSubmissionId } = useSubmissionTemplate();

  useEffect(() => {
    setSubmissionId(id);
  }, [id]);

  if (!submission) {
    return null;
  }

  return (
    <div className={cn("flex-auto relative", selectedFile && gridClassName)}>
      <SubmissionTemplatePdf />
      <div className={cn(selectedFile ? "flex flex-col gap-6" : gridClassName)}>
        <SubmissionTemplateForm submission={submission} />
        <SubmissionTemplateAttachments />
      </div>
    </div>
  );
};

const gridClassName = "gap-6 grid grid-cols-[3fr_2fr] items-start";

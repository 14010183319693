import { groupBy, orderBy } from "lodash";

import { EmptyState } from "@/components/empty-state";
import { Grid, GridCell, GridRowHeader, GridRowLink } from "@/components/grid";
import { SectionHeader, SectionTitle } from "@/components/section";
import { StateIndicator } from "@/components/state";
import { useInsured } from "@/hooks/use-insured";
import { SetMetadata } from "@/hooks/use-metadata";
import { ExpiredPolicyState } from "@/metadata/policies";
import { PolicyState } from "src/generated/graphql";
import { formatDateString } from "src/utils/date";

const now = Date.now();

export const Policies = () => {
  const { policies, policiesLoading } = useInsured();

  const sortedPolicies = orderBy(policies, (policy) => Date.parse(policy.effectiveDate));
  const groupedPolicies = groupBy(sortedPolicies, (policy) => formatDateString(policy.effectiveDate, "yyyy"));

  return (
    <>
      <SetMetadata title="Policies" crumb />
      {policies[0] ? (
        Object.keys(groupedPolicies).map((date) => (
          <div key={date}>
            <SectionHeader>
              <SectionTitle>
                <h2>{date}</h2>
              </SectionTitle>
            </SectionHeader>
            <Grid className="grid-cols-5">
              <GridRowHeader>
                <GridCell>Carrier</GridCell>
                <GridCell>Product</GridCell>
                <GridCell>Status</GridCell>
                <GridCell>Eff. Date</GridCell>
                <GridCell>Exp. Date</GridCell>
              </GridRowHeader>
              {groupedPolicies[date].map((policy) => {
                const { id, effectiveDate, expirationDate, state, appetiteProduct } = policy;
                const expired = Date.parse(expirationDate) < now;
                return (
                  <GridRowLink key={id} to={id}>
                    <GridCell>{appetiteProduct.carrierName}</GridCell>
                    <GridCell>{appetiteProduct.carrierProductName}</GridCell>
                    <StateIndicator
                      state={expired && state == PolicyState.Issued ? ExpiredPolicyState.Expired : state}
                    />
                    {/* Remember: BindHQ dates do not include timestamps */}
                    <GridCell>{formatDateString(effectiveDate, "MMM d, yyyy")}</GridCell>
                    <GridCell>{formatDateString(expirationDate, "MMM d, yyyy")}</GridCell>
                  </GridRowLink>
                );
              })}
            </Grid>
          </div>
        ))
      ) : (
        <EmptyState loading={policiesLoading} title={<h2>No policies found.</h2>} />
      )}
    </>
  );
};

import { cn } from "src/utils";

export const Section = ({ children, className, ...rest }: React.HTMLAttributes<HTMLDivElement>) => (
  <section className={cn("bg-background", className)} {...rest}>
    {children}
  </section>
);

export const SectionHeader = ({ children, className, ...rest }: React.HTMLAttributes<HTMLDivElement>) => (
  <div className={cn("p-6 space-y-1.5", className)} {...rest}>
    {children}
  </div>
);

export const SectionTitle = ({ children, className, ...rest }: React.HTMLAttributes<HTMLDivElement>) => (
  <div className={cn("flex gap-4 items-center justify-between", className)} {...rest}>
    {children}
  </div>
);

export const SectionDescription = ({ children, className, ...rest }: React.HTMLAttributes<HTMLParagraphElement>) => (
  <p className={cn("text-muted-foreground text-sm", className)} {...rest}>
    {children}
  </p>
);

export const SectionContent = ({ children, className, ...rest }: React.HTMLAttributes<HTMLDivElement>) => (
  <div className={cn("p-6 pt-0", className)} {...rest}>
    {children}
  </div>
);

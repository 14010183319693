import { MaterialSymbol } from "material-symbols";
import { createContext, useContext, useEffect, useState } from "react";

export interface PageMetadata {
  title: string;
  icon?: MaterialSymbol;
  crumb?: boolean;
  menu?: React.ReactElement;
}

interface MetadataState {
  metadata: PageMetadata[];
  handleMetadata: (metadata: PageMetadata) => void;
}

const defaultMetadataState: MetadataState = {
  metadata: [{ title: "QuoteWell" }],
  handleMetadata: () => {},
};

const MetaData = createContext<MetadataState>(defaultMetadataState);

export function MetadataProvider({ children }: { children: React.ReactNode }) {
  const [metadata, setMetadata] = useState<PageMetadata[]>(defaultMetadataState.metadata);

  const pushMetadata = (page: PageMetadata) => setMetadata((pages) => [...pages, page]);
  const popMetadata = () => setMetadata((pages) => pages.slice(0, -1));

  const handleMetadata = (page: PageMetadata) => {
    pushMetadata(page);
    return () => popMetadata();
  };

  const value = {
    metadata,
    handleMetadata,
  };

  return <MetaData.Provider value={value}>{children}</MetaData.Provider>;
}

export const useMetadata = () => {
  const context = useContext(MetaData);

  if (context === undefined) {
    throw new Error("useMetadata must be used within a MetadataProvider");
  }

  return context;
};

export const SetMetadata = (metadata: PageMetadata) => {
  const { handleMetadata } = useMetadata();

  useEffect(() => handleMetadata(metadata), []);

  return null;
};

import { FC } from "react";

import { GridRowAction } from "@/components/grid";
import { ButtonProps } from "@/components/ui/button";
import { Icon } from "@/components/ui/icon";
import { RuleFragment } from "src/generated/graphql";

import { useDeleteSegmentAppetiteProductRule } from "../../hooks/use-delete-segment-appetite-product-rule";

export interface DeleteRuleButtonProps extends ButtonProps {
  rule: RuleFragment;
  segmentAppetiteProductId: string;
}

export const DeleteRuleButton: FC<DeleteRuleButtonProps> = ({ rule, segmentAppetiteProductId, children, ...props }) => {
  const { deleteRule } = useDeleteSegmentAppetiteProductRule();

  return (
    <GridRowAction label="Delete" {...props} onClick={() => deleteRule({ id: rule.id, segmentAppetiteProductId })}>
      {children || <Icon icon="delete" />}
    </GridRowAction>
  );
};

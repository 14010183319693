import { isDefined } from "@cp/toolkit";
import { first } from "lodash";
import { ReactNode, useState } from "react";
import { Link } from "react-router-dom";
import { z } from "zod";

import { ButtonGroup } from "@/components/button-group";
import { Group } from "@/components/group";
import { HasInternalRole } from "@/components/has-role";
import { Button } from "@/components/ui/button";
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogTrigger } from "@/components/ui/dialog";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { Icon } from "@/components/ui/icon";
import { ViewPdfDialog } from "@/files/view-pdf-dialog";
import { FieldTextarea } from "@/forms/fields/field-textarea";
import { Form } from "@/forms/form";
import { FormReset } from "@/forms/form-reset";
import { FormSubmit } from "@/forms/form-submit";
import {
  AgentActionType,
  FilesByLabelQuery,
  FileUploadFragment,
  OpportunityRequirementsQuery,
  SupplementalForm,
  useCreateAgentActionLogMutation,
  useOpportunityRequirementsQuery,
} from "src/generated/graphql";
import { cn } from "src/utils";
import { formatDate } from "src/utils/date";

type IAgentAction = NonNullable<
  NonNullable<ReturnType<typeof useOpportunityRequirementsQuery>["data"]>["opportunity"]
>["agentActions"][0];

export const AgentAction = ({
  action,
  opportunity,
  clientId,
  onCompleteAgentAction,
  onUncompleteAgentAction,
  onDeleteAgentAction,
}: {
  action: IAgentAction;
  opportunity: NonNullable<OpportunityRequirementsQuery["opportunity"]>;
  clientId: string;
  onCompleteAgentAction: (id: string) => void;
  onUncompleteAgentAction: (id: string) => void;
  onDeleteAgentAction: (id: string) => void;
}) => {
  return (
    <div className="flex gap-4 items-center justify-between px-6 py-3 text-foreground text-sm">
      <CompletionToggleDropdown
        action={action}
        onCompleteAgentAction={onCompleteAgentAction}
        onUncompleteAgentAction={onUncompleteAgentAction}
      >
        <Icon
          icon={
            action.complete
              ? "check_circle"
              : opportunity.filesProcessing && action.actionType === AgentActionType.UploadFile
              ? "cached"
              : "circle"
          }
          className={cn("filled", {
            "text-success": action.complete,
            "opacity-10": !action.complete,
            "animate-spin text-muted-foreground":
              opportunity.filesProcessing && !action.complete && action.actionType === AgentActionType.UploadFile,
          })}
        />
      </CompletionToggleDropdown>
      <AgentActionWrapper action={action} opportunityId={opportunity.id} clientId={clientId}>
        {action.title}
      </AgentActionWrapper>
      <AgentActionResponse action={action} onCompleteAgentAction={onCompleteAgentAction} />
      <AgentActionRelatedDocuments action={action} />
      {!action.complete && (
        <HasInternalRole>
          <Button
            variant="outline"
            size="xs"
            theme="destructive"
            display="icon"
            onClick={() => onDeleteAgentAction(action.id)}
          >
            <Icon icon="delete" />
          </Button>
        </HasInternalRole>
      )}
    </div>
  );
};

const AgentActionWrapper = ({
  action,
  opportunityId,
  clientId,
  children,
}: React.PropsWithChildren<{
  action: IAgentAction;
  opportunityId: string;
  clientId: string;
}>) => {
  if (action.supplementalForm) {
    const link = (() => {
      switch (action.supplementalForm) {
        case SupplementalForm.Daycare:
          return "/ategrity-daycare-supplemental.pdf";
        case SupplementalForm.ResidentialLro:
          return "/Please fill out if vacant - GenStar - LRO Supplemental.pdf";
        default:
          return `/supplementals/${clientId}?form=${action.supplementalForm}&opportunityId=${opportunityId}`;
      }
    })();

    return (
      <>
        {children}
        <Button variant="outline" size="xs" className="ml-auto" asChild>
          <Link to={link} target="_blank">
            Open Supplemental
            <Icon icon="open_in_new" className="text-muted-foreground" />
          </Link>
        </Button>
      </>
    );
  }

  return <div className="mr-auto">{children}</div>;
};

const AgentActionResponse = ({
  action,
  onCompleteAgentAction,
}: {
  onCompleteAgentAction: (id: string) => void;
  action: IAgentAction;
}) => {
  const [open, setOpen] = useState(false);
  const [addResponse] = useCreateAgentActionLogMutation();

  if (!action.logs) {
    return null;
  }

  if (action.actionType === AgentActionType.UploadFile) {
    return null;
  }

  const latestRepsonse = first(action.logs.filter((l) => l.response))?.response;

  const validationSchema = z.object({
    response: z.string().optional(),
  });

  const defaultValues = {
    response: "",
  };

  const handleSubmit = async (formData: z.infer<typeof validationSchema>) => {
    await addResponse({
      variables: {
        type: action.actionType,
        agentActionId: action.id,
        response: formData.response,
      },
      onCompleted: () => {
        onCompleteAgentAction(action.id);
      },
    });
  };

  if (!latestRepsonse) {
    return (
      <Dialog open={open} onOpenChange={setOpen}>
        <DialogTrigger asChild>
          <Button variant="outline" size="xs">
            Add Response
          </Button>
        </DialogTrigger>
        <DialogContent className="sm:max-w-md">
          <DialogHeader>
            <DialogTitle> Add Your Response</DialogTitle>
          </DialogHeader>
          <Form onSubmit={handleSubmit} defaultValues={defaultValues} validationSchema={validationSchema}>
            <Group>
              <FieldTextarea name="response" />
              <ButtonGroup>
                <FormReset onClick={() => setOpen(false)} />
                <FormSubmit />
              </ButtonGroup>
            </Group>
          </Form>
        </DialogContent>
      </Dialog>
    );
  }

  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button variant="outline" size="xs">
          View Response
        </Button>
      </DialogTrigger>
      <DialogContent className="max-h-(--portrait-max-height) overflow-auto text-xs">
        <DialogHeader>
          <DialogTitle>Response</DialogTitle>
        </DialogHeader>
        {latestRepsonse}
      </DialogContent>
    </Dialog>
  );
};

const AgentActionRelatedDocuments = ({ action }: { action: IAgentAction }) => {
  if (!action.logs) {
    return null;
  }

  const files = action.logs.flatMap((l) => l.file).filter((f) => isDefined(f) && f !== undefined) ?? [];

  if (files.length === 0) {
    return null;
  }

  const [selectedFile, setSelectedFile] = useState<FileUploadFragment | undefined>();

  return (
    <div className="flex gap-1">
      {files.map(
        (file) =>
          file && (
            <Button
              key={file.id}
              variant="outline"
              size="xs"
              onClick={() => {
                setSelectedFile(file);
              }}
            >
              <Icon icon="description" className="text-muted-foreground" />
              {formatDate(new Date(file?.createdAt), "L-dd-yy")}
            </Button>
          )
      )}
      <ViewPdfDialog
        file={selectedFile}
        files={files as FilesByLabelQuery["filesByLabel"]}
        setSelectedFile={setSelectedFile}
      />
    </div>
  );
};

interface CompletionToggleDropdownProps {
  children: ReactNode;
  action: IAgentAction;
  onCompleteAgentAction: (id: string) => void;
  onUncompleteAgentAction: (id: string) => void;
}

const CompletionToggleDropdown: React.FC<CompletionToggleDropdownProps> = ({
  children,
  action,
  onCompleteAgentAction,
  onUncompleteAgentAction,
}) => (
  <div className="flex items-center justify-center w-6">
    <HasInternalRole elseShow={children}>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button variant="ghost" size="xs" display="icon">
            {children}
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent>
          {action.complete ? (
            <DropdownMenuItem
              onClick={() => {
                onUncompleteAgentAction(action.id);
              }}
            >
              Mark as Incomplete
            </DropdownMenuItem>
          ) : (
            <DropdownMenuItem
              onClick={() => {
                onCompleteAgentAction(action.id);
              }}
            >
              Mark Complete
            </DropdownMenuItem>
          )}
        </DropdownMenuContent>
      </DropdownMenu>
    </HasInternalRole>
  </div>
);

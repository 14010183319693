import { ComponentProps, FC, ReactNode } from "react";

import { cn } from "src/utils";

export const FieldGrid: FC<ComponentProps<"div">> = ({ className, ...props }) => (
  <div {...props} className={cn("grid grid-cols-[140px_1fr] auto-rows-min gap-x-4 divide-y -my-6", className)} />
);

export interface FieldGridRowProps extends ComponentProps<"div"> {
  label: ReactNode;
}

export const FieldGridRow: FC<FieldGridRowProps> = ({ className, label, children, ...props }) => (
  <div {...props} className={cn("relative col-span-full grid grid-cols-subgrid py-6", className)}>
    <div>
      <h4 className="text-xs text-muted-foreground font-semibold sticky -top-2">{label}</h4>
    </div>
    <div>{children}</div>
  </div>
);

import { MutationFunctionOptions } from "@apollo/client";
import { useCallback } from "react";

import { useToast } from "@/hooks/use-toast";

import {
  UpdateSegmentAppetiteProductRuleInput,
  UpdateSegmentAppetiteProductRuleMutation,
  useUpdateSegmentAppetiteProductRuleMutation,
} from "src/generated/graphql";
import { useSegment } from "./use-segment";

export const useUpdateSegmentAppetiteProductRule = () => {
  const { segment } = useSegment();
  const { toast } = useToast();

  const [updateRuleMutation, { loading: isUpdatingRule }] = useUpdateSegmentAppetiteProductRuleMutation({
    awaitRefetchQueries: true,
    onCompleted: () => toast({ title: "Rule added" }),
    refetchQueries: ["Segment"],
  });

  const updateRule = useCallback(
    async (
      input: UpdateSegmentAppetiteProductRuleInput,
      options?: Omit<MutationFunctionOptions<UpdateSegmentAppetiteProductRuleMutation>, "variables">
    ) => await updateRuleMutation({ variables: { input }, ...options }),
    [segment?.id]
  );

  return {
    updateRule,
    isUpdatingRule,
  };
};

import { HasInternalRole } from "@/components/has-role";
import { ScrollPaneColumns } from "@/components/scroll-pane";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { SetMetadata } from "@/hooks/use-metadata";
import { Classification } from "./classification";
import { InsuredDetailsMenu } from "./insured-details-menu";
import { InsuredLinksCard } from "./insured-links-card";
import { InsuredSOV } from "./insured-sov";
import { InsuredSummary } from "./insured-summary";
import { SelectAgent } from "./select-agent";

export const InsuredDetails = () => (
  <>
    <SetMetadata title="Insured Details" crumb menu={<InsuredDetailsMenu />} />
    <div className="p-6 space-y-6">
      <InsuredLinksCard />
      <ScrollPaneColumns className="grid-cols-2">
        <InsuredSummary />
        <div className="space-y-6">
          <Classification />
          <HasInternalRole>
            <Card>
              <CardHeader>
                <CardTitle>Statement of Values</CardTitle>
              </CardHeader>
              <CardContent>
                <InsuredSOV />
              </CardContent>
            </Card>
            <Card>
              <CardHeader>
                <CardTitle>Select Agent</CardTitle>
              </CardHeader>
              <CardContent>
                <SelectAgent />
              </CardContent>
            </Card>
          </HasInternalRole>
        </div>
      </ScrollPaneColumns>
    </div>
  </>
);

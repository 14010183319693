import { ReactFlowProvider } from "@xyflow/react";
import { Outlet } from "react-router";

import { ScrollPane } from "@/components/scroll-pane";
import { SetMetadata } from "@/hooks/use-metadata";
import { FileProcessingPipelineGraphContainer } from "./file-processing-pipeline-graph/file-processing-pipeline-graph";
import { FileProcessingPipelineSidePanel } from "./file-processing-pipeline-side-panel";
import { FileProcessingPipelineProvider } from "./file-processing-pipeline.provider";

export const FileProcessingPipeline = () => (
  <>
    <SetMetadata title="File Processing Pipeline" />
    <ReactFlowProvider>
      <FileProcessingPipelineProvider>
        <ScrollPane className="flex-row">
          <div className="flex flex-auto items-center justify-center size-full">
            <FileProcessingPipelineGraphContainer />
          </div>
          <FileProcessingPipelineSidePanel>
            <Outlet />
          </FileProcessingPipelineSidePanel>
        </ScrollPane>
      </FileProcessingPipelineProvider>
    </ReactFlowProvider>
  </>
);

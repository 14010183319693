import { GridCell, GridRow } from "@/components/grid";
import { StateIcon } from "@/components/state";
import { SubmissionFragment } from "src/generated/graphql";
import { formatTimezoneDateString } from "src/utils/date";
import { SubmissionActions } from "./actions/submission-actions";

export const Submission = ({ submission }: { submission: SubmissionFragment }) => {
  const { appetiteProduct, state, stateTransitions, createdAt } = submission;
  const { carrierName, carrierProductName } = appetiteProduct;
  const lastTransition =
    stateTransitions.length > 0 ? stateTransitions[stateTransitions.length - 1] : { createdAt, details: "" };

  return (
    <GridRow className="bg-transparent">
      <StateIcon state={state} />
      <GridCell className="font-semibold">{carrierName}</GridCell>
      <GridCell>{carrierProductName}</GridCell>
      <GridCell>{formatTimezoneDateString(lastTransition.createdAt, "MMM d, yyyy")}</GridCell>
      <SubmissionActions submission={submission} details={lastTransition.details} />
    </GridRow>
  );
};

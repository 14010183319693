import { useVertical } from "./components/use-vertical";
import { TemplateRequirements } from "./template-requirements";

export const VerticalRequirements = () => {
  const { templates } = useVertical();
  return (
    <div className="divide-y">
      {templates.map((template) => (
        <div key={template.id} className="p-6">
          <TemplateRequirements template={template} />
        </div>
      ))}
    </div>
  );
};

import { getAuthRequestHeaders } from "@cp/auth";
import { KnownClientData } from "@qw/qw-common";
import { createContext, FC, MouseEventHandler, PropsWithChildren, useContext, useMemo, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useFormContext } from "react-hook-form";
import { DraggingOverlay } from "./dragging-overlay";

export type SetValuesFn = (keyVals: Array<[string, string | undefined]>) => void;

export interface LoadFromDocProviderProviderContextValue {
  dragging: boolean;
  loading: boolean;
  completed: boolean;
  fileName?: string;
  fileUrl?: string;
  reset: () => void;
  onClick?: MouseEventHandler<HTMLElement>;
  parentProps: Omit<ReturnType<ReturnType<typeof useDropzone>["getRootProps"]>, "onClick">;
}

export const LoadFromDocProviderProviderContext = createContext<LoadFromDocProviderProviderContextValue | null>(null);

export interface LoadFromDocProviderProviderProps {
  setFile?: (file: File) => void;
}

export const LoadFromDocProviderProvider: FC<PropsWithChildren<LoadFromDocProviderProviderProps>> = ({
  setFile,
  children,
  ...props
}) => {
  const [fileName, setFileName] = useState<string>();
  const [fileUrl, setFileUrl] = useState<string>();
  const [loading, setLoading] = useState(false);
  const [completed, setCompleted] = useState(false);

  const formMethods = useFormContext();

  const setValues: SetValuesFn = (keyVals) => {
    keyVals.forEach(([k, v]) => formMethods.setValue(k, v, { shouldValidate: true }));
  };

  const onFileDropped = async (_acceptedFiles: File[]) => {
    setLoading(true);

    const rawAcord125 = new Uint8Array(await _acceptedFiles[0].arrayBuffer()).reduce(
      (acc, char) => acc + String.fromCharCode(char),
      ""
    );

    setFileName(_acceptedFiles[0].name);
    setFileUrl(`data:application/pdf;base64,${btoa(rawAcord125)}`);

    const fd = new FormData();
    fd.set("file", _acceptedFiles[0]);
    const res = await fetch("/api/file-processing/process-acord-125", {
      method: "POST",
      headers: getAuthRequestHeaders(),
      body: fd,
    });

    const json = await res.json();

    const map = new Map<string, string>(
      json.fields.map(({ key, value }: { key: string; value: string }) => [key, value])
    );

    if (setFile) {
      setFile(_acceptedFiles[0]);
    }

    setValues &&
      setValues([
        ["clientName", map.get(KnownClientData.FirstNamedInsured)],
        ["desiredEffectiveDate", map.get(KnownClientData.ProposedEffectiveDate)],
        ["state", map.get("Premises_Information:State")],
        ["revenue", map.get("Premises_Information:Annual_Revenues")],
        ["description", map.get(KnownClientData.DescriptionOfOperations)],
      ]);

    setLoading(false);
    setCompleted(true);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop: onFileDropped });
  const { onClick, ...parentProps } = getRootProps();

  const reset = () => {
    setCompleted(false);
    setValues &&
      setValues([
        ["clientName", undefined],
        ["desiredEffectiveDate", undefined],
        ["state", undefined],
        ["description", undefined],
      ]);
  };

  const value = useMemo(
    () => ({
      dragging: isDragActive,
      loading,
      completed,
      fileName,
      fileUrl,
      reset,
      onClick,
      parentProps,
    }),
    [isDragActive, loading, completed, fileName, fileUrl, reset, onClick, parentProps]
  );

  return (
    <LoadFromDocProviderProviderContext.Provider value={value} {...props}>
      {children}
      <DraggingOverlay />
      <input {...getInputProps()} />
    </LoadFromDocProviderProviderContext.Provider>
  );
};

export const useLoadFromDoc = () => {
  const context = useContext(LoadFromDocProviderProviderContext);

  if (!context) {
    throw new Error("useLoadFromDoc must be used within a LoadFromDocProviderProvider");
  }

  return context;
};

import { useVertical } from "./components/use-vertical";
import { TemplateRules } from "./template-rules";

export const VerticalRules = () => {
  const { templates } = useVertical();
  return (
    <div className="divide-y">
      {templates.map((template) => (
        <div key={template.id} className="p-6">
          <TemplateRules template={template} />
        </div>
      ))}
    </div>
  );
};

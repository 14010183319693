import { StateCard } from "@/components/state";
import { Separator } from "@/components/ui/separator";
import { useQuote } from "@/hooks/use-quote";
import { AGENT_MESSAGE_METADATA } from "@/metadata/states";
import { formatDateString, formatTimezoneDateString } from "src/utils/date";
import { formatDollarsAndCents } from "src/utils/strings";

export const QuoteInfo = () => {
  const { quote } = useQuote();

  const {
    premium,
    mep,
    agentCommission,
    carrierFee,
    brokerFee,
    inspectionFee,
    effectiveDate,
    expirationDate,
    state,
    stateTransitions,
    createdAt,
  } = quote;

  const stateProps = AGENT_MESSAGE_METADATA[state];
  const lastTransition = stateTransitions.length > 0 ? stateTransitions[stateTransitions.length - 1] : { createdAt };

  return (
    <StateCard {...stateProps}>
      <Separator />
      <div className="gap-6 grid grid-cols-3 p-6">
        <dl>
          <dt>Premium</dt>
          <dd>{premium && formatDollarsAndCents(premium)}</dd>
        </dl>
        <dl>
          <dt>MEP</dt>
          <dd>{mep ?? "0"}%</dd>
        </dl>
        <dl>
          <dt>Commission</dt>
          <dd>{agentCommission}%</dd>
        </dl>
        <dl>
          <dt>Carrier Fee</dt>
          <dd>{carrierFee && formatDollarsAndCents(carrierFee)}</dd>
        </dl>
        <dl>
          <dt>Broker Fee</dt>
          <dd>{brokerFee && formatDollarsAndCents(brokerFee)}</dd>
        </dl>
        <dl>
          <dt>Inspection Fee</dt>
          <dd>{inspectionFee && formatDollarsAndCents(inspectionFee)}</dd>
        </dl>
        <dl>
          <dt>Effective Date</dt>
          <dd>{effectiveDate ? formatDateString(effectiveDate, "MMMM d, yyyy") : "Effective date not set"}</dd>
        </dl>
        <dl>
          <dt>Expiration Date</dt>
          <dd>{expirationDate ? formatDateString(expirationDate, "MMMM d, yyyy") : "Expiration date not set"}</dd>
        </dl>
        <dl>
          <dt>Date Created</dt>
          <dd>{formatTimezoneDateString(createdAt, "MMMM d, yyyy")}</dd>
        </dl>
        <dl>
          <dt>Date Updated</dt>
          <dd>{formatTimezoneDateString(lastTransition.createdAt, "MMMM d, yyyy")}</dd>
        </dl>
      </div>
    </StateCard>
  );
};

import { z } from "zod";

import { Group } from "@/components/group";
import { useModal } from "@/components/modal-provider";
import { Button } from "@/components/ui/button";
import { FieldSelect } from "@/forms/fields/field-select";
import { FieldTextarea } from "@/forms/fields/field-textarea";
import { QUOTE_STATE_METADATA } from "@/metadata/quotes";
import {
  QuoteAction,
  QuoteFragment,
  useSendBindRequestMutation,
  useTransitionQuoteMutation,
} from "src/generated/graphql";

import { BindHQProducerAlert } from "../../components/bindhq-producer-alert";

export const BindRequestReceivedButton = ({ quote }: { quote: QuoteFragment }) => {
  const { openConfirmation, openForm } = useModal();
  const [transition] = useTransitionQuoteMutation();
  const [sendBindRequestTrigger] = useSendBindRequestMutation();

  const handleTransition = async (action: QuoteAction) => {
    const isConfirmed = await openConfirmation({
      title: `Confirm ${QUOTE_STATE_METADATA[quote?.state].actionDisplayNames?.[action] ?? action}`,
    });

    if (!isConfirmed) {
      return;
    }

    await transition({
      variables: {
        input: {
          id: quote.id,
          expectedState: quote.state,
          action,
        },
      },
    });
  };

  const sendBindRequest = async () =>
    await openForm(<SendBindRequestForm quote={quote} />, {
      title: "Send Bind Request",
      defaultValues: { tria: "", outstandingSubjectivities: "", carrierContactInformation: "" },
      validationSchema: z.object({
        tria: z.string().min(1, { message: "TRIA selection is required" }),
        outstandingSubjectivities: z.string().min(1, { message: "Outstanding subjectivities is required" }),
        carrierContactInformation: z.string().optional(),
      }),
      onSubmit: async (fd) =>
        await sendBindRequestTrigger({
          variables: {
            input: {
              quoteId: quote.id,
              tria: fd.tria,
              outstandingSubjectivities: fd.outstandingSubjectivities,
              carrierContactInformation: fd.carrierContactInformation,
            },
          },
          onCompleted: () => handleTransition(QuoteAction.ReceiveBindRequest),
        }),
    });

  return (
    <Button onClick={sendBindRequest} variant="outline" size="sm" display="flex">
      Bind Request Received
    </Button>
  );
};

const SendBindRequestForm = ({ quote }: { quote: QuoteFragment }) => {
  const { closeForm } = useModal();

  return (
    <Group>
      <BindHQProducerAlert
        bindHqProducer={quote.submission.opportunity.bindHqProducer}
        insuredId={quote.insuredId}
        opportunityId={quote.submission.opportunityId}
        onReassignUserClick={closeForm}
      />

      <FieldSelect
        name="tria"
        placeholder="TRIA - Accepted / Rejected / Included / N/A"
        options={[
          { label: "Accepted", value: "Accepted" },
          { label: "Rejected", value: "Rejected" },
          { label: "Included", value: "Included" },
          { label: "N/A", value: "N/A" },
        ]}
      />

      <FieldTextarea
        name="outstandingSubjectivities"
        placeholder="Outstanding subjectivities (enter N/A if none apply)"
      />

      <FieldTextarea name="carrierContactInformation" placeholder="Carrier contact (if brokerage)" />
    </Group>
  );
};

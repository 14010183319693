import { HasInternalRole } from "@/components/has-role";
import { Button } from "@/components/ui/button";
import { Icon } from "@/components/ui/icon";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover";
import {
  AppetiteGuidesForOpportunityQuery,
  QuoteSubmissionFragment,
  SubmissionFragment,
  SubmissionState,
} from "src/generated/graphql";
import { Declined } from "./declined";
import { MarketBlocked } from "./market-blocked";
import { Pending } from "./pending";
import { ReadyToSubmit } from "./ready-to-submit";
import { Referred } from "./referred";
import { RuledOut } from "./ruled-out";
import { SubmissionMenu } from "./submission-menu";
import { SubmissionQuotes } from "./submission-quotes";
import { Submitted } from "./submitted";
import { UploadQuote } from "./upload-quote";

export const SubmissionActions = ({
  submission,
  details,
}: {
  submission: SubmissionFragment;
  details?: string | null;
}) => {
  const { state } = submission;

  const renderSubmissionState = () => {
    switch (state) {
      case SubmissionState.Pending:
        return <Pending submission={submission} />;
      case SubmissionState.ReadyToSubmit:
        return <ReadyToSubmit submission={submission} />;
      case SubmissionState.Submitted:
        return <Submitted submission={submission} />;
      case SubmissionState.RuledOut:
        return <RuledOut submission={submission} />;
      case SubmissionState.Referred:
        return <Referred submission={submission} />;
      case SubmissionState.MarketBlocked:
        return <MarketBlocked submission={submission} />;
      case SubmissionState.Declined:
        return <Declined submission={submission} />;
      default:
        return <SubmissionMenu submission={submission} />;
    }
  };

  return (
    <div className="flex gap-2 items-center justify-end">
      <SubmissionQuotes submission={submission} />
      {details && (
        <Popover>
          <PopoverTrigger asChild>
            <Button variant="ghost" size="xs" display="icon">
              <Icon icon="help" />
            </Button>
          </PopoverTrigger>
          <PopoverContent className="text-xs">{details}</PopoverContent>
        </Popover>
      )}
      <HasInternalRole>
        <UploadQuote submission={submission} />
        {renderSubmissionState()}
      </HasInternalRole>
    </div>
  );
};

export type MarketingEffortSubmission = SubmissionFragment &
  QuoteSubmissionFragment & {
    appetiteGuide?: AppetiteGuidesForOpportunityQuery["appetiteGuidesForOpportunity"][number];
  };

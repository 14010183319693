import { SetMetadata } from "@/hooks/use-metadata";
import { BrokerFace } from "./brokerface";

export const PageNotFound = () => (
  <>
    <SetMetadata title="Page Not Found" />
    <div className="p-6 space-y-6">
      <h1 className="text-3xl">We couldn&apos;t find that resource.</h1>
      <BrokerFace />
    </div>
  </>
);

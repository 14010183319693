import { authDataAtom, useLogout, useStopImpersonatingUser } from "@cp/auth";
import { useAtom } from "jotai";
import { MaterialSymbol } from "material-symbols";
import { forwardRef, PropsWithChildren } from "react";
import { NavLink as Link, NavLinkProps } from "react-router-dom";
import { useMediaQuery } from "usehooks-ts";

import { useAccount } from "@/hooks/use-account";
import { Roles } from "src/generated/graphql";
import { cn } from "src/utils";
import { SelectedTheme, useTheme } from "../hooks/use-theme";
import { HasExternalRole, HasInternalRole, HasRole } from "./has-role";
import { MoreMenu } from "./more-menu";
import { Search } from "./search";
import { Avatar } from "./ui/avatar";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuRadioGroup,
  DropdownMenuRadioItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "./ui/dropdown-menu";
import { Icon } from "./ui/icon";
import { QuoteWellLogo } from "./ui/quotewell-logo";
import { Tooltip, TooltipContent, TooltipTrigger } from "./ui/tooltip";

export const AppNav = () => {
  const [authData] = useAtom(authDataAtom);
  const { mutate: logout } = useLogout();
  const { user, impersonatorEmail } = useAccount();
  const { theme, selectedTheme, setSelectedTheme } = useTheme();
  const { mutate: stopImpersonatingUser } = useStopImpersonatingUser();

  return (
    <nav
      className={cn(
        "bottom-0 fixed flex flex-none justify-center left-0 overflow-auto z-30",
        "landscape:flex-col landscape:h-dvh landscape:w-16",
        "portrait:bg-background portrait:h-(--nav-height) portrait:w-full portrait:shadow-xl"
      )}
      id="Sidebar"
    >
      <div className="aspect-square flex items-center justify-center">
        <QuoteWellLogo className={cn("size-7 md:size-9", { invert: theme === "dark" })} />
      </div>
      <div
        className={cn(
          "flex flex-auto items-center gap-2",
          "landscape:flex-col landscape:justify-start",
          "portrait:gap-1 portrait:justify-center"
        )}
      >
        <Search />
        <NavItem to="/insured/new" icon="add_circle" label="New Insured" className="filled" />
        <HasInternalRole>
          <NavItem to="/bankan" icon="view_kanban" label="Bankan" />
          <NavItem to="/segments" icon="category" label="Segments" />
          <HasRole roles={[Roles.Arm]}>
            <NavItem to="/launch" icon="rocket_launch" label="Launch" />
          </HasRole>
          <HasRole
            roles={[Roles.Admin]}
            elseShow={<NavItem to="/supplementals" icon="description" label="Supplementals" />}
          >
            <NavItem to="/file-processing-pipeline" icon="folder_data" label="File Processing Pipeline" />
            <NavItem to="/label-management" icon="label" label="Label Management" />
            <MoreMenu>
              <DropdownMenuItem asChild>
                <Link to="/launch">
                  <Icon icon="rocket_launch" />
                  Launch
                </Link>
              </DropdownMenuItem>
              <DropdownMenuItem asChild>
                <Link to="/supplementals">
                  <Icon icon="draft" />
                  Supplementals
                </Link>
              </DropdownMenuItem>
              <DropdownMenuItem asChild>
                <Link to="/verticals">
                  <Icon icon="layers" />
                  Verticals
                </Link>
              </DropdownMenuItem>
              <DropdownMenuItem asChild>
                <Link to="/admin">
                  <Icon icon="token" />
                  Admin
                </Link>
              </DropdownMenuItem>
            </MoreMenu>
          </HasRole>
        </HasInternalRole>
        <HasExternalRole>
          <NavItem to="/agency" icon="home" label="Home" />
          <NavItem
            to="https://quotewell.notion.site/Help-Center-eedceb626b79430386d913e33f9e8428"
            icon="help"
            label="Help Center"
            target="_blank"
          />
          <NavItem
            to="mailto:support@quotewell.com?subject=QuoteWell+Help"
            icon="mail"
            label="Email QuoteWell Technical Support"
            target="_blank"
          />
        </HasExternalRole>
      </div>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <div className="aspect-square cursor-pointer flex items-center justify-center" id={user.id}>
            <Avatar user={user} impersonated={Boolean(impersonatorEmail)} className="max-md:text-xs" />
          </div>
        </DropdownMenuTrigger>
        <DropdownMenuContent className="min-w-48">
          <DropdownMenuLabel className="items-start">
            <Icon icon="account_circle" className="mt-0.5" />
            <div className="space-y-1.5">
              <div>
                {user.firstName} {user.lastName}
              </div>
              <div className="font-normal text-2xs text-muted-foreground">{user.agency.name}</div>
            </div>
          </DropdownMenuLabel>
          <DropdownMenuSeparator />
          <DropdownMenuLabel>
            <Icon icon="display_settings" />
            Appearance
          </DropdownMenuLabel>
          <DropdownMenuRadioGroup value={selectedTheme} onValueChange={(v) => setSelectedTheme(v as SelectedTheme)}>
            <DropdownMenuRadioItem value="light">Light Mode</DropdownMenuRadioItem>
            <DropdownMenuRadioItem value="dark">Dark Mode</DropdownMenuRadioItem>
            <HasInternalRole>
              <DropdownMenuRadioItem value="beef">Barbie Mode</DropdownMenuRadioItem>
            </HasInternalRole>
            <DropdownMenuRadioItem value="system">System Setting</DropdownMenuRadioItem>
          </DropdownMenuRadioGroup>
          <DropdownMenuSeparator />
          {impersonatorEmail ? (
            <DropdownMenuItem
              onClick={() => {
                stopImpersonatingUser(
                  { impersonatorEmail },
                  { onSuccess: () => window.location.replace(window.location.origin) }
                );
              }}
            >
              <Icon icon="logout" />
              Stop Impersonating
            </DropdownMenuItem>
          ) : (
            <DropdownMenuItem
              onClick={() => {
                if (authData) {
                  logout(
                    { refreshToken: authData.token.refreshToken ?? authData.token.accessToken },
                    { onSuccess: () => window.location.replace("/") }
                  );
                }
              }}
            >
              <Icon icon="login" />
              Sign Out
            </DropdownMenuItem>
          )}
        </DropdownMenuContent>
      </DropdownMenu>
    </nav>
  );
};

interface NavItemProps extends NavLinkProps {
  to: string;
  label: string;
  icon: MaterialSymbol;
  unread?: number;
  className?: string;
}

const NavItem: React.FC<PropsWithChildren<NavItemProps>> = (props) => {
  const portrait = useMediaQuery("(orientation: portrait)");

  return (
    <Tooltip delayDuration={167}>
      <TooltipTrigger asChild>
        <NavLink {...props} />
      </TooltipTrigger>
      <TooltipContent
        side={portrait ? "top" : "right"}
        className={cn(
          "bg-foreground text-background flex font-semibold items-center h-8 pointer-events-none px-3 rounded-md shadow-none text-xs",
          "portrait:-mb-2"
        )}
      >
        {props.label}
      </TooltipContent>
    </Tooltip>
  );
};

const NavLink = forwardRef<HTMLAnchorElement, NavItemProps>(
  ({ to, label, icon, unread = 0, className, ...props }, ref) => {
    const { theme } = useTheme();

    return (
      <Link
        ref={ref}
        className={({ isActive, isPending }: { isActive: boolean; isPending: boolean }) =>
          cn(
            buttonClassName,
            "group",
            {
              "text-primary filled": isActive,
              "text-success": isActive && theme === "beef",
              "text-muted-foreground hover:text-foreground": !isActive,
              "opacity-50": isPending,
            },
            className
          )
        }
        to={to}
        aria-label={label}
        {...props}
      >
        <Icon icon={icon} className={"group-aria-[current=page]:text-primary"} />
        {unread > 0 && (
          <div className="absolute bg-destructive items-center justify-center right-2.5 ring-2 ring-background rounded-full size-1.5 top-2.5 translate-x-1/3" />
        )}
      </Link>
    );
  }
);
NavLink.displayName = "NavLink";

export const buttonClassName =
  "cursor-pointer flex items-center justify-center p-0 relative rounded size-8 md:size-10 text-lg md:text-xl transition-colors transition-opacity";

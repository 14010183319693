import { FC, useMemo } from "react";

import { FieldCombobox, FieldComboboxProps } from "@/forms/fields/field-combobox";
import { useLinesOfBusinessQuery } from "src/generated/graphql";

export interface LinesOfBusinessSelectorProps extends Omit<FieldComboboxProps, "options"> {}

export const LinesOfBusinessSelector: FC<LinesOfBusinessSelectorProps> = (props) => {
  const { data } = useLinesOfBusinessQuery();

  const linesOfBusiness = useMemo(
    () =>
      (
        data?.linesOfBusiness?.map((lineOfBusiness) => ({
          label: lineOfBusiness.displayName,
          value: lineOfBusiness.displayName,
        })) || []
      ).sort((a, b) => a.label.localeCompare(b.label)),
    [data]
  );

  return <FieldCombobox label="Lines of business" {...props} options={linesOfBusiness} />;
};

import { DropdownMenuItem, DropdownMenuLabel } from "@/components/ui/dropdown-menu";
import { Icon } from "@/components/ui/icon";
import { SnoozableMarketingPlan, useBankan } from "@/hooks/use-bankan";
import { formatTimezoneDateString } from "src/utils/date";

export const SnoozeButton = ({ marketingPlan }: { marketingPlan: SnoozableMarketingPlan }) => {
  const { setSnoozableMarketingPlan } = useBankan();
  const { snoozeUntil } = marketingPlan;

  return snoozeUntil ? (
    <DropdownMenuLabel>
      <Icon icon="bedtime" />
      Snoozed until {formatTimezoneDateString(snoozeUntil, "MMMM d")}
    </DropdownMenuLabel>
  ) : (
    <DropdownMenuItem onClick={() => setSnoozableMarketingPlan(marketingPlan)}>
      <Icon icon="notifications_paused" />
      Snooze this Marketing Plan
    </DropdownMenuItem>
  );
};

import { FC } from "react";
import { z } from "zod";

import { Group } from "@/components/group";
import { FieldCombobox } from "@/forms/fields/field-combobox";
import { FieldHidden } from "@/forms/fields/field-hidden";
import { FieldInput } from "@/forms/fields/field-input";
import { FieldTextarea } from "@/forms/fields/field-textarea";
import { AgentActionType, useKnownDocumentSplitterLabelsQuery } from "src/generated/graphql";

export const CreateSegmentRequirementFormSchema = z.object({
  actionType: z.nativeEnum(AgentActionType, { message: "Action type is required" }),
  title: z.string().min(1, { message: "Title is required" }),
  description: z.string().nullable(),
  label: z.string().min(1, { message: "Label is required" }),
});

export const UpdateSegmentRequirementFormSchema = CreateSegmentRequirementFormSchema.extend({
  id: z.string().min(1, { message: "ID is required" }),
});

export const CreateSegmentRequirementFormDefaultValues = {
  segmentId: "",
  actionType: AgentActionType.UploadFile,
  title: "",
  description: "",
  label: "",
};

export const UpdateSegmentRequirementFormDefaultValues = {
  ...CreateSegmentRequirementFormDefaultValues,
  id: "",
};

export const SegmentRequirementFormFields: FC = () => (
  <Group>
    <FieldHidden name="id" />
    <FieldHidden name="segmentId" />
    {/* NOTE: There is only one type (`UploadFile`) available for requirements right now.
    That being the case, the user should not be able to select the action type. */}
    {/* <FieldInput label="Action type" name="actionType" readOnly /> */}
    <FieldInput label="Title" name="title" />
    <KnownDocumentSplitterLabelSelector />
    <FieldTextarea label="Description" name="description" optional />
  </Group>
);

export const KnownDocumentSplitterLabelSelector: FC = () => {
  const { data, loading } = useKnownDocumentSplitterLabelsQuery();

  const options = data?.knownDocumentSplitterLabels.map((label) => ({ label, value: label })) ?? [];

  return <FieldCombobox label="Label" name="label" options={options} loading={loading} />;
};

import { createContext, FC, PropsWithChildren, useContext, useEffect, useMemo, useState } from "react";
import { useLocalStorage } from "usehooks-ts";

import { Bar } from "@/components/ui/bar";
import { Button } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuRadioGroup,
  DropdownMenuRadioItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { Icon } from "@/components/ui/icon";
import { cn } from "src/utils";

export interface PaginationState {
  numPages: number;
  numRecords: number;
  setNumRecords: React.Dispatch<React.SetStateAction<number>>;
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  rowsPerPage: string;
  setRowsPerPage: React.Dispatch<React.SetStateAction<string>>;
}

const initialState: PaginationState = {
  numPages: 1,
  numRecords: 0,
  setNumRecords: () => null,
  page: 1,
  setPage: () => null,
  rowsPerPage: "50",
  setRowsPerPage: () => null,
};

const PaginationContext = createContext<PaginationState>(initialState);

export interface PaginationProviderProps {
  numRecords?: number;
  page?: number;
  rowsPerPage?: string;
  storageKeyPrefix?: string;
  shouldResetPageOnNumRecordsChange?: boolean;
  shouldResetPageOnRowsPerPageChange?: boolean;
}

export function PaginationProvider({ children, ...init }: PropsWithChildren<PaginationProviderProps>) {
  const [numRecords, setNumRecords] = useState(init?.numRecords || initialState.numRecords);
  const [page, setPage] = useState(init.page || initialState.page);
  const [rowsPerPage, setRowsPerPage] = useLocalStorage(
    `${init?.storageKeyPrefix ? `_${init?.storageKeyPrefix}` : ""}_paginationRowsPerPage`,
    init.rowsPerPage || initialState.rowsPerPage
  );

  useEffect(() => {
    setNumRecords(init?.numRecords || initialState.numRecords);
  }, [init?.numRecords]);

  useEffect(() => {
    // If the number of records is less than the first record on the current page, reset the page to the last page.
    // This is to prevent the user from being on a page that no longer exists (i.e. if the number of records is
    // reduced as a result of a user deleting a record).
    if (numRecords && numRecords < page * Number(rowsPerPage) - (Number(rowsPerPage) - 1)) {
      setPage(Math.floor(Math.round(numRecords / Number(rowsPerPage))));
    }
  }, [numRecords, page, rowsPerPage]);

  useEffect(() => {
    setPage(init?.page || initialState.page);
  }, [init?.page]);

  useEffect(() => {
    if (init?.shouldResetPageOnNumRecordsChange) {
      setPage(1);
    }
  }, [numRecords, init?.shouldResetPageOnNumRecordsChange]);

  useEffect(() => {
    if (init?.shouldResetPageOnRowsPerPageChange) {
      setPage(1);
    }
  }, [rowsPerPage, init?.shouldResetPageOnRowsPerPageChange]);

  const numPages = useMemo(() => Math.ceil(numRecords / Number(rowsPerPage)), [numRecords, rowsPerPage]);

  const value = useMemo(
    () => ({
      numPages: numPages || 1,
      numRecords,
      setNumRecords,
      page,
      setPage,
      rowsPerPage,
      setRowsPerPage,
    }),
    [numPages, numRecords, setNumRecords, page, setPage, rowsPerPage, setRowsPerPage]
  );

  return <PaginationContext.Provider value={value}>{children}</PaginationContext.Provider>;
}

export const usePagination = () => {
  const context = useContext(PaginationContext);

  if (context === undefined) {
    throw new Error("usePagination must be used within a PaginationProvider");
  }

  return context;
};

export interface PaginationProps {
  label?: string;
  className?: string;
  rowsPerPageOptions?: string[];
}

export const Pagination: FC<PaginationProps> = ({
  label = "rows",
  className,
  rowsPerPageOptions = ["50", "100", "250", "500"],
}) => {
  const { numPages, numRecords, page, setPage, rowsPerPage, setRowsPerPage } = usePagination();

  const isDisabled = numPages === 1;
  const isPrevDisabled = isDisabled || page === 1;
  const isNextDisabled = isDisabled || page === numPages;

  return (
    <Bar as="footer" className={cn("gap-1 justify-center mt-auto text-muted-foreground text-xs", className)}>
      <div className="absolute flex items-center inset-0 right-auto ml-6 my-auto">
        {Intl.NumberFormat("en-us").format(numRecords)} {label}
      </div>

      <Button variant="ghost" size="xs" display="icon" disabled={isPrevDisabled} onClick={() => setPage(1)}>
        <Icon icon="first_page" />
      </Button>
      <Button
        variant="ghost"
        size="xs"
        display="icon"
        disabled={isPrevDisabled}
        onClick={() => setPage((page) => (page > 1 ? page - 1 : 1))}
      >
        <Icon icon="chevron_left" />
      </Button>
      <span className="flex-none tabular-nums text-center min-w-24">
        Page {Intl.NumberFormat("en-us").format(page)} of {Intl.NumberFormat("en-us").format(numPages)}
      </span>
      <Button
        variant="ghost"
        size="xs"
        display="icon"
        disabled={isNextDisabled}
        onClick={() => setPage((page) => (page < numPages ? page + 1 : numPages))}
      >
        <Icon icon="chevron_right" />
      </Button>
      <Button variant="ghost" size="xs" display="icon" disabled={isNextDisabled} onClick={() => setPage(numPages)}>
        <Icon icon="last_page" />
      </Button>

      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button
            variant="ghost"
            size="xs"
            className="absolute flex items-center inset-0 left-auto mr-2.5 my-auto text-xs"
          >
            {rowsPerPage}
            <Icon icon="keyboard_arrow_down" />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent>
          <DropdownMenuLabel>Rows per page</DropdownMenuLabel>
          <DropdownMenuSeparator />
          <DropdownMenuRadioGroup
            value={rowsPerPage}
            onValueChange={(value) => {
              setRowsPerPage(value);
              setPage(1);
            }}
          >
            {rowsPerPageOptions.map((option) => (
              <DropdownMenuRadioItem key={option} value={option}>
                {option}
              </DropdownMenuRadioItem>
            ))}
          </DropdownMenuRadioGroup>
        </DropdownMenuContent>
      </DropdownMenu>
    </Bar>
  );
};

import { PrimaryLabelDataType } from "src/generated/graphql";

export const PrimaryLabelDataTypeLabel: Record<PrimaryLabelDataType, string> = {
  boolean: "Boolean",
  date: "Date",
  number: "Number",
  percent: "Percent",
  string: "String",
};

export const PrimaryLabelDataTypeOptions = [
  {
    label: PrimaryLabelDataTypeLabel[PrimaryLabelDataType.Boolean],
    value: PrimaryLabelDataType.Boolean,
  },
  {
    label: PrimaryLabelDataTypeLabel[PrimaryLabelDataType.Date],
    value: PrimaryLabelDataType.Date,
  },
  {
    label: PrimaryLabelDataTypeLabel[PrimaryLabelDataType.Number],
    value: PrimaryLabelDataType.Number,
  },
  {
    label: PrimaryLabelDataTypeLabel[PrimaryLabelDataType.Percent],
    value: PrimaryLabelDataType.Percent,
  },
  {
    label: PrimaryLabelDataTypeLabel[PrimaryLabelDataType.String],
    value: PrimaryLabelDataType.String,
  },
];

import { Slot } from "@radix-ui/react-slot";
import { cva, VariantProps } from "class-variance-authority";
import { forwardRef, HTMLAttributes } from "react";

import { cn } from "src/utils";

const groupVariants = cva("gap-4", {
  variants: {
    type: {
      flex: "flex",
      grid: "grid",
    },
    direction: {
      row: "",
      col: "",
    },
    display: {
      inline: "",
    },
  },
  compoundVariants: [
    {
      type: "grid",
      direction: "row",
      className: "grid-flow-col auto-cols-auto",
    },
    {
      type: "grid",
      direction: "col",
      className: "grid-flow-row auto-rows-auto",
    },
    {
      type: "flex",
      direction: "col",
      className: "flex-col",
    },
    {
      type: "flex",
      direction: "row",
      className: "flex-row",
    },
    {
      type: "flex",
      display: "inline",
      className: "inline-flex",
    },
    {
      type: "grid",
      display: "inline",
      className: "inline-grid",
    },
  ],
  defaultVariants: {
    type: "grid",
    direction: "col",
  },
});

export interface GroupProps extends HTMLAttributes<HTMLDivElement>, VariantProps<typeof groupVariants> {
  asChild?: boolean;
}

export const Group = forwardRef<HTMLDivElement, GroupProps>(
  ({ type, direction, display, className, asChild, ...props }, ref) => {
    const Comp = asChild ? Slot : "div";
    return <Comp className={cn(groupVariants({ type, direction, display, className }))} ref={ref} {...props} />;
  }
);

Group.displayName = "Group";

import { STRICT_STATE_OPTIONS } from "@cp/toolkit";
import { FC } from "react";

import { FieldInput } from "@/forms/fields/field-input";
import { FieldNumber } from "@/forms/fields/field-number";
import { FieldRow } from "@/forms/fields/field-row";
import { FieldSelect } from "@/forms/fields/field-select";
import { FieldTextarea } from "@/forms/fields/field-textarea";

import { useLoadFromDoc } from "./use-load-from-doc";

export const InsuredForm: FC = () => {
  const { parentProps, onClick, ...docUploadMethods } = useLoadFromDoc();

  const uploadInProgress = docUploadMethods.loading;

  return (
    <div {...parentProps} className="flex flex-col divide-y">
      <FieldRow icon="business_center">
        <FieldInput
          name="clientName"
          placeholder={uploadInProgress ? "Loading..." : "Insured Name (This is how it will appear on the Policy)"}
          disabled={uploadInProgress}
          borderless
        />
      </FieldRow>

      <FieldRow icon="location_on">
        <FieldSelect
          name="state"
          placeholder={uploadInProgress ? "Loading..." : "Primary state"}
          options={STRICT_STATE_OPTIONS}
          disabled={uploadInProgress}
          borderless
        />
      </FieldRow>

      <FieldRow icon="attach_money">
        <FieldNumber
          name="revenue"
          placeholder="Gross revenue"
          inputProps={{ prefix: "$", thousandSeparator: true, decimalScale: 2 }}
          borderless
        />
      </FieldRow>

      <FieldRow icon="storefront">
        <FieldTextarea
          name="description"
          placeholder={uploadInProgress ? "Loading..." : "Business Description"}
          disabled={uploadInProgress}
          borderless
          inputProps={{ className: "resize-none" }}
        />
      </FieldRow>
    </div>
  );
};

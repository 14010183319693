import { Icon } from "@/components/ui/icon";
import { Spinner } from "@/components/ui/loading";
import { MaterialSymbol } from "material-symbols";
import { useAppetiteProductLinesQuery, useAppetiteProductsCountQuery } from "src/generated/graphql";

export const InsuredHeader = () => {
  const { data: linesData, loading: loadingLines } = useAppetiteProductLinesQuery();
  const { data: productsCount, loading: loadingProducts } = useAppetiteProductsCountQuery();

  return (
    <HeaderWrapper icon="psychology_alt">
      <h2>Insured Basics</h2>
      <p>
        Provide a few details about your insured to help us start quoting from our panel of{" "}
        <strong>
          {loadingProducts ? <Spinner className="inline text-xs" /> : productsCount?.productsCount} risk solutions
        </strong>{" "}
        offering{" "}
        <strong>
          {loadingLines ? <Spinner className="inline text-xs" /> : linesData?.appetiteProductLines.length} types of
          coverage
        </strong>
        .
      </p>
    </HeaderWrapper>
  );
};

export const AgentHeader = () => (
  <HeaderWrapper icon="business_center">
    <h2>Agent Information</h2>
  </HeaderWrapper>
);

const HeaderWrapper = ({ children, icon }: { children: React.ReactNode; icon: MaterialSymbol }) => (
  <header className="flex gap-5 items-start p-6 pl-5">
    <Icon icon={icon} className="filled mt-0.5 text-2xl text-primary" />
    <div className="space-y-3">{children}</div>
  </header>
);

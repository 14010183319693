import { createBrowserRouter, Outlet } from "react-router-dom";

import { AuthGuard } from "@/auth/auth-guard";
import { ErrorMessage } from "@/components/errors/error-message";
import { PageNotFound } from "@/components/errors/page-not-found";
import { HasInternalRole, HasRole } from "@/components/has-role";
import { ModalProvider } from "@/components/modal-provider";
import { Page } from "@/components/page";
import { RefreshWarning } from "@/components/refresh-warning";
import { AccountProvider } from "@/hooks/use-account";
import { Roles } from "src/generated/graphql";
import { adminRoutes } from "./admin-routes";
import { agencyRoutes } from "./agency-routes";
import { armRoutes } from "./arm-routes";
import { insuredRoutes } from "./insured-routes";
import { internalRoutes } from "./internal-routes";
import { publicRoutes } from "./public-routes";
import { redirectRoutes } from "./redirect-routes";

export const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <AuthGuard>
        <ModalProvider>
          <AccountProvider>
            <Page />
            <RefreshWarning />
          </AccountProvider>
        </ModalProvider>
      </AuthGuard>
    ),
    errorElement: <ErrorMessage />,
    children: [
      ...agencyRoutes,
      ...insuredRoutes,
      {
        element: (
          <HasInternalRole elseShow={<PageNotFound />}>
            <Outlet />
          </HasInternalRole>
        ),
        children: internalRoutes,
      },
      {
        element: (
          <HasRole roles={[Roles.Admin]} elseShow={<PageNotFound />}>
            <Outlet />
          </HasRole>
        ),
        children: adminRoutes,
      },
      {
        element: (
          <HasRole roles={[Roles.Admin, Roles.Arm]} elseShow={<PageNotFound />}>
            <Outlet />
          </HasRole>
        ),
        children: armRoutes,
      },
      ...redirectRoutes,
    ],
  },
  ...publicRoutes,
]);

import { Fragment, useEffect } from "react";

import { Side, usePage } from "@/hooks/use-page";
import { cn } from "src/utils";
import { Breadcrumbs } from "./breadcrumbs";
import { SidebarTrigger } from "./sidebar";
import { Bar } from "./ui/bar";

export const ScrollPane = ({
  children,
  className,
  sidebarPosition = null,
  hideBar = false,
  ...rest
}: React.HTMLAttributes<HTMLDivElement> & { hideBar?: boolean; sidebarPosition?: Side }) => {
  const { crumbs, menus, setSidebarSide } = usePage();

  const displayBar = (sidebarPosition || crumbs[0] || menus[0]) && !hideBar;

  useEffect(() => {
    setSidebarSide(sidebarPosition);
  }, []);

  return (
    <main
      className={cn(
        "@container/scrollpane bg-accent flex flex-auto flex-col max-h-dvh max-w-full overflow-auto relative shadow-md z-1",
        "landscape:max-h-(--landscape-max-height) landscape:max-w-(--landscape-max-width) landscape:rounded-md",
        "portrait:max-h-(--portrait-max-height)",
        className
      )}
      style={{ contain: "layout" }}
      {...rest}
    >
      {displayBar && (
        <Bar className="border-b-0">
          <SidebarTrigger />
          <Breadcrumbs />
          <div className="flex gap-3 items-center">
            {menus.map(({ menu }, i) => (
              <Fragment key={i}>{menu!}</Fragment>
            ))}
          </div>
        </Bar>
      )}
      {children}
    </main>
  );
};

export const ScrollPaneColumns = ({ children, className, ...rest }: React.HTMLAttributes<HTMLDivElement>) => (
  <div
    className={cn("flex flex-col gap-x-6 gap-y-5 @4xl/scrollpane:grid @4xl/scrollpane:items-start", className)}
    {...rest}
  >
    {children}
  </div>
);

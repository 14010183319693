import { useApolloClient } from "@apollo/client";
import { Dialog } from "@radix-ui/react-dialog";
import React, { useState } from "react";
import Dropzone from "react-dropzone";
import { useParams } from "react-router";

import { HasInternalRole } from "@/components/has-role";
import { useAccount } from "@/hooks/use-account";
import { useToast } from "@/hooks/use-toast";
import { File_Audience, File_Source } from "src/generated/graphql";
import { cn } from "src/utils";
import { uploadFiles } from "src/utils/file";

import { EditFileDialog } from "./edit-file-dialog";

export const ExternalDropzone = ({ children, className }: { children: React.ReactNode; className?: string }) => {
  const { insuredId } = useParams<"insuredId">();
  const { toast } = useToast();
  const [fileId, setFileId] = useState<string | undefined>(undefined);
  const { user } = useAccount();
  const client = useApolloClient();
  const refetch = () => client.refetchQueries({ include: ["FilesByLabel", "FileTags"] });

  return (
    <Dropzone
      noClick
      onDrop={(files) => {
        toast({ title: "Loading...." });
        if (files.length > 1 && user.internal) {
          toast({ title: "Please upload one file at a time" });
          return;
        }
        void uploadFiles(files, { insuredId, audience: File_Audience.External, source: File_Source.ManualUpload }).then(
          ({ fileId }) => {
            if (fileId) {
              toast({ title: "File uploaded successfully" });
              setFileId(fileId[0]);
            } else {
              toast({ title: "Error uploading file" });
            }
            void refetch();
          }
        );
      }}
    >
      {({ getRootProps, getInputProps }) => (
        <div {...getRootProps()} className={cn("@container", className)}>
          <input {...getInputProps()} />
          {children}
          <HasInternalRole>
            <Dialog onOpenChange={() => setFileId(undefined)} open={fileId ? true : false}>
              {fileId && <EditFileDialog fileId={fileId} onClose={() => setFileId(undefined)} />}
            </Dialog>
          </HasInternalRole>
        </div>
      )}
    </Dropzone>
  );
};

import { Button } from "@/components/ui/button";
import { Icon } from "@/components/ui/icon";
import { Tooltip, TooltipContent, TooltipTrigger } from "@/components/ui/tooltip";
import { SubmissionAction, SubmissionFragment } from "src/generated/graphql";
import { SubmissionMenu } from "./submission-menu";
import { useSubmissionActions } from "./use-submission-actions";

export const MarketBlocked = ({ submission }: { submission: SubmissionFragment }) => {
  const { handleTransition } = useSubmissionActions();
  return (
    <>
      <Tooltip>
        <TooltipTrigger asChild>
          <Button
            variant="ghost"
            size="xs"
            display="icon"
            onClick={() => handleTransition(submission, SubmissionAction.Revert)}
          >
            <Icon icon="undo" />
          </Button>
        </TooltipTrigger>
        <TooltipContent>Revert</TooltipContent>
      </Tooltip>
      <SubmissionMenu submission={submission} />
    </>
  );
};

import { useNavigate } from "react-router";

import { StateCard } from "@/components/state";
import { Button } from "@/components/ui/button";
import { CardContent, CardFooter } from "@/components/ui/card";
import { Spinner } from "@/components/ui/loading";
import { useAlbyApproval } from "@/hooks/use-alby-approval";

export const DefaultApprovalForm = () => {
  const { body } = useAlbyApproval();
  return (
    <AlbyApprovalCard>
      <CardContent>{body && <p>{formatBody(body)}</p>}</CardContent>
      <AlbyApprovalFooter />
    </AlbyApprovalCard>
  );
};

const AlbyApprovalCard = ({ children }: { children: React.ReactNode }) => {
  const { threadId, graphName, selectedTool, header, loading } = useAlbyApproval();
  return (
    <StateCard title={header ?? `${graphName} - ${selectedTool}`} icon="smart_toy" cardClassName="text-primary">
      {loading ? (
        <CardContent>
          <Spinner />
        </CardContent>
      ) : threadId ? (
        children
      ) : (
        // TODO accept/reject info
        <CardContent>No thread ID found.</CardContent>
      )}
    </StateCard>
  );
};

const AlbyApprovalFooter = () => {
  const { approve, decline, disabled } = useAlbyApproval();
  const navigate = useNavigate();

  return (
    <CardFooter className="justify-end">
      <Button
        type="submit"
        variant="outline"
        disabled={disabled}
        onClick={() =>
          decline({
            onCompleted() {
              navigate(-1);
            },
          })
        }
      >
        Decline
      </Button>
      <Button
        type="submit"
        theme="primary"
        disabled={disabled}
        onClick={() =>
          approve(
            {},
            {
              onCompleted() {
                navigate(-1);
              },
            }
          )
        }
      >
        Approve
      </Button>
    </CardFooter>
  );
};

// Slack sections are formatted as <https://example.com|example.com> and we want to convert them to a clickable link
const formatBody = (body: string) => {
  const regex = /<(https?:\/\/[^|]+)\|([^>]+)>/;
  const matches = body.match(regex);

  if (!matches) {
    return body;
  }

  const [fullMatch, url, label] = matches;
  const parts = body.split(fullMatch);

  return (
    <span>
      {parts[0]}
      <a href={url} className="text-blue-600 hover:text-blue-800" target="_blank" rel="noopener noreferrer">
        {label}
      </a>
      {parts[1]}
    </span>
  );
};

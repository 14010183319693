import { cva, type VariantProps } from "class-variance-authority";
import * as React from "react";

import { cn } from "src/utils";

const badgeVariants = cva(
  "flex-none font-semibold inline-flex items-center justify-center h-[1.875em] min-w-[1.875em] text-nowrap transition-colors focus:outline-hidden focus:ring-2 focus:ring-ring focus:ring-offset-2",
  {
    variants: {
      variant: {
        default: "bg-foreground text-background",
        primary: "bg-primary text-primary-foreground",
        secondary: "bg-secondary text-secondary-foreground",
        destructive: "bg-destructive text-destructive-foreground",
        outline: "border text-foreground",
        green: "bg-green-500 text-primary-foreground",
        amber: "bg-amber-500 text-primary-foreground",
        orange: "bg-orange-500 text-primary-foreground",
        lime: "bg-lime-500 text-primary-foreground",
      },
      size: {
        default: "text-2xs",
        xs: "text-xs",
        "3xs": "h-4 min-w-4 text-3xs px-0.5!",
      },
      type: {
        default: "px-[0.875em] rounded-full",
        square: "px-[0.625em] rounded",
      },
    },
    defaultVariants: {
      variant: "default",
      size: "default",
      type: "default",
    },
  }
);

export interface BadgeProps extends React.HTMLAttributes<HTMLDivElement>, VariantProps<typeof badgeVariants> {}

function Badge({ className, variant, size, type, ...props }: BadgeProps) {
  return <div className={cn(badgeVariants({ variant, size, type }), className)} {...props} />;
}

export { Badge, badgeVariants };

import { PdfNumber, PdfTextarea, PdfYesNo } from "@/forms/pdf-fields/pdf-fields";
import { Address } from "./fieldsets/address";
import { GeneralInformation } from "./fieldsets/general-information";
import { LossHistory } from "./fieldsets/loss-history";
import { Paper } from "./fieldsets/paper";
import { Signature } from "./fieldsets/signature";

export const AutoGarage = () => (
  <>
    <Paper>
      <section>
        <h1>Garage</h1>
        <GeneralInformation />
      </section>
      <section>
        <Address header="Mailing Address" rootPath="mailing" />
      </section>
      <section>
        <Address header="Physical Address" rootPath="physical" />
      </section>
    </Paper>
    <Paper>
      <section>
        <h1>Breakdown of Vehicles Repaired By %</h1>
        <table>
          <thead>
            <tr>
              <td colSpan={3} className="big">
                Type
              </td>
              <td colSpan={1} className="big">
                %
              </td>
            </tr>
          </thead>
          <tbody>
            {[
              {
                label: "Private passenger cars, pick-ups, vans, SUVs",
                name: "garage:percentOfVehiclesRepaired:privatePassenger",
              },
              {
                label: "Sports or high performance (Porsche, Corvette, etc)",
                name: "garage:percentOfVehiclesRepaired:sports",
              },
              { label: "Antique or Classic Vehicles", name: "garage:percentOfVehiclesRepaired:antique" },
              { label: "Motorcycles, Motorbikes, Mopeds", name: "garage:percentOfVehiclesRepaired:motorcycle" },
              { label: "Boats (Hull)", name: "garage:percentOfVehiclesRepaired:boatsHull" },
              { label: "Boats (Motors)", name: "garage:percentOfVehiclesRepaired:boatsMotor" },
              { label: "Buses", name: "garage:percentOfVehiclesRepaired:buses" },
              { label: "ATVs, Jet Skis, Snowmobiles", name: "garage:percentOfVehiclesRepaired:atvJetSkiSnowmobiles" },
              {
                label: "Motor homes, Recreational Vehicles",
                name: "garage:percentOfVehiclesRepaired:motorhomeRecreational",
              },
              { label: "Trucks (up to 20,000 lbs GVW)", name: "garage:percentOfVehiclesRepaired:trucksUnder20kLbs" },
              { label: "Trucks (over 20,000 lbs GVW)", name: "garage:percentOfVehiclesRepaired:trucksOver20kLbs" },
              {
                label: "Truck Tractors, 5th Wheels & Semi Trailers",
                name: "garage:percentOfVehiclesRepaired:truckTrailers",
              },
              { label: "Mobile Home Dealer", name: "garage:percentOfVehiclesRepaired:mobileHomeDealer" },
              { label: "Utility Trailers", name: "garage:percentOfVehiclesRepaired:utilityTrailers" },
              { label: "Farm Equipment", name: "garage:percentOfVehiclesRepaired:farmEquipment" },
              { label: "Emergency Vehicles", name: "garage:percentOfVehiclesRepaired:emergencyVehicles" },
              { label: "Other", name: "garage:percentOfVehiclesRepaired:other" },
            ].map(({ label, name }) => (
              <tr key={name}>
                <td colSpan={3}>{label}</td>
                <PdfNumber name={name} />
              </tr>
            ))}
            <tr className="font-bold">
              <td colSpan={3}>Total</td>
              <td>100%</td>
            </tr>
          </tbody>
        </table>
      </section>
    </Paper>
    <Paper>
      <section>
        <h1>Breakdown of Service Work by %</h1>
        <table>
          <thead>
            <tr>
              <td colSpan={3} className="big">
                Type
              </td>
              <td colSpan={1} className="big">
                %
              </td>
            </tr>
          </thead>
          <tbody>
            {[
              { label: "Brakes", name: "garage:percentOfServiceWork:brakes" },
              { label: "Body/Paint", name: "garage:percentOfServiceWork:bodyPaint" },
              { label: "Car Wash Attended", name: "garage:percentOfServiceWork:carWashAttended" },
              { label: "Car Wash Self Serve", name: "garage:percentOfServiceWork:carWashSelfServe" },
              { label: "Gasoline/LPG Sales", name: "garage:percentOfServiceWork:gasolineLpgSales" },
              { label: "Detail", name: "garage:percentOfServiceWork:detail" },
              { label: "Electrical", name: "garage:percentOfServiceWork:electrical" },
              { label: "Muffler", name: "garage:percentOfServiceWork:muffler" },
              { label: "Oil & Lube", name: "garage:percentOfServiceWork:oilLube" },
              { label: "Radiator", name: "garage:percentOfServiceWork:radiator" },
              { label: "Sound System/Alarms", name: "garage:percentOfServiceWork:soundSystemAlarms" },
              { label: "Transmission", name: "garage:percentOfServiceWork:transmission" },
              { label: "Tune-up", name: "garage:percentOfServiceWork:tuneUp" },
              { label: "Window Tinting", name: "garage:percentOfServiceWork:windowTinting" },
              { label: "Windshield Repair", name: "garage:percentOfServiceWork:windshieldRepair" },
              { label: "Windshield Replacement", name: "garage:percentOfServiceWork:windshieldReplacement" },
              { label: "Lift Kit Installation", name: "garage:percentOfServiceWork:liftKitInstallation" },
              { label: "Hitches", name: "garage:percentOfServiceWork:hitches" },
              { label: "Hydraulics", name: "garage:percentOfServiceWork:hydraulics" },
              { label: "Performance Upgrades", name: "garage:percentOfServiceWork:performanceUpgrades" },
              { label: "Suspension (not lift kits)", name: "garage:percentOfServiceWork:suspension" },
              { label: "Tires", name: "garage:percentOfServiceWork:tires" },
              { label: "Valet Parking", name: "garage:percentOfServiceWork:valetParking" },
              { label: "Welding", name: "garage:percentOfServiceWork:welding" },
              { label: "Other", name: "garage:percentOfServiceWork:other" },
            ].map(({ label, name }) => (
              <tr key={name}>
                <td colSpan={3}>{label}</td>
                <PdfNumber name={name} />
              </tr>
            ))}
            <tr className="font-bold">
              <td colSpan={3}>Total</td>
              <td>100%</td>
            </tr>
          </tbody>
        </table>
      </section>
    </Paper>
    <Paper>
      <section>
        <h1>Other Operations</h1>
        <table>
          <tbody>
            <tr>
              <PdfYesNo
                colSpan={4}
                label="Do you have any Dealer operations (New &/or Used)?"
                name="garage:otherOperations:dealer"
              />
            </tr>
            <tr>
              <PdfYesNo
                colSpan={4}
                label="Do you have any Rental/Leasing Operations (Vehicles &/or Trailers)?"
                name="garage:otherOperations:rentLease"
              />
            </tr>
            <tr>
              <PdfYesNo
                colSpan={4}
                label="Do you provide any Loaner vehicles?"
                name="garage:otherOperations:loanerVehicles"
              />
            </tr>
            <tr>
              <PdfYesNo
                colSpan={4}
                label="Do you perform any work on the roadside?"
                name="garage:otherOperations:roadsideWork"
              />
            </tr>
            <tr>
              <PdfYesNo
                colSpan={4}
                label="Do you perform work at the customer’s location?"
                name="garage:otherOperations:workAtCustomersLocation"
              />
            </tr>
            <tr>
              <PdfYesNo
                label="Do you modify, rebuild or perform conversions on vehicles?"
                name="garage:otherOperations:rebuildOrConversion"
              />
              <PdfTextarea
                colSpan={3}
                label="If yes, please explain:"
                name="garage:otherOperations:rebuildOrConversion:description"
              />
            </tr>
            <tr>
              <PdfYesNo
                label="Do you perform any frame straightening?"
                name="garage:otherOperations:frameStraightening"
              />
              <PdfTextarea
                colSpan={2}
                label="If yes, what device is used (laser measuring device, optical measuring device, mechanical gauage)?"
                name="garage:otherOperations:frameStraightening:deviceType"
              />
              <PdfTextarea label="Make & Model:" name="garage:otherOperations:frameStraightening:deviceModel" />
            </tr>
            <tr>
              <PdfYesNo
                colSpan={4}
                label="Do you repair vehicles with damage totaling more than 75% of the ACV of the vehicle?"
                name="garage:otherOperations:repairOver75PercentAcv"
              />
            </tr>
            <tr>
              <PdfYesNo
                colSpan={4}
                label="Do you own, repair, service, or sponsor a race car?"
                name="garage:otherOperations:serviceOrSponsorRaceCar"
              />
            </tr>
            <tr>
              <PdfYesNo
                colSpan={4}
                label="Do you perform any work on airbags (including any deactivating) or breathalyzers?"
                name="garage:otherOperations:airbagOrBreathalyzerWork"
              />
            </tr>
            <tr>
              <PdfYesNo colSpan={4} label="Do you repossess autos?" name="garage:otherOperations:repossessAutos" />
            </tr>
            <tr>
              <PdfYesNo label="Do you tow?" name="garage:otherOperations:towing" />
              <PdfNumber label="For Hire %" name="garage:otherOperations:towing:forHirePercent" />
              <PdfNumber label="Rotation %" name="garage:otherOperations:towing:rotationPercent" />
              <PdfNumber label="Repo %" name="garage:otherOperations:towing:repoPercent" />
            </tr>
          </tbody>
        </table>
      </section>
    </Paper>
    {/* Page Break */}
    <Paper>
      <section>
        <table>
          <tbody>
            <tr>
              <PdfYesNo
                colSpan={4}
                label="Do you dismantle autos or have salvage operations?"
                name="garage:otherOperations:dismantleOrSalvage"
              />
            </tr>
            <tr>
              <PdfTextarea
                colSpan={4}
                label="Explain any other business, owned by you:"
                name="garage:otherOperations:otherBusinessOwnedByYou"
              />
            </tr>
            <tr>
              <PdfYesNo
                colSpan={2}
                label="Do you rent any space at any of your locations to another business?"
                name="garage:otherOperations:rentSpaceToOtherBusinesses"
              />
              <PdfTextarea
                colSpan={2}
                label="If yes, please provide details:"
                name="garage:otherOperations:rentSpaceToOtherBusinesses:description"
              />
            </tr>
          </tbody>
        </table>
      </section>
    </Paper>
    <Paper>
      <section>
        <h1>Security & Protection</h1>
        <table>
          <tbody>
            <tr>
              <PdfYesNo colSpan={1} label="Do you store vehicles overnight?" name="garage:security:overnightStorage" />
              <PdfTextarea
                colSpan={3}
                label="If yes, describe your lot protection (each location) (None, Fence & Gate, Post & Cable, In Building):"
                name="garage:otherOperations:overnightStorage:description"
              />
            </tr>
            <tr>
              <PdfYesNo
                colSpan={4}
                label="Do you park customers' vehicles on the street?"
                name="garage:otherOperations:parkCustomerVehiclesOnStreet"
              />
            </tr>
            <tr>
              <PdfYesNo
                colSpan={4}
                label="Is your lot well lit at night?"
                name="garage:otherOperations:lotWellLitAtNight"
              />
            </tr>
            <tr>
              <PdfYesNo
                colSpan={4}
                label="If you have a spray booth, is it equipped with explosion proof lights, outside ventilation & bay separation?"
                name="garage:otherOperations:spayBoothEquipped"
              />
            </tr>
            <tr>
              <PdfYesNo
                colSpan={4}
                label="Are signs posted to keep customers from the work area?"
                name="garage:otherOperations:signsPosted"
              />
            </tr>
            <tr>
              <PdfYesNo
                colSpan={4}
                label="Are firearms kept on the premises?"
                name="garage:otherOperations:firearmsOnPremises"
              />
            </tr>
            <tr>
              <PdfYesNo
                colSpan={2}
                label="Is your lot patrolled by a security guard?"
                name="garage:otherOperations:securityGuard"
              />
              <PdfYesNo colSpan={2} label="Is the guard armed?" name="garage:otherOperations:securityGuard:isArmed" />
            </tr>
            <tr>
              <PdfYesNo
                colSpan={2}
                label="Do you have any other security devices, i.e., cameras, alarms?"
                name="garage:otherOperations:otherSecurityDevices"
              />
              <PdfTextarea
                colSpan={2}
                label="If yes, please describe:"
                name="garage:otherOperations:otherSecurityDevices:description"
              />
            </tr>
            <tr>
              <PdfYesNo
                colSpan={4}
                label="Do you have any animals on premises?"
                name="garage:otherOperations:animalsOnPremises"
              />
            </tr>
            <tr>
              <PdfYesNo
                colSpan={2}
                label="Do you leave keys in vehicles?"
                name="garage:otherOperations:leaveKeysInVehicles"
              />
              <PdfTextarea
                colSpan={2}
                label="Describe how the keys are controlled (key cabinet, taken home, in/on vehicle):"
                name="garage:otherOperations:keyControl"
              />
            </tr>
          </tbody>
        </table>
      </section>
    </Paper>
    <Paper>
      <section>
        <h1>All Employees and Non-Employees or Family Members</h1>
        <h4>Indicate # of persons in each category</h4>
        <table>
          <thead>
            <tr>
              <td className="big">Full Time</td>
              <td className="big">Part Time (20 hours/week or less)</td>
              <td className="big">Do All Listed Employees have clean MVRs?</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <PdfNumber name="garage:employees:numFullTime" />
              <PdfNumber name="garage:employees:numPartTime" />
              <PdfYesNo name="garage:employees:allCleanMvrs" />
            </tr>
          </tbody>
        </table>
      </section>
      <section>
        <h1>Loss Information</h1>
        <table>
          <tr>
            <PdfYesNo
              label="Was prior coverage ever cancelled or non-renewed?"
              name="garage:lossHistory:priorCoverageCancelledNonRenewed"
            />
            <PdfTextarea
              label="If yes, please explain:"
              name="garage:lossHistory:priorCoverageCancelledNonRenewed:description"
            />
          </tr>
          <tr>
            <PdfYesNo label="No losses ('yes' if none)" name="garage:lossHistory:noLosses" />
            <PdfYesNo
              label="No prior coverage ('yes' if no prior coverage)"
              name="garage:lossHistory:noPriorCoverage"
            />
          </tr>
        </table>
        <br />
        <LossHistory rootPath="garage" numYears="three" />
      </section>
      <section>
        <Signature />
      </section>
    </Paper>
  </>
);

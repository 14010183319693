import { useLoginEmailPassword } from "@cp/auth";
import { useNavigate } from "react-router";
import { Link, useSearchParams } from "react-router-dom";
import { z } from "zod";

import { Group } from "@/components/group";
import { Button } from "@/components/ui/button";
import { Card, CardContent, CardFooter, CardHeader, CardTitle } from "@/components/ui/card";
import { FieldCheckbox } from "@/forms/fields/field-checkbox";
import { FieldInput } from "@/forms/fields/field-input";
import { Form } from "@/forms/form";
import { FormSubmit } from "@/forms/form-submit";
import { SetMetadata } from "@/hooks/use-metadata";
import { AuthCard } from "./auth-card";

const defaultValues = {
  email: "",
  password: "",
  rememberMe: true,
};

const validationSchema = z.object({
  email: z.string().min(1, { message: "Please enter your email address" }),
  password: z.string().min(1, { message: "Please enter your password" }),
  rememberMe: z.boolean(),
});

export const Login = () => {
  const navigate = useNavigate();
  const { mutateAsync: loginEmailPassword, error, isLoading } = useLoginEmailPassword();
  const [searchParams] = useSearchParams();

  const redirect = searchParams.get("redirect") ?? "/";

  const onSubmitEmailPassword = async (data: z.infer<typeof validationSchema>) =>
    await loginEmailPassword(data, { onSuccess: () => navigate(redirect) });

  return (
    <>
      <SetMetadata title="Sign In" />
      <Form onSubmit={onSubmitEmailPassword} defaultValues={defaultValues} validationSchema={validationSchema}>
        <AuthCard
          header="Sign in"
          subHeader="Welcome back! Please enter your credentials to access your account."
          error={error}
        >
          <CardContent>
            <Group>
              <Group className="gap-2">
                <FieldInput name="email" type="email" placeholder="Email" />
                <FieldInput name="password" type="password" placeholder="Password" />
              </Group>
              <FieldCheckbox name="rememberMe" label="Remember me" />
            </Group>
          </CardContent>
          <CardFooter>
            <Group className="flex-1">
              <FormSubmit className="grow" disabled={isLoading || false}>
                Sign In
              </FormSubmit>
              <Button variant="link" disabled={isLoading} asChild>
                <Link to="/forgot-password">Forgot your password?</Link>
              </Button>
            </Group>
          </CardFooter>
        </AuthCard>
      </Form>

      <div className="relative flex py-5 items-center">
        <div className="grow border-t border-muted-foreground"></div>
        <span className="shrink mx-4 text-muted-foreground">Or</span>
        <div className="grow border-t border-muted-foreground"></div>
      </div>

      <Card className="max-w-xs">
        <CardHeader>
          <CardTitle>Email your submission</CardTitle>
          <p>
            You can always send us submissions to{" "}
            <Button variant="link" theme="primary" asChild>
              <a href="mailto:submissions@quotewell.com">submissions@quotewell.com</a>
            </Button>
            .
          </p>
        </CardHeader>
      </Card>
    </>
  );
};

import { ComponentProps, FC, forwardRef } from "react";

import { Icon } from "@/components/ui/icon";
import { Label } from "@/components/ui/label";
import { Switch } from "@/components/ui/switch";
import { useSegment } from "@/segments/hooks/use-segment";
import { cn } from "src/utils";

export const SegmentMenuItemAutoApproveToggle: FC = forwardRef<HTMLLabelElement, ComponentProps<typeof Label>>(
  (props, ref) => {
    const { segment, toggleSegmentAutoApprove, isUpdatingSegment } = useSegment();

    return (
      <Label ref={ref} {...props} className={cn("font-normal! cursor-pointer pr-2!", props.className)}>
        <Icon
          icon="smart_toy"
          className={cn("text-sm", segment!.autoApproveAlby ? "filled text-primary" : "opacity-25")}
        />
        <span>Auto approve</span>
        <span className="flex-1" />
        <Switch
          checked={segment!.autoApproveAlby}
          onCheckedChange={toggleSegmentAutoApprove}
          disabled={isUpdatingSegment}
        />
      </Label>
    );
  }
);
SegmentMenuItemAutoApproveToggle.displayName = "SegmentMenuItemAutoApproveToggle";

import { KnownClientData } from "@qw/qw-common";

import { PdfDate, PdfInput, PdfNumber, PdfYesNo } from "@/forms/pdf-fields/pdf-fields";
import { Address } from "./fieldsets/address";
import { GeneralInformation } from "./fieldsets/general-information";
import { Paper } from "./fieldsets/paper";
import { Schedule } from "./fieldsets/schedule";
import { Signature } from "./fieldsets/signature";

export const Cyber = () => (
  <>
    <Paper>
      <section>
        <h1>Cyber Supplemental</h1>
        <GeneralInformation />
      </section>
      <section>
        <Address header="Mailing Address" rootPath="mailing" />
      </section>
      <section>
        <Address header="Physical Address" rootPath="physical" />
      </section>
    </Paper>
    <Paper>
      <section>
        <table>
          <thead>
            <tr>
              <td colSpan={3} className="big">
                Policy Requests
              </td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <PdfNumber label="Limit" name="cyberRequestLimit" />
              <PdfNumber label="Retention" name="cyberRequestRetention" />
              <PdfDate label="Retroactive Date" name="cyberRetroactiveDate" />
            </tr>
          </tbody>
        </table>
      </section>
      <section>
        <table>
          <thead>
            <tr>
              <td colSpan={2} className="big">
                General Information
              </td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <PdfNumber label="Number of Employees" name={KnownClientData.TotalEmployeeCount} />
              <PdfNumber label="Current Year Annual Revenue" name={KnownClientData.Revenue.CurrentYear} />
            </tr>
          </tbody>
        </table>
      </section>
      <section>
        <table>
          <thead>
            <tr>
              <td className="big">Underwriting Info</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <PdfYesNo
                label="Does the company have data retention and disposal policies?"
                name="dataRententionPolicies"
              />
            </tr>
            <tr>
              <PdfYesNo
                label="Does the Applicant have multi-factor authentication enabled on email access and remote network access?"
                name="multiFactorEnabled"
              />
            </tr>
            <tr>
              <PdfYesNo
                label="Does the company monitor the network in real time for possible intrusions or abnormalities?"
                name="realTimeMonitoring"
              />
            </tr>
            <tr>
              <PdfYesNo
                label="Does the Applicant store or process personal, health, or credit card information of more than 500,000 individuals?"
                name="storeInfoFor500kIndividuals"
              />
            </tr>
            <tr>
              <PdfYesNo
                label="Is data encrypted when sent and received, and are the company's backups encrypted?"
                name="dataEncryption"
              />
            </tr>
          </tbody>
        </table>
      </section>
      <section>
        <table>
          <thead>
            <tr>
              <td className="big">Claim Info</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <PdfYesNo
                label="Is the Applicant aware of any fact, circumstance, situation, event, or Wrongful Act which reasonably could give rise to a Cyber Event, Loss, or a Claim being made against them that would fall within the scope of the Policy for which the Applicant is applying?"
                name="awareOfLoss"
              />
            </tr>
            <tr>
              <PdfYesNo
                label="In the last three (3) years, has the Applicant experienced in excess of $10,000 any Cyber Event, Loss, or been the subject of any Claim made for a Wrongful Act that would fall within the scope of the Policy for which the Applicant is applying?"
                name="lossInLastThreeYears"
              />
            </tr>
          </tbody>
        </table>
      </section>
    </Paper>
    <Paper>
      <Schedule
        title="Website and Email Domains"
        header={
          <tr>
            <th>Domain</th>
          </tr>
        }
      >
        <tr>
          <PdfInput name="domain" />
        </tr>
      </Schedule>
      <Signature />
    </Paper>
  </>
);

import { Link } from "react-router-dom";

import { NavigateBroker } from "@/components/navigate/navigate-broker";
import { Sidebar, SidebarContent, SidebarHeader } from "@/components/sidebar";
import { Spinner } from "@/components/ui/loading";
import { useAlby } from "@/hooks/use-alby";
import { adaptGraph } from "src/utils/adapt-graph";
import { formatTimezoneDateStringDistance } from "src/utils/date";

export const AlbySidebar = () => {
  const { graphs, loading } = useAlby();
  const filteredGraphs = graphs.filter((graph) => graph.toolName !== "InformationRequest");
  return (
    <Sidebar width="20rem">
      <SidebarHeader>
        <NavigateBroker path={`/alby`} allowAll={false} className="mr-auto w-auto" />
      </SidebarHeader>
      <SidebarContent>
        {loading ? (
          <Spinner className="m-6" />
        ) : (
          filteredGraphs.map((graph) => {
            const { id, createdAt, insuredId, graphName, header, invokeParams, toolName } = adaptGraph(graph);
            return (
              <Link
                key={id}
                to={`${insuredId}/${graphName}/${toolName}?${invokeParams}`}
                className="block px-6 py-4 space-y-1.5 text-xs"
              >
                <div className="line-clamp-2">{header?.text.text ?? `${graphName} - ${toolName}`}</div>
                <div className="text-2xs text-muted-foreground">{formatTimezoneDateStringDistance(createdAt)} ago</div>
              </Link>
            );
          })
        )}
      </SidebarContent>
    </Sidebar>
  );
};

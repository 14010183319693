import { forwardRef, useMemo } from "react";
import { useFormContext } from "react-hook-form";

import { Button, ButtonProps } from "@/components/ui/button";
import { Spinner } from "@/components/ui/loading";

export interface FormSubmitProps extends ButtonProps {
  onlyEnableIfDirty?: boolean;
}

export const FormSubmit = forwardRef<HTMLButtonElement, FormSubmitProps>(
  ({ children = "Save", disabled, onlyEnableIfDirty, ...props }, ref) => {
    const { formState, watch } = useFormContext();

    const values = watch();

    const isDisabled = useMemo(() => {
      const hasErrors = Object.keys(formState.errors).length > 0;

      if (formState.isSubmitting) {
        return true;
      }

      if (typeof disabled === "boolean") {
        return disabled;
      }

      if (hasErrors) {
        return true;
      }

      if (onlyEnableIfDirty) {
        return !formState.isDirty;
      }

      return !formState.isValid;
    }, [
      disabled,
      onlyEnableIfDirty,
      formState,
      formState.isDirty,
      formState.isValid,
      formState.isSubmitting,
      formState.errors,
      values,
    ]);

    return (
      <Button ref={ref} theme="primary" {...props} type="submit" disabled={isDisabled}>
        {children}
        {formState.isSubmitting && <Spinner />}
      </Button>
    );
  }
);

FormSubmit.displayName = "FormSubmit";

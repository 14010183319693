/**
 * Supplementals share data across forms by using the same key.
 * Many fields don't work across supplementals (e.g. %revenue from alarm installation doesn't make sense for cyber)
 * However, a number of fields are the same and should be shared across forms.
 * Those are stored here.
 */
export const KnownClientData = {
  FirstNamedInsured: "First_Named_Insured_Name",
  DescriptionOfOperations: "Description_Of_Primary_Operations",
  ProposedEffectiveDate: "Proposed_Effective_Date",
  DBA: "dba",
  NaicsCode: "NAICS",
  BusinessClassification: {
    NaicsCode: "NAICS",
    CglCode: "CGL",
  },
  TotalEmployeeCount: "totalEmployeeCount",
  BusinessEntityType: "businessEntityType",
  YearEstablished: "yearEstablished",
  StatesOfOperation: "statesOfOperation",
  TotalInsurableValue: "totalInsurableValue",
  RequestedLimits: "limitsRequested",
  Revenue: {
    Next12Months: "revenue:next12Months",
    CurrentYear: "revenue:currentYear",
    FirstYearPrior: "revenue:firstYearPrior",
    SecondYearPrior: "revenue:secondYearPrior",
  },
  Payroll: {
    Next12Months: "payroll:next12Months",
    CurrentYear: "payroll:currentYear",
    FirstYearPrior: "payroll:firstYearPrior",
    SecondYearPrior: "payroll:secondYearPrior",
  },
  Subcosts: {
    Next12Months: "subcosts:next12Months",
    CurrentYear: "subcosts:currentYear",
    FirstYearPrior: "subcosts:firstYearPrior",
    SecondYearPrior: "subcosts:secondYearPrior",
  },
  MailingAddress: {
    AddressLine1: "mailingAddress:address1",
    AddressLine2: "mailingAddress:address2",
    City: "mailingAddress:city",
    State: "mailingAddress:state",
    Zip: "mailingAddress:zip",
  },
  PhysicalAddress: {
    AddressLine1: "physicalAddress:address1",
    AddressLine2: "physicalAddress:address2",
    City: "physicalAddress:city",
    State: "physicalAddress:state",
    Zip: "physicalAddress:zip",
  },
  Exposures: "exposures",
  LossHistory: {
    NoLosses5y: "lossHistory:noLosses5y",
    LossDetails: "lossHistory:lossDetails",
  },
  Coverages: {
    Property: "LOB_Commercial_Property",
    CommercialAuto: "LOB_Business_Auto",
    GL: "LOB_Commercial_GL",
    InlandMarine: "LOB_Commercial_Inland_Marine",
    LiquorLiability: "LOB_Liquor_Liability",
    Umbrella: "LOB_Umbrella",
  },
  PremisesInformation: {
    StreetAddress: "Premises_Information:Street_Address",
    City: "Premises_Information:City",
    State: "Premises_Information:State",
    Zip: "Premises_Information:ZIP",
    County: "Premises_Information:County",
  },
  ScheduleOfHazards: {
    ClassCode: "schedule-of-hazards:class_code",
    PremiumBasis: "schedule-of-hazards:premium_basis",
    Exposure: "schedule-of-hazards:exposure",
    ClassificationDescription: "schedule-of-hazards:classification-description",
    HazardNumber: "schedule-of-hazards:hazard_number",
    LocationNumber: "schedule-of-hazards:location_number",
    ProductRate: "schedule-of-hazards:product_rate",
    ProductOpsRate: "schedule-of-hazards:Prod_Ops_Rate",
    ConsolidatedRate: "schedule-of-hazards:Consolidated_Rate",
  },
  PriorCoverages: "priorCoverages",
  CoverageLimits: {
    GeneralAggregateValue: "CoverageLimits:GeneralAggregateValue",
    GeneralAggregateAppliesTo: "CoverageLimits:GeneralAggregateAppliesTo",
    ProductsAndCompletedOperationsAggregate: "CoverageLimits:ProductsAndCompletedOperationsAggregate",
    EachOccurrence: "CoverageLimits:EachOccurrence",
    PersonalAndAdvertisingInjury: "CoverageLimits:PersonalAndAdvertisingInjury",
    DamageToRentedPremises: "CoverageLimits:DamageToRentedPremises",
  },
  CoverageDeductibles: {
    PropertyDamage: "CoverageDeductibles:PropertyDamage",
    BodilyInjury: "CoverageDeductibles:BodilyInjury",
    OtherKey: "CoverageDeductibles:OtherKey",
    OtherValue: "CoverageDeductibles:OtherValue",
  },
  SubjectOfInsurance: {
    Subject: "SubjectOfInsurance:Subject",
    Amount: "SubjectOfInsurance:Amount",
    CauseOfLoss: "SubjectOfInsurance:CauseOfLoss",
  },
  GLAdditionalInformation: "GLAdditionalInformation",
  Acknowledgment: {
    BasicInfo: "Acknowledgment:BasicInfo",
    GL: "Acknowledgment:GL",
    Property: "Acknowledgment:Property",
  },
};

export const KnownClientDataToQwCoverage = {
  [KnownClientData.Coverages.Property]: "Property",
  [KnownClientData.Coverages.CommercialAuto]: "Commercial Auto",
  [KnownClientData.Coverages.GL]: "General Liability",
  [KnownClientData.Coverages.InlandMarine]: "Inland Marine",
  [KnownClientData.Coverages.LiquorLiability]: "Liquor Liability",
  [KnownClientData.Coverages.Umbrella]: "Excess Liability/Umbrella",
};

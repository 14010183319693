import { FC, useMemo } from "react";
import { useSearchParam } from "react-use";
import { z } from "zod";

import { ButtonGroup } from "@/components/button-group";
import { Grid, GridCell, GridEmpty, GridLoading, GridRow, GridRowHeader } from "@/components/grid";
import { Group } from "@/components/group";
import { Card, CardHeader, CardTitle } from "@/components/ui/card";
import { FieldCombobox } from "@/forms/fields/field-combobox";
import { Form } from "@/forms/form";
import { useUpsertSearchParams } from "@/hooks/use-upsert-search-params";
import { RuleFragment, SegmentAppetiteProductFragment } from "src/generated/graphql";

import { RuleFormSchema } from "../components/rule-form/rule-form";
import { ruleValueToDisplayValue } from "../components/rule-form/rule-form.helpers";
import { useSegment } from "../hooks/use-segment";
import { CreateRuleButton } from "./components/create-rule-button";
import { DeleteRuleButton } from "./components/delete-rule-button";
import { UpdateRuleButton } from "./components/update-rule-button";

export const SegmentAppetiteProductRules = () => {
  const [searchParams, upsertSearchParams, , removeSearchParams] = useUpsertSearchParams();
  const { segment, isLoadingSegment } = useSegment();

  const segmentAppetiteProducts = segment?.segmentAppetiteProducts || [];

  const selectedProductId = useMemo(() => searchParams.get("segmentAppetiteProductId") || "all", [searchParams]);

  const gridData = useMemo(
    () =>
      segmentAppetiteProducts
        .filter((p) => selectedProductId === "all" || p.id === selectedProductId)
        .flatMap((p) => p.ruleSet.rules.flatMap((r) => ({ ...r, segmentAppetiteProduct: p })))
        .sort((a, b) => (a.createdAt < b.createdAt ? -1 : 1)),
    [segmentAppetiteProducts, selectedProductId]
  );

  const productFilterOptions = useMemo(
    () => [
      { label: "All Products", value: "all" },
      ...segmentAppetiteProducts.map(({ appetiteProduct, id }) => ({
        label: `${appetiteProduct.carrierName} ${appetiteProduct.carrierProductName}`,
        value: id,
      })),
    ],
    [segmentAppetiteProducts]
  );

  const handleProductFilterChange = (value: string) =>
    value === "all"
      ? removeSearchParams(["segmentAppetiteProductId"])
      : upsertSearchParams({ segmentAppetiteProductId: value });

  return (
    <div className="p-6">
      <Card>
        <CardHeader className="pb-3">
          <Group type="flex" direction="row" className="items-center justify-between">
            <CardTitle>Product Rules</CardTitle>
            <Form defaultValues={{ selectedProductId }}>
              <FieldCombobox
                name="selectedProductId"
                placeholder="Filter by product"
                options={productFilterOptions}
                inputProps={{ size: "sm" }}
                onChange={handleProductFilterChange as any}
                className="-my-1 max-w-48"
              />
            </Form>

            <span className="flex-1" />
            <CreateRuleButton className="-my-1" />
          </Group>
        </CardHeader>

        <Grid className="bg-none! grid-cols-[2.5fr_1.5fr_1fr_1fr_3.5rem]">
          <GridRowHeader>
            <GridCell>Product</GridCell>
            <GridCell>Key</GridCell>
            <GridCell>Operator</GridCell>
            <GridCell>Value</GridCell>
            <GridCell />
          </GridRowHeader>

          <SegmentAppetiteProductRulesGridBody data={gridData} loading={isLoadingSegment} />
        </Grid>
      </Card>
    </div>
  );
};

export interface SegmentAppetiteProductRulesGridBodyProps {
  data?: Array<SegmentsGridRowProps["row"]>;
  loading: boolean;
}

export const SegmentAppetiteProductRulesGridBody: FC<SegmentAppetiteProductRulesGridBodyProps> = ({
  data,
  loading,
}) => {
  if (!loading && !data?.length) {
    return <SegmentAppetiteProductRulesGridEmpty />;
  }

  if (loading && !data?.length) {
    return <SegmentAppetiteProductRulesGridLoading />;
  }

  return (
    <>
      {data?.map((row) => (
        <SegmentAppetiteProductRulesGridRow key={row.id} row={row} />
      ))}
    </>
  );
};

export const SegmentAppetiteProductRulesGridLoading: FC = () => <GridLoading rows={1} columns={5} />;

export const SegmentAppetiteProductRulesGridEmpty: FC = () => {
  const segmentAppetiteProductId = useSearchParam("segmentAppetiteProductId") || "";
  const { segment } = useSegment();

  const selectedProduct = useMemo(() => {
    if (!segmentAppetiteProductId || segmentAppetiteProductId === "all") {
      return null;
    }

    return segment?.segmentAppetiteProducts.find((p) => p.id === segmentAppetiteProductId);
  }, [segmentAppetiteProductId, segment?.segmentAppetiteProducts]);

  return (
    <GridEmpty
      title={`No rules ${
        selectedProduct
          ? ` for "${selectedProduct.appetiteProduct.carrierName} ${selectedProduct.appetiteProduct.carrierProductName}"`
          : ""
      }`}
      description={
        <>
          <p>Add a rule to get started.</p>
          <CreateRuleButton variant="secondary" />
        </>
      }
    />
  );
};

interface SegmentsGridRowProps {
  row: RuleFragment & { segmentAppetiteProduct: SegmentAppetiteProductFragment };
}

const SegmentAppetiteProductRulesGridRow: FC<SegmentsGridRowProps> = ({ row }) => (
  <GridRow>
    <GridCell>
      {row.segmentAppetiteProduct.appetiteProduct.carrierName}{" "}
      {row.segmentAppetiteProduct.appetiteProduct.carrierProductName}
    </GridCell>
    <GridCell>{row.inputKey}</GridCell>
    <GridCell>{row.operator}</GridCell>
    <GridCell>{ruleValueToDisplayValue(row.value as z.infer<typeof RuleFormSchema>["value"])}</GridCell>

    <ButtonGroup>
      <UpdateRuleButton rule={row} segmentAppetiteProductId={row.segmentAppetiteProduct.id} />
      <DeleteRuleButton rule={row} segmentAppetiteProductId={row.segmentAppetiteProduct.id} />
    </ButtonGroup>
  </GridRow>
);

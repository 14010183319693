import { FC, PropsWithChildren, useCallback, useMemo } from "react";
import { FieldValues, useFormContext } from "react-hook-form";
import { z } from "zod";

import { Group } from "@/components/group";
import { KnownPrimaryLabelsSelector } from "@/components/known-primary-labels-selector";
import { Alert, AlertDescription } from "@/components/ui/alert";
import { AlertDialogHeader } from "@/components/ui/alert-dialog";
import { Dialog, DialogClose, DialogContent, DialogFooter, DialogTitle, DialogTrigger } from "@/components/ui/dialog";
import { FieldDatePicker } from "@/forms/fields/field-date-picker";
import { FieldInput } from "@/forms/fields/field-input";
import { FieldNumber } from "@/forms/fields/field-number";
import { FieldSelect } from "@/forms/fields/field-select";
import { FieldToggleGroup } from "@/forms/fields/field-toggle-group";
import { Form } from "@/forms/form";
import { FormReset } from "@/forms/form-reset";
import { FormSubmit } from "@/forms/form-submit";
import { PrimaryLabelDataType, RuleInputType, useKnownPrimaryLabelsQuery } from "src/generated/graphql";

import { getRuleOperatorOptions, RuleBooleanValue } from "./rule-form.constants";
import {
  getRuleDefaultOperatorFromValueType,
  getRuleDefaultValueFromValueType,
  ruleValueToFormValue,
} from "./rule-form.helpers";

export const RuleFormSchema = z.object({
  id: z
    .string()
    .optional()
    .transform((v) => v || undefined),
  inputType: z.nativeEnum(RuleInputType, { message: "Please select a type" }),
  inputKey: z.string().min(1, { message: "Please select a key" }),
  operator: z.string().min(1, { message: "Please select an operator" }),
  // This is a bit of a hack, as we only want to store the value type so we can parse the value correctly
  // before submitting to the API. It is set based on the selected primary label and it's removed before
  // submitting to the API via the transform function in the UpdateSegmentFormSchema.
  __valueType: z.nativeEnum(PrimaryLabelDataType).optional(),
  value: z.union([z.string().min(1, { message: "Please enter a value" }), z.number(), z.boolean(), z.date()], {
    message: "Please enter a value",
  }),
});

export const transformRuleFormValues = <TFieldValues extends FieldValues>({
  __valueType,
  ...values
}: z.infer<typeof RuleFormSchema> & TFieldValues) => {
  if (__valueType === PrimaryLabelDataType.Number) {
    return { ...values, value: Number(values.value) };
  }

  if (__valueType === PrimaryLabelDataType.Boolean) {
    return { ...values, operator: "==", value: values.value === RuleBooleanValue.Yes };
  }

  return values;
};

export const RuleSetFormSchema = z.object({
  id: z.string(),
  rules: z.array(RuleFormSchema.transform(transformRuleFormValues)).optional(),
});

export type RuleFormValues = z.infer<typeof RuleFormSchema>;

export function getRuleFormDefaultValues<TFieldValues extends FieldValues>(
  defaultValues?: Partial<RuleFormValues> & TFieldValues
): RuleFormValues & TFieldValues {
  return {
    id: defaultValues?.id ?? undefined,
    inputType: RuleInputType.PrimaryLabel,
    inputKey: defaultValues?.inputKey ?? "",
    operator: defaultValues?.operator ?? "",
    value: ruleValueToFormValue(defaultValues?.value) || "",
  } as RuleFormValues & TFieldValues;
}

export interface RuleFormDialogProps {
  mode?: "create" | "update";
  defaultValues?: RuleFormValues;
  onSubmit: (values: RuleFormValues) => Promise<void>;
}

export const RuleFormDialog: FC<PropsWithChildren<RuleFormDialogProps>> = ({
  mode = "create",
  children,
  defaultValues: overrideDefaultValues,
  onSubmit,
}) => {
  const defaultValues = getRuleFormDefaultValues(overrideDefaultValues);

  return (
    <Dialog>
      <DialogTrigger asChild>{children}</DialogTrigger>

      <DialogContent className="sm:max-w-md">
        <AlertDialogHeader>
          <DialogTitle>{mode === "create" ? "Add" : "Edit"} rule</DialogTitle>
        </AlertDialogHeader>

        <Form validationSchema={RuleFormSchema} defaultValues={defaultValues} onSubmit={onSubmit}>
          <Group className="gap-6">
            <RuleFormContent />

            <DialogFooter>
              <DialogClose asChild>
                <FormReset />
              </DialogClose>
              <DialogClose asChild>
                <FormSubmit>{mode === "create" ? "Add" : "Update"}</FormSubmit>
              </DialogClose>
            </DialogFooter>
          </Group>
        </Form>
      </DialogContent>
    </Dialog>
  );
};

export const RuleFormContent = () => {
  const { data } = useKnownPrimaryLabelsQuery();
  const { watch, resetField, setValue } = useFormContext<RuleFormValues>();

  const knownPrimaryLabels = data?.knownPrimaryLabels;
  const inputKeyValue = watch("inputKey");

  const dataType = useMemo(
    () => knownPrimaryLabels?.find?.((pl) => pl.primaryKey === inputKeyValue)?.dataType,
    [knownPrimaryLabels, inputKeyValue]
  );

  const handleInputKeyChange = useCallback(
    (value: any) => {
      const valueType = knownPrimaryLabels?.find?.((pl) => pl.primaryKey === value)?.dataType;

      setValue("__valueType", valueType || undefined);

      // NOTE: This is a hack because the value update does not apply when called without the setTimeout.
      // I need to dig into why that is, but didn't want to spend a bunch of time on it, so I went with the expedient solution here. (Jared)
      setTimeout(() => {
        resetField("operator", { defaultValue: getRuleDefaultOperatorFromValueType(valueType) });
        resetField("value", { defaultValue: getRuleDefaultValueFromValueType(valueType) });
      }, 10);
    },
    [knownPrimaryLabels]
  );

  return (
    <Group>
      <KnownPrimaryLabelsSelector label="Key" name="inputKey" onChange={handleInputKeyChange} />

      {dataType && (
        <>
          {[PrimaryLabelDataType.Number, PrimaryLabelDataType.Percent].includes(dataType) && <RuleNumberFields />}

          {dataType === PrimaryLabelDataType.Boolean && <RuleBooleanFields />}

          {dataType === PrimaryLabelDataType.String && <RuleStringFields />}

          {dataType === PrimaryLabelDataType.Date && (
            <Alert>
              <AlertDescription>
                <h5>Data type not supported</h5>
                <p className="text-muted-foreground text-xs">We do not yet support rules for the selected data type.</p>
              </AlertDescription>
            </Alert>
          )}
        </>
      )}

      {!!inputKeyValue && !dataType && (
        <Alert>
          <AlertDescription>
            <h5>Missing data type</h5>
            <p className="text-muted-foreground text-xs">The chosen primary label has no data type.</p>
          </AlertDescription>
        </Alert>
      )}
    </Group>
  );
};

export const RuleBooleanFields = () => (
  <FieldToggleGroup
    label="Value"
    name="value"
    options={Object.values(RuleBooleanValue).map((value) => ({ label: value, value }))}
  />
);

export const RuleNumberFields = () => (
  <>
    <FieldSelect
      label="Operator"
      name="operator"
      options={getRuleOperatorOptions(PrimaryLabelDataType.Number)}
      inputProps={{ className: "flex-wrap" }}
    />
    <FieldNumber label="Value" name="value" />
  </>
);

export const RuleStringFields = () => (
  <>
    <FieldSelect
      label="Operator"
      name="operator"
      options={getRuleOperatorOptions(PrimaryLabelDataType.String)}
      inputProps={{ className: "flex-wrap" }}
    />
    <FieldInput label="Value" name="value" />
  </>
);

/* Not currently in use, because we don't have a use case for dates right now, 
  and we need input from product about how we intend to use dates with rules */
export const RuleDateFields = () => (
  <>
    <FieldSelect
      label="Operator"
      name="operator"
      options={getRuleOperatorOptions(PrimaryLabelDataType.Date)}
      inputProps={{ className: "flex-wrap" }}
    />
    <FieldDatePicker label="Value" name="value" dateFormat="yyyy-MM-dd" />
  </>
);

import { useMemo, useState } from "react";
import { z } from "zod";

import { Group } from "@/components/group";
import { useModal } from "@/components/modal-provider";
import { SearchInput } from "@/components/search-input";
import { Button } from "@/components/ui/button";
import { Loading } from "@/components/ui/loading";
import { FieldCheckboxGroup } from "@/forms/fields/field-checkbox-group";
import {
  useAddProductToVerticalMarketingPlanTemplateMutation,
  useAppetiteProductsQuery,
  VerticalMarketingPlanTemplateFragment,
} from "src/generated/graphql";
import { cn } from "src/utils";

import { useVertical } from "./use-vertical";

export const AddProducts = ({ template }: { template: VerticalMarketingPlanTemplateFragment }) => {
  const { openForm } = useModal();
  const { refetch } = useVertical();

  const [addProductToVerticalMarketingPlanTemplateTrigger] = useAddProductToVerticalMarketingPlanTemplateMutation({
    onCompleted: refetch,
  });

  const addProductToVerticalMarketingPlan = async (verticalMarketingPlanTemplateId: string) =>
    await openForm(<SelectProducts />, {
      title: "Select Products",
      defaultValues: { products: [] },
      validationSchema: z.object({ products: z.array(z.string()) }),
      onSubmit: async (fd) =>
        await addProductToVerticalMarketingPlanTemplateTrigger({
          variables: { input: { verticalMarketingPlanTemplateId, appetiteProductIds: fd.products } },
        }),
    });

  return (
    <Button
      variant="outline"
      size="xs"
      className={cn(template.products.length === 0 && "border-current! text-destructive!")}
      onClick={() => addProductToVerticalMarketingPlan(template.id)}
    >
      Add Products
    </Button>
  );
};

function SelectProducts() {
  const { data, loading } = useAppetiteProductsQuery();
  const [term, setTerm] = useState("");

  const productOptions = useMemo(
    () =>
      [...(data?.appetiteProducts ?? [])]
        .sort((a, b) => a.carrierName.localeCompare(b.carrierName))
        .filter(
          (p) =>
            p.carrierName.toLowerCase().includes(term.toLowerCase()) ||
            p?.carrierProductName?.toLowerCase().includes(term.toLowerCase())
        )
        .map((product) => ({
          label: `${product.carrierName} ${product.carrierProductName}`,
          value: product.id,
        })),
    [data?.appetiteProducts, term]
  );

  if (loading) {
    return <Loading label="Loading products..." />;
  }

  return (
    <Group>
      <SearchInput name="search" value={term} placeholder="Search Products" onValueChange={setTerm} />
      <FieldCheckboxGroup name="products" options={productOptions} />

      {/* NOTE: I attempted to use a multi-select combobox here, but for some reason the popover
      overflow scroll wasn't working when used in a dialog. We'll need to look into this later. */}
      {/* <FieldCombobox name="products" placeholder="Select products" options={productOptions} multiple /> */}
    </Group>
  );
}

import { useModal } from "@/components/modal-provider";
import { Button } from "@/components/ui/button";
import { POLICY_STATE_METADATA } from "@/metadata/policies";
import { PolicyAction, PolicyFragment, useTransitionPolicyMutation } from "src/generated/graphql";

export const Issued = ({ policy }: { policy: PolicyFragment }) => {
  const { openConfirmation } = useModal();

  const [transition] = useTransitionPolicyMutation();

  const handleTransition = async (action: PolicyAction) => {
    const isConfirmed = await openConfirmation({
      title: `Confirm ${POLICY_STATE_METADATA[policy?.state].actionDisplayNames?.[action] ?? action}`,
      description: `${policy.appetiteProduct.carrierName} - ${policy.appetiteProduct.carrierProductName}`,
    });

    if (!isConfirmed) {
      return;
    }

    await transition({
      variables: {
        input: {
          id: policy.id,
          expectedState: policy.state,
          action,
        },
      },
    });
  };

  return (
    <Button onClick={() => handleTransition(PolicyAction.Cancel)} variant="outline" size="sm" display="flex">
      Cancel Policy
    </Button>
  );
};

import { ButtonProps } from "@/components/ui/button";
import { FC } from "react";

import { GridRowAction } from "@/components/grid";
import { Icon } from "@/components/ui/icon";
import { AppetiteProductFragment } from "src/generated/graphql";

import { useRemoveSegmentAppetiteProduct } from "../../hooks/use-remove-segment-appetite-product";

export interface RemoveAppetiteProductButtonProps extends ButtonProps {
  appetiteProduct: AppetiteProductFragment;
}

export const RemoveAppetiteProductButton: FC<RemoveAppetiteProductButtonProps> = ({
  appetiteProduct,
  children,
  ...props
}) => {
  const { removeAppetiteProduct } = useRemoveSegmentAppetiteProduct();

  return (
    <GridRowAction label="Delete" {...props} onClick={() => removeAppetiteProduct(appetiteProduct)}>
      {children || <Icon icon="delete" />}
    </GridRowAction>
  );
};

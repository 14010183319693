import { MutationFunctionOptions } from "@apollo/client";
import { useCallback } from "react";

import { useOpenConfirmation } from "@/components/modal-provider";
import { useToast } from "@/hooks/use-toast";
import {
  AppetiteProductFragment,
  RemoveAppetiteProductsFromSegmentMutation,
  useRemoveAppetiteProductsFromSegmentMutation,
} from "src/generated/graphql";

import { useSegment } from "./use-segment";

export const useRemoveSegmentAppetiteProduct = () => {
  const { segment } = useSegment();
  const { openConfirmation } = useOpenConfirmation();
  const { toast } = useToast();

  const [removeAppetiteProductsMutation, { loading: isRemovingAppetiteProducts }] =
    useRemoveAppetiteProductsFromSegmentMutation({
      awaitRefetchQueries: true,
      onCompleted: () => toast({ title: "Appetite product removed" }),
      refetchQueries: ["Segment"],
    });

  const removeAppetiteProduct = useCallback(
    async (
      appetiteProduct: AppetiteProductFragment,
      options?: Omit<MutationFunctionOptions<RemoveAppetiteProductsFromSegmentMutation>, "variables">
    ) => {
      const isConfirmed = await openConfirmation({
        title: "Remove appetite product",
        description: `Are you sure you want to remove the "${appetiteProduct.carrierName} ${
          appetiteProduct.carrierProductName
        }" appetite product from the "${segment!.name}" segment?`,
        buttonProps: { theme: "destructive", children: "Remove" },
      });

      if (!isConfirmed) {
        return;
      }

      return await removeAppetiteProductsMutation({
        variables: { input: { id: segment!.id, appetiteProductIds: [appetiteProduct.id] } },
        ...options,
      });
    },
    [segment?.id]
  );

  return {
    removeAppetiteProduct,
    isRemovingAppetiteProducts,
  };
};

import { AlbyToolCallFragment } from "src/generated/graphql";

export const adaptGraph = (graph: AlbyToolCallFragment) => {
  const { input: rawInput, output: rawOutput } = graph;
  const input = JSON.parse(rawInput);
  const invokeParams = JSON.parse(input.invokeParams);
  const output = JSON.parse(JSON.parse(rawOutput));
  const header = output?.slackBlocks?.find((block: any) => block.type === "header");

  return {
    ...graph,
    header,
    invokeParams: new URLSearchParams(invokeParams).toString(),
  };
};

import { FetchResult, MutationFunctionOptions } from "@apollo/client";
import React, { createContext, useContext, useEffect, useState } from "react";

import {
  AlbyActionType,
  ResumeGraphMutation,
  ResumeGraphMutationVariables,
  useResumableGraphQuery,
  useResumeGraphMutation,
} from "src/generated/graphql";

interface AlbyApprovalContext {
  threadId: string | null;
  loading: boolean;
  graphName: string;
  selectedTool: string;
  invokeParams: Record<string, string> | null;
  onToolSelectedParams: Record<string, any> | null;
  header: string | null;
  body: string | null;
  resumeLoading: boolean;
  disabled: boolean;
  approve: (
    brokerInput: any,
    options?: MutationFunctionOptions<ResumeGraphMutation, ResumeGraphMutationVariables>
  ) => Promise<FetchResult<ResumeGraphMutation>>;
  decline: (
    options?: MutationFunctionOptions<ResumeGraphMutation, ResumeGraphMutationVariables>
  ) => Promise<FetchResult<ResumeGraphMutation>>;
}

const AlbyApprovalContext = createContext<AlbyApprovalContext>({} as AlbyApprovalContext);

export const AlbyApprovalProvider = ({
  graphName,
  insuredId,
  selectedTool,
  invokeParams,
  children,
}: {
  graphName: string;
  insuredId: string;
  selectedTool: string;
  invokeParams: Record<string, string>;
  children: React.ReactNode;
}) => {
  const [disabled, setDisabled] = useState(false);
  const { data, loading } = useResumableGraphQuery({
    variables: {
      input: {
        name: graphName,
        selectedTool,
        insuredId,
        invokeParams: JSON.stringify(invokeParams),
      },
    },
  });

  const threadId = data?.resumableGraph?.threadId;
  const actualInvokeParams = data?.resumableGraph?.input
    ? JSON.parse(JSON.parse(data.resumableGraph.input).invokeParams)
    : null;
  const onToolSelectedParams = data?.resumableGraph?.output ? JSON.parse(JSON.parse(data.resumableGraph.output)) : null;
  const header = onToolSelectedParams?.slackBlocks?.find((block: any) => block.type === "header")?.text.text ?? null;
  const body = onToolSelectedParams?.slackBlocks.find((block: any) => block.type === "section")?.text.text ?? null;

  const [resumeGraph, { loading: resumeLoading }] = useResumeGraphMutation({
    refetchQueries: ["ResumableGraph"],
  });

  useEffect(() => {
    if (resumeLoading) {
      setDisabled(true);
    }
  }, [resumeLoading]);

  const approve = (
    brokerInput: any,
    options: MutationFunctionOptions<ResumeGraphMutation, ResumeGraphMutationVariables> = {}
  ) => {
    if (!threadId) {
      throw new Error("Missing threadId");
    }

    return resumeGraph({
      ...options,
      variables: {
        input: {
          threadId,
          actionType: AlbyActionType.Approve,
          brokerInput: brokerInput ? JSON.stringify(brokerInput) : undefined,
        },
      },
    });
  };

  const decline = (options: MutationFunctionOptions<ResumeGraphMutation, ResumeGraphMutationVariables> = {}) => {
    if (!threadId) {
      throw new Error("Missing threadId");
    }

    return resumeGraph({
      ...options,
      variables: {
        input: {
          threadId,
          actionType: AlbyActionType.Decline,
        },
      },
    });
  };

  return (
    <AlbyApprovalContext.Provider
      value={{
        threadId: threadId ?? null,
        loading,
        graphName,
        selectedTool,
        invokeParams: actualInvokeParams,
        onToolSelectedParams,
        header,
        body,
        approve,
        decline,
        resumeLoading,
        disabled,
      }}
    >
      {children}
    </AlbyApprovalContext.Provider>
  );
};

export const useAlbyApproval = () => {
  return useContext(AlbyApprovalContext);
};

import { type ComponentProps, type FC } from "react";
import { NumericFormat } from "react-number-format";

import { Input } from "@/components/ui/input";
import { cn } from "src/utils";

import { useField } from "../hooks/use-field";
import { FieldBase, FieldBaseProps } from "./field-base";
import { FIELD_BORDERLESS_CLASS_NAME } from "./field.constants";

export type FieldNumberProps = FieldBaseProps & {
  placeholder?: string;
  borderless?: boolean;
  onChange?: ComponentProps<typeof NumericFormat>["onValueChange"];
  onBlur?: ComponentProps<typeof NumericFormat>["onBlur"];
  inputProps?: ComponentProps<typeof NumericFormat>;
};

export const FieldNumber: FC<FieldNumberProps> = ({
  placeholder,
  borderless,
  onChange,
  onBlur,
  inputProps,
  readOnly,
  ...props
}) => {
  const { field, hasError } = useField({ name: props.name, disabled: props.disabled, inputProps });

  // !!IMPORTANT!!: We need to destructure the `onChange` from the `field` object to avoid passing
  // it to the `NumericFormat` component, because it is overriding the `onValueChange` prop.
  const { onChange: onFieldChange, ref: fieldRef, ...restField } = field;

  const parseNumber = (value: string) => {
    const numberValue = inputProps?.decimalScale ? Number.parseFloat(value) : Number.parseInt(value);
    return Number.isNaN(numberValue) ? undefined : numberValue;
  };

  const handleChange: FieldNumberProps["onChange"] = (value, sourceInfo) => {
    field.onChange(parseNumber(value.value));
    onChange?.(value, sourceInfo);
  };

  const handleBlur: FieldNumberProps["onBlur"] = (event) => {
    field.onBlur();
    onBlur?.(event);
  };

  return (
    <FieldBase {...props}>
      <NumericFormat
        readOnly={readOnly}
        {...restField}
        {...inputProps}
        placeholder={placeholder}
        onValueChange={handleChange}
        onBlur={handleBlur}
        customInput={Input}
        getInputRef={fieldRef}
        className={cn(
          {
            "border-destructive": hasError && !field.disabled,
            [FIELD_BORDERLESS_CLASS_NAME]: borderless,
          },
          inputProps?.className
        )}
      />
    </FieldBase>
  );
};

export interface FieldCurrencyProps extends FieldNumberProps {}

export const FieldCurrency: FC<FieldCurrencyProps> = (props) => (
  <FieldNumber
    {...props}
    inputProps={{
      prefix: "$",
      thousandSeparator: true,
      decimalScale: 2,
      ...props.inputProps,
    }}
  />
);

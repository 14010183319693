import { RouteObject } from "react-router-dom";

import { ErrorMessage } from "@/components/errors/error-message";
import { Launch, LaunchAgencies } from "@/launch/launch";
import { LaunchAgency } from "@/launch/launch-agency";
import { LaunchAgents } from "@/launch/launch-agents";

export const armRoutes: RouteObject[] = [
  {
    path: "launch",
    element: <Launch />,
    errorElement: <ErrorMessage />,
    children: [
      { index: true, element: <LaunchAgencies /> },
      {
        id: "agency",
        path: ":agencyId",
        element: <LaunchAgency />,
        children: [
          {
            index: true,
            element: <LaunchAgents />,
          },
        ],
      },
    ],
  },
];

import { ApolloProvider } from "@apollo/client";
import { QueryClient, QueryClientProvider } from "react-query";
import { RouterProvider } from "react-router-dom";

import { apolloClient } from "src/graphql/client";
import { Toaster } from "./components/ui/toaster";
import { TooltipProvider } from "./components/ui/tooltip";
import { PageProvider } from "./hooks/use-page";
import { ThemeProvider } from "./hooks/use-theme";
import { router } from "./router/router";

import "material-symbols/rounded.css";
import "./globals.css";
import { MetadataProvider } from "./hooks/use-metadata";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      suspense: true,
      useErrorBoundary: false,
    },
  },
});

export const App = () => (
  <QueryClientProvider client={queryClient}>
    <ApolloProvider client={apolloClient}>
      <ThemeProvider>
        <MetadataProvider>
          <PageProvider>
            <TooltipProvider delayDuration={222}>
              <RouterProvider router={router} />
              <Toaster />
            </TooltipProvider>
          </PageProvider>
        </MetadataProvider>
      </ThemeProvider>
    </ApolloProvider>
  </QueryClientProvider>
);

import { DropdownMenuItem } from "@/components/ui/dropdown-menu";
import { Icon } from "@/components/ui/icon";
import { OpportunityDetailsFragment, useCreateEmailDraftMutation } from "src/generated/graphql";

export const CreateEmail = ({ opportunity }: { opportunity: OpportunityDetailsFragment }) => {
  const [createEmailDraft] = useCreateEmailDraftMutation();

  const {
    id,
    insured: { agent, name },
  } = opportunity;

  const handleClicked = async () => {
    if (opportunity) {
      await createEmailDraft({
        variables: {
          input: {
            opportunityId: id,
            to: [agent.email],
            cc: ["submissions@quotewell.com"],
            subject: `Update on ${name}`,
            text: "",
          },
        },
        onCompleted: (data) => {
          if (data?.createEmailDraft) {
            window.open(data.createEmailDraft, "_blank");
          }
        },
      });
    }
  };

  return (
    <DropdownMenuItem onClick={handleClicked}>
      <Icon icon="mail" /> Send Email to Agent
    </DropdownMenuItem>
  );
};

import { MutationFunctionOptions } from "@apollo/client";
import { useCallback } from "react";

import { useOpenConfirmation } from "@/components/modal-provider";
import { useToast } from "@/hooks/use-toast";
import { DeleteSegmentRequirementMutation, useDeleteSegmentRequirementMutation } from "src/generated/graphql";

import { useSegment } from "./use-segment";

export const useDeleteSegmentRequirement = () => {
  const { segment } = useSegment();
  const { openConfirmation } = useOpenConfirmation();
  const { toast } = useToast();

  const [deleteSegmentRequirementMutation, { loading: isDeletingSegmentRequirement }] =
    useDeleteSegmentRequirementMutation({
      awaitRefetchQueries: true,
      onCompleted: () => toast({ title: "Segment requirement deleted" }),
      refetchQueries: ["Segment"],
    });

  const deleteSegmentRequirement = useCallback(
    async (id: string, options?: Omit<MutationFunctionOptions<DeleteSegmentRequirementMutation>, "variables">) => {
      const isConfirmed = await openConfirmation({
        title: "Delete Segment Requirement",
        description: "Are you sure you want to delete this segment requirement?",
        buttonProps: { theme: "destructive", children: "Delete" },
      });

      if (!isConfirmed) {
        return;
      }

      await deleteSegmentRequirementMutation({
        variables: { input: { id, segmentId: segment!.id } },
        ...options,
      });
    },
    [segment?.id]
  );

  return {
    deleteSegmentRequirement,
    isDeletingSegmentRequirement,
  };
};

import { useAtom } from "jotai";
import { useMutation, UseMutationOptions, useQueryClient } from "react-query";
import { authDataAtom, authTokenValidatedAtom, impersonatorAuthDataAtom } from "../atoms/atoms";
import { useAuthContext } from "../context/AuthContext";
import { StopImpersonatingUserDocument, StopImpersonatingUserMutation } from "../generated/operations";
import { userAccountQueryKeys } from "../utils/queryKeys";
import { GraphQLAuthError, GraphQLErrorResponse } from "./authError";

interface StopImpersonatingUserArgs {
  impersonatorEmail: string;
}

type TData = StopImpersonatingUserMutation["stopImpersonatingUser"];

export default function useStopImpersonatingUser(
  options: UseMutationOptions<TData, GraphQLErrorResponse<GraphQLAuthError>, StopImpersonatingUserArgs> = {}
) {
  const queryClient = useQueryClient();
  const [, setAuthData] = useAtom(authDataAtom);
  const [impersonatorAuthData, setImpersonatorAuthData] = useAtom(impersonatorAuthDataAtom);
  const [, setTokenValidated] = useAtom(authTokenValidatedAtom);
  const { authClientId, fetcher } = useAuthContext();

  return useMutation<TData, GraphQLErrorResponse<GraphQLAuthError>, StopImpersonatingUserArgs>(
    (args: StopImpersonatingUserArgs) => {
      if (!impersonatorAuthData) {
        throw new Error("Impersonator data is missing! Cannot stop impersonating user.");
      }

      return fetcher(
        StopImpersonatingUserDocument,
        {
          authClientId,
          ...args,
        },
        { Authorization: `Bearer ${impersonatorAuthData?.token.accessToken}` }
      ).then((data) => {
        setImpersonatorAuthData(null); // clear impersonator auth data, since we're not impersonating anymore
        return data.stopImpersonatingUser;
      });
    },
    {
      onSuccess: (data, vars, context) => {
        void options.onSuccess?.(data, vars, context);

        setAuthData({
          token: data.token,
          shouldRemember: true,
        });
        setTokenValidated(true);

        // Invalidate queries to force refetch with impersonator's user context
        void queryClient.invalidateQueries(userAccountQueryKeys.detail("myAccount"));
        queryClient.setQueryData(userAccountQueryKeys.detail("myAccount"), data.user);
      },
    }
  );
}

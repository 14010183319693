import { FC } from "react";
import { z } from "zod";

import { Group } from "@/components/group";
import { FieldCombobox } from "@/forms/fields/field-combobox";
import { SegmentFragment } from "src/generated/graphql";

import { RuleFormContent, RuleFormSchema, transformRuleFormValues } from "../../components/rule-form/rule-form";

export const CreateSegmentAppetiteProductRuleFormSchema = RuleFormSchema.extend({
  segmentAppetiteProductId: z.string().min(1, { message: "Please select a product" }),
}).transform(transformRuleFormValues);

export const UpdateSegmentAppetiteProductRuleFormSchema = RuleFormSchema.extend({
  id: z.string().min(1, { message: "ID is required" }),
  segmentAppetiteProductId: z.string().min(1, { message: "Please select a product" }),
}).transform(transformRuleFormValues);

export interface SegmentRuleFormProps {
  mode?: "create" | "update";
  segment?: SegmentFragment;
}

export const SegmentAppetiteProductRuleForm: FC<SegmentRuleFormProps> = ({ segment, mode }) => {
  const segmentAppetiteProductOptions = segment?.segmentAppetiteProducts.map((p) => ({
    label: `${p.appetiteProduct.carrierName} ${p.appetiteProduct.carrierProductName}`,
    value: p.id,
  }));

  return (
    <Group>
      <FieldCombobox
        label="Appetite product"
        name="segmentAppetiteProductId"
        options={segmentAppetiteProductOptions}
        readOnly={mode === "update"}
      />
      <RuleFormContent />
    </Group>
  );
};

import { QuoteState } from "src/generated/graphql";
import { StateMetadata } from "./states";

export enum DisplayQuoteStateBindRequestReceivedActions {
  Bind = "Bind Quote",
  Invalidate = "Invalidate Quote",
}

export enum DisplayQuoteStateDeliveredActions {
  Invalidate = "Invalidate Quote",
  ReceiveBindRequest = "Received Bind Request",
  ReceiveNotTaken = "Receive Not Taken Notice",
}

export enum DisplayQuoteStateNotTakenActions {
  Invalidate = "Invalidate Quote",
  ReceiveBindRequest = "Receive Bind Request",
}

export enum DisplayQuoteStateCreatedActions {
  Invalidate = "Invalidate Quote",
  Process = "Process Quote",
}

export enum DisplayQuoteStateProcessedActions {
  Deliver = "Deliver Quote",
  Invalidate = "Invalidate Quote",
}

export const QUOTE_STATE_METADATA: Record<QuoteState, StateMetadata> = {
  [QuoteState.Invalid]: {
    className: "text-slate-600",
    cardClassName: "outline-slate-500",
    icon: "close",
    label: "Invalid",
    title: "Invalid Quote",
    actionDisplayNames: {}, // No specific actions for Invalid state
  },
  [QuoteState.Created]: {
    className: "filled text-slate-600",
    cardClassName: "outline-slate-500",
    icon: "request_quote",
    label: "Created",
    title: "New Quote",
    actionDisplayNames: DisplayQuoteStateCreatedActions,
    displayQuoteNotes: true,
  },
  [QuoteState.Unredacted]: {
    className: "filled text-orange-600",
    cardClassName: "outline-orange-500",
    icon: "warning",
    label: "Unredacted",
    title: "Unredacted Quote",
    actionDisplayNames: DisplayQuoteStateCreatedActions,
    displayQuoteNotes: true,
  },
  [QuoteState.Redacted]: {
    className: "filled text-amber-600",
    cardClassName: "outline-amber-500",
    icon: "task",
    label: "Redacted",
    title: "Redacted Quote",
    actionDisplayNames: DisplayQuoteStateCreatedActions,
    displayQuoteNotes: true,
  },
  [QuoteState.Processed]: {
    className: "filled text-emerald-600",
    cardClassName: "outline-emerald-500",
    icon: "send",
    label: "Ready to Send",
    title: "Ready to Send",
    actionDisplayNames: DisplayQuoteStateProcessedActions,
    displayQuoteNotes: true,
  },
  [QuoteState.Delivered]: {
    className: "filled text-blue-600",
    cardClassName: "outline-blue-500",
    icon: "mail",
    label: "Delivered",
    title: "Thank you for the chance to earn your business!",
    description: "Please email your broker directly with bind orders when you are ready to proceed.",
    actionDisplayNames: DisplayQuoteStateDeliveredActions,
  },
  [QuoteState.BindRequestReceived]: {
    className: "text-emerald-600",
    cardClassName: "outline-emerald-500",
    icon: "thumb_up",
    label: "Bind Request Received",
    title: "Thank you for the bind order!",
    description: "We are currently processing it and will follow up with next steps shortly.",
    actionDisplayNames: DisplayQuoteStateBindRequestReceivedActions,
  },
  [QuoteState.Bound]: {
    className: "filled text-purple-600",
    cardClassName: "outline-purple-500",
    icon: "verified",
    label: "Bound",
    title: "Thank you for binding with QuoteWell!",
    description: "Please check the policies section for policy details.",
    actionDisplayNames: {}, // No specific actions for Bound state
  },
  [QuoteState.NotTaken]: {
    className: "text-red-600",
    cardClassName: "outline-red-500",
    icon: "thumb_down",
    label: "Not Taken",
    actionDisplayNames: DisplayQuoteStateNotTakenActions,
  },
};

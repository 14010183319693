import { Navigate, RouteObject } from "react-router-dom";

import { Admin } from "@/admin/admin";
import { AppetiteNote } from "@/admin/appetite-notes/appetite-note";
import { AppetiteNotes } from "@/admin/appetite-notes/appetite-notes";
import { Appetite } from "@/admin/appetite/appetite";
import { Enablement } from "@/admin/enablement/enablement";
import { Tags } from "@/admin/tags/tags";
import { ErrorMessage } from "@/components/errors/error-message";
import { DocumentLabelDetailsSidePanel } from "@/file-processing-pipeline/document-label-details/document-label-details";
import { FileProcessingPipeline } from "@/file-processing-pipeline/file-processing-pipeline";
import { FileProcessorDetailsSidePanel } from "@/file-processing-pipeline/file-processor-details/file-processor-details";
import { IssuesSidePanel } from "@/file-processing-pipeline/issues/issues";
import { ExtractedLabels } from "@/label-management/extracted-labels";
import { ExtractedLabel } from "@/label-management/extracted-labels/details";
import { LabelManagement } from "@/label-management/label-management";
import { PrimaryLabels } from "@/label-management/primary-labels";
import { PrimaryLabel } from "@/label-management/primary-labels/details";

export const adminRoutes: RouteObject[] = [
  {
    path: "admin",
    element: <Admin />,
    errorElement: <ErrorMessage />,
    children: [
      {
        index: true,
        element: <Navigate to="assignments" replace />,
      },
      {
        path: "appetite",
        element: <Appetite />,
      },
      {
        path: "appetite-notes",
        children: [
          {
            index: true,
            element: <AppetiteNotes />,
          },
          {
            path: "create",
            element: <AppetiteNote mode="create" />,
          },
          {
            path: ":appetiteNoteId/edit",
            element: <AppetiteNote mode="edit" />,
          },
        ],
      },
      {
        path: "assignments",
        element: <Enablement />,
      },
      {
        path: "tags",
        element: <Tags />,
      },
    ],
  },
  {
    path: "label-management",
    element: <LabelManagement />,
    errorElement: <ErrorMessage />,
    children: [
      {
        index: true,
        element: <Navigate to="extracted-labels" replace />,
      },
      {
        path: "extracted-labels",
        element: <ExtractedLabels />,
        children: [
          {
            path: ":id",
            element: <ExtractedLabel />,
          },
        ],
      },
      {
        path: "primary-labels",
        element: <PrimaryLabels />,
        children: [
          {
            path: ":id",
            element: <PrimaryLabel />,
          },
        ],
      },
    ],
  },
  {
    path: "file-processing-pipeline",
    element: <FileProcessingPipeline />,
    errorElement: <ErrorMessage />,
    children: [
      {
        path: "file-processor/:fileProcessorId",
        element: <FileProcessorDetailsSidePanel />,
      },
      {
        path: "document-label/:documentLabelId",
        element: <DocumentLabelDetailsSidePanel />,
      },
      {
        path: "issues",
        element: <IssuesSidePanel />,
      },
    ],
  },
];

import { partition } from "lodash";

import { internalCohortMetadata, internalCohorts } from "@/metadata/affiliations";
import { AgencyAffiliationFragment, useAgencyAffiliationQuery } from "src/generated/graphql";
import { cn } from "src/utils";
import { HasInternalRole } from "./has-role";

export const AgencyAffiliations = ({ id }: { id: string }) => {
  const { data: { agency: { affiliations } } = { agency: { affiliations: [] } } } = useAgencyAffiliationQuery({
    variables: {
      id,
    },
  });

  if (!affiliations) {
    return null;
  }

  const [privateAffiliations, publicAffiliations] = partition(affiliations, ({ id }) => internalCohorts.includes(id));

  return (
    <>
      <HasInternalRole>
        {privateAffiliations[0] && (
          <div className="space-y-0.5 text-2xs">
            {privateAffiliations.map((affiliation) => (
              <Cohort key={affiliation.id} affiliation={affiliation} />
            ))}
          </div>
        )}
      </HasInternalRole>
      {publicAffiliations[0] && (
        <div className="space-y-0.5 text-2xs">
          {publicAffiliations.map((affiliation) => (
            <Affiliation key={affiliation.id} affiliation={affiliation} />
          ))}
        </div>
      )}
    </>
  );
};

const Affiliation = ({ affiliation }: { affiliation: AgencyAffiliationFragment }) => (
  <div className="flex gap-2 items-center">
    <div className="flex flex-auto gap-3 items-center truncate">
      {affiliation.logoUrl ? (
        <img src={affiliation?.logoUrl} className="max-h-8 max-w-24 rounded" />
      ) : (
        <span className="font-semibold truncate">{affiliation?.name}</span>
      )}
    </div>
    <HasInternalRole>
      {(affiliation.brokerageCommission || affiliation.bindingCommission) && (
        <div className="flex-none gap grid grid-cols-2 items-center leading-none w-24">
          {affiliation.brokerageCommission && (
            <>
              <span>Brokerage:</span>
              <span className="text-right">{`${Number(affiliation?.brokerageCommission).toFixed(2)}%`}</span>
            </>
          )}
          {affiliation.bindingCommission && (
            <>
              <span>Binding:</span>
              <span className="text-right">{`${Number(affiliation?.bindingCommission).toFixed(2)}%`} </span>
            </>
          )}
        </div>
      )}
    </HasInternalRole>
  </div>
);

const Cohort = ({ affiliation }: { affiliation: AgencyAffiliationFragment }) => {
  const { name, iconClassName } = internalCohortMetadata[affiliation.id];
  return (
    <div className="flex gap-1.5 items-center">
      <span className={cn("bg-linear-150 block rounded-full size-2", iconClassName)} />
      <span className="font-semibold text-foreground truncate">{name}</span>
    </div>
  );
};

import { Outlet, useLocation } from "react-router";

import { NavigateArm } from "@/components/navigate/navigate-arm";
import { NavigateBroker } from "@/components/navigate/navigate-broker";
import { ScrollPane } from "@/components/scroll-pane";
import { Bar, BarLink, BarLinks } from "@/components/ui/bar";
import { useAccount } from "@/hooks/use-account";
import { SetMetadata } from "@/hooks/use-metadata";
import { Roles } from "src/generated/graphql";
import { BankanFilter } from "./bankan-filter";
import { SnoozeDialog } from "./snooze/snooze-dialog";

export const Bankan = () => {
  const { user } = useAccount();
  const { pathname } = useLocation();

  const segment = pathname.split("/")[2];
  const id = user.roles.some((role) => [Roles.Arm, Roles.Broker].includes(role)) ? user.id : "";

  return (
    <>
      <SetMetadata title="Bankan" />
      <ScrollPane>
        <Bar>
          <BarLinks>
            <BarLink to="dj">DJ</BarLink>
            <BarLink to={`broker/${id}`}>Broker</BarLink>
            <BarLink to={`sts/${id}`}>STS</BarLink>
            <BarLink to={`arm/${id}`}>ARM</BarLink>
          </BarLinks>
          <div className="flex gap-3 items-center ml-auto">
            {segment === "arm" ? <NavigateArm path="/bankan/arm" /> : <NavigateBroker path={`/bankan/${segment}`} />}
            <BankanFilter />
          </div>
        </Bar>
        <div className="flex flex-auto overflow-auto">
          <Outlet />
        </div>
        <SnoozeDialog />
      </ScrollPane>
    </>
  );
};

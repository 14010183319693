import { useNodes } from "@xyflow/react";
import cloneDeep from "lodash/cloneDeep";
import type { FC } from "react";
import { useNavigate } from "react-router";

import { Group } from "@/components/group";
import { useModal } from "@/components/modal-provider";
import { Loading } from "@/components/ui/loading";
import { FieldHidden } from "@/forms/fields/field-hidden";
import { Form } from "@/forms/form";
import { FormReset } from "@/forms/form-reset";
import { FormSubmit } from "@/forms/form-submit";
import { toast } from "@/hooks/use-toast";
import {
  CreatePipelineVersionInput,
  FileProcessingPipelineQuery,
  useCreateFilePipelineVersionMutation,
  useFileProcessingPipelineQuery,
  useFileProcessorQuery,
} from "src/generated/graphql";

import {
  convertPipelineDataToNodesAndEdges,
  findNodeById,
  findParentNodeByChildId,
} from "../../file-processing-pipeline.helpers";

export interface RemoveProcessorFormValues {
  id: string;
}

export interface RemoveProcessorFormProps {
  processorId: string;
}

export const RemoveProcessorFormContent: FC<RemoveProcessorFormProps> = () => {
  const { closeModal } = useModal();

  return (
    <Group className="gap-6 mt-2">
      <FieldHidden name="id" />

      <div className="flex justify-end gap-2">
        <FormReset onClick={closeModal}>Cancel</FormReset>
        <FormSubmit theme="destructive">Remove processor</FormSubmit>
      </div>
    </Group>
  );
};

export const RemoveProcessorForm: FC<RemoveProcessorFormProps> = (props) => {
  const navigate = useNavigate();
  const { closeModal } = useModal();
  const { data: pipelineData, loading: pipelineDataLoading } = useFileProcessingPipelineQuery();
  const { data: fileProcessorData, loading: fileProcessorDataLoading } = useFileProcessorQuery({
    variables: { id: props.processorId },
  });
  const [createPipelineVersion] = useCreateFilePipelineVersionMutation({
    // TODO: We need to figure out the `optimisticResponse` for this mutation.
    // update: (cache, { data }) => {
    //   cache.writeQuery({
    //     query: FileProcessingPipelineDocument,
    //     data: { fileProcessingPipeline: data?.createPipelineVersion },
    //   });
    // },
  });

  const handleSubmit = async (values: RemoveProcessorFormValues) => {
    if (!pipelineData || !fileProcessorData) {
      return;
    }

    const input = getRemoveProcessorInput(values.id, pipelineData.fileProcessingPipeline);

    if (!input) {
      return;
    }

    await createPipelineVersion({
      variables: { input },
      refetchQueries: ["FileProcessingPipeline", "FileProcessor", "FileProcessingPipelineIssues"],
    });

    navigate(`/file-processing-pipeline`, { replace: true });
    toast({ title: `${fileProcessorData.fileProcessor?.name} processor removed` });
    closeModal();
  };

  if (!pipelineData && pipelineDataLoading && !fileProcessorData && fileProcessorDataLoading) {
    return <Loading />;
  }

  return (
    <Form defaultValues={{ id: props.processorId }} onSubmit={handleSubmit}>
      <RemoveProcessorFormContent {...props} />
    </Form>
  );
};

export interface UseRemoveProcessorFormModalOptions {
  processorId?: string;
}

export const useRemoveProcessorFormModal = ({ processorId }: UseRemoveProcessorFormModalOptions) => {
  const { openModal } = useModal();
  const nodes = useNodes();

  const processor = findNodeById(processorId, nodes);

  return {
    openRemoveProcessorForm: async () => {
      if (!processorId || !processor) {
        return;
      }

      await openModal(() => <RemoveProcessorForm processorId={processorId} />, {
        type: "dialog",
        title: "Are you sure you want to remove the processor?",
        description: "There is currently no way to undo this action. However, you may re-add the processor later.",
      });
    },
  };
};

function getRemoveProcessorInput(
  processorId: string,
  pipelineData: FileProcessingPipelineQuery["fileProcessingPipeline"]
): CreatePipelineVersionInput | null {
  const pipelineDataCopy = cloneDeep(pipelineData);
  // QUESTION: Can we just use the `useNodes` and `useEdges` hooks here?
  const { nodes, edges } = convertPipelineDataToNodesAndEdges(pipelineDataCopy);

  const currentNode = findNodeById(processorId, nodes);

  if (!currentNode) {
    return null;
  }

  const parentNode = findParentNodeByChildId(currentNode.id, nodes, edges);

  if (!parentNode) {
    return null;
  }

  const existingLabel = pipelineDataCopy.pipeline.transitions.find(
    (transition) => transition.label === parentNode.data.name && transition.sourceNodeName === parentNode.data.category
  );

  if (!existingLabel) {
    return null;
  }

  existingLabel.destinationNodes = existingLabel.destinationNodes.filter((node) => node.id !== processorId);

  const connectedNodes = [
    pipelineDataCopy.pipeline.initial,
    ...pipelineDataCopy.pipeline.transitions.flatMap((transition) => transition.destinationNodes),
  ];

  // Filter out any nodes that are now disconnected as a result of removing the processor
  const filteredTransitions = pipelineDataCopy.pipeline.transitions.filter((transition) =>
    connectedNodes.some((node) => node.name === transition.sourceNodeName)
  );

  return {
    name: "FileUploadPipeline",
    pipeline: {
      initial: pipelineDataCopy.pipeline.initial,
      transitions: filteredTransitions,
    },
  };
}

import { Outlet, useParams } from "react-router";

import { SectionDescription, SectionHeader, SectionTitle } from "@/components/section";
import { Bar, BarLink, BarLinks } from "@/components/ui/bar";
import { SetMetadata } from "@/hooks/use-metadata";
import { useAgencyQuery } from "src/generated/graphql";
import { LaunchAgencyMenu } from "./launch-agency-menu";

export const LaunchAgency = () => {
  const { agencyId } = useParams();

  const { data: { agency } = {} } = useAgencyQuery({
    variables: {
      id: agencyId!,
    },
    skip: !agencyId,
  });

  if (!agency) {
    return null;
  }

  const { name, owner } = agency;

  return (
    <>
      <SetMetadata title={name} crumb menu={<LaunchAgencyMenu />} />
      <SectionHeader>
        <SectionTitle>
          <h1>{name}</h1>
        </SectionTitle>
        <SectionDescription>{owner ? `${owner.firstName} ${owner.lastName}` : "No owner"}</SectionDescription>
      </SectionHeader>
      <Bar>
        <BarLinks>
          <BarLink to="." end>
            Agents &amp; Insureds
          </BarLink>
        </BarLinks>
      </Bar>
      <Outlet />
    </>
  );
};

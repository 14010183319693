import { assertNever } from "@cp/toolkit";
import { FC, useState } from "react";
import { useFormContext } from "react-hook-form";
import { z } from "zod";

import { ButtonGroup } from "@/components/button-group";
import { CglCodeSelector } from "@/components/cgl-code-selector";
import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from "@/components/ui/card";
import { Form } from "@/forms/form";
import { FormReset } from "@/forms/form-reset";
import { FormSubmit } from "@/forms/form-submit";
import { useInsured } from "@/hooks/use-insured";
import { useToast } from "@/hooks/use-toast";
import { useUpsertSearchParams } from "@/hooks/use-upsert-search-params";
import { BusinessClassFragment, BusinessClassSystem, useUpdateInsuredMutation } from "src/generated/graphql";
import { parseError } from "src/utils";

import { Group } from "@/components/group";
import { Separator } from "@/components/ui/separator";
import { AiClassCodeGuesser } from "./ai-class-code-guesser";

const InsuredFormSchema = z.object({
  naicsCode: z
    .string()
    .transform((val) => (val === "" ? null : val))
    .refine((val) => val === null || val.length === 6, { message: "NAICS code must be 5 digits" })
    .nullable()
    .optional(),
  cglCode: z
    .string()
    .transform((val) => (val === "" ? null : val))
    .refine((val) => val === null || val.length === 5, { message: "CGL code must be 5 digits" })
    .nullable()
    .optional(),
});
type InsuredFormValues = z.infer<typeof InsuredFormSchema>;

export const Classification = () => {
  const [editing, setEditing] = useState(false);

  return (
    <Card>
      {editing ? (
        <UpdateInsuredForm setEditing={setEditing} />
      ) : (
        <>
          <CardHeader>
            <CardTitle>Business Classification</CardTitle>
          </CardHeader>
          <ClassCodes />
          <CardFooter>
            <Button variant="secondary" display="flex" onClick={() => setEditing(true)}>
              Edit Classification
            </Button>
          </CardFooter>
        </>
      )}
    </Card>
  );
};

const UpdateInsuredForm = ({ setEditing }: { setEditing: (bool: boolean) => void }) => {
  const [results, setResults] = useState<BusinessClassFragment[] | undefined>();
  const { toast } = useToast();
  const { insured } = useInsured();
  const [updateInsured] = useUpdateInsuredMutation();

  const defaultValues: InsuredFormValues = {
    ...insured,
    // temporarily handles only one NAICS code for simplicity
    naicsCode: insured.naicsCodes[0] ?? undefined,
    cglCode: insured.isoCglCodes[0] ?? undefined,
  };

  const handleSubmit = async ({ naicsCode, cglCode }: InsuredFormValues) => {
    // necessary to assign this to a variable (naicsCode) satisfy TS type checker in updateClient()
    // setEditing(false);
    await updateInsured({
      variables: {
        input: {
          id: insured.id,
          // temporarily handles only one NAICS code for simplicity
          naicsCodes: naicsCode ? [naicsCode] : [],
          isoCglCodes: cglCode ? [cglCode] : [],
        },
      },
      refetchQueries: ["SearchAppetiteForOpportunity"],
      onCompleted: () => setEditing(false),
      onError: (error) => toast({ title: "Error", description: parseError(error.message), variant: "destructive" }),
    });
  };

  return (
    <>
      <CardHeader>
        <CardTitle>Edit Business Classification</CardTitle>
        <CardDescription>
          You can select classification codes yourself, or input a description of the insured&apos;s business below, and
          our AI-powered tool will make some suggestions.
        </CardDescription>
      </CardHeader>
      <AiClassCodeGuesser setResults={setResults} />
      <Form onSubmit={handleSubmit} validationSchema={InsuredFormSchema} defaultValues={defaultValues}>
        <UpdateInsuredFormContent results={results} />
        <ButtonGroup className="px-6 pb-6">
          <FormReset onClick={() => setEditing(false)}>Cancel</FormReset>
          <FormSubmit />
        </ButtonGroup>
      </Form>
    </>
  );
};

export interface UpdateInsuredFormContentProps {
  results?: BusinessClassFragment[];
}

const UpdateInsuredFormContent: FC<UpdateInsuredFormContentProps> = ({ results }) => {
  const formMethods = useFormContext();

  const handleResultClick = (result: BusinessClassFragment) => {
    const fieldName = result.system === BusinessClassSystem.Naics ? "naicsCode" : "cglCode";

    formMethods.setValue(fieldName, result.code);
  };

  return (
    <>
      {results ? (
        <div className="bg-accent border-y">
          {results.length > 0 ? (
            <>
              <h6 className="mx-6 my-3 text-muted-foreground">Click on a result below to select it</h6>
              <div className="divide-y">
                {results.map((code) => (
                  <Row code={code} onClick={handleResultClick} key={code.code} />
                ))}
              </div>
            </>
          ) : (
            <h6 className="mx-6 my-3 text-muted-foreground">No results found.</h6>
          )}
        </div>
      ) : (
        <Separator />
      )}
      <Group className="p-6">
        <CglCodeSelector
          name="naicsCode"
          label="NAICS Code"
          placeholder="Search NAICS Classifications"
          classSystems={[BusinessClassSystem.Naics]}
          useCodeAsDisplayValue={false}
        />
        <CglCodeSelector
          name="cglCode"
          label="CGL Code"
          placeholder="Search CGL Classifications"
          classSystems={[BusinessClassSystem.IsoGl]}
          useCodeAsDisplayValue={false}
        />
      </Group>
    </>
  );
};

const ClassCodes = () => {
  const { insured } = useInsured();
  // Reverse the order in which we display the systems to match how we display them in editing mode.
  const businessClasses = [...insured.businessClasses].reverse();
  return (
    <CardContent className="space-y-3">
      {businessClasses.map((bc) => (
        <BusinessClassPill businessClass={bc} key={`${bc.system}:${bc.code}`} />
      ))}
    </CardContent>
  );
};

const BusinessClassPill: FC<{ businessClass: BusinessClassFragment }> = ({ businessClass }) => {
  const [, upsertSearchParams] = useUpsertSearchParams();
  return (
    <div>
      <Badge className="cursor-pointer text-2xs" onClick={() => upsertSearchParams({ term: businessClass.code })}>
        {`${systemToFriendly(businessClass.system)}: ${businessClass.code}`}
      </Badge>
      <p className="text-xs italic pt-1">{businessClass.description}</p>
    </div>
  );
};

export const systemToFriendly = (system: BusinessClassSystem) => {
  switch (system) {
    case BusinessClassSystem.IsoGl:
      return "CGL";
    case BusinessClassSystem.Naics:
      return "NAICS";
    default:
      assertNever(system);
      return system;
  }
};

interface RowProps {
  code: BusinessClassFragment;
  onClick: (code: BusinessClassFragment) => void;
}

const Row: FC<RowProps> = ({ code, onClick }) => (
  <div onClick={() => onClick(code)} className="cursor-pointer gap-2 grid grid-cols-[6rem_1fr] px-6 py-3 text-xs">
    <span>
      <Badge variant="secondary" className="gap-1">
        <span>{systemToFriendly(code.system)}</span>
        <span>{code.code}</span>
      </Badge>
    </span>
    {code.description}
  </div>
);

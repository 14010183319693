import { forwardRef, useMemo } from "react";
import { useFormContext } from "react-hook-form";

import { Button, ButtonProps } from "@/components/ui/button";

export interface FormResetProps extends ButtonProps {
  onlyShowIfDirty?: boolean;
  onlyEnableIfDirty?: boolean;
}

export const FormReset = forwardRef<HTMLButtonElement, FormResetProps>(
  (
    { children = "Cancel", variant = "outline", onClick, disabled, onlyShowIfDirty, onlyEnableIfDirty, ...props },
    ref
  ) => {
    const { formState, reset } = useFormContext();

    const isDisabled = useMemo(() => {
      if (typeof disabled === "boolean") {
        return disabled;
      }

      if (onlyEnableIfDirty) {
        return !formState.isDirty;
      }

      return formState.isSubmitting;
    }, [disabled, onlyEnableIfDirty, formState.isDirty, formState.isSubmitting]);

    const handleClick: FormResetProps["onClick"] = (event) => {
      reset();
      onClick?.(event);
    };

    if (onlyShowIfDirty && !formState.isDirty) {
      return null;
    }

    return (
      <Button ref={ref} {...props} type="button" variant={variant} disabled={isDisabled} onClick={handleClick}>
        {children}
      </Button>
    );
  }
);

FormReset.displayName = "FormReset";

import { Group } from "@/components/group";
import { Button } from "@/components/ui/button";
import { Card, CardContent, CardFooter, CardHeader, CardTitle } from "@/components/ui/card";
import { Icon } from "@/components/ui/icon";
import { Separator } from "@/components/ui/separator";
import { FieldTextarea } from "@/forms/fields/field-textarea";
import { useAlbyApproval } from "@/hooks/use-alby-approval";
import { useInsured } from "@/hooks/use-insured";
import { FC } from "react";
import { SubmissionFragment } from "src/generated/graphql";

export interface AlbySubmissionApprovalCardProps {
  submission: SubmissionFragment;
}

export const AlbySubmissionApprovalCard: FC<AlbySubmissionApprovalCardProps> = ({ submission }) => {
  const { insured } = useInsured();
  const { disabled } = useAlbyApproval();

  return (
    <Card className="hover-outline outline-primary">
      <CardHeader>
        <CardTitle className="text-primary">
          <Icon icon="smart_toy" />
          <span className="mr-auto">May I submit to {submission.appetiteProduct.carrierName}?</span>
        </CardTitle>
      </CardHeader>
      <CardContent className="space-y-4">
        <ul className="space-y-1.5">
          <ChecklistItem>
            We have all required information for the insured <strong>{insured.name}</strong>.
          </ChecklistItem>
          <ChecklistItem>
            <strong>
              {submission.appetiteProduct.carrierName} {submission.appetiteProduct.carrierProductName}
            </strong>{" "}
            is one of the markets on this plan.
          </ChecklistItem>
        </ul>
        <p>I&apos;ve drafted an email using our standard submission template. It&apos;ll be sent to:</p>
        <ul className="list-disc ml-6">
          {submission.appetiteProduct.submissionCarrierContacts?.map((contact, key) => (
            <li key={key}>
              <a href={`mailto:{contact.email}`} className="underline">
                {contact.firstName} {contact.lastName} ({contact.email})
              </a>
            </li>
          ))}
        </ul>
        <p>Upon approval, I&apos;ll send this email from your inbox straightaway!</p>
        <p className="italic">&mdash; Alby</p>
      </CardContent>

      <Group className="p-6 pt-0">
        <Separator />
        <FieldTextarea
          description="Add an (optional) custom message at the beginning of the email."
          label="Custom Message"
          name="customMessage"
        />
      </Group>

      <CardFooter>
        <Button type="submit" theme="primary" className="ml-auto" disabled={disabled}>
          Approve
        </Button>
      </CardFooter>
    </Card>
  );
};

const ChecklistItem = ({ children }: { children: React.ReactNode }) => (
  <li className="flex items-start gap-3 ml-px">
    <Icon icon="check_circle" className="filled mt-1 text-success" />
    <div>{children}</div>
  </li>
);

import { FC } from "react";

import { MoreMenu } from "@/components/more-menu";
import { Button } from "@/components/ui/button";
import { DropdownMenuItem, DropdownMenuLabel, DropdownMenuSeparator } from "@/components/ui/dropdown-menu";
import { Icon } from "@/components/ui/icon";
import { cn } from "src/utils";

import { Spinner } from "@/components/ui/loading";
import { useSegment } from "../hooks/use-segment";
import { SegmentMenuItemActiveToggle } from "./menu-items/segment-menu-item-active-toggle";
import { SegmentMenuItemAutoApproveToggle } from "./menu-items/segment-menu-item-auto-approve-toggle";
import { SegmentMenuItemDelete } from "./menu-items/segment-menu-item-delete";
import { SegmentMenuItemEdit } from "./menu-items/segment-menu-item-edit";
import { SegmentMenuItemSplitToggle } from "./menu-items/segment-menu-item-split-toggle";

export const SegmentMenu: FC = () => {
  const { segment, isLoadingSegment, toggleSegmentActive, toggleSegmentSplit, toggleSegmentAutoApprove } = useSegment();

  if (isLoadingSegment && !segment) {
    return <Spinner />;
  }

  if (!segment) {
    return null;
  }

  return (
    <>
      <Button
        onClick={() => toggleSegmentActive(!segment.active)}
        variant="outline"
        size="xs"
        className={cn("gap-2 cursor-pointer", segment.active && "border-success!")}
      >
        <Icon icon="circle" className={cn(segment.active ? "filled text-success" : "opacity-25")} />
        {segment.active ? "Active" : "Inactive"}
      </Button>

      <Button
        onClick={() => toggleSegmentSplit(!segment.split)}
        variant="outline"
        size="xs"
        className={cn("gap-2 cursor-pointer", segment.split && "border-success!")}
      >
        <Icon icon="splitscreen_portrait" className={cn(segment.split && "border-primary!")} />
        {`Splitting ${segment.split ? "Enabled" : "Disabled"}`}
      </Button>

      <Button
        onClick={() => toggleSegmentAutoApprove(!segment.autoApproveAlby)}
        variant="outline"
        size="xs"
        className={cn("gap-2 cursor-pointer", segment.autoApproveAlby && "border-primary!")}
      >
        <Icon
          icon="smart_toy"
          className={cn("text-sm", segment.autoApproveAlby ? "filled text-primary" : "opacity-25")}
        />
        Auto-approve {segment.autoApproveAlby ? "on" : "off"}
      </Button>

      <MoreMenu className="-mr-1.5">
        <DropdownMenuLabel asChild>
          <SegmentMenuItemActiveToggle />
        </DropdownMenuLabel>

        <DropdownMenuSeparator />

        <DropdownMenuLabel asChild>
          <SegmentMenuItemSplitToggle />
        </DropdownMenuLabel>

        <DropdownMenuSeparator />

        <DropdownMenuLabel asChild>
          <SegmentMenuItemAutoApproveToggle />
        </DropdownMenuLabel>

        <DropdownMenuSeparator />

        <DropdownMenuItem asChild>
          <SegmentMenuItemEdit />
        </DropdownMenuItem>

        <DropdownMenuItem asChild>
          <SegmentMenuItemDelete />
        </DropdownMenuItem>
      </MoreMenu>
    </>
  );
};

import { MaterialSymbol } from "material-symbols";

export const internalCohortMetadata: Record<string, { name: string; icon: MaterialSymbol; iconClassName: string }> = {
  "02e3f4e8-1957-49cb-89eb-7ce647c89163": {
    name: "Platinum Agency",
    icon: "trophy",
    iconClassName: "from-slate-500 via-slate-400 to-slate-900",
  },
  "978f7f6f-c27b-45ed-b6f2-30f395a06d1f": {
    name: "Gold Agency",
    icon: "license",
    iconClassName: "from-yellow-500 via-yellow-400 to-yellow-900",
  },
  "38cfb6ae-8103-4b1c-b2d6-29c3b4034f4a": {
    name: "Silver Agency",
    icon: "license",
    iconClassName: "from-neutral-500 via-neutral-400 to-neutral-900",
  },
};

export const internalCohorts = Object.keys(internalCohortMetadata);

// export const cohorts = [
//   ...internalCohorts,
//   "729aec1e-8d34-47a0-8b33-c3ed521a53a0", // FirstConnect
// ];

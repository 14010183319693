import { DefaultValues } from "react-hook-form";
import { input, z, ZodObject } from "zod";

import { ButtonGroup } from "@/components/button-group";
import { CglCodeSelector } from "@/components/cgl-code-selector";
import { Group } from "@/components/group";
import { Bar } from "@/components/ui/bar";
import { FieldInput } from "@/forms/fields/field-input";
import { FieldTextarea } from "@/forms/fields/field-textarea";
import { Form } from "@/forms/form";
import { FormReset } from "@/forms/form-reset";
import { FormSubmit } from "@/forms/form-submit";
import { VerticalFragment } from "src/generated/graphql";
import { cn } from "src/utils";

interface VerticalFormProps<T extends ZodObject<any>> {
  vertical?: VerticalFragment;
  schema: T;
  onSubmit: (values: z.infer<T>) => unknown | Promise<unknown>;
  onCancel?: () => void;
  defaultValues?: DefaultValues<input<T>>;
  className?: string;
}

export const VerticalForm = <T extends ZodObject<any>>({
  schema,
  onSubmit,
  onCancel,
  defaultValues,
  className,
}: VerticalFormProps<T>) => {
  const handleSubmit = async (values: z.infer<typeof schema>) => await onSubmit(values);

  return (
    <Form
      validationSchema={schema}
      onSubmit={handleSubmit}
      defaultValues={defaultValues}
      className={cn("flex flex-auto flex-col gap-6", className)}
    >
      <div className="flex-auto p-6">
        <Group>
          <FieldInput label="Name" name="name" />
          <FieldTextarea label="Description" name="description" optional />
          <CglCodeSelector multiple optional />
        </Group>
      </div>
      <Bar as="footer">
        <ButtonGroup>
          {onCancel && <FormReset onClick={onCancel} />}
          <FormSubmit className="ml-auto" />
        </ButtonGroup>
      </Bar>
    </Form>
  );
};

import React, { useState } from "react";

import { Button } from "@/components/ui/button";
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogTrigger } from "@/components/ui/dialog";
import { Icon } from "@/components/ui/icon";
import { Tooltip, TooltipContent, TooltipTrigger } from "@/components/ui/tooltip";
import { useMarketingPlan } from "@/hooks/use-marketing-plan";
import { useToast } from "@/hooks/use-toast";
import {
  CarrierContactFragment,
  CarrierContactType,
  useAddCarrierContactToAppetiteProductMutation,
} from "src/generated/graphql";

import { CarrierContactForm } from "./carrier-contact-form";
import { CarrierContactSelector } from "./carrier-contact-selector";

interface Props {
  type: CarrierContactType;
  contacts: CarrierContactFragment[];
  appetiteProductId: string;
}

export const CreateCarrierContactButton: React.FC<Props> = ({ type, appetiteProductId }) => {
  const { toast } = useToast();
  const { refetchQueries } = useMarketingPlan();
  const [open, setOpen] = React.useState(false);
  const [showForm, setShowForm] = useState(false);
  const [addCarrierContact] = useAddCarrierContactToAppetiteProductMutation();

  const handleCreateComplete = () => {
    setOpen(false);
    setTimeout(() => setShowForm(false), 300);
  };

  const handleSelected = (selected: CarrierContactFragment | null) => {
    if (selected) {
      if (selected.id === "new") {
        setShowForm(true);
      } else {
        void addCarrierContact({
          variables: { input: { id: selected.id, appetiteProductId, type } },
          onCompleted: () => {
            toast({ title: "Contact added" });
            setOpen(false);
          },
          refetchQueries,
        });
      }
    }
  };

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <Tooltip>
        <TooltipTrigger asChild>
          <DialogTrigger asChild>
            <Button variant="ghost" size="xs" display="icon" onClick={() => setOpen(true)}>
              <Icon icon="add_2" />
            </Button>
          </DialogTrigger>
        </TooltipTrigger>
        <TooltipContent>Create / Add Contact</TooltipContent>
      </Tooltip>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>{showForm ? "Create" : "Add"} Carrier Contact</DialogTitle>
        </DialogHeader>

        {showForm ? (
          <CarrierContactForm appetiteProductId={appetiteProductId} type={type} onCompleted={handleCreateComplete} />
        ) : (
          <CarrierContactSelector selected={null} setSelected={handleSelected} />
        )}
      </DialogContent>
    </Dialog>
  );
};

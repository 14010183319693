import { FC } from "react";
import { useParams } from "react-router";

import { Group } from "@/components/group";
import { Loading } from "@/components/ui/loading";
import { SheetHeader, SheetTitle } from "@/components/ui/sheet";
import { FieldGrid, FieldGridRow } from "@/forms/fields/field-grid";
import { ExtractedLabelFragment, useExtractedLabelQuery } from "src/generated/graphql";

import { AssignPrimaryLabelPopover } from "./components/assign-primary-label-popover";

export interface ExtractedLabelFormProps {
  extractedLabel: ExtractedLabelFragment;
}

export const ExtractedLabelForm: FC<ExtractedLabelFormProps> = ({ extractedLabel }) => {
  return (
    <FieldGrid>
      <FieldGridRow label="Key">
        <p className="text-sm">{extractedLabel.key}</p>
      </FieldGridRow>

      <FieldGridRow label="Source">
        <p className="text-sm">{extractedLabel.source}</p>
      </FieldGridRow>

      <FieldGridRow label="Count">
        <p className="text-sm">{extractedLabel.count}</p>
      </FieldGridRow>

      <FieldGridRow label="Primary Label(s)">
        <div className="-mx-3 -my-2">
          <AssignPrimaryLabelPopover extractedLabel={extractedLabel} className="text-sm text-left" />
        </div>
      </FieldGridRow>

      <FieldGridRow label="Frequently Received Values">
        <p className="text-sm text-muted-foreground">Coming soon</p>
      </FieldGridRow>
    </FieldGrid>
  );
};

export const ExtractedLabel = () => {
  const { id } = useParams();
  const { data, loading } = useExtractedLabelQuery({ variables: { id: id as string } });

  if (!data?.extractedLabel) {
    return null;
  }

  return (
    <Group>
      <SheetHeader>
        <SheetTitle>Edit Extracted Label</SheetTitle>
      </SheetHeader>

      {loading && !data?.extractedLabel && <Loading label="Loading extracted label details..." />}

      {data.extractedLabel && <ExtractedLabelForm extractedLabel={data.extractedLabel} />}
    </Group>
  );
};

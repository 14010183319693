import { FC, MouseEvent, useState } from "react";
import { useFieldArray, useFormContext, useWatch } from "react-hook-form";
import { z } from "zod";

import { Group } from "@/components/group";
import { useModal } from "@/components/modal-provider";
import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import { Icon } from "@/components/ui/icon";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Spinner } from "@/components/ui/loading";
import { FieldTextarea } from "@/forms/fields/field-textarea";
import { useInsured } from "@/hooks/use-insured";
import { useToast } from "@/hooks/use-toast";
import { File_Audience, File_Source } from "src/generated/graphql";
import { UploadFileOptions, uploadFiles } from "src/utils/file";

export const SendEmailFormSchema = z.object({
  emailComment: z.string().optional(),
  files: z.array(z.object({ id: z.string(), name: z.string() })).optional(),
});

export const SendEmailFormDefaultValues = {
  emailComment: "",
  files: [],
};

export type SendEmailFormValues = z.infer<typeof SendEmailFormSchema>;

export interface SendEmailFormDefaultAttachment {
  name: string;
}

export interface SendEmailFormContentProps {
  defaultAttachments?: SendEmailFormDefaultAttachment[];
  uploadFileOptions?: Partial<UploadFileOptions>;
}

export const SendEmailFormFields: FC<SendEmailFormContentProps> = ({ defaultAttachments, uploadFileOptions }) => (
  <Group>
    <FieldTextarea name="emailComment" label="Email body" rows={10} />
    <FieldFileUpload defaultAttachments={defaultAttachments} uploadFileOptions={uploadFileOptions} />
  </Group>
);

interface FieldFileUploadProps {
  defaultAttachments?: SendEmailFormDefaultAttachment[];
  uploadFileOptions?: Partial<UploadFileOptions>;
}

// TODO: Extract to a separate reusable component
const FieldFileUpload: FC<FieldFileUploadProps> = ({ defaultAttachments, uploadFileOptions }) => {
  const { control } = useFormContext<SendEmailFormValues>();
  const { toast } = useToast();
  const [uploadingFile, setUploadingFile] = useState(false);

  const { append, remove } = useFieldArray({ control, name: "files" });
  const files = useWatch({ name: "files", control });

  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const filesArray = [...(e.target.files || [])];

    if (!filesArray?.length) {
      return;
    }

    if (filesArray.some((file) => file.type !== "application/pdf")) {
      toast({ title: "We only accept PDF files" });
      return;
    }

    if (!uploadFileOptions?.insuredId) {
      toast({ title: "Error - missing insured ID" });
      return;
    }

    try {
      setUploadingFile(true);

      const { fileData } = await uploadFiles(filesArray, {
        audience: File_Audience.Internal,
        source: File_Source.ManualUpload,
        ...uploadFileOptions,
      });

      if (fileData) {
        append(fileData.map(({ id, filename: name }) => ({ id, name })));
      }

      setUploadingFile(false);
      e.target.files = null;
      e.target.value = "";
    } catch {
      setUploadingFile(false);
      toast({ title: "Error uploading file", description: "Please try again" });
    }
  };

  const fileCount = (files?.length || 0) + 1;

  return (
    <Group className="gap-2">
      <Group className="gap-1.5">
        <Button
          variant="outline"
          type="button"
          onKeyUp={(e) => ["Enter", "Space"].includes(e.code) && e.currentTarget.click()}
          disabled={uploadingFile}
          className="text-left justify-start gap-2 font-normal pl-3 pr-2.5"
          asChild
        >
          <Label tabIndex={0}>
            <Icon icon="attach_file_add" className="size-4 text-base text-foreground opacity-50" />
            <span>Attach files</span>
            <span className="flex-1" />
            {uploadingFile && <Spinner />}
            <span className="text-2xs text-muted-foreground font-semibold">PDF</span>
            <Badge type="square" variant="secondary">
              {fileCount}
            </Badge>
            <Input type="file" onChange={handleFileChange} accept=".pdf" multiple className="hidden" />
          </Label>
        </Button>
      </Group>

      <Group className="gap-1.5">
        <ul className="flex flex-col gap-1.5">
          {defaultAttachments?.map((attachment) => (
            <li
              key={attachment.name}
              className="h-8 text-xs flex items-center gap-1.5 bg-foreground/7.5 text-foreground pl-2 pr-3 py-2 rounded-md"
            >
              <Icon icon="attach_file" className="size-3 text-sm text-foreground opacity-50" />
              <span className="text-xs">{attachment.name}</span>
            </li>
          ))}

          {files?.map(({ id, name }, index) => (
            <FileAttachmentItem key={id} name={name} removable onRemove={() => remove(index)} />
          ))}
        </ul>
      </Group>
    </Group>
  );
};

interface FileAttachmentItemProps {
  name: string;
  removable?: boolean;
  onRemove: (event: MouseEvent<HTMLButtonElement>) => void;
}

const FileAttachmentItem: FC<FileAttachmentItemProps> = ({ name, removable, onRemove }) => (
  <li className="h-8 text-xs flex items-center gap-1.5 bg-foreground/7.5 text-foreground pl-2 pr-1.5 py-2 rounded-md">
    <Icon icon="attach_file" className="size-3 text-sm text-foreground opacity-50" />
    <span className="text-xs">{name}</span>
    <span className="flex-1" />
    {removable && (
      <Button variant="ghost" size="2xs" display="icon" type="button" onClick={onRemove}>
        <Icon icon="close" />
      </Button>
    )}
  </li>
);

export const useSendEmailForm = () => {
  const { openForm } = useModal();
  const { insured } = useInsured();

  return async ({
    defaultAttachments,
    uploadFileOptions,
    ...openFormOptions
  }: SendEmailFormContentProps & Partial<Parameters<typeof openForm>[1]> = {}) =>
    await openForm(
      <SendEmailFormFields
        defaultAttachments={defaultAttachments}
        uploadFileOptions={{ insuredId: insured?.id, ...uploadFileOptions }}
      />,
      {
        title: "Send Email",
        ...openFormOptions,
        validationSchema: SendEmailFormSchema,
        defaultValues: { ...SendEmailFormDefaultValues, ...openFormOptions.defaultValues },
        submitButtonProps: { children: "Send email", ...openFormOptions.submitButtonProps },
      }
    );
};

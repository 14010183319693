import { createContext, PropsWithChildren, useContext } from "react";
import { useParams } from "react-router";
import { useVerticalQuery, VerticalFragment } from "src/generated/graphql";

export type VerticalTemplate = NonNullable<
  ReturnType<typeof useVerticalQuery>["data"]
>["vertical"]["marketingPlanTemplates"][0];

interface VerticalState {
  vertical: VerticalFragment;
  templates: VerticalTemplate[];
  loading: boolean;
  refetch: () => void;
}

const VerticalContext = createContext<VerticalState>({} as VerticalState);

export function VerticalProvider(props: PropsWithChildren) {
  const { verticalId } = useParams();

  const {
    data: { vertical } = {},
    loading,
    refetch,
  } = useVerticalQuery({
    variables: { id: verticalId! },
    skip: !verticalId,
  });

  if (!vertical) {
    return null;
  }

  const templates = [...vertical.marketingPlanTemplates]
    .sort((a, b) => a.lobs[0]?.charCodeAt(0) - b.lobs[0]?.charCodeAt(0))
    .sort((a, b) => b.lobs.length - a.lobs.length);

  const value = {
    vertical,
    templates,
    loading,
    refetch,
  };

  return <VerticalContext.Provider value={value} {...props} />;
}

export const useVertical = () => {
  const context = useContext(VerticalContext);

  if (context === undefined) {
    throw new Error("useVertical must be used within a VerticalProvider");
  }

  return context;
};

import { forwardRef } from "react";

import { Button, ButtonProps } from "@/components/ui/button";
import { Icon } from "@/components/ui/icon";

export type FilterButtonProps = Omit<ButtonProps, "children">;

export const FilterButton = forwardRef<HTMLButtonElement, FilterButtonProps>((props, ref) => (
  <Button ref={ref} variant="ghost" size="sm" {...props}>
    Filter
    <Icon icon="filter_list" />
  </Button>
));

FilterButton.displayName = "FilterButton";

import { z } from "zod";

import { PrimaryLabelDataType, VerticalMarketingPlanTemplateProductFragment } from "src/generated/graphql";

export type RuleItem = PrimaryLabelRuleItem | ClientDataRuleItem | MinimumPremiumRuleItem | StateRuleItem | CglRuleItem;
export type Rule = RuleItem[];
export type Rules = Rule[];

export enum RuleType {
  MinimumPremium = "minimum-premium",
  ClientData = "client-data",
  PrimaryLabel = "primary-label",
  State = "state",
  CGL = "cgl",
}

export enum ClientDataRuleValueType {
  Text = "text",
  Number = "number",
  Boolean = "boolean",
}

export interface PrimaryLabelRuleItem {
  type: RuleType.PrimaryLabel;
  key: string;
  operator?: PrimaryLabelRuleOperator;
  value?: string;
}

export interface ClientDataRuleItem {
  type: RuleType.ClientData;
  key: string;
  operator?: ClientDataRuleOperator;
  valueType: ClientDataRuleValueType;
  value?: string;
}

export interface MinimumPremiumRuleItem {
  type: RuleType.MinimumPremium;
  value?: number;
}

export interface StateRuleItem {
  type: RuleType.State;
  operator?: StateRuleOperator;
  value?: string;
}

export interface CglRuleItem {
  type: RuleType.CGL;
  operator?: CglRuleOperator;
  value?: string;
}

export enum ClientDataRuleTextOperator {
  StartsWith = "StartsWith",
  EndsWith = "EndsWith",
  Contains = "Contains",
  Equals = "Equals",
}

export enum ClientDataRuleNumberOperator {
  GreaterThan = ">",
  GreaterThanEqualTo = ">=",
  Equal = "=",
  LessThan = "<",
  LessThanEqualTo = "<=",
}

export enum ClientDataRuleBooleanOperator {
  ExcludeIf = "ExcludeIf",
}

export type ClientDataRuleOperator =
  | ClientDataRuleBooleanOperator
  | ClientDataRuleNumberOperator
  | ClientDataRuleTextOperator;

export enum PrimaryLabelRuleStringOperator {
  StartsWith = "StartsWith",
  EndsWith = "EndsWith",
  Contains = "Contains",
  Equals = "Equals",
}

export enum PrimaryLabelRuleNumberOperator {
  GreaterThan = ">",
  GreaterThanEqualTo = ">=",
  Equal = "=",
  LessThan = "<",
  LessThanEqualTo = "<=",
}

export enum PrimaryLabelRuleDateOperator {
  GreaterThan = ">",
  GreaterThanEqualTo = ">=",
  Equal = "=",
  LessThan = "<",
  LessThanEqualTo = "<=",
}

export enum PrimaryLabelRuleBooleanOperator {
  ExcludeIf = "ExcludeIf",
}

export type PrimaryLabelRuleOperator =
  | PrimaryLabelRuleStringOperator
  | PrimaryLabelRuleNumberOperator
  | PrimaryLabelRuleDateOperator
  | PrimaryLabelRuleBooleanOperator;

export enum StateRuleOperator {
  ExcludeIf = "ExcludeIf",
  IncludeIf = "IncludeIf",
}

export enum CglRuleOperator {
  ExcludeIf = "ExcludeIf",
  IncludeIf = "IncludeIf",
}

const ClientDataRuleSchema = z.object({
  type: z.literal(RuleType.ClientData),
  key: z.string().optional(),
  operator: z.string().optional(),
  valueType: z.string(),
  value: z.union([z.string(), z.number()]).optional(),
});

const PrimaryLabelRuleSchema = z.object({
  type: z.literal(RuleType.PrimaryLabel),
  key: z.string().optional(),
  operator: z.string().optional(),
  value: z.union([z.string(), z.number()]).optional(),
});

const MinimumPremiumRuleSchema = z.object({
  type: z.literal(RuleType.MinimumPremium),
  value: z.union([z.string(), z.number()]).optional(),
});

const StateRuleSchema = z.object({
  type: z.literal(RuleType.State),
  operator: z.string().optional(),
  value: z.union([z.string(), z.number()]).optional(),
});

const CglRuleSchema = z.object({
  type: z.literal(RuleType.CGL),
  operator: z.string().optional(),
  value: z.union([z.string(), z.number()]).optional(),
});

export const RuleSchema = z.discriminatedUnion("type", [
  PrimaryLabelRuleSchema,
  ClientDataRuleSchema,
  MinimumPremiumRuleSchema,
  StateRuleSchema,
  CglRuleSchema,
]);

export function getRuleKey(rule: Rule) {
  return rule.map((r) => `${r.type}--${"key" in r ? r.key : ""}`).join("---");
}

export function getRuleFromKey(ruleKey: string) {
  return ruleKey.split("---").map((part) => {
    const [type, key] = part.split("--");
    return { type, key };
  });
}

export const validRule = (rule: RuleItem) => {
  if (!rule.value) {
    return false;
  }

  if (rule.type === RuleType.MinimumPremium) {
    return true;
  }

  // TODO: Need to make sure operator matches an available operator for the valueType
  // We have another ticket open for this, so we will address this in a future PR.
  // if (rule.operator && )

  return !!rule.operator;
};

export const operatorString = {
  [CglRuleOperator.ExcludeIf]: "excluded",
  [CglRuleOperator.IncludeIf]: "included",
  [ClientDataRuleNumberOperator.Equal]: "is equal to",
  [ClientDataRuleNumberOperator.GreaterThan]: "is greater than",
  [ClientDataRuleNumberOperator.GreaterThanEqualTo]: "is greater than or equal to",
  [ClientDataRuleNumberOperator.LessThan]: "is less than",
  [ClientDataRuleNumberOperator.LessThanEqualTo]: "is less than or equal to",
  [ClientDataRuleTextOperator.Contains]: "contains",
  [ClientDataRuleTextOperator.EndsWith]: "ends with",
  [ClientDataRuleTextOperator.Equals]: "equals",
  [ClientDataRuleTextOperator.StartsWith]: "starts with",

  // TODO: Replace `ClientDataRule` operators with `PrimaryLabelRule` operators
  // [PrimaryLabelRuleNumberOperator.Equal]: "is equal to",
  // [PrimaryLabelRuleNumberOperator.GreaterThan]: "is greater than",
  // [PrimaryLabelRuleNumberOperator.GreaterThanEqualTo]: "is greater than or equal to",
  // [PrimaryLabelRuleNumberOperator.LessThan]: "is less than",
  // [PrimaryLabelRuleNumberOperator.LessThanEqualTo]: "is less than or equal to",
  // [PrimaryLabelRuleTextOperator.Contains]: "contains",
  // [PrimaryLabelRuleTextOperator.EndsWith]: "ends with",
  // [PrimaryLabelRuleTextOperator.Equals]: "equals",
  // [PrimaryLabelRuleTextOperator.StartsWith]: "starts with",
};

export const PrimaryLabelRuleOperatorItemsMap = {
  [PrimaryLabelDataType.String]: PrimaryLabelRuleStringOperator,
  [PrimaryLabelDataType.Number]: PrimaryLabelRuleNumberOperator,
  [PrimaryLabelDataType.Percent]: PrimaryLabelRuleNumberOperator,
  [PrimaryLabelDataType.Date]: PrimaryLabelRuleDateOperator,
  [PrimaryLabelDataType.Boolean]: PrimaryLabelRuleBooleanOperator,
};

export const ClientDataRuleOperatorItemsMap = {
  [ClientDataRuleValueType.Text]: ClientDataRuleTextOperator,
  [ClientDataRuleValueType.Number]: ClientDataRuleNumberOperator,
  [ClientDataRuleValueType.Boolean]: ClientDataRuleBooleanOperator,
};

export const getRuleFromProduct = (products: VerticalMarketingPlanTemplateProductFragment, ruleKey: string) => {
  const rules = JSON.parse(products.rules) as Rules;
  const ruleIndex = rules.findIndex((rule) => getRuleKey(rule) === ruleKey);

  return rules[ruleIndex][0];
};

import { addHours, formatDistanceToNow, parseISO } from "date-fns";

import { Button } from "@/components/ui/button";
import { DatePicker } from "@/components/ui/date-picker";
import { Icon } from "@/components/ui/icon";
import { useToast } from "@/hooks/use-toast";
import {
  ActionExecutionOperation,
  ActionsQuery,
  useDeleteActionMutation,
  useUpdateActionMutation,
} from "src/generated/graphql";

export const ScheduledFollowUp = ({ action }: { action: ActionsQuery["actions"][number] }) => {
  const [updateAction] = useUpdateActionMutation();
  const [deleteAction] = useDeleteActionMutation({ variables: { id: action.id }, refetchQueries: ["Actions"] });
  const { toast } = useToast();

  const approveAction = () =>
    updateAction({ variables: { input: { id: action.id, executedOperation: ActionExecutionOperation.Approved } } });

  return (
    <div className="relative text-primary">
      <span className="font-medium">Scheduled </span>
      <span className="opacity-75">{formatDistanceToNow(parseISO(action.autoExecuteAt), { addSuffix: true })}</span>
      <nav className="absolute flex gap-1 inset-0 items-center left-auto my-auto text-xs">
        <DatePicker
          variant="ghost"
          size="xs"
          display="icon"
          value={action.autoExecuteAt}
          onChange={async (value) => {
            if (!value || value < new Date()) {
              return toast({ title: "Date must be in the future", variant: "destructive" });
            }

            return await updateAction({
              // add 10 hours, so the email gets sent around 10am
              variables: { input: { id: action.id, autoExecuteAt: addHours(value, 10)?.toISOString() } },
            });
          }}
        >
          <Icon icon="event" />
        </DatePicker>
        <Button variant="ghost" size="xs" display="icon" onClick={() => approveAction()}>
          <Icon icon="send" />
        </Button>
        <Button variant="ghost" size="xs" display="icon" onClick={() => deleteAction()}>
          <Icon icon="delete" />
        </Button>
      </nav>
    </div>
  );
};

import { UUID } from "@cp/toolkit";
import { useAtom } from "jotai";
import { useState } from "react";
import { z } from "zod";

import { eventsCommentAtom } from "@/atoms";
import { useModal } from "@/components/modal-provider";
import { AlertDialogAction, AlertDialogCancel, AlertDialogFooter } from "@/components/ui/alert-dialog";
import { Form, FormSubmitHandler } from "@/forms/form";
import { useAccount } from "@/hooks/use-account";
import { useToast } from "@/hooks/use-toast";
import { useCreateCommentMutation, useUpdateCommentMutation } from "src/generated/graphql";
import { cn } from "src/utils";

import { NoteInput } from "../notes";

export const CommentFormSchema = z.object({
  text: z.string().min(1),
  sendAsEmail: z.boolean().optional(),
  files: z.array(z.any()),
});

interface CommentFormProps {
  className?: string;
  opportunityId: string;
  commentId?: string;
  text?: string;
  setEditMode?: (editMode: boolean) => void;
}

export const CommentForm = ({ className, opportunityId, commentId, text, setEditMode }: CommentFormProps) => {
  const { user } = useAccount();
  const [createComment] = useCreateCommentMutation();
  const [updateComment] = useUpdateCommentMutation();
  const { toast } = useToast();
  const { openModal, closeModal } = useModal();
  const [loading, setLoading] = useState(false);
  const [, setComment] = useAtom(eventsCommentAtom);

  const defaultValues = {
    text: text || "",
    sendAsEmail: false,
    files: [],
  };

  const handleSubmit: FormSubmitHandler<z.infer<typeof CommentFormSchema>> = async (values, methods) => {
    const isConfirmed = user.internal
      ? await openModal(
          () => (
            <AlertDialogFooter>
              <AlertDialogCancel onClick={() => closeModal(false)}>Cancel</AlertDialogCancel>
              <AlertDialogAction theme="primary" onClick={() => closeModal(true)}>
                Confirm
              </AlertDialogAction>
            </AlertDialogFooter>
          ),
          { title: "This comment will be sent to the agent" }
        )
      : true;

    if (!isConfirmed) {
      return;
    }

    if (commentId && text && setEditMode) {
      void updateComment({
        variables: { input: { id: commentId as UUID, text: values.text } },
        onCompleted: () => {
          toast({ title: "Comment updated" });
          setEditMode(false);
        },
        onError: () => toast({ title: "Error", description: "An error occurred.", variant: "destructive" }),
        refetchQueries: ["OpportunityEvents"],
      });
    } else {
      setLoading(true);
      void createComment({
        variables: { input: { opportunityId, text: values.text } },
        onCompleted: async () => {
          setLoading(false);
          methods.reset();
          setComment(true);
          toast({ title: "Comment added" });
        },
        onError: () => {
          setLoading(false);
          toast({ title: "Error", description: "An error occurred.", variant: "destructive" });
        },
        refetchQueries: ["OpportunityEvents"],
      });
    }
  };

  return (
    <Form
      onSubmit={handleSubmit}
      validationSchema={CommentFormSchema}
      defaultValues={defaultValues}
      className={cn("p-3", className)}
    >
      <NoteInput name="text" placeholder="Add a comment" loading={loading} />
    </Form>
  );
};

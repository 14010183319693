import { UUID } from "@cp/toolkit";
import { MaterialSymbol } from "material-symbols";
import { FC } from "react";
import { z } from "zod";

import { Button } from "@/components/ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Icon } from "@/components/ui/icon";
import { Spinner } from "@/components/ui/loading";
import { RelativeDate } from "@/components/ui/relative-date";
import { Form, FormSubmitHandler } from "@/forms/form";
import { useField } from "@/forms/hooks/use-field";
import { useToast } from "@/hooks/use-toast";
import { useCreateOpportunityNoteMutation, useOpportunityNotesQuery } from "src/generated/graphql";
import { cn } from "src/utils";
import { AutoresizingTextarea, AutoresizingTextareaProps } from "./ui/autoresizing-textarea";

export const Notes = ({ opportunityId }: { opportunityId: UUID }) => {
  const { data: { opportunity } = {} } = useOpportunityNotesQuery({
    variables: { id: opportunityId },
    fetchPolicy: "cache-and-network",
  });

  if (!opportunity) {
    return null;
  }

  return (
    <Card>
      {opportunity.notes[0] && (
        <>
          <CardHeader>
            <CardTitle>Notes</CardTitle>
          </CardHeader>
          <CardContent className="pb-3 space-y-6">
            {opportunity.notes.map((note) => (
              <div key={note.id} className="space-y-1.5">
                <div className="text-xs whitespace-pre-wrap" style={{ overflowWrap: "anywhere" }}>
                  {note.text}
                </div>
                <footer className="flex gap-1 items-center text-2xs text-muted-foreground">
                  <strong>
                    {note.creator.firstName} {note.creator.lastName}
                  </strong>
                  <RelativeDate date={note.createdAt} />
                </footer>
              </div>
            ))}
          </CardContent>
        </>
      )}

      <footer className="p-3">
        <NoteForm opportunityId={opportunityId} />
      </footer>
    </Card>
  );
};

export const NoteFormSchema = z.object({
  text: z.string().min(1),
});

export const NoteForm = ({ opportunityId, loading }: { opportunityId: string; loading?: boolean }) => {
  const { toast } = useToast();
  const [createNote] = useCreateOpportunityNoteMutation();

  const defaultValues = { text: "" };

  const handleSubmit: FormSubmitHandler<z.infer<typeof NoteFormSchema>> = async (values, methods) =>
    await createNote({
      variables: { input: { opportunityId, ...values } },
      onCompleted: () => methods.reset(),
      onError: () => toast({ title: "Error", description: "An error occurred.", variant: "destructive" }),
      refetchQueries: ["OpportunityNotes"],
    });

  return (
    <Form validationSchema={NoteFormSchema} defaultValues={defaultValues} onSubmit={handleSubmit}>
      <NoteInput name="text" placeholder="Add a note" loading={loading} />
    </Form>
  );
};

export interface NoteInputProps extends AutoresizingTextareaProps {
  icon?: MaterialSymbol;
  name: string;
  loading?: boolean;
}

export const NoteInput: FC<NoteInputProps> = ({ icon = "send", loading, className, ...props }) => {
  const { field } = useField({ name: props.name });

  return (
    <AutoresizingTextarea
      {...field}
      {...props}
      className={cn(
        "bg-accent border-none focus-visible:ring-0 focus-visible:ring-offset-0 min-h-10 p-3 pr-14 rounded-md shadow-inner text-xs",
        className
      )}
    >
      <Button
        type="submit"
        variant="ghost"
        size="xs"
        display="icon"
        className="absolute bottom-1 h-8 right-1 text-primary! w-8"
      >
        {loading ? <Spinner /> : <Icon icon={icon} />}
      </Button>
    </AutoresizingTextarea>
  );
};
NoteInput.displayName = "NoteInput";

import get from "lodash/get";
import { useMemo } from "react";
import { useController, useFormContext } from "react-hook-form";

type UseControllerProps = Parameters<typeof useController>[0];

export type UseFieldProps<T extends Record<string, any>> = Omit<UseControllerProps, "control"> & {
  inputProps?: T;
};

export function useField<T extends Record<string, any>>({ name, disabled, inputProps, ...props }: UseFieldProps<T>) {
  const { control, formState } = useFormContext();
  const { field } = useController({ ...props, control, name, disabled: disabled || inputProps?.disabled });

  const hasError = useMemo(() => !!get(formState.errors, name), [formState, name]);

  return { field, formState, hasError };
}

import { LinkList, ListLink, SidebarSection } from "@/components/sidebar";
import { StateIndicator } from "@/components/state";
import { Icon } from "@/components/ui/icon";
import { Spinner } from "@/components/ui/loading";
import { useInsured } from "@/hooks/use-insured";
import { OpportunityFragment } from "src/generated/graphql";
import { formatDateString } from "src/utils/date";

export const InsuredMarketingPlans = () => {
  const { activeOpportunities, opportunitiesLoading } = useInsured();

  if (opportunitiesLoading) {
    return <Spinner className="m-6" />;
  }

  if (!activeOpportunities[0]) {
    return null;
  }

  return (
    <SidebarSection>
      <h6 className="text-muted-foreground">Active Marketing Plans</h6>
      <OpportunityList opportunities={activeOpportunities} />
    </SidebarSection>
  );
};

export const OpportunityList = ({ opportunities }: { opportunities: OpportunityFragment[] }) => (
  <LinkList>
    {opportunities.map((opportunity) => (
      <ListLink
        key={opportunity.id}
        to={`/insured/${opportunity.insuredId}/plans/${opportunity.id}`}
        subheader={formatDateString(opportunity.desiredEffectiveDate, "MMM d, yyyy")}
      >
        <span className="mr-auto truncate">
          {opportunity.selectedLinesOfBusiness[0]
            ? opportunity.selectedLinesOfBusiness.join(", ")
            : "No Coverage Selected"}
        </span>
        {opportunity.renewalOf && <Icon icon="cycle" />}
        <StateIndicator state={opportunity.state} displayLabel={false} />
      </ListLink>
    ))}
  </LinkList>
);

import { MaterialSymbol } from "material-symbols";

import { MarketingPlanState, PolicyState, QuoteState, SubmissionState } from "src/generated/graphql";
import { MARKETING_PLAN_STATE_METADATA } from "./marketing-plans";
import { ExpiredPolicyState, EXPIRED_POLICY_STATE_METADATA, POLICY_STATE_METADATA } from "./policies";
import { QUOTE_STATE_METADATA } from "./quotes";
import { SUBMISSION_STATE_METADATA } from "./submissions";

export interface StateMetadata {
  label: string;
  className?: string;
  cardClassName?: string;
  icon: MaterialSymbol;
  title?: React.ReactNode;
  description?: React.ReactNode;
  actionDisplayNames?: Record<string, string>;
  displayQuoteNotes?: boolean;
}

export type States = MarketingPlanState | SubmissionState | QuoteState | PolicyState | ExpiredPolicyState;

export const STATE_METADATA: Record<States, StateMetadata> = {
  ...MARKETING_PLAN_STATE_METADATA,
  ...SUBMISSION_STATE_METADATA,
  ...QUOTE_STATE_METADATA,
  ...POLICY_STATE_METADATA,
  ...EXPIRED_POLICY_STATE_METADATA,
};

export const AGENT_MESSAGE_METADATA: Record<MarketingPlanState | QuoteState | PolicyState, StateMetadata> = {
  ...MARKETING_PLAN_STATE_METADATA,
  ...QUOTE_STATE_METADATA,
  ...POLICY_STATE_METADATA,
};

import { Grid, GridCell, GridRowHeader } from "@/components/grid";
import { HasInternalRole } from "@/components/has-role";
import { StateCard } from "@/components/state";
import { useAccount } from "@/hooks/use-account";
import { useMarketingPlan } from "@/hooks/use-marketing-plan";
import { MarketingPlanActions } from "@/marketing-plans/actions/marketing-plan-actions";
import { AGENT_MESSAGE_METADATA } from "@/metadata/states";
import { SubmissionTemplateProvider } from "@/submission-template/use-submission-template";
import { MarketingPlanState, SubmissionState } from "src/generated/graphql";
import { Submission } from "./submission";

const SUBMISSION_ORDER = [
  SubmissionState.Quoted,
  SubmissionState.Submitted,
  SubmissionState.Referred,
  SubmissionState.ReadyToSubmit,
  SubmissionState.Pending,
  SubmissionState.RuledOut,
  SubmissionState.MarketBlocked,
  SubmissionState.Declined,
];

const AGENT_VISIBLE_SUBMISSIONS = new Set([
  SubmissionState.Quoted,
  SubmissionState.Submitted,
  SubmissionState.Referred,
  SubmissionState.RuledOut,
  SubmissionState.MarketBlocked,
  SubmissionState.Declined,
]);

export const Submissions = () => {
  const { user } = useAccount();
  const { opportunity } = useMarketingPlan();

  if (opportunity.state === MarketingPlanState.InformationGathering) {
    return null;
  }

  const { state, submissions } = opportunity;

  const visibleSubmissions = submissions.filter(
    (submission) => AGENT_VISIBLE_SUBMISSIONS.has(submission.state) || user.internal
  );

  const stateProps = AGENT_MESSAGE_METADATA[state];

  return (
    <StateCard
      actions={
        <HasInternalRole>
          <MarketingPlanActions />
        </HasInternalRole>
      }
      {...stateProps}
    >
      {visibleSubmissions.length > 0 && (
        <Grid className="bg-transparent grid-cols-[1.5rem_1fr_1.5fr_1fr_1.5fr]">
          <GridRowHeader className="-mt-3">
            <GridCell />
            <GridCell>Carrier</GridCell>
            <GridCell>Product</GridCell>
            <GridCell>Updated</GridCell>
            <GridCell />
          </GridRowHeader>
          <SubmissionTemplateProvider>
            {visibleSubmissions
              .sort((a, b) => {
                const aIndex = SUBMISSION_ORDER.indexOf(a.state);
                const bIndex = SUBMISSION_ORDER.indexOf(b.state);
                return aIndex - bIndex;
              })
              .map((submission) => (
                <Submission key={submission.id} submission={submission} />
              ))}
          </SubmissionTemplateProvider>
        </Grid>
      )}
    </StateCard>
  );
};

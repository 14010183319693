import { Slot } from "@radix-ui/react-slot";
import { cva, VariantProps } from "class-variance-authority";
import { FC, forwardRef, PropsWithChildren, ReactNode, useEffect, useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { Link, LinkProps } from "react-router-dom";
import { z } from "zod";

import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert";
import { Button, ButtonProps } from "@/components/ui/button";
import { Icon } from "@/components/ui/icon";
import { Skeleton } from "@/components/ui/skeleton";
import { Tooltip, TooltipContent, TooltipTrigger } from "@/components/ui/tooltip";
import { FieldCheckbox } from "@/forms/fields/field-checkbox";
import { Form, FormProps } from "@/forms/form";
import { cn } from "src/utils";

export interface GridRowData extends Record<string, any> {
  id: string;
}

export type GridData = GridRowData[];

export interface GridProps extends React.HTMLAttributes<HTMLDivElement> {
  asChild?: boolean;
}

export const Grid = ({ asChild, className, ...props }: GridProps) => {
  const Comp = asChild ? Slot : "div";
  return <Comp className={cn("bg-inherit divide-y gap-x-4 grid auto-rows-min px-6 text-xs", className)} {...props} />;
};

export const gridRowClassName = [
  "col-span-full grid grid-cols-subgrid items-center -mx-6 px-6 relative",
  "bg-background min-h-12 last:shadow-border-b last:in-[.bg-card]:shadow-none in-[.bg-card]:bg-transparent",
  "data-[dragging=true]:bg-background! data-[dragging=true]:border! data-[dragging=true]:-mx-[calc(1.5rem+1px)]!",
];

const gridRowHeaderClassName = "bg-inherit font-semibold min-h-10 text-2xs text-muted-foreground";

export interface GridRowProps extends React.HTMLAttributes<HTMLDivElement> {
  asChild?: boolean;
}

export const GridRow = ({ asChild, className, ...props }: GridRowProps) => {
  const Comp = asChild ? Slot : "div";
  return <Comp className={cn(gridRowClassName, className)} {...props} />;
};

const gridRowHeaderVariants = cva([gridRowClassName[0], gridRowHeaderClassName], {
  variants: {
    position: {
      relative: "",
      sticky: "border-b -mb-px top-12 sticky z-10",
    },
  },
  defaultVariants: {
    position: "relative",
  },
});

export interface GridRowHeaderProps
  extends React.HTMLAttributes<HTMLElement>,
    VariantProps<typeof gridRowHeaderVariants> {}

export const GridRowHeader = ({ className, position, ...props }: GridRowHeaderProps) => (
  <header className={cn(gridRowHeaderVariants({ position }), className)} {...props} />
);

export const GridRowFooter = ({ className, ...props }: React.HTMLAttributes<HTMLElement>) => (
  <footer className={cn(gridRowClassName[0], gridRowHeaderClassName, className)} {...props} />
);

export const GridRowLink = forwardRef<HTMLAnchorElement, LinkProps>(({ children, className, ...props }, ref) => (
  <Link ref={ref} className={cn(gridRowClassName, "group", className)} {...props}>
    {children}
    <Icon
      icon="arrow_forward"
      className={cn(
        "flex items-center justify-center pointer-events-none rounded-full ",
        "opacity-25 group-hover:opacity-100 transition-all",
        "absolute top bottom right-4 group-hover:right-3 m-auto size-4"
      )}
    />
  </Link>
));
GridRowLink.displayName = "GridRowLink";

export interface GridCellProps extends React.HTMLAttributes<HTMLDivElement> {
  asChild?: boolean;
}

export const GridCell = ({ asChild, className, ...props }: GridCellProps) => {
  const Comp = asChild ? Slot : "div";
  return <Comp className={cn("truncate", className)} {...props} />;
};

export const GridCellLoading = (props: React.HTMLAttributes<HTMLDivElement>) => (
  <div {...props}>
    <Skeleton className="inline-block h-4 w-3/4" />
  </div>
);

export interface GridLoadingProps extends React.HTMLAttributes<HTMLDivElement> {
  rows?: number;
  columns: number;
}

export const GridLoading: FC<GridLoadingProps> = ({ rows = 1, columns, className, ...props }) => (
  <>
    {Array.from({ length: rows }).map((_, i) => (
      <GridRow className={cn("[&>*:last-child]:text-right", className)} key={i} {...props}>
        {Array.from({ length: columns }).map((_, j) => (
          <GridCellLoading key={j} />
        ))}
      </GridRow>
    ))}
  </>
);

export interface GridEmptyProps {
  title?: ReactNode;
  description?: ReactNode;
  className?: string;
  orientation?: "horizontal" | "vertical";
}

export const GridEmpty: FC<PropsWithChildren<GridEmptyProps>> = ({
  title,
  description,
  className,
  orientation = "vertical",
  children,
}) => (
  <div className={cn("col-span-full py-6", className)}>
    <Alert
      className={cn(
        "text-center py-6",
        orientation === "horizontal" && "flex flex-row items-center text-left px-5 py-4 gap-2"
      )}
      variant="default"
    >
      <div>
        {title && <AlertTitle className="text-base">{title}</AlertTitle>}
        {description && <AlertDescription className="space-y-3 text-muted-foreground">{description}</AlertDescription>}
      </div>
      <span className="flex-1" />
      {children}
    </Alert>
  </div>
);

export interface GridRowActionProps extends ButtonProps {
  label?: ReactNode;
}

export const GridRowAction = forwardRef<HTMLButtonElement, GridRowActionProps>(({ children, label, ...props }, ref) => (
  <Tooltip>
    <TooltipTrigger asChild>
      <Button ref={ref} variant="ghost" size="xs" display="icon" {...props}>
        {children}
      </Button>
    </TooltipTrigger>
    {label && <TooltipContent>{label}</TooltipContent>}
  </Tooltip>
));
GridRowAction.displayName = "GridRowAction";

export interface GridSelectAllCheckbox {
  data: GridData;
}

export const GridSelectAllCheckbox: FC<GridSelectAllCheckbox> = ({ data }) => {
  const { watch, setValue } = useFormContext();

  const values = watch();

  const handleSelectAllChange = (checked: boolean) => {
    setValue("rowIds", checked ? data?.map((row) => row.id) : []);
  };

  const checkedState = useMemo(() => {
    if (!data?.length || !values?.rowIds?.length) {
      return false;
    }

    if (data?.length === values?.rowIds?.length) {
      return true;
    }

    return "indeterminate";
  }, [data?.length, values?.rowIds?.length]);

  return (
    <FieldCheckbox name="rowIdsSelectAll" onChange={handleSelectAllChange} inputProps={{ checked: checkedState }} />
  );
};

export interface GridRowCheckboxProps {
  row: GridRowData;
}

export const GridRowCheckbox: FC<GridRowCheckboxProps> = ({ row }) => {
  const { setValue, getValues } = useFormContext();

  useEffect(
    () => () => {
      // Right now, this works, because we don't have a use case for hiding/showing rows
      // while keeping them selected. If we every do need to hide/show rows while maintaining
      // the selection, we will need to change this.
      // One idea would be to have a way to hide/show a row using a `hidden` prop on the row.
      // Then we could reference the grid `data` and see if the row still exists.
      setValue(
        `rowIds`,
        getValues("rowIds").filter((id: string) => id !== row.id)
      );
    },
    []
  );

  return <FieldCheckbox name="rowIds" value={row.id} multiple />;
};

export const GridFormSchema = z.object({
  rowIds: z.array(z.string()),
  rowIdsSelectAll: z.boolean().optional(),
});

export type GridFormValues = z.infer<typeof GridFormSchema>;

export interface GridFormProps extends FormProps<GridFormValues, typeof GridFormSchema> {}

export const GridForm: FC<GridFormProps> = (props) => {
  const defaultValues = {
    rowIds: [] as string[],
    rowIdsSelectAll: false,
  };

  return (
    <Form<GridFormValues, typeof GridFormSchema>
      validationSchema={GridFormSchema}
      defaultValues={defaultValues}
      {...props}
    />
  );
};

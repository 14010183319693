import { useEffect, useState } from "react";

import { Grid, GridCell, GridRow, GridRowHeader } from "@/components/grid";
import { ReorderGroup, ReorderHandle, ReorderItem } from "@/components/reorder";
import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import { Card, CardFooter, CardHeader } from "@/components/ui/card";
import { Icon } from "@/components/ui/icon";
import { Spinner } from "@/components/ui/loading";
import { Separator } from "@/components/ui/separator";
import { useUpdateVerticalMarketingPlanTemplateProductRankMutation } from "src/generated/graphql";
import { cn } from "src/utils";
import { AddProducts } from "./components/add-products";
import { TemplateHeader } from "./components/template-header";
import { UpdateSubmissionEmail } from "./components/update-submission-email";
import { useTemplate } from "./components/use-template";
import { useVertical, VerticalTemplate } from "./components/use-vertical";

export const TemplateHierarchy = ({ template }: { template: VerticalTemplate }) => {
  const [selected, setSelected] = useState("");
  const [keys, setKeys] = useState<string[]>(template.products.map((p) => p.id));
  const [updating, setUpdating] = useState(false);
  const { editProductRule } = useTemplate({ template });
  const { loading, refetch } = useVertical();

  useEffect(() => {
    setKeys(template.products?.map((p) => p.id));
  }, [template.products?.length]);

  const [updateVerticalMarketingPlanProductMarketRankTrigger] =
    useUpdateVerticalMarketingPlanTemplateProductRankMutation({
      onCompleted() {
        setUpdating(false);
        void refetch();
      },
    });

  useEffect(() => {
    if (selected) {
      setUpdating(true);
      void updateVerticalMarketingPlanProductMarketRankTrigger({
        variables: { input: { id: selected, rank: keys.indexOf(selected) } },
      });
    }
  }, [keys]);

  const { products } = template;
  const { appetiteProduct } = products.find((product) => product.id === selected) ?? {};

  return (
    <Card className="grid grid-cols-[2fr_1px_3fr]">
      <div className="flex flex-col justify-between">
        <TemplateHeader template={template} />
        {products[0] && (
          <Grid asChild className="grid-cols-[1rem_1fr_2fr_1fr]">
            <ReorderGroup values={keys} onReorder={setKeys}>
              <GridRowHeader>
                <GridCell>Rk</GridCell>
                <GridCell>Carrier</GridCell>
                <GridCell>Product</GridCell>
                <GridCell className="text-right">Sub. Email</GridCell>
              </GridRowHeader>
              {keys.map((key) => {
                const product = products.find((p) => p.id === key);

                if (!product) {
                  return null;
                }

                return (
                  <GridRow
                    asChild
                    key={key}
                    className={cn("cursor-pointer", product.id === selected && "bg-secondary/35!")}
                    onPointerDown={() => setSelected(product.id)}
                  >
                    <ReorderItem dragListener={false} value={key}>
                      <div className="-ml-1.5">
                        {(updating || loading) && selected === product.id ? (
                          <Spinner className="ml-1.5" />
                        ) : (
                          <ReorderHandle order={product.rank + 1} />
                        )}
                      </div>
                      <GridCell>{product.appetiteProduct.carrierName}</GridCell>
                      <GridCell>{product.appetiteProduct.carrierProductName}</GridCell>
                      <GridCell className="text-right">
                        <UpdateSubmissionEmail product={product} />
                      </GridCell>
                    </ReorderItem>
                  </GridRow>
                );
              })}
            </ReorderGroup>
          </Grid>
        )}
        <CardFooter className="bg-accent border-t py-3 rounded-bl-lg">
          <AddProducts template={template} />
        </CardFooter>
      </div>
      <Separator orientation="vertical" />
      {selected && (
        <div className="flex flex-col justify-between">
          <CardHeader className="font-semibold flex-row gap-2 h-16 items-center text-sm">
            {appetiteProduct?.carrierName}: {appetiteProduct?.carrierProductName}
          </CardHeader>
          <Grid className="grid-cols-[1.5fr_4fr_1fr_1fr_1.5rem] mb-auto">
            <GridRowHeader>
              <GridCell>Type</GridCell>
              <GridCell>Rule</GridCell>
              <GridCell>Operator</GridCell>
              <GridCell>Value</GridCell>
              <div />
            </GridRowHeader>
            {products.map((product) =>
              JSON.parse(product.rules).flatMap((rule: any, i: number) => (
                <GridRow key={i} className={cn(product.id !== selected && "hidden")}>
                  <GridCell>
                    <Badge variant="secondary" type="square">
                      {rule[0].type}
                    </Badge>
                  </GridCell>
                  <GridCell>{rule[0].key}</GridCell>
                  <GridCell>{rule[0].operator}</GridCell>
                  <GridCell>{rule[0].value}</GridCell>
                  <Button variant="ghost" size="xs" display="icon" onClick={() => editProductRule(product, i)}>
                    <Icon icon="edit" />
                  </Button>
                </GridRow>
              ))
            )}
          </Grid>
          <CardFooter className="bg-accent border-t rounded-br-lg">
            <span className="h-7" />
          </CardFooter>
        </div>
      )}
    </Card>
  );
};

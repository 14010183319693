import { z } from "zod";

import { useModal } from "@/components/modal-provider";
import { Button } from "@/components/ui/button";
import { Spinner } from "@/components/ui/loading";
import { FieldTextarea } from "@/forms/fields/field-textarea";
import { useToast } from "@/hooks/use-toast";
import { PolicyFragment, useCreateProcessedBinderMutation, useQuoteQuery } from "src/generated/graphql";

export const GenerateProcessedBinderButton = ({ policy }: { policy: PolicyFragment }) => {
  const { id, quoteId } = policy;
  const { openForm } = useModal();
  const { toast } = useToast();
  const [createBinder, { loading }] = useCreateProcessedBinderMutation();
  const { data } = useQuoteQuery({ variables: { id: quoteId } });

  const quote = data?.quote;

  if (!quote) {
    return null;
  }

  const createQuoteMetadata = async () =>
    await openForm(
      <FieldTextarea
        name="outstandingSubjectivities"
        placeholder="Outstanding subjectivities (enter N/A if none apply)"
      />,
      {
        title: "Create Binder",
        defaultValues: { outstandingSubjectivities: quote.outstandingSubjectivities ?? "" },
        validationSchema: z.object({
          outstandingSubjectivities: z.string().min(1, { message: "Outstanding subjectivities is required" }),
        }),
        onSubmit: async (fd) =>
          await createBinder({
            variables: { input: { policyId: id, outstandingSubjectivities: fd.outstandingSubjectivities } },
            onCompleted: () => toast({ title: "Binder processed" }),
          }),
      }
    );

  return (
    <Button onClick={createQuoteMetadata} variant="outline" size="sm" display="flex" disabled={loading}>
      Generate Binder Cover Letter {loading && <Spinner />}
    </Button>
  );
};

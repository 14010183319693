import { Outlet, useNavigate } from "react-router-dom";

import { ScrollPane } from "@/components/scroll-pane";
import { useInsured } from "@/hooks/use-insured";
import { SetMetadata } from "@/hooks/use-metadata";
import { InsuredSidebar } from "./insured-sidebar/insured-sidebar";

export const InsuredRoot = () => {
  const navigate = useNavigate();
  const { insured } = useInsured();

  if (insured.mergedIntoId) {
    navigate(`/insured/${insured.mergedIntoId}`);
  }

  return (
    <>
      <SetMetadata title={insured.name} crumb />
      <InsuredSidebar />
      <ScrollPane sidebarPosition="left">
        <Outlet />
      </ScrollPane>
    </>
  );
};

import { STRICT_STATE_OPTIONS } from "@cp/toolkit";
import { add } from "date-fns";
import { FC, useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useNavigate } from "react-router";
import { z } from "zod";

import { Button } from "@/components/ui/button";
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from "@/components/ui/card";
import { Spinner } from "@/components/ui/loading";
import { FieldCheckbox } from "@/forms/fields/field-checkbox";
import { FieldDatePicker } from "@/forms/fields/field-date-picker";
import { FieldInput } from "@/forms/fields/field-input";
import { FieldCurrency, FieldNumber } from "@/forms/fields/field-number";
import { FieldSelect } from "@/forms/fields/field-select";
import { FieldTextarea } from "@/forms/fields/field-textarea";
import { Form } from "@/forms/form";
import { FormSubmit } from "@/forms/form-submit";
import { useInsured } from "@/hooks/use-insured";
import { useQuote } from "@/hooks/use-quote";
import { useToast } from "@/hooks/use-toast";
import {
  AlbyActionType,
  ProposalValuesQuery,
  QuoteAction,
  QuoteFragment,
  SubmissionDetailsQueryResult,
  useCreateProcessedQuotePdfMutation,
  useProposalValuesQuery,
  useResumableGraphQuery,
  useResumeGraphMutation,
  useSubmissionQuery,
  useTransitionQuoteMutation,
} from "src/generated/graphql";
import { cn } from "src/utils";
import { uploadProcessedQuote } from "src/utils/file";
import { QuoteJobs } from "./quote-jobs";

export const STATES_WITH_NO_BROKER_FEE = ["SD", "NH", "MT"];

export type Submission = NonNullable<NonNullable<SubmissionDetailsQueryResult["data"]>["submission"]>;

//#region Zod Schema
const QuoteProposalValuesSchema = z
  .object({
    carrierName: z
      .string({
        required_error: "Carrier name is required",
        invalid_type_error: "Carrier name must be a string",
      })
      .min(1, "Carrier name cannot be empty"),

    subjectivities: z
      .string({
        required_error: "Subjectivities are required",
        invalid_type_error: "Subjectivities must be a string",
      })
      .min(1, "Subjectivities cannot be empty"),

    insuredName: z
      .string({
        required_error: "Insured name is required",
        invalid_type_error: "Insured name must be a string",
      })
      .min(1, "Insured name cannot be empty"),

    effectiveDate: z.date({
      message: "Effective date is required",
    }),
    expirationDate: z.date().optional().nullable(),

    // QUESTION: Should we just make the `state` input a `Select` component and use the `STRICT_STATE_OPTIONS` for the options?
    homeState: z
      .string({
        required_error: "Home state is required",
        invalid_type_error: "Home state must be a string",
      })
      .length(2, "Home state must be a 2-letter state code"),

    premium: z.coerce.number().min(0, "Broker fee must be greater than 0").optional().nullable(),

    agentCommission: z
      .number({ message: "Agent commission is required" })
      .min(0, "Agent commission must be greater than 0")
      .max(100, "Agent commission must be 100 or less"),

    grossCommission: z
      .number({ message: "Gross commission is required" })
      .min(0, "Gross commission must be greater than 0")
      .max(100, "Gross commission must be 100 or less"),

    brokerFee: z.coerce.number().min(0, "Broker fee must be greater than 0").optional().nullable(),

    noInspectionFee: z.coerce.boolean(),

    inspectionFee: z.coerce.number().min(0, "Inspection fee must be greater than 0").optional().nullable(),

    carrierFee: z.coerce.number().min(0, "Carrier fee must be greater than 0").optional().nullable(),

    mep: z.coerce.number().min(0, "MEP must be greater than 0").optional().nullable(),
  })
  .superRefine((data, ctx) => {
    if (STATES_WITH_NO_BROKER_FEE.includes(data.homeState) && data.brokerFee && data.brokerFee > 0) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: `Broker fee is not allowed in ${STATES_WITH_NO_BROKER_FEE.join(", ")}`,
        path: ["brokerFee"],
      });
    }
    if (
      !(
        (!data.noInspectionFee && data.inspectionFee && data.inspectionFee > 0) ||
        (data.noInspectionFee && !data.inspectionFee)
      )
    ) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "Inspection fee is required if no inspection fee is not checked",
        path: ["inspectionFee"],
      });
    }
  });
//#endregion

export const QuoteProposalForm = () => {
  const { insured } = useInsured();
  const { quote } = useQuote();
  const navigate = useNavigate();
  const { toast } = useToast();

  const [createQuotePDF, { loading: creatingPDF }] = useCreateProcessedQuotePdfMutation();

  const {
    data: quoteProposalData,
    error,
    loading: loadingValues,
  } = useProposalValuesQuery({
    variables: { input: { quoteId: quote.id } },
    pollInterval: 2000,
    skip: !quote.redactedQuote,
  });

  const { data: submissionData } = useSubmissionQuery({ variables: { id: quote.submissionId } });

  const { data, refetch } = useResumableGraphQuery({
    variables: {
      input: {
        name: "QuoteTransitionGraph",
        selectedTool: "generate-cover-letter",
        insuredId: insured.id,
        invokeParams: JSON.stringify({ quoteId: quote.id }),
      },
    },
  });

  const [resumeGraph, { loading: resumingGraph }] = useResumeGraphMutation();

  const resumableGraphThreadId = data?.resumableGraph?.threadId;
  const submission = submissionData?.submission;
  const quoteValues = quoteProposalData?.proposalValues;

  const generateQuote = async (values: z.infer<typeof QuoteProposalValuesSchema>) => {
    // eslint-disable-next-line unicorn/prefer-ternary
    if (resumableGraphThreadId) {
      return resumeGraph({
        variables: {
          input: {
            threadId: resumableGraphThreadId,
            actionType: AlbyActionType.Approve,
            brokerInput: JSON.stringify({
              homeState: values.homeState,
              carrierName: values.carrierName,
              subjectivities: values.subjectivities?.replaceAll("-", ""),
              effectiveDate: new Date(values.effectiveDate),
              expirationDate: values.expirationDate ? new Date(values.expirationDate) : null,
              mep: values.mep ?? 0, // don't divide by 100, it's done on backend
              premium: Number(values.premium),
              agentCommission: values.agentCommission ?? 0, // don't divide by 100, it's done on backend
              grossCommission: values.grossCommission ?? 0, // don't divide by 100, it's done on backend
              inspectionFee: Number(values.inspectionFee),
              carrierFee: Number(values.carrierFee),
              brokerFee: Number(values.brokerFee),
            }),
          },
        },
        onCompleted() {
          toast({ title: "Quote proposal generated" });
          navigate(-1);
        },
        onError: (error) => {
          refetch();
          toast({ title: error.message });
        },
        refetchQueries: ["SubmissionDetails", "Quote"],
      });
    } else {
      return createQuotePDF({
        variables: {
          input: {
            quoteId: quote.id,
            submissionId: quote.submissionId,
            homeState: values.homeState,
            insuredName: values.insuredName,
            carrierName: values.carrierName ?? "",
            inspectionFee: Number(values.inspectionFee),
            agentCommission: values.agentCommission ? values.agentCommission / 100 : 0,
            grossCommission: values.grossCommission ? values.grossCommission / 100 : 0,
            brokerFee: Number(values.brokerFee),
            mep: values.mep ? values.mep / 100 : 0,
            premium: Number(values.premium),
            carrierFee: Number(values.carrierFee),
            subjectivities:
              values.subjectivities
                ?.replaceAll("-", "")
                .split("\n")
                .map((s: string) => s.trim()) ?? [],
            effectiveDate: new Date(values.effectiveDate),
            expirationDate: values.expirationDate ? new Date(values.expirationDate) : null,
          },
        },
        onError: (error) => toast({ title: error.message }),
        onCompleted: () => toast({ title: "Quote proposal generated" }),
        refetchQueries: ["SubmissionDetails", "Quote"],
      });
    }
  };

  if (error) {
    return (
      <Card className="bg-red-600 text-white">
        <CardHeader>
          <CardTitle className="text-white">Sorry! An error occurred while fetching the proposal data.</CardTitle>
          <CardDescription className="text-white">
            Message: {error.message ?? "An error occurred while fetching the proposal data."}
          </CardDescription>
        </CardHeader>
      </Card>
    );
  }

  if (!quote.redactedQuote) {
    return <EnhancedLoading label="Waiting for file upload" />;
  }

  if (loadingValues || quoteValues === null || !submission) {
    return <EnhancedLoading label="Loading quote data" />;
  }

  if (creatingPDF) {
    return <EnhancedLoading label="Creating Quote Proposal" />;
  }

  return (
    <>
      <Card className="divide-y">
        <CardHeader>
          <CardTitle>Generate Quote</CardTitle>
        </CardHeader>
        <Form validationSchema={QuoteProposalValuesSchema} onSubmit={generateQuote}>
          <QuoteProposalFormContent quote={quote} quoteValues={quoteValues} loading={creatingPDF || resumingGraph} />
        </Form>
      </Card>
      {quote.redactedQuote && (
        <Card className="divide-y">
          <QuoteJobs fileId={quote.redactedQuote.id} />
        </Card>
      )}
    </>
  );
};

interface QuoteProposalFormContentProps {
  quote: QuoteFragment;
  quoteValues: ProposalValuesQuery["proposalValues"];
  loading: boolean;
}

const QuoteProposalFormContent: FC<QuoteProposalFormContentProps> = ({ quote, quoteValues, loading }) => {
  const { insured } = useInsured();
  const formMethods = useFormContext();

  const { watch } = formMethods;

  const grossCommission = Number(watch("grossCommission")) / 100;
  const agentCommission = Number(watch("agentCommission")) / 100;
  const premium = Number(watch("premium"));
  const brokerFee = Number(watch("brokerFee"));
  const noInspectionFee = watch("noInspectionFee");

  useEffect(() => {
    if (noInspectionFee) {
      formMethods.setValue("inspectionFee", 0, { shouldValidate: true });
    }
  }, [noInspectionFee]);

  const dealYield =
    grossCommission && agentCommission && premium && brokerFee
      ? (((grossCommission - agentCommission) * premium + brokerFee) / premium) * 100
      : 0;

  useEffect(() => {
    if (quoteValues) {
      const effectiveDate = new Date(quoteValues.effectiveDate ?? new Date());
      const expirationDate = add(effectiveDate, { years: 1 });
      const defaultValues = {
        carrierName: quoteValues?.carrierName ?? "",
        insuredName: quoteValues?.insuredName ?? insured.name ?? "",
        homeState: quoteValues?.homeState ?? "",
        effectiveDate: effectiveDate,
        expirationDate: expirationDate,
        subjectivities: (quoteValues.subjectivities ?? []).join("\n"),
        premium: quoteValues?.premium,
        agentCommission: quoteValues?.agentCommission ? quoteValues.agentCommission * 100 : undefined,
        grossCommission: quoteValues?.grossCommission ? quoteValues.grossCommission * 100 : undefined,
        brokerFee: quoteValues?.brokerFee,
        inspectionFee: quoteValues?.inspectionFee,
        noInspectionFee: false,
        carrierFee: null,
        mep: quoteValues?.mep ? quoteValues.mep * 100 : 25,
      };
      formMethods.reset(defaultValues);
    }
  }, [insured, quoteValues]);

  return (
    <>
      <div className="grid grid-cols-6 gap-4 p-6">
        <FieldInput name="carrierName" label="Carrier Name" className="col-span-3" />
        <FieldInput name="insuredName" label="Insured Name" className="col-span-3" />
        <FieldSelect name="homeState" label="Home State" options={STRICT_STATE_OPTIONS} className="col-span-2" />
        <FieldDatePicker name="effectiveDate" label="Effective Date" className="col-span-2" />
        <FieldDatePicker name="expirationDate" label="Expiration Date" className="col-span-2" />
        <FieldCurrency name="premium" label="Premium" />
        <FieldCurrency name="brokerFee" label="Broker Fee (total)" />
        <dl>
          <dt>Confirm No Inspection Fee</dt>
          <dd className="mt-1.5">
            <FieldCheckbox name="noInspectionFee" />
          </dd>
        </dl>
        <FieldNumber name="inspectionFee" label="Inspection Fee (total)" />
        <FieldCurrency name="carrierFee" label="Carrier Fee (if applicable)" />
        <FieldNumber name="mep" label="MEP %" />
        <FieldNumber inputProps={{ decimalScale: 2 }} name="grossCommission" label="Gross Commission %" />
        <FieldNumber inputProps={{ decimalScale: 2 }} name="agentCommission" label="Agent Commission %" />
        <dl>
          <dt>Yield</dt>
          <dd>{dealYield.toFixed(2)} %</dd>
        </dl>
        <FieldTextarea
          name="subjectivities"
          label="Subjectivities"
          placeholder="List subjectivities"
          rows={8}
          className="col-span-6"
        />
      </div>
      <CardFooter>
        <FormSubmit variant="outline" theme="default" disabled={loading} />
        <UploadProcessedQuoteButton quote={quote} />
      </CardFooter>
    </>
  );
};

const UploadProcessedQuoteButton = ({ quote }: { quote: QuoteFragment }) => {
  const { toast } = useToast();
  const [submitting, setSubmitting] = useState(false);
  const [transition] = useTransitionQuoteMutation({ refetchQueries: ["Quote"] });

  return (
    <Button asChild variant="outline">
      <label className={cn(submitting ? "cursor-wait opacity-60" : "cursor-pointer")}>
        <input
          type="file"
          name="file"
          className="hidden"
          onChange={async (e) => {
            setSubmitting(true);

            if (e.target.files && e.target.files.length > 0) {
              const file = e.target.files[0];

              if (file.type !== "application/pdf") {
                toast({ title: "We only accept PDF files" });
                setSubmitting(false);
                return;
              }

              if (!quote) {
                toast({ title: "Error" });
                setSubmitting(false);
                return;
              }

              await uploadProcessedQuote(file, quote.id).then((res) => {
                if (res.success) {
                  void transition({
                    variables: {
                      input: {
                        id: quote.id,
                        expectedState: quote.state,
                        action: QuoteAction.ManuallyProcess,
                      },
                    },
                    onCompleted: () => {
                      toast({ title: "Processed Quote Uploaded" });
                    },
                  });
                } else {
                  toast({ title: "Error" });
                }
              });
            }
            setSubmitting(false);
          }}
        />
        Upload Processed Quote {submitting && <Spinner />}
      </label>
    </Button>
  );
};

const EnhancedLoading = ({ label }: { label: string }) => (
  <Card className="w-full max-w-md mx-auto my-8">
    <CardContent className="flex flex-col items-center justify-center p-8">
      <Spinner className="size-8 mb-4" />
      <p className="text-lg font-medium text-center text-foreground">{label}</p>
    </CardContent>
  </Card>
);

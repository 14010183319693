import { addDays } from "date-fns";

import { Button } from "@/components/ui/button";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import { Icon } from "@/components/ui/icon";
import { ActionsQuery, useCreateActionMutation } from "src/generated/graphql";
import { Group } from "../../components/group";
import { ExecutedFollowUp } from "./executed-follow-up";
import { ScheduledFollowUp } from "./scheduled-follow-up";

interface FollowUpsTableProps {
  actions: ActionsQuery["actions"];
  opportunityId: string;
}

export const FollowUpsTable = ({ actions, opportunityId }: FollowUpsTableProps) => {
  const [createAction] = useCreateActionMutation({
    variables: {
      input: {
        opportunityId,
        actionType: actions[0].actionType,
        title: actions[0].title,
        description: actions[0].description,
        // Get the greater of [tomorrow, or the Action with the latest autoExecuteAt]
        autoExecuteAt: new Date(
          Math.max(
            addDays(Date.now(), 1).getTime(),
            addDays(new Date(actions[actions.length - 1].autoExecuteAt), 1).getTime()
          )
        ),
      },
    },
    refetchQueries: ["Actions"],
  });

  return (
    <Card>
      <CardHeader>
        <Group direction="row" className="items-center justify-between">
          <CardTitle className="truncate">{actions[0].title}</CardTitle>
          <Button variant="ghost" size="xs" display="icon" onClick={() => createAction()}>
            <Icon icon="add_2" />
          </Button>
        </Group>
        <CardDescription className="text-xs">{actions[0].description}</CardDescription>
      </CardHeader>

      <CardContent className="space-y-4 text-xs">
        {actions.length === 0 && <h4>No actions yet to approve</h4>}
        {actions.map((action) =>
          action.executedAt ? (
            <ExecutedFollowUp key={action.id} action={action} />
          ) : (
            <ScheduledFollowUp key={action.id} action={action} />
          )
        )}
      </CardContent>
    </Card>
  );
};

import { z } from "zod";

import { useModal } from "@/components/modal-provider";
import {
  useUpdateVerticalMarketingPlanTemplateProductRulesMutation,
  VerticalMarketingPlanTemplateFragment,
  VerticalMarketingPlanTemplateProductFragment,
} from "src/generated/graphql";
import { getRuleKey, Rule, Rules, RuleSchema, RuleType } from "../rule";
import { ProductRuleForm } from "./product-rule-form";
import { TemplateRuleForm } from "./template-rule-form";
import { useVertical } from "./use-vertical";

export const useTemplate = ({ template }: { template: VerticalMarketingPlanTemplateFragment }) => {
  const { openForm, openConfirmation } = useModal();
  const { refetch } = useVertical();

  const [updateVerticalMarketingPlanTemplateProductRulesTrigger] =
    useUpdateVerticalMarketingPlanTemplateProductRulesMutation({
      onCompleted: refetch,
    });

  const addTemplateRule = async () => {
    const validationSchema = z.object({
      rule: z.array(z.object({ type: z.string(), key: z.string().optional(), valueType: z.string().optional() })),
    });

    const defaultValues = {
      rule: [{ type: "", key: "", valueType: "" }],
    };

    const onSubmit = async (fd: z.infer<typeof validationSchema>) =>
      await Promise.all(
        template.products.map(async (p) => {
          const parsedRules = JSON.parse(p.rules);
          const updatedRules = [...parsedRules, fd.rule];

          return await updateVerticalMarketingPlanTemplateProductRulesTrigger({
            variables: { input: { id: p.id, rules: JSON.stringify(updatedRules) } },
          });
        })
      );

    await openForm(<TemplateRuleForm />, {
      title: "Add template rule",
      defaultValues,
      validationSchema,
      onSubmit,
    });
  };

  const editTemplateRule = async (ruleKey: string, update: () => void) => {
    const firstMatch = template.products
      .flatMap((p) => JSON.parse(p.rules) as Rules)
      .find((rule) => getRuleKey(rule) === ruleKey);

    if (!firstMatch) {
      return;
    }

    const getExistingRule = (rule: Rule) => {
      const ruleItem = rule[0];

      switch (ruleItem.type) {
        case RuleType.ClientData:
          return {
            type: ruleItem.type,
            key: ruleItem.key,
            valueType: ruleItem.valueType,
          };
        case RuleType.PrimaryLabel:
          return {
            type: ruleItem.type,
            key: ruleItem.key,
          };
        default:
          return { type: ruleItem.type };
      }
    };

    await openForm(<TemplateRuleForm />, {
      title: "Edit Template Rule",
      defaultValues: { rule: [getExistingRule(firstMatch)] },
      validationSchema: z.object({
        rule: z.array(
          z.object({
            type: z.string(),
            key: z.string().optional(),
            valueType: z.string().optional(),
          })
        ),
      }),
      onSubmit: async (fd) =>
        await Promise.all(
          template.products.map((p) => {
            const parsedRules = JSON.parse(p.rules) as Rules;
            const updatedRules = parsedRules.map((rule) => {
              const key = getRuleKey(rule);
              if (key !== ruleKey) {
                return rule;
              }

              return [{ ...rule[0], ...fd.rule[0] }];
            });
            return updateVerticalMarketingPlanTemplateProductRulesTrigger({
              variables: { input: { id: p.id, rules: JSON.stringify(updatedRules) } },
              update,
            });
          })
        ),
    });
  };

  const deleteTemplateRule = async (ruleKey: string, update: () => void) => {
    const isConfirmed = await openConfirmation({
      title: "Delete Template Rule",
      buttonProps: { theme: "destructive" },
    });

    if (!isConfirmed) {
      return;
    }

    const rules = template.products.map((product) => {
      const parsedRules = JSON.parse(product.rules) as Rules;
      return parsedRules.filter((r) => getRuleKey(r) !== ruleKey);
    });

    await Promise.all(
      template.products.map((product, i) =>
        updateVerticalMarketingPlanTemplateProductRulesTrigger({
          variables: { input: { id: product.id, rules: JSON.stringify(rules[i]) } },
          update,
        })
      )
    );
  };

  const editProductRules = async (products: VerticalMarketingPlanTemplateProductFragment[], ruleKey: string) => {
    const rules = JSON.parse(products[0].rules) as Rules;
    const ruleIndex = rules.findIndex((rule) => getRuleKey(rule) === ruleKey);

    await openForm(<ProductRuleForm products={products} />, {
      title: "Edit Product Rule",
      validationSchema: z.object({ rule: RuleSchema }),
      defaultValues: { rule: rules[ruleIndex][0] },
      onSubmit: async (fd) => {
        const updatedRules = rules.map((rule, i: number) => {
          if (i !== ruleIndex) {
            return rule;
          }

          return [fd.rule];
        });

        await Promise.all(
          products.map((p) =>
            updateVerticalMarketingPlanTemplateProductRulesTrigger({
              variables: { input: { id: p.id, rules: JSON.stringify(updatedRules) } },
            })
          )
        );
      },
    });
  };

  const editProductRule = async (product: VerticalMarketingPlanTemplateProductFragment, index: number) => {
    const rules = JSON.parse(product.rules);

    await openForm(<ProductRuleForm products={[product]} />, {
      title: product.appetiteProduct.carrierName,
      description: product.appetiteProduct.carrierProductName,
      validationSchema: z.object({ rule: RuleSchema }),
      defaultValues: { rule: rules[index][0] },
      onSubmit: async (fd) => {
        const updatedRules = rules.map((rule: any, i: number) => {
          if (i !== index) {
            return rule;
          }

          return [fd.rule];
        });

        await updateVerticalMarketingPlanTemplateProductRulesTrigger({
          variables: { input: { id: product.id, rules: JSON.stringify(updatedRules) } },
        });
      },
    });
  };

  return {
    addTemplateRule,
    editTemplateRule,
    deleteTemplateRule,
    editProductRules,
    editProductRule,
  };
};

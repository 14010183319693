import { useReactFlow } from "@xyflow/react";
import { useNavigate } from "react-router";

import { ToolbarMenuItem } from "@/components/ui/toolbar";
import { useKeyboardShortcut } from "@/hooks/use-keyboard-shortcut";

import { initialViewport } from "../../../file-processing-pipeline.constants";
import { useFileProcessingPipelineState } from "../../../file-processing-pipeline.provider";

export const CollapseAll = () => {
  const navigate = useNavigate();
  const { setExpandedNodes, expandedNodes } = useFileProcessingPipelineState();
  const { setViewport } = useReactFlow();

  const handleCollapseAllClick = async () => {
    setExpandedNodes([]);
    navigate(`/file-processing-pipeline`);
    await setViewport(initialViewport, { duration: 1000 });
  };

  useKeyboardShortcut("ctrl+c", handleCollapseAllClick);

  return (
    <ToolbarMenuItem
      label="Collapse all"
      icon="collapse_content"
      shortcut="⌃C"
      onClick={handleCollapseAllClick}
      disabled={expandedNodes.length === 0}
    />
  );
};

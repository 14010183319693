import { uniqBy } from "lodash";
import { FC, useMemo, useState } from "react";
import { useAsync } from "react-use";
import { useDebounceValue } from "usehooks-ts";
import { FieldCombobox, FieldComboboxOption, FieldComboboxProps } from "./field-combobox";

export interface FieldComboboxAsyncProps extends Omit<FieldComboboxProps, "options" | "async" | "asyncValue"> {
  loadDefaultOptions?: () => Promise<FieldComboboxOption[]>;
  loadOptions: (term?: string) => Promise<FieldComboboxOption[]>;
}

export const FieldComboboxAsync: FC<FieldComboboxAsyncProps> = ({ loadOptions, loadDefaultOptions, ...props }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedSearchTerm] = useDebounceValue(searchTerm, 200);

  const { value: defaultOptions = [] } = useAsync(async () => loadDefaultOptions?.(), [loadDefaultOptions]);
  const { value: options = [] } = useAsync(
    async () => loadOptions(debouncedSearchTerm),
    [debouncedSearchTerm, loadOptions]
  );

  const uniqueOptions = useMemo(
    () => uniqBy([...defaultOptions, ...(options || [])], "value"),
    [defaultOptions, options]
  );

  return <FieldCombobox {...props} options={uniqueOptions} onCommandInputValueChange={setSearchTerm} async />;
};

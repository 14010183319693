import React, { FC } from "react";

import { Loading } from "@/components/ui/loading";
import { FieldCombobox, FieldComboboxOption } from "@/forms/fields/field-combobox";
import { Form } from "@/forms/form";
import { useToast } from "@/hooks/use-toast";
import {
  FindFileUploadQuery,
  useFileTagsQuery,
  useFindFileUploadQuery,
  useUpdateFileUploadTagsMutation,
} from "src/generated/graphql";

export default function TagSelector({ fileId }: { fileId: string }) {
  const { toast } = useToast();

  const { data: fileData, loading, refetch } = useFindFileUploadQuery({ variables: { input: { id: fileId } } });

  const [update] = useUpdateFileUploadTagsMutation();

  const updateTag = async (value: string, remove?: boolean) =>
    await update({
      variables: { input: { id: fileId, label: value } },
      onError: (e) => toast({ title: e.message }),
      onCompleted: () => {
        toast({ title: `Tag ${remove ? "removed" : "added"}` });
        refetch();
      },
      refetchQueries: ["FileTags", "FilesByLabel"],
    });

  const add = async (value: string) => await updateTag(value);
  const remove = async (value: string) => await updateTag(value, true);

  if (loading && !fileData?.findFile) {
    return <Loading />;
  }

  if (!fileData) {
    return <div>File not found</div>;
  }

  return <TagForm fileData={fileData} onAdd={add} onRemove={remove} />;
}

const TagForm: FC<{
  fileData: FindFileUploadQuery;
  onAdd?: (value: string) => void;
  onRemove?: (value: string) => void;
}> = ({ fileData, onAdd, onRemove }) => {
  const { data } = useFileTagsQuery({ variables: { input: {} } });

  const defaultValues = {
    tags: fileData?.findFile.labels ?? [],
  };

  const [prevValue, setPrevValue] = React.useState<string[]>(defaultValues.tags);

  const handleChange = (value: string | string[]) => {
    const newValue = value as string[];

    const added = newValue.filter((v) => !prevValue.includes(v));
    const removed = prevValue.filter((v) => !value.includes(v));

    added.forEach((v) => onAdd?.(v));
    removed.forEach((v) => onRemove?.(v));

    setPrevValue(newValue);
  };

  const options = (data?.fileTags || []).map((tag) => {
    const hidden = ["to:", "from:"].some((v) => tag.label.includes(v));

    return { label: tag.label, value: tag.label, itemProps: { hidden } } as FieldComboboxOption;
  });

  return (
    <Form defaultValues={defaultValues}>
      <FieldCombobox name="tags" placeholder="Search for labels" options={options} onChange={handleChange} multiple />
    </Form>
  );
};

import { FC } from "react";
import { useFieldArray, useFormContext, UseFormReturn } from "react-hook-form";
import { z } from "zod";

import { ButtonGroup } from "@/components/button-group";
import { CglCodeSelector } from "@/components/cgl-code-selector";
import { Grid, GridCell, GridEmpty, GridRow, GridRowAction, GridRowHeader } from "@/components/grid";
import { Group } from "@/components/group";
import { LinesOfBusinessSelector } from "@/components/lines-of-business-selector";
import { Button } from "@/components/ui/button";
import { Card, CardContent, CardFooter, CardHeader, CardTitle } from "@/components/ui/card";
import { Icon } from "@/components/ui/icon";
import { Separator } from "@/components/ui/separator";
import { FieldHidden } from "@/forms/fields/field-hidden";
import { FieldInput } from "@/forms/fields/field-input";
import { FieldTextarea } from "@/forms/fields/field-textarea";
import { Form } from "@/forms/form";
import { FormReset } from "@/forms/form-reset";
import { FormSubmit } from "@/forms/form-submit";
import { useToast } from "@/hooks/use-toast";
import { UpdateSegmentCriteriaInput, useKnownPrimaryLabelsQuery } from "src/generated/graphql";

import { RuleFormDialog, RuleFormValues, RuleSetFormSchema } from "./components/rule-form/rule-form";
import { ruleValueToDisplayValue, ruleValueToFormValue } from "./components/rule-form/rule-form.helpers";
import { useSegment } from "./hooks/use-segment";

export const SegmentCriteriaFormSchema = z.object({
  id: z.string(),
  linesOfBusiness: z.array(z.string()).min(1, { message: "Please select at least one line of business" }),
  isoCglCodes: z.array(z.string()).optional(),
  ruleSet: RuleSetFormSchema.optional(),
});

export const UpdateSegmentFormSchema = z.object({
  id: z.string(),
  name: z.string().min(1, "Please enter a name"),
  description: z.string().optional(),
  segmentCriteria: SegmentCriteriaFormSchema,
});

export type UpdateSegmentFormValues = z.infer<typeof UpdateSegmentFormSchema>;

export const SegmentCriteria = () => {
  const { toast } = useToast();
  const { segment, updateSegment } = useSegment();

  const defaultValues = {
    id: segment?.id ?? "",
    name: segment?.name ?? "",
    description: segment?.description ?? "",
    segmentCriteria: {
      id: segment?.segmentCriteria.id ?? "",
      linesOfBusiness: segment?.segmentCriteria.linesOfBusiness ?? [],
      isoCglCodes: segment?.segmentCriteria.isoCglCodes ?? [],
      ruleSet: {
        id: segment?.segmentCriteria.ruleSet?.id ?? "",
        rules: segment?.segmentCriteria.ruleSet?.rules ?? [],
      },
    },
  };

  const handleSubmit = async (values: UpdateSegmentFormValues, methods: UseFormReturn<UpdateSegmentFormValues>) =>
    await updateSegment(values as UpdateSegmentCriteriaInput, {
      onCompleted: () => {
        toast({ title: "Segment updated" });
        methods.reset(values);
      },
      onError: () => toast({ title: "Failed to update segment", variant: "destructive" }),
    });

  return (
    <div className="p-6">
      <Form
        validationSchema={UpdateSegmentFormSchema}
        defaultValues={defaultValues as UpdateSegmentCriteriaInput}
        onSubmit={handleSubmit}
        mode="onChange"
        className="flex flex-auto flex-col max-w-3xl mx-auto"
      >
        <FieldHidden name="id" />
        <FieldHidden name="segmentCriteria.id" />

        <Card className="relative">
          <CardHeader>
            <CardTitle>Details</CardTitle>
          </CardHeader>

          <CardContent>
            <Group className="gap-6">
              <Group>
                <FieldInput name="name" label="Name" />
                <FieldTextarea name="description" label="Description" rows={3} optional />
              </Group>

              <Separator />

              <h4>Criteria</h4>

              <Group>
                <LinesOfBusinessSelector label="Lines of business" name="segmentCriteria.linesOfBusiness" multiple />
                <CglCodeSelector name="segmentCriteria.isoCglCodes" optional multiple />
              </Group>

              <Separator />

              <Group className="gap-2">
                <RulesGrid />
              </Group>
            </Group>
          </CardContent>

          <CardFooter className="z-10 sticky bottom-0 bg-card border-t pt-5 rounded-b-lg gap-2">
            <UnsavedChangesNotice />
            <ButtonGroup>
              <FormReset onlyEnableIfDirty />
              <FormSubmit onlyEnableIfDirty />
            </ButtonGroup>
          </CardFooter>
        </Card>
      </Form>
    </div>
  );
};

export const UnsavedChangesNotice = () => {
  const { formState } = useFormContext<UpdateSegmentFormValues>();

  if (!formState.isDirty) {
    return null;
  }

  return (
    <div className="flex items-center justify-center gap-2 text-sm text-muted-foreground">
      <Icon icon="warning" className="text-lg text-amber-500" />
      <span>You have unsaved changes. Save them before you go.</span>
    </div>
  );
};

export const RulesGrid = () => {
  const { control } = useFormContext<UpdateSegmentFormValues>();
  const { fields, append, remove } = useFieldArray({ control, name: "segmentCriteria.ruleSet.rules" });

  const handleSubmit = async (values: RuleFormValues) => append(values);

  return (
    <>
      <FieldHidden name="segmentCriteria.ruleSet.id" />

      <Group type="flex" direction="row">
        <h4>Rules</h4>
        <span className="flex-1" />
        <RuleFormDialog onSubmit={handleSubmit}>
          <Button variant="outline" size="sm">
            <Icon icon="add" /> Add rule
          </Button>
        </RuleFormDialog>
      </Group>

      <Grid className="grid-cols-[2fr_1fr_1fr_3.5rem] -mx-6">
        <GridRowHeader>
          <GridCell>Key</GridCell>
          <GridCell>Operator</GridCell>
          <GridCell>Value</GridCell>
          <GridCell />
        </GridRowHeader>

        {fields.length === 0 && (
          <GridEmpty orientation="horizontal" description="No rules have been added." className="pt-6 pb-0">
            <RuleFormDialog onSubmit={handleSubmit}>
              <Button type="button" variant="outline" size="sm">
                <Icon icon="add" /> Add rule
              </Button>
            </RuleFormDialog>
          </GridEmpty>
        )}

        {fields.map((field, index) => (
          <RulesGridRow key={field.id} index={index} onDelete={() => remove(index)} />
        ))}
      </Grid>
    </>
  );
};

export interface RulesGridRowProps {
  index: number;
  onDelete: () => void;
}

export const RulesGridRow: FC<RulesGridRowProps> = ({ index, onDelete }) => {
  const { watch, setValue } = useFormContext<UpdateSegmentFormValues>();
  const rule = watch(`segmentCriteria.ruleSet.rules.${index}`);
  const { data } = useKnownPrimaryLabelsQuery();

  const knownPrimaryLabels = data?.knownPrimaryLabels;

  if (!rule) {
    return null;
  }

  const defaultValues = {
    ...rule,
    __valueType: knownPrimaryLabels?.find((l) => l.primaryKey === rule.inputKey)?.dataType || undefined,
    value: ruleValueToFormValue(rule.value) || "",
  };

  const handleSubmit = async (values: RuleFormValues) =>
    setValue(`segmentCriteria.ruleSet.rules.${index}`, values, { shouldValidate: true, shouldDirty: true });

  return (
    <GridRow>
      <GridCell>{rule.inputKey}</GridCell>
      <GridCell>{rule.operator}</GridCell>
      <GridCell>{ruleValueToDisplayValue(rule.value)}</GridCell>
      <GridCell>
        <ButtonGroup>
          <RuleFormDialog mode="update" defaultValues={defaultValues as RuleFormValues} onSubmit={handleSubmit}>
            <GridRowAction label="Edit">
              <Icon icon="edit" />
            </GridRowAction>
          </RuleFormDialog>
          <GridRowAction label="Delete" onClick={onDelete}>
            <Icon icon="delete" />
          </GridRowAction>
        </ButtonGroup>
      </GridCell>
    </GridRow>
  );
};

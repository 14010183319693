import { cva, type VariantProps } from "class-variance-authority";
import { NavLink, NavLinkProps, useSearchParams } from "react-router-dom";

import { cn } from "src/utils";

const barVariants = cva(
  "bg-inherit box-content flex flex-none font-medium gap-3 items-center justify-between px-6 text-xs",
  {
    variants: {
      as: {
        nav: "",
        footer: "py-3",
        header: "h-12",
      },
      position: {
        relative: "relative",
        sticky: "sticky z-20",
      },
    },
    compoundVariants: [
      {
        as: "header",
        position: "sticky",
        className: "peer top-0 peer-[&]:top-12 border-b",
      },
      {
        as: "footer",
        position: "sticky",
        className: "bottom-0 border-t",
      },
    ],
    defaultVariants: {
      as: "header",
      position: "sticky",
    },
  }
);

interface BarProps extends React.HTMLAttributes<HTMLElement>, VariantProps<typeof barVariants> {}

export const Bar = ({ as, position, className, ...props }: BarProps) => {
  const Component = as ?? "header";
  return <Component className={cn(barVariants({ as, position }), className)} {...props} />;
};

export const BarLinks = ({ children }: React.HTMLAttributes<HTMLElement>) => (
  <nav className="flex flex-auto gap-6 self-stretch">{children}</nav>
);

export const BarLink = ({
  to,
  children,
  className,
  insertParams = false,
  ...props
}: NavLinkProps & { insertParams?: boolean }) => {
  const [searchParams] = useSearchParams();
  const params = insertParams && searchParams ? `?${searchParams.toString()}` : "";

  return (
    <NavLink
      className={({ isActive }: { isActive: boolean }) =>
        cn(
          "flex-none flex gap-2 items-center transition-colors",
          isActive ? "text-primary shadow-[0_1px_var(--color-primary)]" : "text-muted-foreground",
          className
        )
      }
      to={`${to as string}${params}`}
      role="link"
      {...props}
    >
      {children}
    </NavLink>
  );
};

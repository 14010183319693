import { KnownClientData } from "@qw/qw-common";

import { useModal } from "@/components/modal-provider";
import { useToast } from "@/hooks/use-toast";
import {
  useAddVerticalMarketingPlanTemplateProductRequirementMutation,
  useDeleteVerticalMarketingPlanTemplateProductRequirementMutation,
  useUpdateVerticalMarketingPlanTemplateProductRequirementMutation,
  VerticalMarketingPlanTemplateProductFragment,
} from "src/generated/graphql";
import { RequirementForm, RequirementFormSchema } from "./requirement-form";
import { useVertical } from "./use-vertical";

export const useTemplateRequirements = ({ products }: { products: VerticalMarketingPlanTemplateProductFragment[] }) => {
  const { openForm, openConfirmation } = useModal();
  const { toast } = useToast();
  const { refetch } = useVertical();

  const [addVerticalMarketingPlanTemplateProductRequirementTrigger] =
    useAddVerticalMarketingPlanTemplateProductRequirementMutation({
      onCompleted: refetch,
      onError: (e) =>
        toast({
          title: e.message.includes("Unique")
            ? "Requirement with the same title already exists"
            : "Unable to create requirement",
        }),
    });

  const [updateVerticalMarketingPlanTemplateProductRequirementTrigger] =
    useUpdateVerticalMarketingPlanTemplateProductRequirementMutation({
      onCompleted: refetch,
    });

  const [deleteVerticalMarketingPlanTemplateProductRequirementTrigger] =
    useDeleteVerticalMarketingPlanTemplateProductRequirementMutation({
      onCompleted: refetch,
    });

  const addRequirement = async () =>
    await openForm(<RequirementForm />, {
      title: "Add Requirement",
      validationSchema: RequirementFormSchema,
      onSubmit: async (fd) =>
        await addVerticalMarketingPlanTemplateProductRequirementTrigger({
          variables: {
            input: {
              verticalMarketingPlanTemplateProductIds: products.map((product) => product.id),
              title: fd.title,
              description: fd.description,
              groups: JSON.stringify(fd.groups),
            },
          },
        }),
    });

  const addAcord125 = async () => {
    const groups = [
      {
        title: "Complete Basic Information",
        description: "",
        elements: [
          {
            element: "date",
            props: JSON.stringify({ label: "Effective Date" }),
            clientDataKey: KnownClientData.ProposedEffectiveDate,
            array: false,
          },
          {
            element: "input",
            props: JSON.stringify({ label: "Mailing Address" }),
            clientDataKey: KnownClientData.MailingAddress.AddressLine1,
            array: false,
          },
          {
            element: "input",
            props: JSON.stringify({ label: "Mailing City" }),
            clientDataKey: KnownClientData.MailingAddress.City,
            array: false,
          },
          {
            element: "input",
            props: JSON.stringify({ label: "Mailing State" }),
            clientDataKey: KnownClientData.MailingAddress.State,
            array: false,
          },
          {
            element: "input",
            props: JSON.stringify({ label: "Mailing Zip" }),
            clientDataKey: KnownClientData.MailingAddress.Zip,
            array: false,
          },
          {
            element: "input",
            props: JSON.stringify({ label: "Year Business Started" }),
            clientDataKey: KnownClientData.YearEstablished,
            array: false,
          },
          {
            element: "textarea",
            props: JSON.stringify({ label: "Description Of Operations" }),
            clientDataKey: KnownClientData.DescriptionOfOperations,
            array: false,
          },
          {
            element: "textarea",
            props: JSON.stringify({ label: "Prior Coverage Details" }),
            clientDataKey: KnownClientData.PriorCoverages,
            array: false,
          },
          {
            element: "checkbox",
            props: JSON.stringify({ label: "No prior losses in the last 5 years" }),
            clientDataKey: KnownClientData.LossHistory.NoLosses5y,
            array: false,
          },
        ],
      },
      {
        title: "Add Premises",
        description: "",
        elements: [
          {
            element: "building",
            props: JSON.stringify({ label: "Premises Information" }),
            clientDataKey: "Premises_Information",
            array: false,
          },
        ],
      },
      {
        title: "",
        description: "",
        elements: [
          {
            element: "checkbox",
            props: JSON.stringify({
              label:
                "I AFFIRM THAT I AM AN AUTHORIZED REPRESENTATIVE OF THE APPLICANT AND REPRESENT THAT REASONABLE INQUIRY HAS BEEN MADE TO OBTAIN THE ANSWERS TO QUESTIONS ON THIS APPLICATION. I REPRESENT THAT THE ANSWERS ARE TRUE, CORRECT AND COMPLETE TO THE BEST OF MY KNOWLEDGE.",
              required: true,
            }),
            clientDataKey: KnownClientData.Acknowledgment.BasicInfo,
            array: false,
          },
        ],
      },
    ];

    await addVerticalMarketingPlanTemplateProductRequirementTrigger({
      variables: {
        input: {
          verticalMarketingPlanTemplateProductIds: products.map((product) => product.id),
          title: "Upload Acord 125",
          description: "",
          label: "acord-125",
          groups: JSON.stringify(groups),
        },
      },
    });
  };

  const addAcord126 = async () => {
    const groups = [
      {
        title: "Select GL Coverage/Deductibles",
        description: "",
        elements: [
          {
            element: "select-with-money",
            props: JSON.stringify({
              label: "General Aggregate Limit",
              selectClientDataKey: KnownClientData.CoverageLimits.GeneralAggregateAppliesTo,
              items: [
                { value: "Policy Limit" },
                { value: "Project Limit" },
                { value: "Location Limit" },
                { value: "Other Limit" },
              ],
            }),
            clientDataKey: KnownClientData.CoverageLimits.GeneralAggregateValue,
            array: false,
          },
          {
            element: "input",
            props: JSON.stringify({ label: "Product and Completed Operations Limit" }),
            clientDataKey: KnownClientData.CoverageLimits.ProductsAndCompletedOperationsAggregate,
            array: false,
          },
          {
            element: "input",
            props: JSON.stringify({ label: "Each Occurrence Limit" }),
            clientDataKey: KnownClientData.CoverageLimits.EachOccurrence,
            array: false,
          },
          {
            element: "input",
            props: JSON.stringify({ label: "Personal & Advertising Injury Limit" }),
            clientDataKey: KnownClientData.CoverageLimits.PersonalAndAdvertisingInjury,
            array: false,
          },
          {
            element: "input",
            props: JSON.stringify({ label: "Damage to Rented Premises Limit" }),
            clientDataKey: KnownClientData.CoverageLimits.DamageToRentedPremises,
            array: false,
          },
          {
            element: "input",
            props: JSON.stringify({ label: "Property Damage Deductible" }),
            clientDataKey: KnownClientData.CoverageDeductibles.PropertyDamage,
            array: false,
          },
          {
            element: "input",
            props: JSON.stringify({ label: "Bodily Injury Deductible" }),
            clientDataKey: KnownClientData.CoverageDeductibles.BodilyInjury,
            array: false,
          },
          {
            element: "free-fill-with-money",
            props: JSON.stringify({
              label: "Other Deductible",
              selectClientDataKey: KnownClientData.CoverageDeductibles.OtherKey,
            }),
            clientDataKey: KnownClientData.CoverageDeductibles.OtherValue,
            array: false,
          },
        ],
      },

      {
        title: "Schedule of Hazards",
        description: "",
        elements: [{ element: "schedule-of-hazards", props: "{}", clientDataKey: "schedule-of-hazards", array: false }],
      },
      {
        title: "Additional Information",
        description: "",
        elements: [
          {
            element: "textarea",
            props: JSON.stringify({ label: "" }),
            clientDataKey: KnownClientData.GLAdditionalInformation,
            array: false,
          },
        ],
      },
      {
        title: "",
        description: "",
        elements: [
          {
            element: "checkbox",
            props: JSON.stringify({
              label:
                "I AFFIRM THAT I AM AN AUTHORIZED REPRESENTATIVE OF THE APPLICANT AND REPRESENT THAT REASONABLE INQUIRY HAS BEEN MADE TO OBTAIN THE ANSWERS TO QUESTIONS ON THIS APPLICATION. I REPRESENT THAT THE ANSWERS ARE TRUE, CORRECT AND COMPLETE TO THE BEST OF MY KNOWLEDGE.",
              required: true,
            }),
            clientDataKey: KnownClientData.Acknowledgment.GL,
            array: false,
          },
        ],
      },
    ];

    await addVerticalMarketingPlanTemplateProductRequirementTrigger({
      variables: {
        input: {
          verticalMarketingPlanTemplateProductIds: products.map((product) => product.id),
          title: "Upload Acord 126",
          description: "",
          label: "acord-126",
          groups: JSON.stringify(groups),
        },
      },
    });
  };

  const addAcord140 = async () => {
    const groups = [
      {
        title: "Basic Details",
        description: "",
        elements: [{ element: "property-details", props: "{}", clientDataKey: "", array: false }],
      },
      {
        title: "Subject of Insurance",
        description: "",
        elements: [{ element: "subject-of-insurance", props: "{}", clientDataKey: "", array: true }],
      },
      {
        title: "",
        description: "",
        elements: [
          {
            element: "checkbox",
            props: JSON.stringify({
              label:
                "I AFFIRM THAT I AM AN AUTHORIZED REPRESENTATIVE OF THE APPLICANT AND REPRESENT THAT REASONABLE INQUIRY HAS BEEN MADE TO OBTAIN THE ANSWERS TO QUESTIONS ON THIS APPLICATION. I REPRESENT THAT THE ANSWERS ARE TRUE, CORRECT AND COMPLETE TO THE BEST OF MY KNOWLEDGE.",
              required: true,
            }),
            clientDataKey: KnownClientData.Acknowledgment.Property,
            array: false,
          },
        ],
      },
    ];

    await addVerticalMarketingPlanTemplateProductRequirementTrigger({
      variables: {
        input: {
          verticalMarketingPlanTemplateProductIds: products.map((product) => product.id),
          title: "Upload Acord 140",
          description: "",
          label: "acord-140",
          groups: JSON.stringify(groups),
        },
      },
    });
  };

  const updateRequirement = async (originalTitle: string) => {
    const requirements = products
      .flatMap((product) => product.requirements)
      .filter((requirement) => requirement.title === originalTitle);

    const defaultValues = {
      title: requirements[0].title,
      description: requirements[0].description ?? undefined,
      groups: JSON.parse(requirements[0].groups || `[]`).map((group: any) => ({
        ...group,
        elements: group.elements?.map((element: any) => ({
          ...element,
          props: typeof element.props === "string" ? JSON.parse(element.props) : element.props,
        })),
      })),
    };

    await openForm(<RequirementForm />, {
      title: "Edit Requirement",
      defaultValues,
      validationSchema: RequirementFormSchema,
      onSubmit: async (fd) => {
        const ids = requirements.map((requirement) => requirement.id);

        await updateVerticalMarketingPlanTemplateProductRequirementTrigger({
          variables: {
            input: {
              ids,
              title: fd.title,
              description: fd.description,
              groups: JSON.stringify(fd.groups),
            },
          },
        });
      },
    });
  };

  const deleteRequirement = async (originalTitle: string) => {
    const requirements = products
      .flatMap((product) => product.requirements)
      .filter((requirement) => requirement.title === originalTitle);

    const isConfirmed = await openConfirmation({ title: "Delete Requirement", buttonProps: { theme: "destructive" } });

    if (!isConfirmed) {
      return;
    }

    const ids = requirements.map((requirement) => requirement.id);

    await deleteVerticalMarketingPlanTemplateProductRequirementTrigger({ variables: { input: { ids } } });
  };

  return {
    addAcord125,
    addAcord126,
    addAcord140,
    addRequirement,
    updateRequirement,
    deleteRequirement,
  };
};

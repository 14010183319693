import { FileButton } from "@/components/file-button";
import { HasInternalRole } from "@/components/has-role";
import { NoActions } from "@/components/no-actions";
import { CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { useQuote } from "@/hooks/use-quote";

export const QuoteFiles = () => {
  const {
    quote: { redactedQuote, processedQuote },
  } = useQuote();

  return (
    <>
      <CardHeader>
        <CardTitle>Files</CardTitle>
      </CardHeader>
      <CardContent className="space-y-2">
        <HasInternalRole>{redactedQuote && <FileButton file={redactedQuote} badge="Redacted" />}</HasInternalRole>
        {processedQuote && <FileButton file={processedQuote} badge="Processed" />}
        {!redactedQuote && !processedQuote && <NoActions label="No Files Found" />}
      </CardContent>
    </>
  );
};

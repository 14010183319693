import { FC, PropsWithChildren } from "react";

import {
  ContextMenu,
  ContextMenuContent,
  ContextMenuItem,
  ContextMenuLabel,
  ContextMenuSeparator,
  ContextMenuTrigger,
} from "@/components/ui/context-menu";
import { cn } from "src/utils";

import { useSegment } from "../hooks/use-segment";
import { SegmentMenuItemActiveToggle } from "./menu-items/segment-menu-item-active-toggle";
import { SegmentMenuItemAutoApproveToggle } from "./menu-items/segment-menu-item-auto-approve-toggle";
import { SegmentMenuItemDelete } from "./menu-items/segment-menu-item-delete";
import { SegmentMenuItemEdit } from "./menu-items/segment-menu-item-edit";

export const SegmentContextMenu: FC<PropsWithChildren> = ({ children }) => {
  const { isDeletingSegment } = useSegment();

  return (
    <ContextMenu modal={false}>
      <ContextMenuTrigger
        disabled={isDeletingSegment}
        asChild
        className={cn(isDeletingSegment ? "opacity-50 pointer-events-none cursor-not-allowed" : "")}
      >
        {children}
      </ContextMenuTrigger>

      <ContextMenuContent>
        <ContextMenuLabel asChild>
          <SegmentMenuItemActiveToggle />
        </ContextMenuLabel>

        <ContextMenuSeparator />

        <ContextMenuLabel asChild>
          <SegmentMenuItemAutoApproveToggle />
        </ContextMenuLabel>

        <ContextMenuSeparator />

        <ContextMenuItem asChild>
          <SegmentMenuItemEdit />
        </ContextMenuItem>

        <ContextMenuItem asChild>
          <SegmentMenuItemDelete />
        </ContextMenuItem>
      </ContextMenuContent>
    </ContextMenu>
  );
};

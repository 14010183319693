import { Button } from "@/components/ui/button";
import { useMarketingPlan } from "@/hooks/use-marketing-plan";
import { MarketingPlanAction, useTransitionMarketingPlanMutation } from "src/generated/graphql";

export const InformationReview = () => {
  const { opportunity, refetchQueries } = useMarketingPlan();
  const [transition] = useTransitionMarketingPlanMutation();

  const handleTransition = async (action: MarketingPlanAction) => {
    await transition({
      variables: {
        input: {
          id: opportunity.id,
          expectedState: opportunity.state,
          action,
        },
      },
      refetchQueries,
    });
  };

  return (
    <Button variant="outline" size="xs" onClick={() => handleTransition(MarketingPlanAction.StartMarketing)}>
      Start Marketing
    </Button>
  );
};

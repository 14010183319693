import { gql, useQuery } from "@apollo/client";
import { getIntrospectionQuery } from "graphql";
import { useEffect, useState } from "react";

const INTROSPECTION_QUERY = gql`
  ${getIntrospectionQuery()}
`;

export const useAPIVersion = () => {
  const [version, setVersion] = useState<string | null>(null);
  const { data, loading } = useQuery(INTROSPECTION_QUERY, {
    pollInterval: 5000,
  });

  useEffect(() => {
    if (!data) {
      return;
    }

    const encoder = new TextEncoder();
    (async () => {
      setVersion(
        [...new Uint8Array(await crypto.subtle.digest("SHA-256", encoder.encode(JSON.stringify(data))))]
          .map((char) => char.toString(16))
          .join("")
      );
    })().catch(() => {});
  }, [data]);

  return {
    version,
    loading,
  };
};

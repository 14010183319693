import { FC, useMemo } from "react";
import { z } from "zod";

import { AppetiteProductSelector } from "@/components/appetite-product-selector";
import { useOpenForm } from "@/components/modal-provider";
import { Button, ButtonProps } from "@/components/ui/button";

import { useAddSegmentAppetiteProducts } from "../../hooks/use-add-segment-appetite-products";
import { useSegment } from "../../hooks/use-segment";

export interface AddAppetiteProductsButtonProps extends ButtonProps {}

export const AddAppetiteProductsFormSchema = z.object({
  appetiteProductIds: z.array(z.string()).min(1, { message: "Please select at least one product" }),
});

export const AddAppetiteProductsButton: FC<AddAppetiteProductsButtonProps> = ({ children, ...props }) => {
  const { openForm } = useOpenForm();
  const { segment } = useSegment();
  const { addAppetiteProducts } = useAddSegmentAppetiteProducts();

  const handleSubmit = ({ appetiteProductIds }: z.infer<typeof AddAppetiteProductsFormSchema>) =>
    addAppetiteProducts(appetiteProductIds);

  const defaultValues = { appetiteProductIds: [] };

  const disabledProductIds = useMemo(
    () => segment?.segmentAppetiteProducts.map((p) => p.appetiteProductId) || [],
    [segment?.segmentAppetiteProducts]
  );

  const handleClick = async () =>
    await openForm(
      <AppetiteProductSelector name="appetiteProductIds" disabledProductIds={disabledProductIds} multiple />,
      {
        title: "Select products",
        defaultValues,
        validationSchema: AddAppetiteProductsFormSchema,
        onSubmit: handleSubmit,
      }
    );

  return (
    <Button variant="outline" size="sm" {...props} onClick={handleClick}>
      {children || "Add Products"}
    </Button>
  );
};

import { partition } from "lodash";

import { Loading } from "@/components/ui/loading";
import { useBankan } from "@/hooks/use-bankan";
import { COLUMN_STATE_METADATA } from "@/metadata/bankan-columns";
import { BankanPolicyColumn, useBankanPolicyColumnQuery } from "src/generated/graphql";
import { ExpiringPoliciesCard } from "../cards/expiring-policies-card";
import { PoliciesCard } from "../cards/policies-card";
import { BankanColumn } from "./bankan-column";

export const PolicyColumn = ({ column }: { column: BankanPolicyColumn }) => {
  const { agencyId, brokerId, ownerId, pinnedIds } = useBankan();

  const {
    data: { bankanPolicyColumn: { groupedPolicies } } = { bankanPolicyColumn: { groupedPolicies: [] } },
    loading,
  } = useBankanPolicyColumnQuery({
    variables: {
      input: {
        agencyId,
        brokerId,
        ownerId,
        column,
      },
    },
    fetchPolicy: "cache-and-network",
  });

  const [pinned, unpinned] = partition(groupedPolicies, ({ insured }) => pinnedIds.includes(insured.id));
  const cardClassName = COLUMN_STATE_METADATA[column]["cardClassName"];

  return (
    <BankanColumn id={column} count={groupedPolicies.length} loading={loading}>
      {loading ? (
        <Loading />
      ) : (
        [...pinned, ...unpinned].map((group, key) =>
          column === BankanPolicyColumn.ExpiringSoon ? (
            <ExpiringPoliciesCard key={`${group.insuredId}-${key}`} group={group} />
          ) : (
            <PoliciesCard key={`${group.insuredId}-${key}`} group={group} cardClassName={cardClassName} />
          )
        )
      )}
    </BankanColumn>
  );
};

import { useParams } from "react-router-dom";

import { AgencyAffiliations } from "@/components/agency-affiliations";
import { HasInternalRole } from "@/components/has-role";
import { NavigateAgency } from "@/components/navigate/navigate-agency";
import { Sidebar, SidebarContent, SidebarHeader, SidebarSection } from "@/components/sidebar";
import { useAccount } from "@/hooks/use-account";
import { AgencyRequests } from "./agency-requests";
import { PinnedInsureds } from "./pinned-insureds";

export const AgencySidebar = ({ id }: { id: string }) => {
  const { user } = useAccount();
  const { agencyId } = useParams();

  return (
    <Sidebar>
      <SidebarHeader>
        <HasInternalRole elseShow={<span className="truncate">{user.agency.name}</span>}>
          <NavigateAgency path="/agency" buttonProps={{ variant: "outline", size: "sm" }} />
        </HasInternalRole>
      </SidebarHeader>
      <SidebarContent>
        <AgencyRequests id={id} />
        <PinnedInsureds />
        <HasInternalRole>
          {agencyId && (
            <SidebarSection>
              <AgencyAffiliations id={agencyId} />
            </SidebarSection>
          )}
        </HasInternalRole>
      </SidebarContent>
    </Sidebar>
  );
};

import { useClientDataHandlers } from "@/client-data/use-client-data-handlers";
import { Unstyled } from "@/forms/pdf-fields/unstyled-fields";

interface PdfFieldProps {
  colSpan?: number;
  index?: number;
  label?: string;
  name: string;
  rowSpan?: number;
}

export const PdfInput: React.FC<PdfFieldProps & React.InputHTMLAttributes<HTMLInputElement>> = ({
  colSpan,
  label,
  name,
  rowSpan,
  type = "text",
  index,
  ...props
}) => (
  <PdfLabel colSpan={colSpan} label={label} rowSpan={rowSpan} name={name}>
    <Unstyled.Input type={type} name={name} {...props} {...useClientDataHandlers({ name, index })} />
  </PdfLabel>
);

export const PdfDate: React.FC<PdfFieldProps & React.InputHTMLAttributes<HTMLInputElement>> = ({
  colSpan,
  label,
  name,
  rowSpan,
  type = "text",
  index,
  ...props
}) => (
  <PdfLabel colSpan={colSpan} label={label} rowSpan={rowSpan} name={name}>
    <Unstyled.Date type={type} name={name} {...props} {...useClientDataHandlers({ name, index })} />
  </PdfLabel>
);

export const PdfNumber: React.FC<PdfFieldProps & React.InputHTMLAttributes<HTMLInputElement>> = ({
  label,
  name,
  colSpan,
  rowSpan,
  type = "text",
  index,
  ...props
}) => (
  <PdfLabel colSpan={colSpan} label={label} rowSpan={rowSpan} name={name}>
    <Unstyled.Number type={type} name={name} {...props} {...useClientDataHandlers({ name, index })} />
  </PdfLabel>
);

export const PdfMoney: React.FC<PdfFieldProps & React.InputHTMLAttributes<HTMLInputElement>> = ({
  colSpan,
  label,
  name,
  rowSpan,
  type = "text",
  index,
  ...props
}) => (
  <PdfLabel colSpan={colSpan} label={label} rowSpan={rowSpan} name={name}>
    <Unstyled.Money type={type} name={name} {...props} {...useClientDataHandlers({ name, index })} />
  </PdfLabel>
);

export const PdfTextarea: React.FC<PdfFieldProps & React.TextareaHTMLAttributes<HTMLTextAreaElement>> = ({
  colSpan,
  label,
  name,
  rowSpan,
  index,
  ...props
}) => (
  <PdfLabel colSpan={colSpan} label={label} rowSpan={rowSpan} name={name}>
    <Unstyled.TextArea name={name} rows={3} {...props} {...useClientDataHandlers({ name, index })} />
  </PdfLabel>
);

const PdfLabel = ({
  children,
  colSpan = 1,
  label,
  rowSpan = 1,
  name,
}: {
  children: React.ReactNode;
  colSpan?: number;
  label?: string;
  rowSpan?: number;
  name: string;
  index?: number;
}) => (
  <td colSpan={colSpan} rowSpan={rowSpan} style={{ padding: 0 }}>
    <Unstyled.Label style={{ padding: label ? "5pt 8pt" : "0pt 8pt" }} name={name}>
      {label ?? null}
      {children}
    </Unstyled.Label>
  </td>
);

export const PdfYesNo = ({ colSpan, label, name, rowSpan, index }: PdfFieldProps) => (
  <td colSpan={colSpan} rowSpan={rowSpan}>
    <h4 style={{ fontWeight: 400, marginBottom: "8pt" }}>{label}</h4>
    <Unstyled.RadioBoolean name={name} {...useClientDataHandlers({ name, index })} />
  </td>
);

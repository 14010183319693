import type { ComponentProps, FC } from "react";

import { Textarea } from "@/components/ui/textarea";
import { cn } from "src/utils";

import { useField } from "../hooks/use-field";
import { FieldBase, FieldBaseProps } from "./field-base";
import { FIELD_BORDERLESS_CLASS_NAME } from "./field.constants";

export type FieldTextareaProps = FieldBaseProps & {
  rows?: number;
  placeholder?: string;
  borderless?: boolean;
  defaultValue?: ComponentProps<typeof Textarea>["defaultValue"];
  onChange?: ComponentProps<typeof Textarea>["onChange"];
  onBlur?: ComponentProps<typeof Textarea>["onBlur"];
  inputProps?: ComponentProps<typeof Textarea>;
};

export const FieldTextarea: FC<FieldTextareaProps> = ({
  rows = 4,
  placeholder,
  borderless,
  defaultValue,
  onChange,
  onBlur,
  inputProps,
  readOnly,
  ...props
}) => {
  const { field, hasError } = useField({ name: props.name, disabled: props.disabled, inputProps });

  const handleChange: FieldTextareaProps["onChange"] = (event) => {
    field.onChange(event);
    onChange?.(event);
  };

  const handleBlur: FieldTextareaProps["onBlur"] = (event) => {
    field.onBlur();
    onBlur?.(event);
  };

  return (
    <FieldBase {...props}>
      <Textarea
        readOnly={readOnly}
        rows={rows}
        placeholder={placeholder}
        {...field}
        {...inputProps}
        onChange={handleChange}
        onBlur={handleBlur}
        className={cn(
          "min-h-0",
          {
            "border-destructive": hasError && !field.disabled,
            [FIELD_BORDERLESS_CLASS_NAME]: borderless,
            "resize-none": borderless,
          },
          inputProps?.className
        )}
      />
    </FieldBase>
  );
};

import { FC } from "react";

import { GridRowAction } from "@/components/grid";
import { useOpenForm } from "@/components/modal-provider";
import { ButtonProps } from "@/components/ui/button";
import { Icon } from "@/components/ui/icon";
import { SegmentRequirementFragment } from "src/generated/graphql";

import { useUpdateSegmentRequirement } from "../../hooks/use-update-segment-requirement";
import {
  SegmentRequirementFormFields,
  UpdateSegmentRequirementFormDefaultValues,
  UpdateSegmentRequirementFormSchema,
} from "./segment-requirement-form";

export interface UpdateSegmentRequirementButtonProps extends ButtonProps {
  segmentRequirement: SegmentRequirementFragment;
}

export const UpdateSegmentRequirementButton: FC<UpdateSegmentRequirementButtonProps> = ({
  segmentRequirement,
  children,
  ...props
}) => {
  const { openForm } = useOpenForm();
  const { updateSegmentRequirement } = useUpdateSegmentRequirement();

  const handleClick = async () =>
    await openForm(<SegmentRequirementFormFields />, {
      title: "Update Requirement",
      defaultValues: { ...UpdateSegmentRequirementFormDefaultValues, ...segmentRequirement },
      validationSchema: UpdateSegmentRequirementFormSchema,
      onSubmit: async (values) => await updateSegmentRequirement(values),
    });

  return (
    <GridRowAction label="Edit" {...props} onClick={handleClick}>
      {children || <Icon icon="edit" />}
    </GridRowAction>
  );
};

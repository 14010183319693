import { CSSProperties, forwardRef } from "react";

import { cn } from "src/utils";
import { Textarea, TextareaProps } from "./textarea";

export interface AutoresizingTextareaProps extends TextareaProps {
  children?: React.ReactNode;
}

export const AutoresizingTextarea = forwardRef<HTMLTextAreaElement, AutoresizingTextareaProps>(
  ({ children, className, rows = 1, ...props }, ref) => (
    <div className="grid relative">
      <div className={cn(className, "pointer-events-none invisible whitespace-pre-wrap")} style={style}>
        {props.value}
        <br />
      </div>
      <Textarea ref={ref} rows={rows} className={cn("resize-none", className)} style={style} {...props} />
      {children}
    </div>
  )
);
AutoresizingTextarea.displayName = "AutoresizingTextarea";

const style: CSSProperties = { gridArea: "1 / 1 / 2 / 2", overflowWrap: "anywhere" };

import { format } from "date-fns";
import { useAtom } from "jotai";
import { useState } from "react";
import { useParams } from "react-router";

import { correspondenceAtom, deletedFilesAtom, fileLabelsAtom, selectTagsAtom } from "@/atoms";
import { Grid, GridCell, GridRow, GridRowHeader } from "@/components/grid";
import { HasInternalRole } from "@/components/has-role";
import { Section, SectionHeader, SectionTitle } from "@/components/section";
import { Badge } from "@/components/ui/badge";
import { Spinner } from "@/components/ui/loading";
import { FileUploadFragment, useFilesByLabelQuery } from "src/generated/graphql";
import { cn } from "src/utils";
import { FileMenu } from "./file-menu";
import { FilesOptions } from "./files-options";
import { ViewPdfDialog } from "./view-pdf-dialog";

export const FilesList = () => {
  const { insuredId } = useParams<"insuredId">();
  const { fileId } = useParams<"fileId">(); // allow URL to specify a file to view
  const [selectedTags] = useAtom(selectTagsAtom);
  const [deletedFiles] = useAtom(deletedFilesAtom);
  const [fileLabels] = useAtom(fileLabelsAtom);
  const [correspondence] = useAtom(correspondenceAtom);
  const [selectedFile, setSelectedFile] = useState<FileUploadFragment | undefined>();

  // Load files with direct link to opportunity first, since they're quick, and likely to include most files
  const { data: { filesByLabel: linkedFiles = [] } = {}, loading } = useFilesByLabelQuery({
    variables: {
      input: {
        insuredId: insuredId || "",
        includeGmailAttachments: false,
        labels: selectedTags ? [selectedTags] : [],
        deletedAt: deletedFiles,
      },
    },
    onCompleted: (data) => fileId && setSelectedFile(data.filesByLabel.find((file) => file.id === fileId)),
  });

  // Concurrently load all files (incl. email attachments), because this can take a while
  const { data: { filesByLabel: allFiles = [] } = {} } = useFilesByLabelQuery({
    variables: {
      input: {
        insuredId: insuredId || "",
        includeGmailAttachments: true,
        labels: selectedTags ? [selectedTags] : [],
        deletedAt: deletedFiles,
      },
    },
  });

  let files = allFiles.length > 0 ? allFiles : linkedFiles;
  files = correspondence ? files : files.filter((file) => !file.labels.includes("Correspondence"));

  return (
    <Section className="bg-background col-span-3">
      <SectionHeader className="hidden @3xl:block">
        <SectionTitle>
          <h3 className="mr-auto">{selectedTags || "All Files"}</h3>
          {loading && <Spinner />}
          <FilesOptions />
        </SectionTitle>
      </SectionHeader>
      <Grid className="grid-cols-[4fr_1fr_4rem_1.5rem]">
        <GridRowHeader className="bg-background">
          <GridCell>File Name</GridCell>
          <GridCell>Uploaded By</GridCell>
          <GridCell>Date</GridCell>
          <GridCell />
        </GridRowHeader>
        {files.map((file) => (
          <GridRow key={file?.id} className={cn("py-3", file?.deletedAt && "text-destructive")}>
            <GridCell className="truncate" onClick={() => setSelectedFile(file)}>
              <div className="truncate">{file?.filename}</div>
              {fileLabels && file.labels[0] && (
                <div className="flex flex-wrap gap-2 mt-2 text-3xs">
                  {file.labels.map((label) => (
                    <Badge key={label} variant="secondary" size="3xs">
                      {label}
                    </Badge>
                  ))}
                </div>
              )}
            </GridCell>
            <GridCell>
              {file.uploader?.firstName} {file.uploader?.lastName}
            </GridCell>
            <GridCell>{format(new Date(file.deletedAt ?? file.createdAt), "M/d/yy")}</GridCell>
            <HasInternalRole>
              <FileMenu file={file} />
            </HasInternalRole>
          </GridRow>
        ))}
      </Grid>
      <ViewPdfDialog file={selectedFile} files={files} setSelectedFile={setSelectedFile} />
    </Section>
  );
};

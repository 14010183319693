import { PdfDate, PdfInput, PdfNumber } from "@/forms/pdf-fields/pdf-fields";
import { Schedule } from "./schedule";

interface Props {
  rootPath: string;
  source?: string;
  numYears?: string;
}

export const LossHistory: React.FC<Props> = ({ rootPath, source = "supplemental", numYears: years = "five" }) => (
  <Schedule
    title="Loss History"
    source={source}
    description={<h4>{`Were there any claims, losses, or suits against the applicant in the past ${years} years?`}</h4>}
    header={
      <tr>
        <th style={{ width: "20%" }}>Date</th>
        <th>Description</th>
        <th style={{ width: "13%" }}>Loss</th>
        <th style={{ width: "13%" }}>Amount Paid</th>
      </tr>
    }
  >
    <tr>
      <PdfDate name={`${rootPath}Loss:date`} index={0} />
      <PdfInput name={`${rootPath}Loss:description`} index={0} />
      <PdfNumber name={`${rootPath}Loss:loss`} index={0} />
      <PdfNumber name={`${rootPath}Loss:paid`} index={0} />
    </tr>
  </Schedule>
);

import { useNavigate, useParams } from "react-router";

import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { useUpsertSearchParams } from "@/hooks/use-upsert-search-params";
import { useBrokerAccountsQuery } from "src/generated/graphql";
import { cn } from "src/utils";

export const NavigateBroker = ({
  className,
  path,
  allowAll = true,
}: {
  className?: string;
  path: string;
  allowAll?: boolean;
}) => {
  const navigate = useNavigate();
  const { brokerId } = useParams();
  const [searchParams] = useUpsertSearchParams();
  const { data: { userAccounts } = { userAccounts: [] } } = useBrokerAccountsQuery({ fetchPolicy: "cache-first" });

  const handleChange = (e: string) => {
    const id = e === "all" ? "" : `/${e}`;
    const params = searchParams ? `?${searchParams.toString()}` : null;
    navigate(`${path}${id}${params}`);
  };

  return (
    <Select name="brokerId" value={brokerId ?? "all"} onValueChange={(e) => handleChange(e)}>
      <SelectTrigger className={cn("h-8 text-xs", className)}>
        <SelectValue placeholder="Select Broker" />
      </SelectTrigger>
      <SelectContent>
        {allowAll && <SelectItem value="all">All Brokers</SelectItem>}
        {userAccounts.map((acc) => (
          <SelectItem key={acc.id} value={acc.id}>
            {acc.firstName} {acc.lastName}
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  );
};

import { FetchResult, MutationFunctionOptions } from "@apollo/client";
import { createContext, FC, PropsWithChildren, useCallback, useContext, useMemo } from "react";
import { useNavigate, useParams } from "react-router";

import { useOpenConfirmation } from "@/components/modal-provider";
import { useToast } from "@/hooks/use-toast";
import {
  DeleteSegmentMutation,
  SegmentFragment,
  UpdateSegmentInput,
  UpdateSegmentMutation,
  useDeleteSegmentMutation,
  useSegmentQuery,
  useUpdateSegmentMutation,
} from "src/generated/graphql";

export interface SegmentContextValue {
  segmentId?: string;
  segment?: SegmentFragment;
  toggleSegmentActive: (active: boolean) => Promise<FetchResult<UpdateSegmentMutation>>;
  toggleSegmentSplit: (splitInSegment: boolean) => Promise<FetchResult<UpdateSegmentMutation>>;
  toggleSegmentAutoApprove: (autoApproveAlby: boolean) => Promise<FetchResult<UpdateSegmentMutation>>;
  updateSegment: (
    input: UpdateSegmentInput,
    options?: Omit<MutationFunctionOptions<UpdateSegmentMutation>, "variables">
  ) => Promise<FetchResult<UpdateSegmentMutation>>;
  deleteSegment: () => Promise<FetchResult<DeleteSegmentMutation> | undefined>;
  isLoadingSegment: boolean;
  isUpdatingSegment: boolean;
  isDeletingSegment: boolean;
}

export const SegmentContext = createContext<SegmentContextValue>({} as SegmentContextValue);

export interface SegmentProviderProps {
  segment?: SegmentFragment;
}

export const SegmentProvider: FC<PropsWithChildren<SegmentProviderProps>> = (props) => {
  const { segmentId } = useParams<{ segmentId: string }>();
  const navigate = useNavigate();
  const { openConfirmation } = useOpenConfirmation();
  const { toast } = useToast();

  const { data, loading: isLoadingSegment } = useSegmentQuery({
    variables: { id: segmentId! },
    skip: !segmentId || !!props.segment,
  });

  const segment = props.segment || data?.segment;

  const [updateSegmentMutation, { loading: isUpdatingSegment }] = useUpdateSegmentMutation({
    awaitRefetchQueries: true,
    refetchQueries: ["Segment", "Segments", "PaginatedSegments"],
  });

  const [deleteSegmentMutation, { loading: isDeletingSegment }] = useDeleteSegmentMutation({
    awaitRefetchQueries: true,
    fetchPolicy: "no-cache",
    onCompleted: () => toast({ title: "Segment deleted" }),
    refetchQueries: ["Segments", "PaginatedSegments"],
  });

  const updateSegment = useCallback(
    async (input: UpdateSegmentInput, options?: Omit<MutationFunctionOptions<UpdateSegmentMutation>, "variables">) =>
      await updateSegmentMutation({ variables: { input }, ...options }),
    [segment?.id]
  );

  const toggleSegmentActive = useCallback(
    async (active: boolean) => await updateSegmentMutation({ variables: { input: { id: segment!.id, active } } }),
    [segment?.id]
  );

  const toggleSegmentSplit = useCallback(
    async (split: boolean) => await updateSegmentMutation({ variables: { input: { id: segment!.id, split } } }),
    [segment?.id]
  );

  const toggleSegmentAutoApprove = useCallback(
    async (autoApproveAlby: boolean) =>
      await updateSegmentMutation({ variables: { input: { id: segment!.id, autoApproveAlby } } }),
    [segment?.id]
  );

  const deleteSegment = useCallback(async () => {
    const isConfirmed = await openConfirmation({
      title: "Delete segment",
      description: `Are you sure you want to delete "${segment!.name}" segment?`,
      buttonProps: { theme: "destructive", children: "Delete" },
    });

    if (!isConfirmed) {
      return;
    }

    return await deleteSegmentMutation({
      variables: { id: segment!.id },
      onCompleted: () => navigate("/segments"),
    });
  }, [segment?.id]);

  const value = useMemo(
    () => ({
      segmentId: segment?.id,
      segment,
      toggleSegmentActive,
      toggleSegmentSplit,
      toggleSegmentAutoApprove,
      updateSegment,
      deleteSegment,
      isLoadingSegment,
      isUpdatingSegment,
      isDeletingSegment,
    }),
    [
      segment,
      toggleSegmentActive,
      toggleSegmentSplit,
      toggleSegmentAutoApprove,
      updateSegment,
      deleteSegment,
      isLoadingSegment,
      isUpdatingSegment,
      isDeletingSegment,
    ]
  );

  return <SegmentContext.Provider value={value} {...props} />;
};

export const useSegment = () => {
  const context = useContext(SegmentContext);

  if (context === undefined) {
    throw new Error("useSegment must be used within a SegmentProvider");
  }

  return context;
};

import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { z } from "zod";

import { Group } from "@/components/group";
import { Button } from "@/components/ui/button";
import { Icon } from "@/components/ui/icon";
import { Separator } from "@/components/ui/separator";
import { Sheet, SheetContent, SheetDescription, SheetFooter, SheetHeader, SheetTitle } from "@/components/ui/sheet";
import { FieldInput } from "@/forms/fields/field-input";
import { Form } from "@/forms/form";
import { FormReset } from "@/forms/form-reset";
import { FormSubmit } from "@/forms/form-submit";
import { toast } from "@/hooks/use-toast";
import { CarrierContactType, useUpdateAppetiteProductMutation } from "src/generated/graphql";

import { MarketingEffortSubmission } from "../submission-actions";
import { CarrierContactList } from "./carrier-contact-list";

const AppetiteProductFormSchema = z.object({
  portalLink: z.string().url().optional().or(z.literal("")),
  submissionInstructions: z.string().optional(),
});

export const CarrierContacts: React.FC<{
  submission: MarketingEffortSubmission;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ submission, open, setOpen }) => {
  const [editMode, setEditMode] = useState(false);

  const [updateAppetiteProduct] = useUpdateAppetiteProductMutation({
    onCompleted: () => setEditMode(false),
    onError: () => toast({ title: "Error", description: "We're sorry, an error occurred.", variant: "destructive" }),
  });

  const defaultValues = {
    portalLink: submission.appetiteProduct.portalLink || "",
    submissionInstructions: submission.appetiteProduct.submissionInstructions || "",
  };

  const handleSubmit = async (values: z.infer<typeof AppetiteProductFormSchema>) =>
    await updateAppetiteProduct({
      variables: {
        input: {
          id: submission.appetiteProduct.id,
          portalLink: values.portalLink,
          submissionInstructions: values.submissionInstructions,
        },
      },
    });

  const submissionContacts = submission.appetiteProduct.submissionCarrierContacts || [];
  const referenceContacts = submission.appetiteProduct.referenceCarrierContacts || [];

  return (
    <Sheet open={open} onOpenChange={() => setOpen((open) => !open)}>
      <SheetContent className="overflow-y-auto">
        <Group className="gap-6">
          <SheetHeader className="mb-0">
            <SheetTitle>{submission.appetiteProduct.carrierName}</SheetTitle>
            <SheetDescription>{submission.appetiteProduct.carrierProductName}</SheetDescription>
          </SheetHeader>

          <Separator />

          {!editMode && (
            <Group className="gap-2">
              <div className="flex justify-between items-center">
                <h5>Submission Instructions</h5>
                <Button variant="ghost" size="xs" display="icon" onClick={() => setEditMode(true)}>
                  <Icon icon="edit" />
                </Button>
              </div>

              <div>
                <span className="text-sm text-muted-foreground">
                  {submission.appetiteProduct.submissionInstructions || "No instructions listed."}
                </span>{" "}
                {submission.appetiteProduct.portalLink ? (
                  <p>
                    <Button variant="link" asChild>
                      <a
                        href={submission.appetiteProduct.portalLink}
                        target="_blank"
                        rel="noreferrer"
                        className="underline"
                      >
                        {submission.appetiteProduct.portalLink}
                      </a>
                    </Button>
                  </p>
                ) : (
                  <span className="text-sm text-muted-foreground">No link available. Click pencil to add.</span>
                )}
              </div>
            </Group>
          )}

          {editMode && (
            <Form validationSchema={AppetiteProductFormSchema} onSubmit={handleSubmit} defaultValues={defaultValues}>
              <Group>
                <FieldInput name="portalLink" label="Portal link" />
                <FieldInput name="submissionInstructions" label="Submission instructions" />

                <SheetFooter className="py-0 mb-0">
                  <FormReset onClick={() => setEditMode(false)} />
                  <FormSubmit />
                </SheetFooter>
              </Group>
            </Form>
          )}

          <Separator />

          <CarrierContactList
            type={CarrierContactType.Submission}
            contacts={submissionContacts}
            appetiteProductId={submission.appetiteProduct.id}
          />

          <Separator />

          <CarrierContactList
            type={CarrierContactType.Reference}
            contacts={referenceContacts}
            appetiteProductId={submission.appetiteProduct.id}
          />
        </Group>
      </SheetContent>
    </Sheet>
  );
};

// This component is essentially a wrapper used to watch the effectiveDate field
// When effectiveDate is updated, default the expirationDate to 1 year later
export const FieldWatcher = ({ children }: { children: React.ReactNode }) => {
  const { getValues, setValue, watch } = useFormContext();

  const effectiveDate = watch("effectiveDate");
  const expirationDate = getValues("expirationDate");
  useEffect(() => {
    // Don't overwrite expirationDate if it's already set
    if (effectiveDate && !expirationDate) {
      const oneYearLater = new Date(new Date(effectiveDate).setFullYear(new Date(effectiveDate).getFullYear() + 1));
      setValue("expirationDate", oneYearLater.toISOString());
    }
  }, [effectiveDate, setValue]);

  return <>{children}</>;
};

import { KnownClientData } from "@qw/qw-common";
import { SentryLogger } from "@qw/sentry";
import { isNil } from "lodash";
import { useContext } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { useNavigate } from "react-router";
import { z } from "zod";

import { AlbySubmissionApprovalCard } from "@/alby/approval-forms/alby-submission-approval-card";
import { ClientDataContext } from "@/client-data/client-data-context";
import { useClientDataHandlers } from "@/client-data/use-client-data-handlers";
import { Grid, GridCell, GridRow, GridRowHeader } from "@/components/grid";
import { Group } from "@/components/group";
import { Alert, AlertDescription } from "@/components/ui/alert";
import { Button } from "@/components/ui/button";
import { Card, CardContent } from "@/components/ui/card";
import { Icon } from "@/components/ui/icon";
import { Spinner } from "@/components/ui/loading";
import { Separator } from "@/components/ui/separator";
import { FieldInput } from "@/forms/fields/field-input";
import { FieldTextarea } from "@/forms/fields/field-textarea";
import { Form } from "@/forms/form";
import { useAlbyApproval } from "@/hooks/use-alby-approval";
import { useInsured } from "@/hooks/use-insured";
import { useToast } from "@/hooks/use-toast";
import { SubmissionDetailsQuery, useClientDataLazyQuery, useDeleteClientDataRowMutation } from "src/generated/graphql";
import { formatDate } from "src/utils/date";

import { formatMoney } from "@cp/toolkit";
import { useSubmissionTemplate } from "./use-submission-template";

export const SubmissionTemplateFormSchema = z.object({
  [KnownClientData.MailingAddress.AddressLine1]: z.string().optional(),
  [KnownClientData.MailingAddress.City]: z.string().optional(),
  [KnownClientData.MailingAddress.State]: z
    .string()
    .regex(new RegExp(/^([A-Z]{2})?$/), "")
    .optional(),
  [KnownClientData.MailingAddress.Zip]: z
    .string()
    .regex(new RegExp(/^(\d{5})?$/), "")
    .optional(),
  [KnownClientData.DescriptionOfOperations]: z.string().optional(),
  [KnownClientData.Exposures]: z.string().optional(),
  [KnownClientData.RequestedLimits]: z.string().optional(),
  customMessage: z.string().optional(),
  glLoss: z
    .array(
      z.object({
        date: z
          .string()
          .regex(new RegExp(/^([01]\d\/[0-3]\d\/\d{4})?$/), "")
          .optional(),
        description: z.string().optional(),
        loss: z
          .string()
          .regex(new RegExp(/^(\d+)?$/), "")
          .optional(),
        paid: z
          .string()
          .regex(new RegExp(/^(\d+)?$/), "")
          .optional(),
      })
    )
    .optional(),
});

export const SubmissionTemplateForm = ({ submission }: { submission: SubmissionDetailsQuery["submission"] }) => {
  const navigate = useNavigate();
  const { toast } = useToast();
  const { threadId, approve, decline, disabled } = useAlbyApproval();
  const { insuredId, insured } = useInsured();
  const { fileIds, createEmailDraft, createEmailDraftLoading } = useSubmissionTemplate();

  const [clientData] = useClientDataLazyQuery();

  const marketingPlanUrl = `/insured/${insured.id}/plans/${submission.opportunityId}`;

  const defaultValues = async () => {
    if (!insuredId) {
      console.warn("No insuredId present. Data won't be saved.");
      return {};
    }

    const { data: supplementalResult, error } = await clientData({
      variables: { input: { insuredId } },
    });

    if (!supplementalResult) {
      SentryLogger.warn(`Supplemental result was undefined for Insured (${insuredId})`);
      return {};
    }

    if (error) {
      SentryLogger.exception(error);
      toast({
        title: "Error",
        description: "There was an error loading your supplemental data.",
        variant: "destructive",
      });
      return {};
    }

    const isPropertyOnly =
      submission.opportunity.selectedLinesOfBusiness.length === 1 &&
      submission.opportunity.selectedLinesOfBusiness[0] === "Property";

    const supplementalValues = supplementalResult.clientData.reduce((acc, curr) => {
      if (isNil(curr.index)) {
        if (isPropertyOnly) {
          if (curr.key === KnownClientData.RequestedLimits) {
            return acc;
          }
          if (curr.key === KnownClientData.Exposures && submission.opportunity.insured.tiv) {
            return {
              ...acc,
              [curr.key]: `TIV: ${formatMoney(submission.opportunity.insured.tiv)}`,
            };
          }
        }
        return { ...acc, [curr.key]: curr.value };
      }

      const [parentKey, childKey] = curr.key.split(":");
      acc[parentKey] = acc[parentKey] || [];
      acc[parentKey][curr.index] = acc[parentKey][curr.index] || {};
      acc[parentKey][curr.index][childKey] = curr.value || "";

      return acc;
    }, {} as Record<string, any>);

    return supplementalValues;
  };

  const handleCreateDraft = async () => {
    threadId && (await decline());
    await createEmailDraft(fileIds);
    navigate(marketingPlanUrl);
  };

  const handleSubmit = async (values: z.infer<typeof SubmissionTemplateFormSchema>) => {
    if (!threadId) {
      await handleCreateDraft();
      return;
    }

    await approve(
      {
        attachments: fileIds,
        customMessage: values.customMessage,
        descriptionOfOperations: values[KnownClientData.DescriptionOfOperations],
        desiredEffectiveDate: formatDate(new Date(submission.opportunity.desiredEffectiveDate)),
        exposures: values[KnownClientData.Exposures],
        mailingAddressLine1: values[KnownClientData.MailingAddress.AddressLine1],
        mailingAddressCity: values[KnownClientData.MailingAddress.City],
        mailingAddressState: values[KnownClientData.MailingAddress.State],
        mailingAddressZip: values[KnownClientData.MailingAddress.Zip],
        primaryState: submission.opportunity.insured.primaryState,
        requestLimits: values[KnownClientData.RequestedLimits],
      },
      {
        onCompleted(data) {
          if (data.resumeGraph.approvedBy) {
            toast({ title: "Alby action approved" });
          }
          navigate(marketingPlanUrl);
        },
        refetchQueries: ["ResumableGraph"],
      }
    );
  };

  return (
    <Form
      mode="onChange"
      validationSchema={SubmissionTemplateFormSchema}
      defaultValues={defaultValues}
      onSubmit={handleSubmit}
      className="space-y-6"
    >
      {threadId && <AlbySubmissionApprovalCard submission={submission} />}
      <Card>
        <CardContent className="pt-5">
          <ClientDataContext.Provider value={{ insuredId }}>
            <Group className="gap-6">
              <SubmissionTemplateFormContent />
              <div className="flex justify-end gap-2">
                <Button
                  disabled={disabled || createEmailDraftLoading}
                  onClick={handleCreateDraft}
                  {...(threadId ? { type: "button", variant: "outline" } : { type: "submit", theme: "primary" })}
                >
                  Create Draft
                  {disabled || (createEmailDraftLoading && <Spinner />)}
                </Button>
              </div>
            </Group>
          </ClientDataContext.Provider>
        </CardContent>
      </Card>
    </Form>
  );
};

const SubmissionTemplateFormContent = () => (
  <>
    <Group>
      <h3>Mailing Address</h3>
      <FieldInput
        label="Street Address Line 1"
        name={KnownClientData.MailingAddress.AddressLine1}
        {...useClientDataHandlers({ name: KnownClientData.MailingAddress.AddressLine1 })}
      />
      <FieldInput
        label="Street Address Line 2"
        name={KnownClientData.MailingAddress.AddressLine2}
        {...useClientDataHandlers({ name: KnownClientData.MailingAddress.AddressLine2 })}
      />

      <Group className="grid-cols-[2fr_1fr_1fr]">
        <FieldInput
          label="City"
          name={KnownClientData.MailingAddress.City}
          {...useClientDataHandlers({ name: KnownClientData.MailingAddress.City })}
        />
        <FieldInput
          label="State"
          placeholder="TX"
          name={KnownClientData.MailingAddress.State}
          {...useClientDataHandlers({ name: KnownClientData.MailingAddress.State })}
        />
        <FieldInput
          label="Zip Code"
          placeholder="78701"
          name={KnownClientData.MailingAddress.Zip}
          {...useClientDataHandlers({ name: KnownClientData.MailingAddress.Zip })}
        />
      </Group>
    </Group>

    <Separator />

    <Group>
      <FieldTextarea
        label="Description Of Operations"
        rows={6}
        name={KnownClientData.DescriptionOfOperations}
        {...useClientDataHandlers({ name: KnownClientData.DescriptionOfOperations })}
      />

      <Group className="grid-cols-2">
        <FieldInput
          label="Exposures"
          name={KnownClientData.Exposures}
          {...useClientDataHandlers({ name: KnownClientData.Exposures })}
        />
        <FieldInput
          label="Attached Information"
          name="attachedInformation"
          {...useClientDataHandlers({ name: "attachedInformation" })}
        />
      </Group>

      <Group className="grid-cols-2">
        <FieldInput
          label="Limits Requested"
          name={KnownClientData.RequestedLimits}
          {...useClientDataHandlers({ name: KnownClientData.RequestedLimits })}
        />
        <FieldInput
          label="Pending Information"
          name="pendingInformation"
          {...useClientDataHandlers({ name: "pendingInformation" })}
        />
      </Group>
    </Group>

    <Separator />

    <LossHistory />
  </>
);

const LossHistory = () => {
  const { insuredId } = useContext(ClientDataContext);
  const { control } = useFormContext();
  const { fields, append, remove } = useFieldArray({ control, name: "glLoss" });
  const [deleteClientData] = useDeleteClientDataRowMutation();

  const deleteFormValue = (key: string, index: number) => {
    if (!insuredId) {
      console.warn("No insuredId present. Data won't be saved.");
      return;
    }

    deleteClientData({ variables: { input: { insuredId, key, index, source: "supplemental" } } });
  };

  const onAddRow = () => append({ date: "", description: "", loss: "", paid: "" });

  const onDeleteRow = () => {
    const index = fields.length - 1;

    remove(index);

    ["date", "description", "loss", "paid"].forEach((field) => {
      deleteFormValue(`glLoss:${field}`, index);
    });
  };

  return (
    <Group>
      <Group className="gap-1">
        <Group direction="row" className="items-center justify-between">
          <h3>Loss History</h3>
          <Group direction="row" className="gap-2">
            <Button type="button" variant="outline" display="icon" size="xs" onClick={onDeleteRow}>
              <Icon icon="remove" />
            </Button>
            <Button type="button" variant="outline" display="icon" size="xs" onClick={onAddRow}>
              <Icon icon="add" />
            </Button>
          </Group>
        </Group>
        <p className="text-sm text-muted-foreground">
          Were there any claims, losses, or suits against the applicant in the past five years?
        </p>
      </Group>

      <Grid className="grid-cols-[2fr_3fr_1fr_1fr] p-0 divide-y-0">
        <GridRowHeader>
          <div>Date</div>
          <div>Description</div>
          <div>Loss</div>
          <div>Amount Paid</div>
        </GridRowHeader>

        {!fields?.length && (
          <GridRow>
            <GridCell className="col-span-full">
              <Alert>
                <AlertDescription className="text-muted-foreground">No items added yet.</AlertDescription>
              </Alert>
            </GridCell>
          </GridRow>
        )}

        {fields.map((field, index) => (
          <LossHistoryRow key={field.id} index={index} />
        ))}
      </Grid>
    </Group>
  );
};

const LossHistoryRow = ({ index }: { index: number }) => (
  <GridRow>
    <FieldInput
      placeholder="MM/DD/YYYY"
      name={`glLoss.${index}.date`}
      {...useClientDataHandlers({ name: `glLoss.${index}.date`, dataKey: `glLoss:date`, index })}
    />
    <FieldInput
      name={`glLoss.${index}.description`}
      {...useClientDataHandlers({ name: `glLoss.${index}.description`, dataKey: `glLoss:description`, index })}
    />
    <FieldInput
      name={`glLoss.${index}.loss`}
      {...useClientDataHandlers({ name: `glLoss.${index}.loss`, dataKey: `glLoss:loss`, index })}
    />
    <FieldInput
      name={`glLoss.${index}.paid`}
      {...useClientDataHandlers({ name: `glLoss.${index}.paid`, dataKey: `glLoss:paid`, index })}
    />
  </GridRow>
);

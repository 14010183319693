import { ForwardedRef, MutableRefObject } from "react";

export function setRefs<T>(forwardedRef: ForwardedRef<T | null>, internalRef: MutableRefObject<T | null>) {
  return (node: T | null) => {
    internalRef.current = node;

    if (typeof forwardedRef === "function") {
      forwardedRef(node);
    } else if (forwardedRef) {
      forwardedRef.current = node;
    }
  };
}

import { useNavigate, useParams } from "react-router";
import { useSearchParams } from "react-router-dom";

import { Button } from "@/components/ui/button";
import { useToast } from "@/hooks/use-toast";
import { useCreateClientDataPdfMutation } from "src/generated/graphql";
import { generatePdfInput } from "./generate-anvil-button";

export const SubmitSupplementalButton = ({ filename }: { filename: string }) => {
  const { toast } = useToast();
  const navigate = useNavigate();
  const { clientId } = useParams();
  const [searchparams] = useSearchParams();
  const [submitPDF, { loading }] = useCreateClientDataPdfMutation();
  const opportunityId = searchparams.get("opportunityId");

  return (
    <Button
      variant="secondary"
      size="sm"
      onClick={async () => {
        const body = await generatePdfInput();

        await submitPDF({
          variables: {
            input: {
              opportunityId: opportunityId!,
              filename: filename,
              ...body,
            },
          },
          onCompleted: () => {
            toast({ title: "Supplemental has been Submitted" });
            navigate(`/insured/${clientId}/plans/${opportunityId}`);
          },
        });
      }}
      disabled={loading}
    >
      Submit Complete Supplemental
    </Button>
  );
};

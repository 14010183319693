import { Link, Outlet, useParams } from "react-router-dom";

import { ScrollPane } from "@/components/scroll-pane";
import { SetMetadata } from "@/hooks/use-metadata";
import { AlbySidebar } from "./alby-sidebar";

export const Alby = () => {
  const { insuredId } = useParams();
  return (
    <>
      <SetMetadata title="Alby" />
      <AlbySidebar />
      <ScrollPane sidebarPosition={insuredId ? "left" : null}>
        <Outlet />
      </ScrollPane>
    </>
  );
};

export const AlbyMenu = () => {
  const { insuredId, graphName, selectedTool } = useParams();

  const queryString = window.location.search;
  const params = new URLSearchParams(queryString);
  const paramsString = params.toString();

  return (
    <Link to={`/insured/${insuredId}/alby/${graphName}/tool/${selectedTool}?${paramsString}`} target="_blank">
      View Insured
    </Link>
  );
};

import { FC } from "react";

import { GridRowAction } from "@/components/grid";
import { ButtonProps } from "@/components/ui/button";
import { Icon } from "@/components/ui/icon";
import { SegmentRequirementFragment } from "src/generated/graphql";

import { useDeleteSegmentRequirement } from "../../hooks/use-delete-segment-requirement";

export interface DeleteSegmentRequirementButtonProps extends ButtonProps {
  segmentRequirement: SegmentRequirementFragment;
}

export const DeleteSegmentRequirementButton: FC<DeleteSegmentRequirementButtonProps> = ({
  segmentRequirement,
  children,
  ...props
}) => {
  const { deleteSegmentRequirement } = useDeleteSegmentRequirement();

  return (
    <GridRowAction label="Delete" {...props} onClick={() => deleteSegmentRequirement(segmentRequirement.id)}>
      {children || <Icon icon="delete" />}
    </GridRowAction>
  );
};

import { useMatch, useNavigate } from "react-router";

import { ToolbarMenuItem } from "@/components/ui/toolbar";
import { useKeyboardShortcut } from "@/hooks/use-keyboard-shortcut";

export const Issues = () => {
  const navigate = useNavigate();

  const issuesUrl = "/file-processing-pipeline/issues";

  const match = useMatch(issuesUrl);

  const handleIssuesClick = () => navigate(match ? "/file-processing-pipeline" : issuesUrl);

  useKeyboardShortcut(["command+i", "ctrl+i"], handleIssuesClick);

  return <ToolbarMenuItem label="Issues" icon="adjust" shortcut="⌃I" onClick={handleIssuesClick} active={!!match} />;
};

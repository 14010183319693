import { ApolloError } from "@apollo/client";
import { createContext, useContext } from "react";
import { useParams } from "react-router";

import { PageNotFound } from "@/components/errors/page-not-found";
import { QuoteQuery, useQuoteQuery } from "src/generated/graphql";
import { useUpsertSearchParams } from "./use-upsert-search-params";

interface QuoteContextValue {
  error?: ApolloError;
  quote: NonNullable<QuoteQuery["quote"]>;
  quoteId: string;
  loading: boolean;
  refetch: () => void;
  refetchQueries: string[];
}

const QuoteContext = createContext({} as QuoteContextValue);

export const QuoteProvider = ({ children }: { children: React.ReactNode }) => {
  const { quoteId } = useParams<"quoteId">();
  const [searchParams] = useUpsertSearchParams();
  const quoteIdParam = searchParams.get("quoteId");

  const id = quoteIdParam ?? quoteId ?? "";

  const {
    data: { quote } = {},
    loading,
    error,
    refetch,
  } = useQuoteQuery({
    variables: { id },
    skip: !id,
    fetchPolicy: "cache-and-network",
  });

  if (!id || (!loading && !quote)) {
    return <PageNotFound />;
  }

  if (!quote) {
    return null;
  }

  const value = {
    error,
    loading,
    quote,
    quoteId: id,
    refetch,
    refetchQueries: ["Quote"],
  };

  return <QuoteContext.Provider value={value}>{children}</QuoteContext.Provider>;
};

export const useQuote = () => {
  const context = useContext(QuoteContext);

  if (!context) {
    throw new Error("useQuote must be used within a QuoteProvider");
  }

  return context;
};

import { MutationFunctionOptions } from "@apollo/client";
import { useCallback } from "react";

import { useToast } from "@/hooks/use-toast";
import {
  UpdateSegmentRequirementInput,
  UpdateSegmentRequirementMutation,
  useUpdateSegmentRequirementMutation,
} from "src/generated/graphql";

import { useSegment } from "./use-segment";

export const useUpdateSegmentRequirement = () => {
  const { segment } = useSegment();
  const { toast } = useToast();

  const [updateSegmentRequirementMutation, { loading: isUpdatingSegmentRequirement }] =
    useUpdateSegmentRequirementMutation({
      awaitRefetchQueries: true,
      onCompleted: () => toast({ title: "Segment requirement updated" }),
      refetchQueries: ["Segment"],
    });

  const updateSegmentRequirement = useCallback(
    async (
      input: Omit<UpdateSegmentRequirementInput, "segmentId">,
      options?: Omit<MutationFunctionOptions<UpdateSegmentRequirementMutation>, "variables">
    ) =>
      await updateSegmentRequirementMutation({
        variables: { input: { ...input, segmentId: segment!.id } },
        ...options,
      }),
    [segment?.id]
  );

  return {
    updateSegmentRequirement,
    isUpdatingSegmentRequirement,
  };
};

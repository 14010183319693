import { MaterialSymbol } from "material-symbols";
import React from "react";
import ReactMarkdown from "react-markdown";
import remarkBreaks from "remark-breaks";
import remarkGfm from "remark-gfm";

import { Icon } from "@/components/ui/icon";
import { RelativeDate } from "@/components/ui/relative-date";
import { OpportunityEventFragment } from "src/generated/graphql";
import { cn } from "src/utils";

export const OpportunityEvent: React.FC<Omit<OpportunityEventFragment, "__typename"> & { icon: MaterialSymbol }> = ({
  createdAt,
  icon,
  text,
}) => {
  return (
    <div className="bg-accent flex gap-4 items-start p-4 text-sm w-full">
      <Icon icon={icon} className="bg-accent -my-1 size-6 text-muted-foreground" />
      <div className="flex-auto [&>p]:inline text-xs">
        <ReactMarkdown
          remarkPlugins={[remarkBreaks, remarkGfm]}
          components={{
            ul: (props) => {
              const { className, children } = props;

              return <ul className={cn("list-disc list-inside", className)}>{children}</ul>;
            },
          }}
        >
          {text}
        </ReactMarkdown>
        <RelativeDate date={createdAt} className="ml-1 text-muted-foreground" />
      </div>
    </div>
  );
};

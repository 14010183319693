import { ComponentProps, FC, useMemo } from "react";

import { Checkbox } from "@/components/ui/checkbox";
import { Label } from "@/components/ui/label";
import { cn } from "src/utils";

import { useField } from "../hooks/use-field";
import { FieldBase, FieldBaseProps } from "./field-base";

export type FieldCheckboxProps = FieldBaseProps & {
  value?: string;
  multiple?: boolean;
  onChange?: ComponentProps<typeof Checkbox>["onCheckedChange"];
  onBlur?: ComponentProps<typeof Checkbox>["onBlur"];
  inputProps?: ComponentProps<typeof Checkbox>;
};

export const FieldCheckbox: FC<FieldCheckboxProps> = ({
  label,
  value,
  multiple,
  onChange,
  onBlur,
  inputProps,
  ...props
}) => {
  const { field, hasError } = useField({ name: props.name, disabled: props.disabled, inputProps });

  const isChecked = useMemo(() => {
    if (inputProps?.checked !== undefined) {
      return inputProps.checked;
    }

    return multiple ? field.value?.includes(value) : !!field.value;
  }, [inputProps?.checked, field.value, value, multiple]);

  const handleChange: FieldCheckboxProps["onChange"] = (checked) => {
    if (multiple) {
      const values = field.value?.includes(value)
        ? field.value?.filter((v: string) => v !== value)
        : [...(field.value || []), value];

      field.onChange(values);
      onChange?.(values);
      return;
    }

    field.onChange(checked);
    onChange?.(checked);
  };

  const handleBlur: FieldCheckboxProps["onBlur"] = (event) => {
    field.onBlur();
    onBlur?.(event);
  };

  return (
    <FieldBase labelOrientation="horizontal" labelPosition="end" {...props}>
      <Label className="flex flex-auto items-center gap-1.5 text-sm font-normal group-data-[disabled]/form-field:cursor-not-allowed group-data-[disabled]/form-field:pointer-events-none group-data-[disabled]/form-field:opacity-70">
        <Checkbox
          {...field}
          {...inputProps}
          value={value}
          checked={isChecked}
          onCheckedChange={handleChange}
          onBlur={handleBlur}
          className={cn({ "border border-destructive": hasError && !field.disabled }, inputProps?.className)}
        />
        {label}
      </Label>
    </FieldBase>
  );
};

import { z } from "zod";

import { ButtonGroup } from "@/components/button-group";
import { Group } from "@/components/group";
import { FieldInput } from "@/forms/fields/field-input";
import { Form } from "@/forms/form";
import { FormReset } from "@/forms/form-reset";
import { FormSubmit } from "@/forms/form-submit";
import { useMarketingPlan } from "@/hooks/use-marketing-plan";
import { useToast } from "@/hooks/use-toast";
import {
  CarrierContactFragment,
  CarrierContactType,
  useCreateCarrierContactMutation,
  useUpdateCarrierContactMutation,
} from "src/generated/graphql";

const FormSchema = z.object({
  firstName: z.string().min(1, "First name is required"),
  lastName: z.string().min(1, "Last name is required"),
  email: z.string().email("Please enter a valid email address"),
  phone: z.string().optional(),
  title: z.string().optional(),
});

interface Props {
  carrierContact?: CarrierContactFragment;
  type: CarrierContactType;
  appetiteProductId: string;
  onCompleted: () => void;
}

export const CarrierContactForm: React.FC<Props> = ({ carrierContact, type, appetiteProductId, onCompleted }) => {
  const { toast } = useToast();
  const { refetchQueries } = useMarketingPlan();

  const [createCarrierContact] = useCreateCarrierContactMutation();
  const [updateCarrierContact] = useUpdateCarrierContactMutation();

  const defaultValues = {
    firstName: carrierContact?.firstName || "",
    lastName: carrierContact?.lastName || "",
    email: carrierContact?.email || "",
    phone: carrierContact?.phone ?? "",
    title: carrierContact?.title ?? "",
  };

  const handleSubmit = async (values: z.infer<typeof FormSchema>) => {
    if (carrierContact) {
      return await updateCarrierContact({
        variables: { input: { id: carrierContact.id, ...values } },
        onCompleted: () => {
          toast({ title: "Contact updated" });
          onCompleted();
        },
        refetchQueries,
      });
    }

    return await createCarrierContact({
      variables: { input: { appetiteProductId, type, ...values } },
      onCompleted: () => {
        toast({ title: "Contact created" });
        onCompleted();
      },
      refetchQueries,
    });
  };

  return (
    <Form validationSchema={FormSchema} defaultValues={defaultValues} onSubmit={handleSubmit}>
      <Group>
        <FieldInput name="firstName" label="First name" />
        <FieldInput name="lastName" label="Last name" />
        <FieldInput name="email" label="Email" type="email" />
        <FieldInput name="phone" label="Phone" />
        <FieldInput name="title" label="Title" />

        <ButtonGroup>
          <FormReset onClick={onCompleted} />
          <FormSubmit />
        </ButtonGroup>
      </Group>
    </Form>
  );
};

import { useForgotPassword } from "@cp/auth";
import { Link } from "react-router-dom";
import { z } from "zod";

import { Group } from "@/components/group";
import { Button } from "@/components/ui/button";
import { CardContent, CardFooter } from "@/components/ui/card";
import { FieldInput } from "@/forms/fields/field-input";
import { Form } from "@/forms/form";
import { FormSubmit } from "@/forms/form-submit";
import { SetMetadata } from "@/hooks/use-metadata";
import { AuthCard } from "./auth-card";

const validationSchema = z.object({
  email: z.string().email({ message: "Please enter a valid email address" }),
});

const defaultValues = {
  email: "",
};

export function ForgotPassword() {
  const { mutateAsync, isSuccess, error } = useForgotPassword();

  const handleSubmit = async (formData: z.infer<typeof validationSchema>) => await mutateAsync(formData);

  if (isSuccess) {
    return (
      <AuthCard header="Success!" subHeader="Please check your inbox.">
        <CardContent>
          <Button variant="link" asChild>
            <Link to="..">&larr; Go back to login</Link>
          </Button>
        </CardContent>
      </AuthCard>
    );
  }

  return (
    <>
      <SetMetadata title="Forgot Password" />
      <Form validationSchema={validationSchema} defaultValues={defaultValues} onSubmit={handleSubmit}>
        <AuthCard
          header="Forgot password"
          subHeader="Hey, it happens to the best of us&mdash;input your email and we'll send a password reset email."
          error={error}
        >
          <CardContent>
            <FieldInput type="email" name="email" placeholder="Email" />
          </CardContent>
          <CardFooter>
            <Group className="flex-1">
              <FormSubmit disabled={false}>Send me the Email</FormSubmit>
              <Button variant="link" asChild>
                <Link to="..">&larr; Go back to login</Link>
              </Button>
            </Group>
          </CardFooter>
        </AuthCard>
      </Form>
    </>
  );
}

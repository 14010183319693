import { Link } from "react-router-dom";

import { GridCell, GridRow, GridRowHeader } from "@/components/grid";
import { InsuredPin } from "@/components/insured-pin";
import { StateIndicator } from "@/components/state";
import { BankanMarketingPlanColumnQuery } from "src/generated/graphql";
import { formatDateString } from "src/utils/date";

type Group = BankanMarketingPlanColumnQuery["bankanMarketingPlanColumn"]["groupedMarketingPlans"][number];

export const InsuredsRow = ({ group }: { group: Group }) => {
  const { insuredId, insuredName, marketingPlans } = group;

  return (
    <GridRow key={insuredId} className="items-start py-4">
      <GridCell>
        <InsuredPin id={insuredId} className="h-4" />
      </GridCell>
      <GridCell className="font-semibold">
        <Link to={`/insured/${insuredId}`} key={insuredId}>
          {insuredName}
        </Link>
      </GridCell>
      <div className="col-span-4 space-y-4">
        {marketingPlans[0] ? (
          marketingPlans.map((opp) => (
            <Link to={`/insured/${insuredId}/plans/${opp.id}`} key={opp.id} className="gap-4 grid grid-cols-4">
              <GridCell className="space-y-1">
                {opp.selectedLinesOfBusiness[0] ? (
                  opp.selectedLinesOfBusiness.map((lob) => <div key={lob}>{lob}</div>)
                ) : (
                  <div>No lines of business</div>
                )}
              </GridCell>
              <GridCell>
                <StateIndicator state={opp.state} />
              </GridCell>
              <GridCell>{formatDateString(opp.desiredEffectiveDate, "MMM d, YYY")}</GridCell>
              <GridCell>{opp.broker ? `${opp.broker?.firstName} ${opp.broker?.lastName}` : "Unassigned"}</GridCell>
            </Link>
          ))
        ) : (
          <div className="font-semibold">No Marketing Plans</div>
        )}
      </div>
    </GridRow>
  );
};

export const InsuredsHeader = () => (
  <GridRowHeader>
    <GridCell />
    <GridCell>Insured</GridCell>
    <GridCell>Lines</GridCell>
    <GridCell>Status</GridCell>
    <GridCell>Eff. Date</GridCell>
    <GridCell>Broker</GridCell>
  </GridRowHeader>
);

import { Avatar } from "@/components/ui/avatar";
import { Button } from "@/components/ui/button";
import { Card } from "@/components/ui/card";
import { Icon } from "@/components/ui/icon";
import { UserAccountFragment } from "src/generated/graphql";
import { useToast } from "../hooks/use-toast";

interface Props {
  agent?: UserAccountFragment;
  broker?: UserAccountFragment;
}

export const Team = ({ agent, broker }: Props) => (
  <Card className="p-6 space-y-4">
    {broker && <UserInfo user={broker} title="QuoteWell" />}
    {agent && <UserInfo user={agent} title={agent.agency.name} showEmail={true} />}
  </Card>
);

const UserInfo = ({ user, title, showEmail }: { user: UserAccountFragment; title: string; showEmail?: boolean }) => {
  const toast = useToast();
  return (
    <div className="flex gap-4 items-center">
      <Avatar user={user} className="text-sm" />
      <div className="flex-auto space-y-0.5">
        <div className="font-semibold text-xs truncate">{`${user?.firstName} ${user?.lastName}`}</div>
        <div className="italic text-muted-foreground text-2xs truncate">{title}</div>
        {showEmail && (
          <div
            className="italic text-muted-foreground text-2xs truncate cursor-pointer"
            onClick={() => {
              void navigator.clipboard.writeText(user.email);
              toast.toast({ title: "Copied email to clipboard" });
            }}
          >
            {user.email}
          </div>
        )}{" "}
      </div>
      <Button variant="ghost" size="xs" display="icon" className="rounded-full" asChild>
        <a
          href={`https://mail.google.com/mail/u/0/?fs=1&to=${encodeURIComponent(user.email)}&tf=cm`}
          target="_blank"
          rel="noreferrer"
        >
          <Icon icon="mail" />
        </a>
      </Button>
    </div>
  );
};

import { ViewPdfIframe } from "@/files/view-pdf-iframe";
import { ViewPdfNav } from "@/files/view-pdf-nav";
import { useSubmissionTemplate } from "./use-submission-template";

export const SubmissionTemplatePdf = () => {
  const { files, selectedFile, setSelectedFile } = useSubmissionTemplate();

  if (!selectedFile) {
    return null;
  }

  return (
    <div className="h-[calc(100dvh_-_6rem_-_1px)] sticky top-0">
      <ViewPdfIframe file={selectedFile} style={{ height: "100%", marginTop: 0, width: "100%", zIndex: 0 }} />
      <ViewPdfNav files={files} selectedFile={selectedFile} setSelectedFile={setSelectedFile} />
    </div>
  );
};

import { UUID } from "@cp/toolkit";
import { MaterialSymbol } from "material-symbols";
import { useState } from "react";
import { useParams } from "react-router";

import { EmptyState } from "@/components/empty-state";
import { FilterButton } from "@/components/filter-button";
import { Grid, GridCell, GridRow, GridRowHeader } from "@/components/grid";
import { NavigateBroker } from "@/components/navigate/navigate-broker";
import { ScrollPane } from "@/components/scroll-pane";
import { SectionHeader, SectionTitle } from "@/components/section";
import { Collapsible, CollapsibleContent, CollapsibleTrigger } from "@/components/ui/collapsible";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuRadioGroup,
  DropdownMenuRadioItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { Icon } from "@/components/ui/icon";
import { Spinner } from "@/components/ui/loading";
import { Separator } from "@/components/ui/separator";
import { useAccount } from "@/hooks/use-account";
import { SetMetadata } from "@/hooks/use-metadata";
import { useFilesByUserQuery } from "src/generated/graphql";
import { cn } from "src/utils";
import { formatTimezoneDateString } from "src/utils/date";

const now = Date.now();

interface Metadata {
  icon: MaterialSymbol;
  className: string;
}

const jobsMetadata: Record<string, Metadata> = {
  scheduled: { icon: "today", className: "text-muted" },
  running: { icon: "hourglass_top", className: "text-primary" },
  completed: { icon: "check_circle", className: "text-success" },
  error: { icon: "warning", className: "text-destructive" },
};

export const Jobs = () => {
  const { user } = useAccount();
  const { brokerId } = useParams();

  const [filesInput, setFilesInput] = useState<{
    createdAtHours: string;
  }>({
    createdAtHours: "3",
  });

  const { data: { filesByUser } = { filesByUser: [] }, loading } = useFilesByUserQuery({
    variables: {
      input: {
        uploaderId: (brokerId as UUID) ?? user.id,
        createdAt: now - 1000 * 60 * 60 * Number(filesInput.createdAtHours),
      },
    },
  });

  return (
    <>
      <SetMetadata title="File Processing Jobs" />
      <ScrollPane>
        <SectionHeader className="relative">
          <SectionTitle>
            <h1>File Processing</h1>
            <NavigateBroker path="/jobs" allowAll={false} className="ml-auto w-auto" />
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <FilterButton />
              </DropdownMenuTrigger>
              <DropdownMenuContent>
                <DropdownMenuLabel>Uploaded in last...</DropdownMenuLabel>
                <DropdownMenuRadioGroup
                  value={filesInput.createdAtHours}
                  onValueChange={(createdAtHours) => setFilesInput((filesInput) => ({ ...filesInput, createdAtHours }))}
                >
                  <DropdownMenuRadioItem value="3">3 Hours</DropdownMenuRadioItem>
                  <DropdownMenuRadioItem value="12">12 Hours</DropdownMenuRadioItem>
                  <DropdownMenuRadioItem value="24">24 Hours</DropdownMenuRadioItem>
                  <DropdownMenuRadioItem value="48">48 Hours</DropdownMenuRadioItem>
                </DropdownMenuRadioGroup>
              </DropdownMenuContent>
            </DropdownMenu>
          </SectionTitle>
        </SectionHeader>
        {filesByUser[0] ? (
          <div className="divide-y">
            {filesByUser.map((file) => {
              const { id, insured, filename, fileProcessingJobs } = file;
              const running = fileProcessingJobs?.some((job) => job.status === "running");

              const Row = () => (
                <div className="bg-accent flex gap-1 h-10 items-center px-6 text-xs w-full">
                  {insured?.name}
                  <Icon icon="keyboard_arrow_right" />
                  <span className="truncate">{filename}</span>
                  <span className="flex items-center mr-auto">
                    {running ? <Spinner /> : <Icon icon="check_circle" className="filled text-muted-foreground" />}
                  </span>
                  <Icon icon="unfold_more" className="hidden group-data-[state=closed]:inline-flex" />
                  <Icon icon="unfold_less" className="hidden group-data-[state=open]:inline-flex" />
                </div>
              );

              return fileProcessingJobs ? (
                <Collapsible key={id} className="group">
                  <CollapsibleTrigger className="cursor-pointer group-data-[state=open]:sticky w-full top-0 z-1">
                    <Row />
                  </CollapsibleTrigger>
                  <CollapsibleContent>
                    <Grid className="grid-cols-[19rem_1fr_8rem]">
                      <GridRowHeader className="bg-accent group-data-[state=open]:sticky top-10 z-1">
                        <GridCell>Job ID</GridCell>
                        <GridCell>Pipeline</GridCell>
                        <GridCell className="text-right">Time</GridCell>
                      </GridRowHeader>
                      {fileProcessingJobs?.map((job) => (
                        <GridRow key={job.id}>
                          <GridCell className="gap-3 flex items-center">
                            <Icon
                              icon={jobsMetadata[job.status].icon}
                              className={cn("filled", jobsMetadata[job.status].className)}
                            />
                            {job.id}
                          </GridCell>
                          <GridCell>{job.pipeline}</GridCell>
                          <GridCell className="text-right tabular-nums">
                            {formatTimezoneDateString(job.createdAt, "MMM d hh:mm:ss aa")}
                          </GridCell>
                        </GridRow>
                      ))}
                    </Grid>
                    <Separator className="mb-px" />
                  </CollapsibleContent>
                </Collapsible>
              ) : (
                <Row />
              );
            })}
          </div>
        ) : (
          <EmptyState loading={loading} title={<h2>No file uploads were found.</h2>} />
        )}
      </ScrollPane>
    </>
  );
};

import { add, compareAsc, format, sub } from "date-fns";
import { FC, useMemo } from "react";
import { useFormContext } from "react-hook-form";

import { Group } from "@/components/group";
import { DialogDescription, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { FieldDatePicker } from "@/forms/fields/field-date-picker";
import { FieldToggleGroup } from "@/forms/fields/field-toggle-group";
import { FormSubmit } from "@/forms/form-submit";
import { formatDateString } from "src/utils/date";

interface SnoozeFormProps {
  desiredEffectiveDate: string;
}

export const SnoozeForm: FC<SnoozeFormProps> = ({ desiredEffectiveDate }) => {
  const { watch } = useFormContext();

  const now = useMemo(() => new Date(), []);
  const effectiveDate = useMemo(() => new Date(desiredEffectiveDate), [desiredEffectiveDate]);

  const oneDayFromNow = useMemo(() => add(now, { days: 1 }), [now]);
  const oneWeekFromNow = useMemo(() => add(now, { weeks: 1 }), [now]);
  const oneMonthFromNow = useMemo(() => add(now, { months: 1 }), [now]);

  const oneDayFromEffDate = useMemo(() => sub(effectiveDate, { days: 1 }), [effectiveDate]);
  const oneDayFromEffDateValid = compareAsc(oneDayFromEffDate, now) > 0;

  const threeDaysFromEffDate = useMemo(() => sub(effectiveDate, { days: 3 }), [effectiveDate]);
  const threeDaysFromEffDateValid = compareAsc(threeDaysFromEffDate, now) > 0;

  const sevenDaysFromEffDate = useMemo(() => sub(effectiveDate, { days: 7 }), [effectiveDate]);
  const sevenDaysFromEffDateValid = compareAsc(sevenDaysFromEffDate, now) > 0;

  const desiredSnoozeUntil = watch("desiredSnoozeUntil");

  return (
    <Group className="gap-6">
      <DialogHeader>
        <DialogTitle>Select Snooze Date</DialogTitle>
        <DialogDescription>Desired effective date: {formatDateString(desiredEffectiveDate)}</DialogDescription>
      </DialogHeader>

      <Group className="gap-2">
        <FieldDatePicker
          name="desiredSnoozeUntil"
          inputProps={{ calendarProps: { disabled: [{ before: now }] } }}
          suppressErrorMessage
        />

        <FieldToggleGroup
          name="desiredSnoozeUntil"
          inputProps={{ className: "grid grid-cols-3" }}
          options={[
            { label: "Snooze for 1D", value: oneDayFromNow.toISOString() },
            { label: "Snooze for 1W", value: oneWeekFromNow.toISOString() },
            { label: "Snooze for 1M", value: oneMonthFromNow.toISOString() },
            {
              label: "1D before Eff. Date",
              value: oneDayFromEffDate.toISOString(),
              itemProps: { disabled: !oneDayFromEffDateValid },
            },
            {
              label: "3D before Eff. Date",
              value: threeDaysFromEffDate.toISOString(),
              itemProps: { disabled: !threeDaysFromEffDateValid },
            },
            {
              label: "7D before Eff. Date",
              value: sevenDaysFromEffDate.toISOString(),
              itemProps: { disabled: !sevenDaysFromEffDateValid },
            },
          ]}
        />
      </Group>

      <FormSubmit>
        Snooze until{desiredSnoozeUntil ? ` ${format(new Date(desiredSnoozeUntil), "MMMM d, yyyy")}` : <>&hellip;</>}
      </FormSubmit>
    </Group>
  );
};

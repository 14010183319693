import { MutationFunctionOptions } from "@apollo/client";
import { useCallback } from "react";

import { useToast } from "@/hooks/use-toast";
import {
  BulkUpdateSegmentAppetiteProductsInput,
  BulkUpdateSegmentAppetiteProductsMutation,
  useBulkUpdateSegmentAppetiteProductsMutation,
} from "src/generated/graphql";

import { useSegment } from "./use-segment";

export const useBulkUpdateSegmentAppetiteProducts = () => {
  const { segment } = useSegment();
  const { toast } = useToast();

  const [bulkUpdateAppetiteProducts, { loading: isUpdatingAppetiteProducts }] =
    useBulkUpdateSegmentAppetiteProductsMutation({
      awaitRefetchQueries: true,
      onCompleted: () => toast({ title: "Appetite products updated" }),
      refetchQueries: ["Segment"],
    });

  const updateAppetiteProducts = useCallback(
    async (
      input: BulkUpdateSegmentAppetiteProductsInput,
      options?: Omit<MutationFunctionOptions<BulkUpdateSegmentAppetiteProductsMutation>, "variables">
    ) =>
      await bulkUpdateAppetiteProducts({
        variables: {
          input: {
            ...input,
            targetYield: input.targetYield ? input.targetYield / 100 : undefined,
            segmentId: segment!.id,
          },
        },
        ...options,
      }),
    [segment?.id]
  );

  return {
    updateAppetiteProducts,
    isUpdatingAppetiteProducts,
  };
};

import { SetMetadata } from "@/hooks/use-metadata";
import { MarketingPlanBankanColumn } from "src/generated/graphql";
import { InsuredColumn } from "./columns/insured-column";
import { MarketingPlanColumn } from "./columns/marketing-plan-column";
import { UserColumn } from "./columns/user-column";

const marketingPlanColumns = [
  MarketingPlanBankanColumn.UnassignedInformationGathering,
  MarketingPlanBankanColumn.UnassignedWaitingForCoverages,
  MarketingPlanBankanColumn.UnassignedOther,
];

export const DjBankan = () => (
  <>
    <SetMetadata title="DJ" />
    <UserColumn />
    <InsuredColumn />
    {marketingPlanColumns.map((column) => (
      <MarketingPlanColumn key={column} column={column} />
    ))}
  </>
);

export function removeSpaces(str: string) {
  return str.replaceAll(/\s/g, "");
}

const dollars = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  maximumFractionDigits: 0,
});

const dollarsAndCents = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  maximumFractionDigits: 2,
});

export const formatDollars = (premium?: number | null) => dollars.format(premium || 0);
export const formatDollarsAndCents = (premium?: number | null) => dollarsAndCents.format(premium || 0);

import { useNodes, type Node } from "@xyflow/react";
import { useMemo, type FC } from "react";
import { useFormContext } from "react-hook-form";
import { z } from "zod";

import { ButtonGroup } from "@/components/button-group";
import { Group } from "@/components/group";
import { useModal } from "@/components/modal-provider";
import { Icon } from "@/components/ui/icon";
import { Loading } from "@/components/ui/loading";
import { Separator } from "@/components/ui/separator";
import { FieldInput } from "@/forms/fields/field-input";
import { FieldSelect } from "@/forms/fields/field-select";
import { Form } from "@/forms/form";
import { FormReset } from "@/forms/form-reset";
import { FormSubmit } from "@/forms/form-submit";
import { useToast } from "@/hooks/use-toast";
import {
  CreatePipelineVersionInput,
  FileProcessingPipelineQuery,
  FileProcessorCategory,
  FileProcessorNodeType,
  useCheckUpstreamFileProcessorMutation,
  useCreateFilePipelineVersionMutation,
  useFileProcessingPipelineQuery,
} from "src/generated/graphql";
import { cn } from "src/utils";

import { processorCategoryToIconMap } from "../../file-processing-pipeline.constants";
import {
  convertPipelineDataToNodesAndEdges,
  findNodeById,
  getNodeIcon,
  getNodeLabel,
} from "../../file-processing-pipeline.helpers";

export interface AddProcessorFormValues {
  id: string;
  type: FileProcessorCategory;
  name: string;
  startPage?: string;
  endPage?: string;
  prompt?: string;
}

export interface AddProcessorFormProps {
  sourceNode: Node;
  targetNode?: Node;
}

const defaultValues = {
  id: "",
  type: FileProcessorCategory.Classifier,
  name: "",
  startPage: "",
  endPage: "",
  prompt: "",
};

export const AddProcessorFormContent: FC<AddProcessorFormProps> = ({ sourceNode, targetNode }) => {
  const formContext = useFormContext();
  const { closeModal } = useModal();

  const type = formContext.watch("type");
  const name = formContext.watch("name");
  const icon = processorCategoryToIconMap[type as FileProcessorCategory];

  const isValid = formContext.formState.isValid;
  const isError = Object.keys(formContext.formState.errors)?.length;

  return (
    <Group className="gap-6 mt-2 max-w-full">
      <div className="flex flex-col items-center text-sm bg-accent p-6 rounded-md border">
        <div className="z-50 -mt-6 -mb-1.5 relative flex items-center h-8 w-[1px] border-l border-input border-dashed after:content-[''] after:absolute after:left-1/2 after:bottom-0 after:-translate-x-1/2 after:size-3 after:bg-input after:rounded-full after:border-accent after:border-[3px]" />

        <div className="opacity-60 h-[36px] max-w-96 grow-0 flex gap-2 items-center bg-background text-muted-foreground shadow pl-2 pr-3 py-2 rounded">
          <Icon
            icon={getNodeIcon(sourceNode)}
            className="shrink-0 text-muted-foreground size-6 flex items-center justify-center border rounded-sm"
          />
          <div className="truncate">{getNodeLabel(sourceNode)}</div>
        </div>

        <div className="flex items-end justify-center z-50 -my-1.5 relative h-12 w-[1px] border-l border-input border-dashed before:content-[''] before:absolute before:left-1/2 before:top-0 before:-translate-x-1/2 before:size-3 before:bg-input before:rounded-full before:border-accent before:border-[3px] after:content-[''] after:absolute after:left-1/2 after:bottom-0 after:-translate-x-1/2 after:size-3 after:bg-input after:rounded-full after:border-accent after:border-[3px]">
          <Icon
            icon={isValid ? "check_circle" : isError ? "cancel" : "add_box"}
            className={cn("relative z-50 bg-accent p-2.5 text-input -mb-1", {
              "text-success filled": isValid,
              "text-destructive filled": isError,
            })}
          />
        </div>

        <div className="rounded-md border border-dashed border-input bg-accent p-2.5">
          <div
            className={cn(
              "relative z-50 max-w-96 h-10 flex gap-2 items-center bg-background border-none shadow pl-2 pr-3 py-2 rounded before:contents-[''] before:absolute before:-inset-[1px] before:rounded before:border before:border-input"
            )}
          >
            <Icon
              icon={icon || "database"}
              className={cn(
                "relative size-6 flex shrink-0 items-center justify-center border rounded-sm bg-primary text-primary-foreground filled"
              )}
            />
            <div className="truncate">{name.trim() || <span className="text-muted-foreground">Enter a name</span>}</div>
          </div>
        </div>

        {targetNode && (
          <>
            <div className="z-50 -mb-1.5 -mt-1.5 relative flex items-center h-12 w-[1px] border-l border-input border-dashed before:content-[''] before:absolute before:left-1/2 before:top-0 before:-translate-x-1/2 before:size-3.5 before:bg-input before:rounded-full before:border-accent before:border-[4px] after:content-[''] after:absolute after:left-1/2 after:bottom-0 after:-translate-x-1/2 after:size-3 after:bg-input after:rounded-full after:border-accent after:border-[3px]" />

            <div className="opacity-60 h-[36px] max-w-96 grow-0 flex gap-2 items-center bg-background  text-muted-foreground shadow pl-2 pr-3 py-2 rounded">
              <Icon
                icon={getNodeIcon(targetNode)}
                className="shrink-0 text-muted-foreground size-6 flex items-center justify-center border rounded-sm"
              />
              <div className="truncate">{getNodeLabel(targetNode)}</div>
            </div>

            <div className="z-50 -mt-1.5 -mb-6 relative flex items-center h-8 w-[1px] border-l border-input border-dashed before:content-[''] before:absolute before:left-1/2 before:top-0 before:-translate-x-1/2 before:size-3 before:bg-input before:rounded-full before:border-accent before:border-[3px]" />
          </>
        )}
      </div>

      <Group>
        <FieldSelect
          name="type"
          label="Type"
          onChange={() => formContext.formState.errors.id && formContext.trigger("id")}
          options={[
            FileProcessorCategory.Classifier,
            FileProcessorCategory.Splitter,
            FileProcessorCategory.Extractor,
            FileProcessorCategory.PromptExtractor,
            FileProcessorCategory.Redactor,
          ].map((type) => ({ label: type, value: type }))}
        />

        <FieldInput name="name" label="Name" placeholder="Enter a name" inputProps={{ autoComplete: "off" }} />

        <FieldInput
          name="id"
          label="File processor ID"
          placeholder="Enter the processor id"
          inputProps={{ autoComplete: "off" }}
        />

        {type === FileProcessorCategory.PromptExtractor && (
          <FieldInput
            name="prompt"
            label="Prompt"
            placeholder="Enter the prompt for extraction"
            inputProps={{ autoComplete: "off" }}
          />
        )}
      </Group>

      <div>
        <h4 className="text-base!">Page range</h4>
        <p className="text-muted-foreground text-sm mt-1.5">
          Enter the page range to use to limit the number of pages processed by this processor with each re-run.
        </p>
      </div>

      <Group direction="row">
        <FieldInput name="startPage" label="Start page" type="number" placeholder="Defaults to first page" />
        <FieldInput name="endPage" label="End page" type="number" placeholder="Defaults to last page" />
      </Group>

      <Separator />

      <ButtonGroup>
        <FormReset onClick={closeModal}>Cancel</FormReset>
        <FormSubmit>Add processor</FormSubmit>
      </ButtonGroup>
    </Group>
  );
};

export const AddProcessorForm: FC<AddProcessorFormProps> = (props) => {
  const { closeModal } = useModal();
  const { toast } = useToast();
  const { data, loading } = useFileProcessingPipelineQuery();
  const [checkUpstreamFileProcessor] = useCheckUpstreamFileProcessorMutation();

  const [createPipelineVersion] = useCreateFilePipelineVersionMutation({
    // TODO: We need to figure out the `optimisticResponse` for this mutation.
    // update: (cache, { data }) => {
    //   cache.writeQuery({
    //     query: FileProcessingPipelineDocument,
    //     data: { fileProcessingPipeline: data?.createPipelineVersion },
    //   });
    // },
  });

  const pipelineData = data?.fileProcessingPipeline;

  const { nodes } = useMemo(
    () => (pipelineData ? convertPipelineDataToNodesAndEdges(pipelineData) : { nodes: [] }),
    [pipelineData]
  );

  const validationSchema = z
    .object({
      id: z
        .string()
        .min(1, { message: "Please enter the processor ID." })
        .regex(/^[\da-z]{15,16}$/, { message: "The processor ID should be a 15-16 character alphanumeric string." })
        .refine(
          (value) => {
            const existingNode = nodes.find(
              (node) => node.type === FileProcessorNodeType.FileProcessor && node.data.id === value
            );

            return !existingNode;
          },
          {
            message: "There is already a processor with that id. Please enter a unique id.",
          }
        ),
      type: z.string().min(1, { message: "Please select a type." }),
      name: z
        .string()
        .min(1, { message: "Please enter a name for the processor" })
        .refine((value) => /^[\w- ]+$/.test(value ?? ""), {
          message: "You may only enter alphanumeric characters, underscores, dashes, and spaces.",
        })
        .refine(
          (value) => {
            const existingNode = nodes.find(
              (node) => node.type === FileProcessorNodeType.FileProcessor && node.data.name === value
            );

            return !existingNode;
          },
          {
            message: "There is already a processor with that name. Please enter a unique name.",
          }
        ),
      startPage: z
        .number({ coerce: true })
        .int()
        .refine((value) => Number(value) >= 0, { message: "Must be a positive number" })
        .optional(),
      endPage: z
        .number({ coerce: true })
        .int()
        .refine((value) => Number(value) >= 0, { message: "Must be a positive number" })
        .optional(),
      prompt: z.string().optional(),
    })
    .superRefine(async (values, context) => {
      if (values.startPage && values.endPage && values.startPage > values.endPage) {
        context.addIssue({
          code: z.ZodIssueCode.custom,
          message: "End page must be greater than or equal to start page",
          path: ["endPage"],
        });
      }

      if (values.type === FileProcessorCategory.PromptExtractor && !values.prompt) {
        context.addIssue({
          code: z.ZodIssueCode.custom,
          message: "Please enter a prompt for the prompt extractor",
          path: ["prompt"],
        });
      }
      if (values.type === FileProcessorCategory.PromptExtractor && !values.prompt) {
        context.addIssue({
          code: z.ZodIssueCode.custom,
          message: "Please enter a prompt for the prompt extractor",
          path: ["prompt"],
        });
      }

      if (values.type !== FileProcessorCategory.PromptExtractor) {
        const { data } = await checkUpstreamFileProcessor({ variables: { id: values.id } });

        if (!data?.checkUpstreamFileProcessor?.exists) {
          context.addIssue({
            code: z.ZodIssueCode.custom,
            message: "There is no upstream processor that matches that ID.",
            path: ["id"],
          });
        }
      }
    });

  const handleSubmit = async (values: AddProcessorFormValues) => {
    if (!pipelineData) {
      return;
    }

    const input = getAddProcessorInput(values, pipelineData, props);

    if (!input) {
      return;
    }

    await createPipelineVersion({
      variables: { input },
      refetchQueries: ["FileProcessingPipeline", "FileProcessor", "FileProcessingPipelineIssues"],
    });

    toast({ title: `${values.name} processor added` });
    closeModal();
  };

  if (!pipelineData && loading) {
    return <Loading />;
  }

  return (
    <Form validationSchema={validationSchema} onSubmit={handleSubmit} defaultValues={defaultValues}>
      <AddProcessorFormContent {...props} />
    </Form>
  );
};

export interface UseAddProcessorFormModalOptions {
  sourceNodeId?: string;
  targetNodeId?: string;
}

export const useAddProcessorFormModal = ({ sourceNodeId, targetNodeId }: UseAddProcessorFormModalOptions) => {
  const { openModal } = useModal();
  const nodes = useNodes();

  const sourceNode = findNodeById(sourceNodeId, nodes);
  const targetNode = findNodeById(targetNodeId, nodes);

  return {
    openAddProcessorForm: async () => {
      if (!sourceNodeId || !sourceNode) {
        return;
      }

      await openModal(() => <AddProcessorForm sourceNode={sourceNode} targetNode={targetNode} />, {
        type: "dialog",
        title: "Add a processor",
        description: (
          <>
            Add a processor{` `}
            {targetNode ? (
              <>
                between <strong>{getNodeLabel(sourceNode)}</strong> and <strong>{getNodeLabel(targetNode)}</strong>
              </>
            ) : (
              <>
                after <strong>{getNodeLabel(sourceNode)}</strong>
              </>
            )}
            .
          </>
        ),
      });
    },
  };
};

function getAddProcessorInput(
  values: AddProcessorFormValues,
  pipelineData: FileProcessingPipelineQuery["fileProcessingPipeline"],
  props: AddProcessorFormProps
): CreatePipelineVersionInput {
  const existingLabel = pipelineData.pipeline.transitions.find(
    (transition) =>
      transition.label === props.sourceNode.data.name && transition.sourceNodeName === props.sourceNode.data.category
  );

  const nodeToAdd = {
    id: values.id,
    category: values.type,
    name: values.name,
    startPage: Number(values?.startPage) ?? undefined,
    endPage: Number(values?.endPage) ?? undefined,
    ...(values.type === FileProcessorCategory.PromptExtractor && values.prompt ? { prompt: values.prompt } : {}),
  };

  const transitions = existingLabel
    ? pipelineData.pipeline.transitions.map((transition) => {
        if (
          transition.label === props.sourceNode.data.name &&
          transition.sourceNodeName === props.sourceNode.data.category
        ) {
          return {
            ...transition,
            destinationNodes: [...transition.destinationNodes, nodeToAdd],
          };
        }

        return transition;
      })
    : [
        ...pipelineData.pipeline.transitions,
        {
          sourceNodeName: props.sourceNode.data.category as string,
          label: props.sourceNode.data.name as string,
          destinationNodes: [nodeToAdd],
        },
      ];

  return {
    name: "FileUploadPipeline",
    pipeline: {
      initial: pipelineData.pipeline.initial,
      transitions,
    },
  };
}

import { coerceBoolean } from "@cp/toolkit";

export const adaptSearchParams = (params: URLSearchParams) => {
  const getParam = (key: string) => params.get(key) ?? undefined;

  return {
    agencyId: getParam("agencyId"),
    agentId: getParam("agentId"),
    term: getParam("term"),
    isTest: coerceBoolean(getParam("isTest")),
    effectiveDate: getParam("effectiveDate") ? Number.parseInt(getParam("effectiveDate")!) : undefined,
    brokerId: getParam("brokerId") === "null" ? null : getParam("brokerId") || undefined,
    page: Number(getParam("page")) || 1,
  };
};

import { FC } from "react";

import { useOpenForm } from "@/components/modal-provider";
import { Button, ButtonProps } from "@/components/ui/button";

import { useCreateSegmentRequirement } from "../../hooks/use-create-segment-requirement";
import {
  CreateSegmentRequirementFormDefaultValues,
  CreateSegmentRequirementFormSchema,
  SegmentRequirementFormFields,
} from "./segment-requirement-form";

export interface CreateSegmentRequirementButtonProps extends ButtonProps {}

export const CreateSegmentRequirementButton: FC<CreateSegmentRequirementButtonProps> = ({ children, ...props }) => {
  const { openForm } = useOpenForm();
  const { createSegmentRequirement } = useCreateSegmentRequirement();

  const handleClick = async () =>
    await openForm(<SegmentRequirementFormFields />, {
      title: "Add Requirement",
      defaultValues: CreateSegmentRequirementFormDefaultValues,
      validationSchema: CreateSegmentRequirementFormSchema,
      onSubmit: async (values) => await createSegmentRequirement(values),
    });

  return (
    <Button variant="outline" size="sm" {...props} onClick={handleClick}>
      {children || "Add Requirement"}
    </Button>
  );
};

import { ComponentProps, FC, forwardRef } from "react";

import { Icon } from "@/components/ui/icon";
import { useSegment } from "@/segments/hooks/use-segment";

export const SegmentMenuItemDelete: FC = forwardRef<HTMLDivElement, ComponentProps<"div">>((props, ref) => {
  const { deleteSegment } = useSegment();

  return (
    <div ref={ref} {...props} onClickCapture={deleteSegment}>
      <Icon icon="delete" className="text-sm" />
      <span>Delete segment</span>
    </div>
  );
});
SegmentMenuItemDelete.displayName = "SegmentMenuItemDelete";

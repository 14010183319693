import { Grid, GridCell, GridRow, GridRowHeader } from "@/components/grid";
import { Group } from "@/components/group";
import { SectionContent, SectionDescription, SectionHeader, SectionTitle } from "@/components/section";
import { Badge } from "@/components/ui/badge";
import { Separator } from "@/components/ui/separator";
import { AppetiteNoteFragment, ClassCodeFragment, useAppetiteNotesQuery } from "src/generated/graphql";
import { cn } from "src/utils";
import { useVertical } from "./components/use-vertical";

const displayCgl = (cgl: ClassCodeFragment) => `${cgl.classCode} ${cgl.description}`;

export const VerticalAppetite = () => {
  const { vertical } = useVertical();

  const { data: { appetiteNotes = [] } = {} } = useAppetiteNotesQuery({
    variables: { input: { isoCglCodes: vertical?.isoCglCodes.map((cgl) => cgl.classCode) } },
    skip: !vertical || vertical.isoCglCodes.length === 0,
  });

  return (
    <>
      <SectionHeader>
        <SectionTitle>
          <h3>Class Codes</h3>
        </SectionTitle>
      </SectionHeader>
      <SectionContent>
        <Pills items={vertical.isoCglCodes.map((cgl) => displayCgl(cgl))} />
      </SectionContent>
      <Separator />
      <AppetiteNotesTable appetiteNotes={appetiteNotes} verticalCgls={vertical.isoCglCodes.map((c) => c.classCode)} />
    </>
  );
};

const AppetiteNotesTable = ({
  appetiteNotes,
  verticalCgls,
}: {
  appetiteNotes: AppetiteNoteFragment[];
  verticalCgls: string[];
}) => {
  return (
    <>
      <SectionHeader>
        <SectionTitle>
          <h3>Appetite Notes</h3>
        </SectionTitle>
        {appetiteNotes.length === 0 && <SectionDescription>No Notes!</SectionDescription>}
      </SectionHeader>
      {appetiteNotes[0] && (
        <Grid className="grid-cols-3">
          <GridRowHeader>
            <GridCell>CGL(s)</GridCell>
            <GridCell>State(s)</GridCell>
            <GridCell>Line(s) Of Business</GridCell>
          </GridRowHeader>
          {appetiteNotes.map((note) => (
            <GridRow key={note.id} className="py-3 gap-3">
              <Pills items={note.isoCglCodes} matches={verticalCgls} />
              <Pills items={note.states} />
              <Pills items={note.lobs} />
              <GridCell className="col-span-full text-sm text-wrap">{note.note}</GridCell>
            </GridRow>
          ))}
        </Grid>
      )}
    </>
  );
};

const Pills = ({ items, matches }: { items: string[]; matches?: string[] }) => {
  const matched = new Set(matches);
  return (
    <Group type="flex" direction="row" className="flex-wrap gap-1">
      {items.map((item) => (
        <Badge
          variant="outline"
          className={cn(matched.size > 0 && matched.has(item) ? "bg-slate-500 text-white" : "bg-slate-300")}
          key={item}
        >
          {item}
        </Badge>
      ))}
    </Group>
  );
};

import { useNavigate } from "react-router-dom";

import { Group } from "@/components/group";
import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert";
import { Button } from "@/components/ui/button";
import { Icon } from "@/components/ui/icon";
import { FC } from "react";
import { BindHqUserFragment } from "src/generated/graphql";

export interface BindHQProducerAlertProps {
  bindHqProducer: BindHqUserFragment;
  insuredId: string;
  opportunityId: string;
  onReassignUserClick?: () => void;
}

export const BindHQProducerAlert: FC<BindHQProducerAlertProps> = ({
  bindHqProducer,
  insuredId,
  opportunityId,
  onReassignUserClick,
}) => {
  const navigate = useNavigate();

  const handleReassignUserClick = () => {
    navigate(`/insured/${insuredId}/plans/${opportunityId}`);
    onReassignUserClick?.();
  };

  return (
    <Alert>
      <Group className="grid-cols-[1rem_1fr] gap-2">
        <Icon icon="info" className="mt-0.5" />
        <div>
          <AlertTitle>
            BindHQ Producer: {bindHqProducer.firstName} {bindHqProducer.lastName}{" "}
            {bindHqProducer.email && <>({bindHqProducer.email.toLowerCase()})</>}
          </AlertTitle>
          <AlertDescription className="text-muted-foreground">
            <p>
              To change the BindHQ Producer, please update the assigned user on the{" "}
              {/* Wrapping in extra span to prevent an orphan period. */}
              <span className="whitespace-nowrap">
                <Button variant="link" onClick={handleReassignUserClick}>
                  marketing plan
                </Button>
                .
              </span>
            </p>
          </AlertDescription>
        </div>
      </Group>
    </Alert>
  );
};

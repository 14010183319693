import type { ComponentProps, FC } from "react";

import { Input } from "@/components/ui/input";
import { cn } from "src/utils";

import { useField } from "../hooks/use-field";
import { FieldBase, FieldBaseProps } from "./field-base";
import { FIELD_BORDERLESS_CLASS_NAME } from "./field.constants";

export type FieldInputProps = FieldBaseProps & {
  placeholder?: string;
  borderless?: boolean;
  type?: ComponentProps<typeof Input>["type"];
  onChange?: ComponentProps<typeof Input>["onChange"];
  onBlur?: ComponentProps<typeof Input>["onBlur"];
  inputProps?: ComponentProps<typeof Input>;
};

export const FieldInput: FC<FieldInputProps> = ({
  placeholder,
  borderless,
  type,
  onChange,
  onBlur,
  inputProps,
  readOnly,
  ...props
}) => {
  const { field, hasError } = useField({ name: props.name, disabled: props.disabled, inputProps });

  const handleChange: FieldInputProps["onChange"] = (event) => {
    field.onChange(event);
    onChange?.(event);
  };

  const handleBlur: FieldInputProps["onBlur"] = (event) => {
    field.onBlur();
    onBlur?.(event);
  };

  return (
    <FieldBase {...props}>
      <Input
        readOnly={readOnly}
        {...field}
        {...inputProps}
        type={type}
        placeholder={placeholder}
        onChange={handleChange}
        onBlur={handleBlur}
        className={cn(
          {
            "border-destructive": hasError && !field.disabled,
            [FIELD_BORDERLESS_CLASS_NAME]: borderless,
          },
          inputProps?.className
        )}
      />
    </FieldBase>
  );
};

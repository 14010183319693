import { getAccessToken } from "@cp/auth";

import { MyAccountDocument, MyAccountQuery, MyAccountQueryVariables } from "src/generated/graphql";
import { apolloClient } from "src/graphql/client";

export async function getAuthedUser() {
  const accessToken = getAccessToken();

  if (accessToken) {
    // fetch account if we have an access token
    const response = await apolloClient
      .query<MyAccountQuery, MyAccountQueryVariables>({
        query: MyAccountDocument,
        fetchPolicy: "cache-first",
      })
      .catch(() => ({ data: undefined }));

    return response.data?.myAccount;
  }

  return null;
}

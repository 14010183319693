import { useEffect, useRef, useState } from "react";

import { useAPIVersion } from "@/hooks/use-api-version";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "./ui/alert-dialog";

export const RefreshWarning = () => {
  const priorVersion = useRef<string | null>(null);
  const [open, setOpen] = useState(false);
  const { version } = useAPIVersion();

  useEffect(() => {
    if (priorVersion.current !== null && version !== priorVersion.current) {
      setOpen(true);
    }

    priorVersion.current = version;
  }, [version]);

  return (
    <AlertDialog open={open} onOpenChange={(open) => setOpen(open)}>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>This app has been updated.</AlertDialogTitle>
          <AlertDialogDescription>
            To avoid errors, we recommend <strong>refreshing</strong> this browser tab.
          </AlertDialogDescription>
        </AlertDialogHeader>
        <p className="italic text-sm">
          If you&apos;re in the middle of something, you can <strong>continue</strong>, but we recommend refreshing at
          your earliest convenience.
        </p>
        <AlertDialogFooter>
          <AlertDialogCancel>Continue</AlertDialogCancel>
          <AlertDialogAction onClick={() => window.location.reload()}>Refresh</AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

import { StatePill } from "@/components/state";
import { Avatar } from "@/components/ui/avatar";
import { PolicyFragment, useOpportunityQuery } from "src/generated/graphql";
import { PolicyDropdown } from "./policy-dropdown";

export const PolicyMenu = ({ policy }: { policy: PolicyFragment }) => {
  const { data: { opportunity } = {} } = useOpportunityQuery({
    variables: {
      id: policy?.opportunityId ?? "",
    },
    skip: !policy?.opportunityId,
    fetchPolicy: "cache-first",
  });

  if (!opportunity) {
    return null;
  }

  return (
    <>
      <Avatar user={opportunity.broker} />
      <StatePill state={policy.state} />
      <PolicyDropdown id={policy.id} state={policy.state} />
    </>
  );
};

import { Fragment } from "react";

import { Separator } from "@/components/ui/separator";
import { BankanPolicyColumnQuery } from "src/generated/graphql";
import { formatDateStringDistance } from "src/utils/date";
import { BankanCard, BankanCardHeader, BankanLink, BankanLinkFooter, BankanLinkText } from "./bankan-card";

type Group = BankanPolicyColumnQuery["bankanPolicyColumn"]["groupedPolicies"][number];

export const ExpiringPoliciesCard = ({ group }: { group: Group }) => {
  const { insuredId, insured, policies } = group;
  return (
    <BankanCard className="outline-destructive/50">
      <BankanCardHeader insured={insured} />
      {policies.map((policy) => {
        const { id, appetiteProduct, policyNumber, expirationDate } = policy;
        return (
          <Fragment key={id}>
            <Separator />
            <BankanLink to={`/insured/${insuredId}/policies/${id}`}>
              <BankanLinkText>
                <strong>{appetiteProduct.carrierName}: </strong>
                {appetiteProduct.carrierProductName}
              </BankanLinkText>
              <BankanLinkFooter>
                <div className="truncate">#{policyNumber}</div>
                {/* Remember: BindHQ dates do not include timestamps */}
                <div className="flex-none">Exp. in {formatDateStringDistance(expirationDate)}</div>
              </BankanLinkFooter>
            </BankanLink>
          </Fragment>
        );
      })}
    </BankanCard>
  );
};

import { Outlet } from "react-router";

import { ScrollPane } from "@/components/scroll-pane";
import { SetMetadata } from "@/hooks/use-metadata";

export const LabelManagement = () => (
  <>
    <SetMetadata title="Label Management" />
    <ScrollPane>
      <Outlet />
    </ScrollPane>
  </>
);

import { useEffect } from "react";

import {
  OnBrokerAssignmentQueueUpdatedDocument,
  OnBrokerAssignmentQueueUpdatedSubscription,
  OnBrokerAssignmentQueueUpdatedSubscriptionVariables,
  Roles,
  useAssignemntQueueQuery,
} from "src/generated/graphql";

export const AssignmentQueue = ({ role = Roles.Broker }: { role: Roles }) => {
  const { data: { brokerAssignmentQueue } = {}, subscribeToMore } = useAssignemntQueueQuery();

  useEffect(
    () =>
      subscribeToMore<OnBrokerAssignmentQueueUpdatedSubscription, OnBrokerAssignmentQueueUpdatedSubscriptionVariables>({
        document: OnBrokerAssignmentQueueUpdatedDocument,
        updateQuery: (prev, { subscriptionData }) =>
          subscriptionData.data
            ? {
                brokerAssignmentQueue: subscriptionData.data.brokerAssignmentQueueUpdated,
              }
            : prev,
      }),
    []
  );

  const assignmentQueue = brokerAssignmentQueue?.brokers ?? [];
  const filteredQueue = assignmentQueue.filter((broker) => broker.roles.includes(role));

  return (
    <div className="space-y-2">
      <h4>{role} Queue</h4>
      <ol className="list-decimal pl-4 space-y-1.5 text-sm">
        {filteredQueue?.map((broker) => (
          <li key={broker.id}>
            {broker.firstName} {broker.lastName}
          </li>
        ))}
      </ol>
    </div>
  );
};

import { Outlet, RouteObject } from "react-router-dom";

import { AlbyApproval } from "@/alby/alby-approval";
import { ErrorMessage } from "@/components/errors/error-message";
import { HasInternalRole } from "@/components/has-role";
import { Files } from "@/files/files";
import { InsuredProvider } from "@/hooks/use-insured";
import { MarketingPlanProvider } from "@/hooks/use-marketing-plan";
import { QuoteProvider } from "@/hooks/use-quote";
import { InsuredAlby } from "@/insured/insured-alby";
import { InsuredDetails } from "@/insured/insured-details/insured-details";
import { InsuredRoot } from "@/insured/insured-root";
import { Coverage } from "@/marketing-plans/coverage";
import { EscapeHatch } from "@/marketing-plans/escape-hatch";
import { History } from "@/marketing-plans/history";
import { MarketingPlan } from "@/marketing-plans/marketing-plan";
import { MarketingPlanDetails } from "@/marketing-plans/marketing-plan-details";
import { MarketingPlans } from "@/marketing-plans/marketing-plans";
import { SelectMarkets } from "@/marketing-plans/select-markets/select-markets";
import { New } from "@/new/new";
import { Policies } from "@/policies/policies";
import { Policy } from "@/policies/policy";
import { Bind } from "@/quotes/bind/bind";
import { Quote } from "@/quotes/quote";
import { Quotes } from "@/quotes/quotes";

const marketingPlanRoutes: RouteObject[] = [
  {
    path: "plans",
    errorElement: <ErrorMessage />,
    children: [
      { index: true, element: <MarketingPlans /> },
      {
        path: ":opportunityId",
        element: (
          <MarketingPlanProvider>
            <MarketingPlan />
          </MarketingPlanProvider>
        ),
        children: [
          {
            index: true,
            element: <MarketingPlanDetails />,
          },
          {
            path: "coverage",
            element: <Coverage />,
          },
          {
            path: "escape-hatch",
            element: <EscapeHatch />,
          },
          {
            path: "files",
            element: <Files />,
          },
          {
            path: "history",
            element: <History />,
          },
          {
            path: "alby",
            element: <InsuredAlby />,
          },
          {
            path: "markets",
            element: <SelectMarkets />,
          },
        ],
      },
    ],
  },
];

const quoteRoutes: RouteObject[] = [
  {
    path: "quotes",
    errorElement: <ErrorMessage />,
    children: [
      {
        index: true,
        element: <Quotes />,
      },
      {
        path: ":quoteId",
        element: (
          <QuoteProvider>
            <Quote />
          </QuoteProvider>
        ),
      },
      {
        path: ":quoteId/bind",
        element: (
          <QuoteProvider>
            <Bind />
          </QuoteProvider>
        ),
      },
    ],
  },
];

const policyRoutes: RouteObject[] = [
  {
    path: "policies",
    errorElement: <ErrorMessage />,
    children: [
      {
        index: true,
        element: <Policies />,
      },
      {
        path: ":policyId",
        element: <Policy />,
      },
    ],
  },
];

export const insuredRoutes: RouteObject[] = [
  {
    path: "insured",
    children: [
      {
        path: "new",
        element: <New />,
      },
      {
        id: "insuredRoot",
        path: ":insuredId",
        element: (
          <InsuredProvider>
            <InsuredRoot />
          </InsuredProvider>
        ),
        errorElement: <ErrorMessage />,
        children: [
          {
            index: true,
            element: <InsuredDetails />,
          },
          {
            element: (
              <HasInternalRole>
                <Outlet />
              </HasInternalRole>
            ),
            children: [
              {
                path: "alby",
                children: [
                  {
                    index: true,
                    element: <InsuredAlby />,
                  },
                  {
                    path: ":graphName/tool/:selectedTool",
                    element: <AlbyApproval />,
                  },
                ],
              },
            ],
          },
          {
            path: "files/:fileId?",
            element: <Files />,
          },
          ...marketingPlanRoutes,
          ...quoteRoutes,
          ...policyRoutes,
        ],
      },
    ],
  },
];

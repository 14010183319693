import { FC } from "react";
import { z } from "zod";

import { ButtonGroup } from "@/components/button-group";
import { ErrorMessage } from "@/components/errors/error-message";
import { Group } from "@/components/group";
import { DialogContent, DialogDescription, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { Loading } from "@/components/ui/loading";
import { Separator } from "@/components/ui/separator";
import { FieldInput } from "@/forms/fields/field-input";
import { FieldSelect } from "@/forms/fields/field-select";
import { Form } from "@/forms/form";
import { FormReset } from "@/forms/form-reset";
import { FormSubmit } from "@/forms/form-submit";
import { useToast } from "@/hooks/use-toast";
import {
  FileUploadFragment,
  File_Audience,
  useFindFileUploadQuery,
  useUpdateFileUploadMutation,
} from "src/generated/graphql";

import TagSelector from "./tag-selector";

export interface EditFileDialogProps {
  fileId: string;
  onClose: () => void;
}

export const EditFileDialog = ({ fileId, onClose }: EditFileDialogProps) => {
  const { data, loading } = useFindFileUploadQuery({ variables: { input: { id: fileId } } });

  const file = data?.findFile;

  return (
    <DialogContent className="sm:max-w-md">
      <DialogTitle>Edit file</DialogTitle>

      {loading && !file && <Loading label="Loading file data..." />}

      {!loading && !file && <ErrorMessage />}

      {file && <EditFileForm file={file} onClose={onClose} />}
    </DialogContent>
  );
};

export interface EditFileForm {
  file: FileUploadFragment;
  onClose: () => void;
}

export const EditFileForm: FC<EditFileForm> = ({ file, onClose }) => {
  const { toast } = useToast();
  const [update] = useUpdateFileUploadMutation();

  const validationSchema = z.object({
    filename: z.string().min(1, { message: "Invalid name" }),
    audience: z.nativeEnum(File_Audience),
  });

  const defaultValues = {
    filename: file.filename ?? "",
    audience: (file?.audience as File_Audience) ?? File_Audience.Internal,
  };

  const handleSubmit = async (values: z.infer<typeof validationSchema>) =>
    await update({
      variables: { input: { id: file.id, ...values } },
      onError: (e) => toast({ title: e.message }),
      onCompleted: () => toast({ title: `File updated` }),
      refetchQueries: ["FilesByLabel"],
    });

  return (
    <Form validationSchema={validationSchema} defaultValues={defaultValues} onSubmit={handleSubmit}>
      <Group>
        <DialogHeader>
          <h4>Edit permissions</h4>
          <DialogDescription>Select who should have access to this file</DialogDescription>
          <DialogDescription>
            The default is `All Users`. Please select `Internal Users Only` if you do not want the agent to have access
            to this file.
          </DialogDescription>
        </DialogHeader>
        <FieldSelect
          name="audience"
          placeholder="Audience"
          options={[
            { value: File_Audience.Internal, label: "Internal users only" },
            { value: File_Audience.External, label: "All users" },
          ]}
        />

        <Separator />

        <DialogHeader>
          <h4>Add tags</h4>
        </DialogHeader>
        <TagSelector fileId={file.id} />

        <Separator />

        <DialogHeader>
          <h4>Edit filename</h4>
        </DialogHeader>
        <FieldInput name="filename" placeholder="New file name" />

        <ButtonGroup>
          <FormReset onClick={onClose}>Close</FormReset>
          <FormSubmit />
        </ButtonGroup>
      </Group>
    </Form>
  );
};
